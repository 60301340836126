<script>
  import dayjs from "dayjs";
  import { getClient, query, mutate } from "svelte-apollo";
  import {
    Calendar,
    Button,
    AvatarIcon,
    EmptyButton,
    formatDateToUI,
    Health,
    toastNotifier,
    LoadingContent,
    Spinner
  } from "@saztrek/firefly";

  import {
    APPROVAL_REQUESTS,
    APPROVE_REQUEST,
    REJECT_REQUEST
  } from "../../../../gql";

  import { currentUser } from "../../../../stores/current_user";

  let startOfMonth = dayjs().startOf("month");
  let endOfMonth = dayjs().endOf("month");
  let startOfCalendar = startOfMonth.day(0);
  let endOfCalendar = endOfMonth.day(6);
  let totalCalendarItems = endOfCalendar.diff(startOfCalendar, "day") + 1;
  let currentMonth = dayjs().month();
  let currentYear = dayjs().year();

  const client = getClient();

  const statusMap = [
    {
      key: "PENDING",
      value: "Pending",
      color: "warning",
      sortValue: 1
    },
    {
      key: "APPROVED",
      value: "Approved",
      color: "secondary",
      sortValue: 2
    },
    {
      key: "DENIED",
      value: "Rejected",
      color: "danger",
      sortValue: 3
    },
    {
      key: "CANCELLED",
      value: "Cancelled",
      color: "unknown",
      sortValue: 4
    }
  ];

  const generateDates = () => {
    return Array(totalCalendarItems)
      .fill()
      .map((e, i) => {
        const dateToProcess = startOfCalendar.add(i, "day");

        const calendarItem = {
          // disabled: dateToProcess.month() !== currentMonth,
          day: dateToProcess.date(),
          // date: dateToProcess.format("MM-DD-YYYY"),
          date: dateToProcess.format("YYYY-MM-DD"),
          tasks: [],
          requests: [],

          generateTasks() {
            const currentObject = this;
            statusMap.forEach((status) => {
              const filterRequests = currentObject.requests.filter(
                (request) => request.status === status.key
              );

              if (filterRequests.length > 0) {
                currentObject.tasks = [
                  ...currentObject.tasks,
                  {
                    detail: `${filterRequests.length} ${status.value}`,
                    type: status.color
                  }
                ];
              }
            });
          }
        };

        return {
          ...calendarItem
        };
      });
  };

  let dates = generateDates();

  const approvalRequestsOp = query(client, {
    query: APPROVAL_REQUESTS,
    fetchPolicy: "network-only",
    variables: {
      findApprovalRequestInput: {
        requestTypeCodes: ["CALENDARLEAVE"],
        data: {
          key: "date",
          values: [dates[0].date, dates[dates.length - 1].date],
          filterMethod: "DATERANGE"
        }
      }
    }
  });

  let loading = true;

  let allRequests = [];
  let requests = [];

  const processResult = (response) => {
    dates = generateDates();
    const { edges } = response.data.approvalRequests;
    allRequests = [];
    edges.forEach((edge) => {
      const { node } = edge;
      const { data } = node;
      const requestDate = data.find((datum) => datum.key === "date").value;

      const dateObjectForCalendar = dates.find(
        (date) => date.date === requestDate
      );

      dateObjectForCalendar.requests = [
        ...dateObjectForCalendar.requests,
        {
          status: node.status
        }
      ];

      allRequests = [
        ...allRequests,
        {
          requestDate,
          code: node.code,
          status: node.status,
          firstName: data.find((datum) => datum.key === "employee").value,
          employeeCode: data.find((datum) => datum.key === "employeeCode")
            .value,
          employee: JSON.parse(
            data.find((datum) => datum.key === "employee").value
          ),
          remarks: data.find((datum) => datum.key === "remarks").value
        }
      ];
    });

    allRequests = allRequests.sort(
      (a, b) =>
        statusMap.find((status) => status.key === a.status).sortValue -
        statusMap.find((status) => status.key === b.status).sortValue
    );

    requests = allRequests;

    dates.forEach((date) => {
      if (date.requests.length > 0) {
        date.generateTasks();
      }
    });
    dates = [...dates];
    loading = false;
  };

  $approvalRequestsOp.then(processResult);

  let currentFilter = "";

  const dayClicked = (event) => {
    const { date } = { ...event.detail };
    if (currentFilter === date.date) {
      currentFilter = "";
      requests = allRequests;
    } else {
      currentFilter = date.date;
      requests = allRequests.filter(
        (request) => request.requestDate === currentFilter
      );
    }
  };

  const actionStatus = [];

  const approveRequest = async (request) => {
    try {
      actionStatus[request.code] = true;
      await mutate(client, {
        mutation: APPROVE_REQUEST,
        variables: {
          approverEmployeeCode: $currentUser.id,
          code: request.code
        }
      });
      toastNotifier.success(
        "Approved!",
        `Leave Request for ${request.employee.name.lastName}, ${
          request.employee.name.firstName
        } on ${formatDateToUI(request.requestDate, dayjs)}`,
        7000
      );

      approvalRequestsOp.refetch().then(processResult);
    } catch (error) {
      toastNotifier.danger("Failed to approve", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }

    actionStatus[request.code] = false;
  };

  const rejectRequest = async (request) => {
    try {
      actionStatus[request.code] = true;
      await mutate(client, {
        mutation: REJECT_REQUEST,
        variables: {
          approverEmployeeCode: $currentUser.id,
          code: request.code
        }
      });
      toastNotifier.success(
        "Rejected",
        `Leave Request for ${request.employee.name.lastName}, ${
          request.employee.name.firstName
        } on ${formatDateToUI(request.requestDate, dayjs)}`,
        7000
      );

      approvalRequestsOp.refetch().then(processResult);
    } catch (error) {
      toastNotifier.danger("Failed to Reject", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
    actionStatus[request.code] = false;
  };

  const regenerateDates = () => {
    totalCalendarItems = endOfCalendar.diff(startOfCalendar, "day") + 1;
    currentMonth = startOfMonth.month();
    currentYear = startOfMonth.year();

    dates = generateDates();
    loading = true;
    approvalRequestsOp
      .refetch({
        findApprovalRequestInput: {
          requestTypeCodes: ["CALENDARLEAVE"],
          data: {
            key: "date",
            values: [dates[0].date, dates[dates.length - 1].date],
            filterMethod: "DATERANGE"
          }
        }
      })
      .then(processResult);
  };

  const nextMonth = () => {
    startOfMonth = startOfMonth.add(1, "month");
    startOfCalendar = startOfMonth.day(0);
    endOfMonth = startOfMonth.endOf("month");
    endOfCalendar = endOfMonth.day(6);

    regenerateDates();
  };

  const previousMonth = () => {
    startOfMonth = startOfMonth.subtract(1, "month");
    startOfCalendar = startOfMonth.day(0);
    endOfMonth = startOfMonth.endOf("month");
    endOfCalendar = endOfMonth.day(6);

    regenerateDates();
  };

  const monthChanged = (event) => {
    const { month } = { ...event.detail };
    if (month > currentMonth) {
      startOfMonth = startOfMonth.add(month - currentMonth, "month");
    } else {
      startOfMonth = startOfMonth.subtract(currentMonth - month, "month");
    }

    startOfCalendar = startOfMonth.day(0);
    endOfMonth = startOfMonth.endOf("month");
    endOfCalendar = endOfMonth.day(6);

    regenerateDates();
  };

  const yearChanged = (event) => {
    const { year } = { ...event.detail };
    if (year > currentYear) {
      startOfMonth = startOfMonth.add(year - currentYear, "year");
    } else {
      startOfMonth = startOfMonth.subtract(currentYear - year, "year");
    }

    startOfCalendar = startOfMonth.day(0);
    endOfMonth = startOfMonth.endOf("month");
    endOfCalendar = endOfMonth.day(6);

    regenerateDates();
  };
</script>

<div class="w-full flex flex-row justify-between -m-2 pt-6">
  <div class="w-2/3 px-2">
    <Calendar
      {loading}
      {dates}
      month={currentMonth}
      year={currentYear}
      on:dayClicked={dayClicked}
      on:nextMonth={nextMonth}
      on:previousMonth={previousMonth}
      on:monthChanged={monthChanged}
      on:yearChanged={yearChanged} />
  </div>
  <div class="w-1/3 px-2">

    <div
      class="shadow rounded px-4 pb-4 pt-0 h-full overflow-y-auto"
      style="max-height: 746px; min-height: 746px;">
      <div class="px-6 pt-4 pb-2 sticky top-0 bg-white">
        <div class="flex flex-row justify-between items-center">
          <div>
            <div class="font-bold text-lg">Requests</div>
            <div class="italic text-sm">
              {#if currentFilter === ''}
                All Requests for the Month
              {:else}Filtered on: {currentFilter}{/if}
            </div>

          </div>
          <div class="w-8 h-8">
            <Button icon="refresh" text="" size="s" />
          </div>
        </div>
      </div>
      <div class="list pb-2 pl-6 pr-1">
        {#if !loading}
          {#each requests as request}
            <div class="flex flex-row items-start justify-between py-1">
              <div class="flex flex-row w-6/12 items-center">
                <div class="pr-2">
                  <AvatarIcon
                    size="l"
                    firstName={request.employee.name.firstName}
                    lastName={request.employee.name.lastName} />

                </div>
                <div class="flex flex-col">
                  <div class="text-primary">
                    {request.employee.name.firstName}
                    {request.employee.name.lastName}
                  </div>
                  <div class="text-xs ">
                    <!-- {getEmployeeObjectFromEdge(edge).employeeNumber} -->
                    {formatDateToUI(request.requestDate, dayjs)}
                  </div>
                  <div class="text-xs italic">
                    <!-- {getEmployeeObjectFromEdge(edge).employeeNumber} -->
                    {request.remarks}
                  </div>

                </div>
                <!-- <div class="text-xs font-bold self-start">
              {getKeyValueFromData(edge, 'date')}
            </div> -->
              </div>
              <div class="flex flex-row w-4/12 items-center justify-center">
                {#if request.status === 'PENDING'}
                  {#if !actionStatus[request.code]}
                    <EmptyButton
                      text="Approve"
                      color="primary"
                      size="tiny"
                      on:click={approveRequest(request)} />
                    <EmptyButton
                      text="Reject"
                      color="danger"
                      size="tiny"
                      on:click={rejectRequest(request)} />
                  {:else}
                    <div
                      class="flex flex-row self-center text-center w-full
                      justify-center">
                      <Spinner size="large" />
                    </div>
                  {/if}
                {/if}
                {#if request.status === 'APPROVED'}
                  <Health status="healthy">
                    <span class="text-secondary">Approved</span>
                  </Health>
                {/if}
                {#if request.status === 'DENIED'}
                  <Health status="failure">
                    <span class="text-danger">Rejected</span>
                  </Health>
                {/if}
                {#if request.status === 'CANCELLED'}
                  <Health status="unknown">
                    <span class="text-grayText">Cancelled</span>
                  </Health>
                {/if}
              </div>
            </div>
          {/each}
        {:else}
          <div class="w-full pt-10 px-6">
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
          </div>
        {/if}
      </div>

    </div>

  </div>
</div>
