<script>
  import { Auth, Functions } from "../../../config/firebase";
  import {
    DancingMermade,
    TextField,
    Button,
    toastNotifier,
    ToastList
  } from "@saztrek/firefly";

  export let currentRoute;
  let loading = true;

  const mode = currentRoute.queryParams.mode;
  const actionCode = currentRoute.queryParams.oobCode;
  const continueUrl = currentRoute.queryParams.continueUrl;
  let accountEmail;
  let newPassword;
  let errorCode;
  let errorMessage;
  let success = false;
  let resetActionHappening = false;

  const handleResetPassword = async () => {
    Auth.verifyPasswordResetCode(actionCode)
      .then((email) => {
        accountEmail = email;
        loading = false;
      })
      .catch((error) => {
        loading = false;
        errorCode = error.code;
        errorMessage =
          "Request for password reset has expired, please try requesting again from the MERMADE mobile application.";
      });
  };

  if (mode === "resetPassword") {
    handleResetPassword();
  }

  const resetPassword = () => {
    if (newPassword.length < 6) {
      console.log(toastNotifier);
      toastNotifier.danger(
        "Error",
        `Minimum password length should be greater than 6 characters`,
        7000
      );
      return;
    }

    resetActionHappening = true;
    Auth.confirmPasswordReset(actionCode, newPassword)
      .then(() => {
        const userDoneReset = Functions.httpsCallable("userDoneReset");

        userDoneReset(accountEmail)
          .then(() => {
            success = true;
          })
          .catch((error) => {
            errorCode = error.code;
            errorMessage =
              "We could not update your user info. Please contact the administrator";
          });
      })
      .catch((error) => {
        errorCode = error.code;
        errorMessage =
          "Request for password reset has expired, please try requesting again from the MERMADE mobile application.";
      });
  };
</script>

<div class="w-full h-full">
  <div class="w-full h-full flex items-center justify-center px-4">
    {#if loading}
      <DancingMermade />
    {:else if errorCode}
      <div class="flex flex-col">
        <h1 class="text-lg font-semibold pb-2">Something went wrong! :'(</h1>
        <div>{errorMessage}</div>
      </div>
    {:else if success}
      <div class="flex flex-col">
        <h1 class="text-lg font-semibold pb-2">Success!</h1>
        <div>
          Password has been successfully reset. You may now login in with your
          new password.
        </div>
      </div>
    {:else}
      <div class="flex flex-col justify-between">
        <div class="pb-2">Reset password for: {accountEmail}</div>
        <div class="pb-8">
          <TextField
            bind:textValue={newPassword}
            placeholder="Type Password Here"
            type="password"
            label="New Password" />
        </div>
        <div class="pt-4">
          <Button
            text="Reset"
            color="primary"
            filled={true}
            on:click={resetPassword}
            loading={resetActionHappening} />
        </div>
      </div>
    {/if}
  </div>

</div>
