<script>
  import SKUHeader from "./EDGARSKUHeader.svelte";

  export let sku;
  export let showWeekSupply = false;
  export let showSuggestedOrder = false;
  export let hasDeliveryChanges = false || sku.hasDeliveryChanges;
  export let hasEndingChanges = false || sku.hasEndingChanges;
  export let hasBadOrderChanges = false || sku.hasBadOrderChanges;
  export let hasTransferInChanges = false || sku.hasTransferInChanges;
  export let hasTransferOutChanges = false || sku.hasTransferOutChanges;
  export let hasAdjustmentChanges = false || sku.hasAdjustmentChanges;
</script>

<div
  class="pb-2 h-full border-l-8 rounded"
  class:border-secondary={!sku.noData}
  class:border-warning={sku.noData}>
  <div class="h-full">
    <SKUHeader
      code={sku.code}
      name={sku.name}
      offtakeUnits={sku.offtakeUnits}
      totalBeginningUnits={sku.totalBeginningUnits}
      noData={sku.noData} />

    <div class="flex flex-col py-2 mx-3 bg-white text-tableText justify-center">

      <div
        class="grid grid-cols-3 gap-4 w-full px-1 py-2"
        style="backface-visibility: hidden;">

        <div
          class="flex flex-col text-center text-secondary"
          class:bg-highlightYellow={hasDeliveryChanges}>
          <div class="font-semibold">{sku.totalDeliveryUnits}</div>
          <div class="text-xs ">Delivery</div>
        </div>
        <div
          class="flex flex-col text-center text-danger"
          class:bg-highlightYellow={hasEndingChanges}>
          <div class="font-semibold">{sku.totalEndingUnits}</div>
          <div class="text-xs ">Ending</div>
        </div>
        <div
          class="flex flex-col text-center text-danger"
          class:bg-highlightYellow={hasBadOrderChanges}>
          <div class="font-semibold">{sku.totalBadOrderUnits}</div>
          <div class="text-xs ">Bad Order</div>
        </div>
        <div
          class="flex flex-col text-center text-secondary"
          class:bg-highlightYellow={hasTransferInChanges}>
          <div class="font-semibold">{sku.totalTransferInUnits}</div>
          <div class="text-xs ">STI</div>
        </div>
        <div
          class="flex flex-col text-center text-danger"
          class:bg-highlightYellow={hasTransferOutChanges}>
          <div class="font-semibold">{sku.totalTransferOutUnits}</div>
          <div class="text-xs ">STO</div>
        </div>
        <div
          class="flex flex-col text-center text-secondary"
          class:bg-highlightYellow={hasAdjustmentChanges}>
          <div class="font-semibold">{sku.totalAdjustmentUnits}</div>
          <div class="text-xs ">Adjustment</div>
        </div>
      </div>

    </div>

    <div
      class="flex flex-row px-4 pb-2 text-xs border-t border-darkerGray
      items-center h-16"
      class:justify-between={showWeekSupply || showSuggestedOrder}
      class:justify-center={!showWeekSupply && !showSuggestedOrder}>
      <div
        class="border-r border-darkerGray px-2 w-1/2 flex flex-row
        justify-between items-center"
        class:hidden={!showWeekSupply}>
        <div>Week Supply</div>
        <div class="">4</div>
      </div>
      <div
        class="border-r border-darkerGray px-2 w-1/2 flex flex-row
        justify-between items-center"
        class:hidden={!showSuggestedOrder}>
        <div>Suggested Order</div>
        <div class="">4</div>
      </div>
      <div class="px-2 w-1/2 flex flex-row justify-evenly">
        <div>UOM</div>
        <div class="font-bold">{sku.unitCount}</div>
      </div>
    </div>

  </div>

</div>
