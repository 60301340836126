<script>
  import dayjs from "dayjs";
  import {
    Icon,
    LoadingContent,
    // TextField,
    Button,
    EmptyButton,
    toastNotifier
  } from "@saztrek/firefly";

  import { getClient, query } from "svelte-apollo";

  import { exportToCSV } from "../../../../utilities/export";

  import {
    NEMO_SHEETS,
    NEMO_SHEETS_LITE,
    BRANCHES,
    GET_NEMO_SHEET
  } from "../../../../gql";
  import {
    dateFormatToServer,
    dateFormatToUI
  } from "../../../../utilities/dateFormatter";

  // import { nearestEndDay } from "../../../../utilities/edgarUtils";

  import BranchList from "../components/NEMOBranchList.svelte";

  const isDaily = false;
  // const daysToIncrement = 1;

  //   import CardView from "./CardView.svelte";
  import TableView from "../components/TableView.svelte";

  import { getTag } from "../../../../utilities/common";

  let currentDate = dayjs().date();

  // let currentEndDay = nearestEndDay;

  let currentStartDay =
    currentDate >= 21 || currentDate <= 5 ? dayjs().date(21) : dayjs().date(6);
  let currentEndDay =
    currentDate >= 21 || currentDate <= 5
      ? dayjs().add(1, "month").date(5)
      : dayjs().date(20);

  const client = getClient();

  const sheetsOp = query(client, {
    query: NEMO_SHEETS_LITE,
    // fetchPolicy: "cache-and-network",
    variables: {
      findNEMOSheetInput: {
        sheetContext: {
          startDate: dateFormatToServer(currentStartDay.toDate())
          // endDate: dateFormatToServer(currentEndDay.toDate())
        }
      }
    }
  });

  const branchesOp = query(client, {
    query: BRANCHES
  });

  let singleSheetOp;
  let allSheetsOp;

  let skus = [];

  let currentSheetCode = "";
  let currentBranch;

  const processBranches = (sheetsResult, branchList) => {
    if (!branchList.data.branches) {
      return [];
    }

    const branchData = branchList.data;
    const sheetsData = sheetsResult.data;

    const { branches } = branchData;
    const { nemoSheets } = sheetsData;

    const returnData = branches.edges.map((edge) => {
      const { node } = edge;
      const branchCode = node.code;

      const nemoSheet = nemoSheets.edges.find(
        (nemoEdge) => nemoEdge.node.branch.code === branchCode
      );

      return {
        code: node.code,
        name: node.name,
        nemoSheet
      };
    });

    return returnData;
  };

  const processSKUEntries = (response) => {
    return response.nemoSheet.skus.edges.map((skuEdge) => {
      const { node } = skuEdge;
      const { details, entries } = node;
      const { tags } = details;
      const { timestamps } = response.nemoSheet;

      const dateCreated =
        timestamps && timestamps.length > 0
          ? dateFormatToServer(
              dayjs(timestamps[timestamps.length - 1]).toDate()
            )
          : "";
      const dateSynced = dateCreated;

      const segment = getTag(tags, "segment");

      return entries.map((entry) => {
        const { data, computed } = entry;
        return {
          code: details.code,
          name: details.name,
          unitCount: details.unitCount,
          core: details.core ? "Core" : "Regular",
          branchCode: response.nemoSheet.branch.code,
          branchName: response.nemoSheet.branch.name,
          brandCode: details.brand.code,
          brandName: details.brand.name,
          categoryCode: details.category.code,
          categoryName: details.category.name,
          chainCode: response.nemoSheet.branch.chain.code,
          chainName: response.nemoSheet.branch.chain.name,
          updatedBy: `${response.nemoSheet.latestCollaborator.name.firstName} ${response.nemoSheet.latestCollaborator.name.lastName}`,
          segment: segment ? segment.value : "",
          expiryDate: data.expiryDate,
          pulloutDate: dateFormatToServer(
            dayjs(data.expiryDate).subtract(7, "day")
          ),
          nearExpiryContainers: data.nearExpiryContainers,
          nearExpiryUnits: data.nearExpiryUnits,
          totalNearExpiryUnits: computed.totalNearExpiryUnits,
          dateCreated,
          dateSynced
        };
      });
    });
  };

  let originalSKUs;
  let loadingSKUs = false;

  const sheetsSKUCache = new Map();

  const branchSelected = (event) => {
    const { branch } = { ...event.detail };

    skus = [];
    loadingSKUs = true;

    if (!branch.nemoSheet) {
      currentSheetCode = null;

      loadingSKUs = false;
    } else {
      currentSheetCode = branch.nemoSheet.node.code;
      currentBranch = branch;

      const cachedSKUs = sheetsSKUCache.get(currentSheetCode);

      if (cachedSKUs) {
        skus = cachedSKUs;
        originalSKUs = skus;
        loadingSKUs = false;
      } else {
        singleSheetOp = query(client, {
          query: GET_NEMO_SHEET,
          variables: {
            code: currentSheetCode
          }
        });

        $singleSheetOp.then((response) => {
          skus = processSKUEntries(response.data).flat();
          originalSKUs = skus;
          loadingSKUs = false;
          sheetsSKUCache.set(currentSheetCode, [...skus]);
        });
      }
    }
  };

  let searchSKUCode;
  let searchSKUName;

  $: if (searchSKUCode || searchSKUName) {
    if (searchSKUCode) {
      skus = skus.filter(
        (sku) =>
          searchSKUCode !== "" &&
          sku.code.toUpperCase().indexOf(searchSKUCode.toUpperCase()) > -1
      );
    }

    if (searchSKUName) {
      skus = skus.filter(
        (sku) =>
          searchSKUName !== "" &&
          sku.name.toUpperCase().indexOf(searchSKUName.toUpperCase()) > -1
      );
    }
  } else if (searchSKUCode === "" && searchSKUName === "") {
    skus = originalSKUs;
  }

  const previousCoverage = () => {
    currentSheetCode = "";
    skus = [];

    if (currentDate >= 21 || currentDate <= 5) {
      currentDate = 6;
      currentStartDay = currentStartDay.date(6);
      currentEndDay = currentStartDay.date(20);
    } else {
      currentDate = 21;
      currentStartDay = currentStartDay.subtract(1, "month").date(21);
      currentEndDay = currentStartDay.add(1, "month").date(5);
    }
  };

  const nextCoverage = () => {
    currentSheetCode = "";
    skus = [];

    if (currentDate >= 21 || currentDate <= 5) {
      currentDate = 6;
      currentStartDay = currentStartDay.add(1, "month").date(6);
      currentEndDay = currentStartDay.date(20);
    } else {
      currentDate = 21;
      currentStartDay = currentStartDay.date(21);
      currentEndDay = currentStartDay.add(1, "month").date(5);
    }
  };

  $: sheetsOp.refetch({
    findDONSheetInput: {
      inventoryDate: dateFormatToServer(currentStartDay.toDate())
    }
  });

  //   let isCardView = true;

  //   const toggleView = () => {
  //     isCardView = !isCardView;
  //   };

  const exportCurrentSheet = () => {
    const cols = [
      `"Updated By"`,
      `"Category Code"`,
      `"Category Name"`,
      `"Segment"`,
      `"Brand Code"`,
      `"Brand Name"`,
      `"SKU Name"`,
      `"SKU Code"`,
      `"Expiry Date"`,
      `"Pullout Date"`,
      `"Near Expiry Containers"`,
      `"Near Expiry Units"`,
      `"Total Near Expiry Units"`,
      `"Branch Code"`,
      `"Branch Name"`,
      `"Chain Code"`,
      `"Chain Name"`,
      `"Date Created"`,
      `"Date Synced"`
    ]
      .join(",")
      .concat("\n");

    const rows = skus
      .map(
        (node) =>
          `"${node.updatedBy}","${node.categoryCode}","${node.categoryName}","${node.segment}","${node.brandCode}","${node.brandName}","${node.name}","${node.code}","${node.expiryDate}","${node.pulloutDate}","${node.nearExpiryContainers}","${node.nearExpiryUnits}","${node.totalNearExpiryUnits}","${node.branchCode}","${node.branchName}","${node.chainCode}","${node.chainName}","${node.dateCreated}","${node.dateSynced}"`
      )
      .join("\r\n");

    exportToCSV(
      cols,
      rows,
      `NEMO Sheets for ${dateFormatToUI(currentStartDay)}`
    );
  };

  let exportAllLoading = false;

  const exportAll = () => {
    exportAllLoading = true;
    allSheetsOp = query(client, {
      query: NEMO_SHEETS,
      variables: {
        findNEMOSheetInput: {
          sheetContext: {
            startDate: dateFormatToServer(currentStartDay.toDate()),
            endDate: dateFormatToServer(currentEndDay.toDate())
          }
        }
      }
    });

    $allSheetsOp
      .then((response) => {
        let allSheets = [];

        const { data } = response;
        const { nemoSheets } = data;
        const { edges } = nemoSheets;

        edges.forEach((branchSheet) => {
          allSheets = [
            ...allSheets,
            processSKUEntries({ nemoSheet: branchSheet.node }).flat()
          ];
        });

        if (allSheets.length === 0) {
          exportAllLoading = false;
          toastNotifier.danger(
            "No Sheets Found!",
            `There are no EDGAR Sheets in this coverage`,
            7000
          );
        } else {
          const cols = [
            `"Branch Code"`,
            `"Branch Name"`,
            `"Chain Code"`,
            `"Chain Name"`,
            `"Updated By"`,
            `"Category Code"`,
            `"Category Name"`,
            `"Segment"`,
            `"Brand Code"`,
            `"Brand Name"`,
            `"SKU Name"`,
            `"SKU Code"`,
            `"Expiry Date"`,
            `"Pullout Date"`,
            `"Near Expiry Containers"`,
            `"Near Expiry Units"`,
            `"Total Near Expiry Units"`,

            `"Date Created"`,
            `"Date Synced"`
          ]
            .join(",")
            .concat("\n");

          const flattenedSheets = allSheets.flat();

          const rows = flattenedSheets
            .map(
              (node) =>
                `"${node.branchCode}","${node.branchName}","${node.chainCode}","${node.chainName}","${node.updatedBy}","${node.categoryCode}","${node.categoryName}","${node.segment}","${node.brandCode}","${node.brandName}","${node.name}","${node.code}","${node.expiryDate}","${node.pulloutDate}","${node.nearExpiryContainers}","${node.nearExpiryUnits}","${node.totalNearExpiryUnits}","${node.dateCreated}","${node.dateSynced}"`
            )
            .join("\r\n");

          exportToCSV(
            cols,
            rows,
            `NEMO Sheets for ${dateFormatToUI(currentStartDay)}`
          );

          exportAllLoading = false;

          toastNotifier.success(
            "Success!",
            `Export successful, waiting for download.`,
            7000
          );
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        toastNotifier.danger(
          "Something went wrong!",
          `Export failed, please contact the administrator`,
          7000
        );
      });
  };
</script>

<div class="m-0 pb-3 text-base flex flex-row items-center justify-center">
  <div class="w-2/12">
    <Button
      text="Export All"
      on:click={exportAll}
      color="secondary"
      loading={exportAllLoading} />
  </div>
  <div class="justify-center w-8/12 flex flex-row">
    <div>
      <EmptyButton
        icon="arrowLeft"
        text=""
        size="tiny"
        on:click={previousCoverage} />
    </div>
    <div class="w-56 text-center">
      {#if !isDaily}
        {dateFormatToUI(currentStartDay.toDate())} to {dateFormatToUI(currentEndDay.toDate())}
      {:else}{dateFormatToUI(currentStartDay.toDate())}{/if}
    </div>
    <div>
      <EmptyButton
        icon="arrowRight"
        text=""
        size="tiny"
        on:click={nextCoverage} />
    </div>
  </div>
  <div
    class="w-1/12 flex flex-row justify-end"
    class:justify-between={currentSheetCode !== '' && !loadingSKUs}>
    <!-- <Button
      text={isCardView ? 'List View' : 'Card View'}
      on:click={toggleView} /> -->
    {#if currentSheetCode !== '' && !loadingSKUs}
      <Button color="secondary" text="Export" on:click={exportCurrentSheet} />
    {/if}
  </div>
</div>

<div
  class="flex flex-row w-full md:-mx-2 justify-between"
  style="min-height: 945px; max-height: 945px; height: 945px;">
  <div class="w-3/12 lg:3/12 px-2 h-full">
    {#await $sheetsOp}
      <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
      </div>
    {:then sheetsResult}
      {#if sheetsResult.loading}
        <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
        </div>
      {:else}
        {#await $branchesOp}
          <div
            class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
          </div>
        {:then branchesResult}
          <BranchList
            branches={processBranches(sheetsResult, branchesResult)}
            on:branchSelected={branchSelected} />
        {/await}
      {/if}

    {/await}
  </div>
  <div class="w-9/12 lg:4/12 pt-2 px-2 h-full border-lightGray rounded border">
    {#if currentSheetCode}
      <div class="w-full flex flex-row pb-4 px-2 h-24">
        <div class="flex flex-col justify-between w-1/2">
          <div class="flex flex-col font-bold">
            <div class="text-sm">{currentSheetCode}</div>
            <div class="text-xs">
              {currentBranch.code} - {currentBranch.name}
            </div>
          </div>
        </div>
        <div class="flex-row flex w-1/2">
          <!-- <div class="flex flex-col w-1/2 text-xs">
            <div class="font-bold">Inventory Dates</div>
            <div class="text-xs h-12 overflow-y-auto">
              {#each currentBranch.edgarSheet.inventoryDates as inventoryDate}
                <div>{dateFormatToUI(inventoryDate)}</div>
              {/each}
            </div>
          </div> -->
          <!-- <div class="flex flex-col w-1/2 text-xs">
            <div class="font-bold ">Delivery Dates</div>
            <div class="text-xs h-12 overflow-y-auto">
              {#each currentBranch.edgarSheet.deliveryDates as deliveryDate}
                <div>{dateFormatToUI(deliveryDate)}</div>
              {/each}
            </div>
          </div> -->

        </div>
      </div>
    {/if}
    <!-- {#if isCardView}
      <CardView {skus} {loadingSKUs} />
    {:else}
      <TableView {skus} {loadingSKUs} />
    {/if} -->

    <TableView {skus} {loadingSKUs} />

    <div class="h-full w-full flex flex-col items-center justify-center">
      <Icon size="l" type="table" />
      <div class="pt-2">
        {#if currentSheetCode === ''}
          Choose a branch on the left.
        {:else}No Sheet created yet for this branch.{/if}
      </div>
    </div>

  </div>
</div>
