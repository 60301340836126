<script>
  import { createEventDispatcher } from "svelte";
  import { navigateTo } from "svelte-router-spa";
  import { Link, TextField, SelectField } from "@saztrek/firefly";
  import { CHAINS } from "../../../../gql";

  import CRUDPage from "../../../components/CRUDPage.svelte";
  import { selectOptions } from "../../../components/defaultObjects";

  const dispatch = createEventDispatcher();

  const columns = [
    {
      field: "code",
      name: "Code",
      renderComponent: Link,
      resolveProps: (data) => {
        return {
          href: `chains/edit/${data.code}`
        };
      }
    },
    {
      field: "name",
      name: "Name",
      dataType: "string"
    },
    {
      field: "account",
      name: "Account",
      renderComponent: Link,
      resolveProps: (value) => {
        return {
          href: `accounts/edit/${value}`
        };
      }
    }
  ];
  const tableActions = [
    {
      icon: "pencil",
      title: "Edit",
      action: (data) => {
        navigateTo(`chains/edit/${data.code}`);
      }
    },
    {
      icon: "trash",
      title: "Inactivate",
      action: (data) => {
        dispatch("inactivateChain", { code: data.code });
      }
    }
  ];

  const findObjectInput = {
    findChainInput: {
      code: undefined,
      name: undefined,
      accountCode: undefined,
      version: undefined
    }
  };

  let code;
  let name;
  let accountCode;
  let isActiveList;

  const resetSearch = () => {
    code = "";
    name = "";
    accountCode = "";

    return {
      findChainInput: {
        code: undefined,
        name: undefined,
        accountCode: undefined
      }
    };
  };

  const updateSearch = () => {
    return {
      findChainInput: {
        code: code !== "" ? code : undefined,
        name: name !== "" ? name : undefined,
        accountCode: accountCode !== "" ? accountCode : undefined
      }
    };
  };

  const goToAddAccount = () => {
    navigateTo("chains/new");
  };

  const processResponse = (response) => {
    const processEdges = (edges) => {
      return edges.map((edge) => {
        const { node } = edge;
        return {
          code: node.code,
          name: node.name,
          account: `${node.account.code} - ${node.account.name}`
        };
      });
    };

    const { data } = response;
    const { chains } = data;
    const { edges } = chains;

    return {
      items: processEdges(edges),
      currentData: chains
    };
  };
</script>

<div class="w-full px-10">
  <CRUDPage
    gqlQuery={CHAINS}
    {columns}
    {tableActions}
    {processResponse}
    {findObjectInput}
    {resetSearch}
    {updateSearch}
    goToAdd={goToAddAccount}>
    <div slot="searchform" class="w-full grid grid-cols-1 md:grid-cols-4 gap-2">
      <TextField type="search" placeholder="Code" bind:textValue={code} />
      <TextField type="search" placeholder="Name" bind:textValue={name} />
      <TextField
        type="search"
        placeholder="Account"
        bind:textValue={accountCode} />
      <SelectField options={selectOptions} bind:value={isActiveList} />
    </div>
  </CRUDPage>
</div>
