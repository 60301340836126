<script>
  import { KeypadMenu } from "@saztrek/firefly";
  import { navigateTo } from "svelte-router-spa";
  import { currentUser } from "../../../stores/current_user";

  const roleTypeForEDGAR = $currentUser.roles.find(
    (role) => role.appCode === "edgar"
  ).isAdmin;

  let items = [
    {
      label: "Sheets",
      action: () => navigateTo("/edgar/sheets"),
      app: "edgar"
    },
    {
      label: "Compliance",
      action: () => navigateTo("/edgar/compliance"),
      app: "edgar"
    },
    {
      label: "Reports",
      action: () => navigateTo("/edgar/reports"),
      app: "edgar"
    }
  ];

  if (roleTypeForEDGAR === "admin") {
    items = [
      ...items,
      {
        label: "Sheets Setup",
        action: () => navigateTo("/edgar/sheetcontexts"),
        app: "edgar"
      },
      {
        label: "Reports Setup",
        action: () => navigateTo("/edgar/reportssetup"),
        app: "edgar"
      },
      {
        label: "Sheets2",
        action: () => navigateTo("/edgar/sheets2"),
        app: "edgar"
      }
    ];
  }
</script>

<div class="flex justify-center pt-20">
  <KeypadMenu {items} size="l" />
</div>
