<script>
  import { createEventDispatcher } from "svelte";
  import { navigateTo } from "svelte-router-spa";
  import { Link, TextField, SelectField } from "@saztrek/firefly";
  import { SKUS_DETAILED } from "../../../gql";

  import CRUDPage from "../../components/CRUDPage.svelte";
  import { selectOptions } from "../../components/defaultObjects";

  const dispatch = createEventDispatcher();

  const columns = [
    {
      field: "code",
      name: "Code",
      renderComponent: Link,
      resolveProps: (data) => {
        return {
          href: `/skus/edit/${data.code}`
        };
      }
    },
    {
      field: "name",
      name: "Name",
      dataType: "string"
    },
    {
      field: "core",
      name: "Core",
      dataType: "string"
    },
    {
      field: "unitCount",
      name: "UOM Count",
      dataType: "number"
    },
    {
      field: "weekSupply",
      name: "Week Supply",
      dataType: "number"
    },
    {
      field: "brand",
      name: "Brand",
      renderComponent: Link,
      resolveProps: (value) => {
        return {
          href: `brands/edit/${value}`
        };
      }
    },
    {
      field: "category",
      name: "Category",
      renderComponent: Link,
      resolveProps: (value) => {
        return {
          href: `categories/edit/${value}`
        };
      }
    }
  ];

  const tableActions = [
    {
      icon: "pencil",
      title: "Edit",
      action: (data) => {
        navigateTo(`/skus/edit/${data.code}`);
      }
    },
    {
      icon: "trash",
      title: "Inactivate",
      action: (data) => {
        dispatch("inactivateBrand", { code: data.code });
      }
    }
  ];

  const findObjectInput = {
    findSKUInput: {
      code: undefined,
      name: undefined,
      version: undefined
    }
  };

  let code;
  let name;
  let categoryCode;
  let isActiveList;

  const resetSearch = () => {
    code = "";
    name = "";
    categoryCode = "";

    return {
      findSKUInput: {
        code: undefined,
        name: undefined,
        categoryCode: undefined
      }
    };
  };

  const updateSearch = () => {
    return {
      findSKUInput: {
        code: code !== "" ? code : undefined,
        name: name !== "" ? name : undefined,
        categoryCode: categoryCode !== "" ? categoryCode : undefined
      }
    };
  };

  const goToAddAccount = () => {
    navigateTo("skus/new");
  };

  const processResponse = (response) => {
    const processEdges = (edges) => {
      return edges.map((edge) => {
        const { node } = edge;
        return {
          code: node.code,
          name: node.name,
          core: node.core ? "Core" : "Regular",
          unitCount: node.unitCount,
          weekSupply: node.weekSupply !== null ? node.weekSupply : "N/A",
          brand: `${node.brand.code} - ${node.brand.name}`,
          category: `${node.category.code} - ${node.category.name}`
        };
      });
    };

    const { data } = response;
    const { skus } = data;
    const { edges } = skus;

    return {
      items: processEdges(edges),
      currentData: skus
    };
  };
</script>

<div class="w-full px-10">
  <CRUDPage
    gqlQuery={SKUS_DETAILED}
    {columns}
    {tableActions}
    {processResponse}
    {findObjectInput}
    {resetSearch}
    {updateSearch}
    goToAdd={goToAddAccount}>
    <div slot="searchform" class="w-full grid grid-cols-1 md:grid-cols-4 gap-2">
      <TextField type="search" placeholder="Code" bind:textValue={code} />
      <TextField type="search" placeholder="Name" bind:textValue={name} />
      <TextField
        type="search"
        placeholder="Category"
        bind:textValue={categoryCode} />
      <SelectField options={selectOptions} bind:value={isActiveList} />
    </div>
  </CRUDPage>
</div>
