const skuMap = [
  {
    itemBarCode: "8996001346365",
    name: "Fres Apple Peach 24 x 50 x 3g",
    productCode: "329545",
    pricePerCS: 672,
    shortName: "FRES APPLE PEACH 50's",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001338063",
    name: "Fres Barley Mint 24x50x3g",
    productCode: "326446",
    pricePerCS: 672,
    shortName: "FRES BARLEY 50's",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001346297",
    name: "Fres Cherry Candy, 24 x 50 x 3g",
    productCode: "326447",
    pricePerCS: 672,
    shortName: "FRES CHERRY 50's",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001346303",
    name: "Fres Grape Candy, 24 x 50 x 3g",
    productCode: "326448",
    pricePerCS: 672,
    shortName: "FRES GRAPE 50's",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001346310",
    name: "Fres Barley Candy Jar 200's 12x200x3g",
    productCode: "329136",
    pricePerCS: 1200.0,
    shortName: "FRES BARLEY JAR",
    unitCount: "12"
  },
  {
    itemBarCode: "8996001346327",
    name: "Fres Cherry Jar, 12x 200 x 3g",
    productCode: "329135",
    pricePerCS: 1200.0,
    shortName: "FRES CHERRY JAR",
    unitCount: "12"
  },
  {
    itemBarCode: "8996001346334",
    name: "Fres Grape Jar, 12 x 200 x 3g",
    productCode: "329137",
    pricePerCS: 1200.0,
    shortName: "FRES GRAPE JAR",
    unitCount: "12"
  },
  {
    itemBarCode: "8996001346396",
    name: "Fres Mixed Candy Jar 12 x 600g x 3g",
    productCode: "320015",
    pricePerCS: 1200.0,
    shortName: "FRES MIXED JAR",
    unitCount: "12"
  },
  {
    itemBarCode: "8996001320051",
    name: "Kopiko Coffeeshot Candy 24x50x3g",
    productCode: "328796",
    pricePerCS: 648,
    shortName: "COFFEE CANDY CLASSIC 50'S",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001320136",
    name: "Kopiko Coffeeshot Cappuccino 24x50x3g",
    productCode: "329102",
    pricePerCS: 648,
    shortName: "COFFEE CANDY CAPPUCCINO 50'S",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001320327",
    name: "Kopiko Coffeeshot Candy Jar 6x200x3g",
    productCode: "329106",
    pricePerCS: 600,
    shortName: "COFFEE CANDY CLASSIC JAR'S",
    unitCount: "6"
  },
  {
    itemBarCode: "8996001355633",
    name: "Beng Beng Chocolate 12 x 10 x 26.5g",
    productCode: "329067",
    pricePerCS: 720,
    shortName: "BENG-BENG REGULAR",
    unitCount: "10"
  },
  {
    itemBarCode: "8996001355978",
    name: "Beng Beng Share It 16 x 95g",
    productCode: "322583",
    pricePerCS: 400,
    shortName: "BENG BENG SHARE IT",
    unitCount: "16"
  },
  {
    itemBarCode: "8996001350584",
    name: "Cal Cheese 10 x 6 x 53.5g",
    productCode: "329808",
    pricePerCS: 498,
    shortName: "CAL CHEESE5 53.5g",
    unitCount: "60"
  },
  {
    itemBarCode: "8996001356661",
    name: "Cal Cheese 35g",
    productCode: "322571",
    pricePerCS: 330,
    shortName: "CAL CHEESE 35g",
    unitCount: "60"
  },
  {
    itemBarCode: "8996001350577",
    name: "Cal Cheese 10 x 20 x 8.5g",
    productCode: "329809",
    pricePerCS: 460,
    shortName: "CAL CHEESE 8.5g",
    unitCount: "20"
  },
  {
    itemBarCode: "8996001351819",
    name: "Cal Cheese Cheese Choco 60 x 35g",
    productCode: "322867",
    pricePerCS: 330,
    shortName: "CAL CHEESE CHOCO 35g",
    unitCount: "60"
  },
  {
    itemBarCode: "8996001351802",
    name: "Cal Cheese Cheese Choco 60 x 53.5g",
    productCode: "322866",
    pricePerCS: 498,
    shortName: "CAL CHEESE CHOCO 53.5g",
    unitCount: "60"
  },
  {
    itemBarCode: "8996001350836",
    name: "Wafello Chocolate Wafer 60 x 53.5g",
    productCode: "330016",
    pricePerCS: 498,
    shortName: "WAFELLO 53.5g",
    unitCount: "60"
  },
  {
    itemBarCode: "8996001351826",
    name: "Wafello Chocolate Wafer 60 x 35g",
    productCode: "330025",
    pricePerCS: 330,
    shortName: "WAFELLO 35g",
    unitCount: "60"
  },
  {
    itemBarCode: "899.6001.35.8337",
    name: "WAFELLO COCO CREME 60X35G",
    productCode: "330025",
    pricePerCS: 330,
    shortName: "WAFELLO COCO CREME 60X35G",
    unitCount: "60"
  },
  {
    itemBarCode: "899.6001.35.1833",
    name: "WAFELLO BUTTER CARAMEL 60X53.5G",
    productCode: "330025",
    pricePerCS: 498,
    shortName: "WAFELLO BUTTER CARAMEL 60X53.5G",
    unitCount: "60"
  },
  {
    itemBarCode: "899.6001.35.8320",
    name: "WAFELLO COCO CREME 60X53.5G",
    productCode: "330025",
    pricePerCS: 498,
    shortName: "WAFELLO COCO CREME 60X53.5G",
    unitCount: "60"
  },
  {
    itemBarCode: "899.6001.35.1949",
    name: "WAFELLO BUTTER CARAMEL 60X35G",
    productCode: "330025",
    pricePerCS: 330,
    shortName: "WAFELLO BUTTER CARAMEL 60X35G",
    unitCount: "60"
  },

  {
    itemBarCode: "8996001355237",
    name: "Superstar Triple Chocolate 12 x12 x 18G",
    productCode: "322582",
    pricePerCS: 570,
    shortName: "SUPERSTAR",
    unitCount: "12"
  },

  {
    itemBarCode: "8996001305218",
    name: "Valmer Chocolate 12 X 10 X 54g",
    productCode: "321038",
    pricePerCS: 660,
    shortName: "VALMER",
    unitCount: "12"
  },
  // TODO: Check this
  {
    itemBarCode: "8996001305218",
    name: "Valmer Chocolate 12 X 12 X 54g",
    productCode: "320485",
    pricePerCS: 660,
    shortName: "VALMER",
    unitCount: "12"
  },
  {
    itemBarCode: "8996001312148",
    name: "Malkist Chocolate 30x10x24g",
    productCode: "321036",
    pricePerCS: 1310.4,
    shortName: "MALKIST",
    unitCount: "30"
  },
  {
    itemBarCode: "8996001303627",
    name: "Danisa Butter Cookies 12x454g",
    productCode: "329650",
    pricePerCS: 2940.0,
    shortName: "DANISA",
    unitCount: "12"
  },
  {
    itemBarCode: "8996001410257",
    name: "Kopiko Black 3-in-1 Minibag 24 x 10 x 30g ",
    productCode: "322630",
    pricePerCS: 1560.0,
    shortName: "BLACK MB",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001410226",
    name: "Kopiko Black 3-in-1 Hanger 24 x 10 x 30g",
    productCode: "322628",
    pricePerCS: 1560.0,
    shortName: "BLACK HANGER",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001340325",
    name: "Kopiko Black 3-In-1 Pouch 8 x 30 x 30g",
    productCode: "322629",
    pricePerCS: 1560.0,
    shortName: "BLACK POUCH",
    unitCount: "8"
  },
  {
    itemBarCode: "8998666001702",
    name: "Kopiko Black 3-in-1 Promo Twin 12 x 10 x 60g",
    productCode: "322627",
    pricePerCS: 1188.0,
    shortName: "BLACK TWIN PACKS",
    unitCount: "12"
  },
  {
    // itemBarCode: "8998666002334",
    itemBarCode: "300027",
    name: "Kopiko Black RTG Cups 60 x 25g",
    productCode: "300027",
    pricePerCS: 816.0,
    shortName: "BLACK RTG",
    unitCount: "60"
  },
  {
    itemBarCode: "8996001410547",
    name: "Kopiko Brown Coffee, 3-in-1 Hanger 24 x 10 x 27.5g",
    productCode: "328890",
    pricePerCS: 1560.0,
    shortName: "BROWN HANGER",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001410820",
    name: "Kopiko Brown Coffee, Minibag 24 x 10 x 27.5g",
    productCode: "328883",
    pricePerCS: 1560.0,
    shortName: "BROWN MB",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001410691",
    name: "Kopiko Brown Coffee, Pouch 8 x 30 x 27.5g",
    productCode: "328882",
    pricePerCS: 1560.0,
    shortName: "BROWN POUCH",
    unitCount: "8"
  },
  {
    itemBarCode: "8998666001719",
    name: "Kopiko Brown Coffee, Promo Twin 12 x 10 x 53g",
    productCode: "329479",
    pricePerCS: 1188.0,
    shortName: "BROWN TWIN PACKS",
    unitCount: "12"
  },
  {
    // itemBarCode: "8998666002334",
    itemBarCode: "300028",
    name: "Kopiko Brown RTG Cups 60 x 27.5g",
    productCode: "300028",
    pricePerCS: 876.0,
    shortName: "BROWN RTG",
    unitCount: "60"
  },
  {
    itemBarCode: "8996001410981",
    name: "Kopiko L.A Coffee, Hanger 24 x 10 x 25g",
    productCode: "325666",
    pricePerCS: 1392.0,
    shortName: "LA COFFEE HANGER",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001410998",
    name: "Kopiko L.A Coffee, Minibag 24x10x25g",
    productCode: "325667",
    pricePerCS: 1392.0,
    shortName: "LA COFFEE MB",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001414309",
    name: "Kopiko Blanca, Hanger 24 x 10 x 30g",
    productCode: "328888",
    pricePerCS: 1560.0,
    shortName: "BLANCA HANGER",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001414316",
    name: "Kopiko Blanca, Minibag 24 x 10 x 30g",
    productCode: "328887",
    pricePerCS: 1560.0,
    shortName: "BLANCA MB",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001414323",
    name: "Kopiko Blanca, Pouch 8 x 30 x 30g",
    productCode: "328889",
    pricePerCS: 1560.0,
    shortName: "BLANCA POUCH",
    unitCount: "8"
  },
  {
    itemBarCode: "8998666001726",
    name: "Kopiko Blanca, Twin Pack 12 x 10 x 52g",
    productCode: "329726",
    pricePerCS: 1188.0,
    shortName: "BLANCA TWIN PACKS",
    unitCount: "12"
  },
  {
    itemBarCode: "8998666002181",
    name: "Kopkiko Blanca RTG Cups 60 x 30g",
    productCode: "30025",
    pricePerCS: 876,
    shortName: "BLANCA RTG",
    unitCount: "60"
  },
  {
    itemBarCode: "8998666002792",
    name: "KOPIKO CREAMY CARAMELO TP 12X10X2X25G PH",
    productCode: "322725",
    pricePerCS: 1188.0,
    shortName: "CARAMELO",
    unitCount: "12"
  },
  {
    itemBarCode: "8998666002846",
    name: "TORACAFE WHITE AND CREAMY  12X(2X10)X26G",
    productCode: "322731",
    pricePerCS: 672,
    shortName: "TORACAFE",
    unitCount: "12"
  },
  {
    itemBarCode: "8996001414002",
    name: "Kopiko Cappuccino Coffee, Hanger 24 x 10 x 25g",
    productCode: "329701",
    pricePerCS: 1560.0,
    shortName: "CAPPUCCINO HANGER",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001414071",
    name: "Kopiko Cappuccino Coffee, Minibag 24 x 10 x 25g",
    productCode: "329703",
    pricePerCS: 1560.0,
    shortName: "CAPPUCCINO MB",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001414088",
    name: "Kopiko Cappuccino Coffee, Pouch 8 x 30 x 25g",
    productCode: "329704",
    pricePerCS: 1560.0,
    shortName: "CAPPUCCINO POUCH",
    unitCount: "8"
  },
  {
    itemBarCode: "8998666001467",
    name: "Kopiko Double Cups, 24 x 10 x 36g",
    productCode: "329744",
    pricePerCS: 1800.0,
    shortName: "DOUBLE CUPS",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001440049",
    name: "Energen Chocolate Hanger 24 x 10 x 40g",
    productCode: "328497",
    pricePerCS: 1560.0,
    shortName: "ENERGEN CHOCO HANGER",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001603055",
    name: "Energen Chocolate Pouch 8 x 30 x 40g",
    productCode: "328493",
    pricePerCS: 1560.0,
    shortName: "ENERGEN CHOCO POUCH",
    unitCount: "8"
  },
  {
    itemBarCode: "8996001603024",
    name: "Energen Chocolate Minibag 24 x 10 x 40g",
    productCode: "328492",
    pricePerCS: 1560.0,
    shortName: "ENERGEN CHOCO MB",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001440124",
    name: "Energen Vanilla Hanger 24 x 10 x 40g",
    productCode: "328494",
    pricePerCS: 1560.0,
    shortName: "ENERGEN VANILLA HANGER",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001603062",
    name: "Energen Vanilla Pouch 8 x 30 x 40g",
    productCode: "328496",
    pricePerCS: 1560.0,
    shortName: "ENERGEN VANILLA POUCH",
    unitCount: "8"
  },
  {
    itemBarCode: "8996001603031",
    name: "Energen Vanilla Minibag 24 x 10 x 40g",
    productCode: "328495",
    pricePerCS: 1560.0,
    shortName: "ENERGEN VANILLA MB",
    unitCount: "24"
  },
  {
    // itemBarCode: "8996001603284",
    itemBarCode: "325899",
    name: "Energen Pandesal Mate 8 x 10 x 30g",
    productCode: "325899",
    pricePerCS: 520.0,
    shortName: "EPM",
    unitCount: "8"
  },
  {
    // itemBarCode: "8996001603284",
    itemBarCode: "325917",
    name: "Energen Champion 24 x 10 x 35g",
    productCode: "325917",
    pricePerCS: 1560.0,
    shortName: "CHAMPION",
    unitCount: "24"
  },
  {
    // itemBarCode: "8996001600221",
    itemBarCode: "899.6001.600221",
    name: "Kopiko 78 C 24x 240 mL",
    productCode: "400117",
    pricePerCS: 360,
    shortName: "K78C",
    unitCount: "24"
  },
  {
    itemBarCode: "8850580500023",
    name: "Kopiko Iced Blanca 24 x 240 mL",
    productCode: "351411",
    pricePerCS: 288.0,
    shortName: "KICE BLACK",
    unitCount: "24"
  },
  {
    itemBarCode: "8850580500016",
    name: "Kopiko Iced Blrown 24 x 240 mL",
    productCode: "351412",
    pricePerCS: 288,
    shortName: "KICE BLACK",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001600269",
    name: "Le Minerale 24 x 600 mL",
    productCode: "328565",
    pricePerCS: 234.0,
    shortName: "LE MINERALE 600 ML",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001600375",
    name: "Le Minerale 24 x 330 mL",
    productCode: "328566",
    pricePerCS: 168,
    shortName: "LE MINERALE 300 ML",
    unitCount: "24"
  },
  {
    itemBarCode: "8996001600399",
    name: "Le Minerale 12 x 1500 mL",
    productCode: "326770",
    pricePerCS: 216,
    shortName: "LE MINERALE 1.5 L",
    unitCount: "12"
  }
];

module.exports = { skuMap };
