<script>
  import { navigateTo } from "svelte-router-spa";

  import {
    TextField,
    Button,
    Tailwindcss,
    AppIcon,
    toastNotifier
  } from "@saztrek/firefly";

  import { Auth } from "../../../config/firebase";

  import { getCookie } from "../../../utilities/cookies";

  import TempLoginGraphic from "../../svg/TempLoginLogo.svelte";
  import TempUpperLeftGraphic from "../../svg/TempUpperLeftGraphic.svelte";
  import TempLowerRightGraphic from "../../svg/TempLowerRightGraphic.svelte";

  let username;
  let password;
  let loginActionHappening = false;

  const postIdTokenToSessionLogin = async (data) => {
    const url = "https://mermade.saztrek.app/sessionLogin";
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      redirect: "follow",
      "Access-Control-Allow-Credentials": true,
      Origin: "https://mermade.saztrek.app/",
      headers: {
        "Content-Type": "application/json"
      },
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data)
    });
    return response.json();
  };

  const login = () => {
    loginActionHappening = true;

    Auth.setPersistence("session").then(function () {
      return Auth.signInWithEmailAndPassword(username, password)
        .then((data) => {
          const user = data.user;
          const uid = user.uid;
          user
            .getIdToken()
            .then((idToken) => {
              const csrfToken = getCookie("csrfToken");

              postIdTokenToSessionLogin({ idToken, csrfToken, uid })
                .then((result) => {
                  navigateTo("/");
                })
                .catch((error) => {
                  console.error("Can't create session");
                  navigateTo("/");
                });
            })

            .catch((error) => {
              console.log(error);
              loginActionHappening = false;
            });
        })
        .catch((error) => {
          toastNotifier.danger(
            "Cannot Login.",
            "Please check username or password for errors.",
            7000
          );
          loginActionHappening = false;
        });
    });
  };
</script>

<div class="h-full w-full">
  <div
    class="landing-page h-full w-full overflow-hidden flex justify-center
    items-start">
    <div class="flex flex-col h-full w-full flex-grow">
      <div class="flex-grow-0 flex flex-col">
        <div class="title bg-primary w-full px-10 text-white">
          <div class="text-2xl h-20" style="line-height: 80px;">
            <div class="inline-block align-middle font-hairline">saztrek</div>
          </div>
        </div>
      </div>
      <div
        class="logoWrapper flex items-center absolute p-6 mb-2 h-24 w-24
        rounded-full shadow-lg bg-white"
        style="right: calc(50% - 46px); top: 2rem;">
        <AppIcon app="mermade" size="l" />
      </div>
      <h3 class="font-normal mt-16 text-center text-2xl leading-10 w-full">
        Saztrek App
      </h3>
      <div class="flex flex-grow flex-col" style="flex-basis: 0%">
        <div
          class="flex flex-row items-center justify-center -m-5 flex-grow"
          style="flex-basis: auto">
          <div
            class="flex mx-10 my-6 flex-grow-0 content-center flex-col
            items-center">
            <div
              class="flex flex-col items-center content-center -m-3 flex-grow"
              style="flex-basis: 22.5rem; min-width: 22.5rem">
              <div class="flex m-3 flex-grow-0 flex-col" style="flex-basis:0%;">
                <TempLoginGraphic />
              </div>

            </div>
          </div>
          <div
            class="my-6 mx-10 flex-grow-0 flex flex-col"
            style="width: 22.5rem;">
            <h3
              class="text-center mb-8 text-2xl font-light tracking-tighter
              leading-10">
              Login
            </h3>
            <div>
              <form class="mt-0 w-auto" on:submit|preventDefault={login}>
                <div>
                  <TextField
                    type="email"
                    id="username"
                    label="Email"
                    bind:textValue={username} />
                </div>
                <div class="mt-16">
                  <TextField
                    type="password"
                    id="password"
                    label="Password"
                    bind:textValue={password} />
                </div>
                <div class="mt-16">
                  <Button
                    type="submit"
                    color="primary"
                    filled="true"
                    text="Log in"
                    loading={loginActionHappening} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="absolute bottom-0 right-0 opacity-50" style="z-index: -1;">
    <TempLowerRightGraphic />
  </div>
  <div class="absolute top-0 left-0 opacity-50" style="z-index: -1;">
    <TempUpperLeftGraphic />
  </div>
  <Tailwindcss />
</div>
