<script>
  import { createEventDispatcher } from "svelte";
  import { navigateTo } from "svelte-router-spa";
  import { Link } from "@saztrek/firefly";
  import { EDGAR_SHEET_CONTEXTS } from "../../../../gql";
  import { dateFormatToUI } from "../../../../utilities/dateFormatter";

  import CRUDPage from "../../../components/CRUDPage.svelte";

  const columns = [
    {
      field: "startDate",
      name: "Start Date",
      renderComponent: Link,
      resolveProps: (data) => {
        return {
          href: `sheetcontexts/edit/${data.startDate},${data.endDate}`
        };
      }
    },
    {
      field: "endDate",
      name: "End Date",
      dataType: "date"
    },
    {
      field: "gracePeriodDate",
      name: "Grace Period Date",
      dataType: "date"
    },
    {
      field: "weekNumber",
      name: "Week Number",
      dataType: "number"
    },
    {
      field: "monthNumber",
      name: "Month Number",
      dataType: "number"
    }
  ];

  const dispatch = createEventDispatcher();

  const tableActions = [
    {
      icon: "pencil",
      title: "Edit",
      action: (data) => {
        navigateTo(
          `edgar/sheetcontexts/edit/${data.startDate},${data.endDate}`
        );
      }
    },
    {
      icon: "trash",
      title: "Inactivate",
      action: (data) => {
        dispatch("inactivateSheetContext", { code: data.code });
      }
    }
  ];

  const processResponse = (response) => {
    const processEdges = (edges) => {
      return edges.map((edge) => {
        const { node } = edge;
        return {
          startDate: dateFormatToUI(node.startDate),
          endDate: node.endDate,
          gracePeriodDate: node.gracePeriodDate,
          weekNumber: node.weekNumber,
          monthNumber: node.monthNumber
        };
      });
    };

    const { data } = response;
    const { edgarSheetContexts } = data;
    const { edges } = edgarSheetContexts;

    return {
      items: processEdges(edges),
      currentData: edgarSheetContexts
    };
  };

  const gotoAddSheetContext = () => {
    navigateTo("/edgar/sheetcontexts/new");
  };
</script>

<div class="w-full px-10">
  <CRUDPage
    gqlQuery={EDGAR_SHEET_CONTEXTS}
    {columns}
    {tableActions}
    {processResponse}
    goToAdd={gotoAddSheetContext} />
</div>
