<script>
  import { createEventDispatcher } from "svelte";
  import {
    TextField,
    Button,
    EmptyButton,
    SelectField,
    toastNotifier
  } from "@saztrek/firefly";

  export let cancelAction;

  export let branches;

  export let buttonLabel;

  export let loading = false;

  let branchCode = null;
  let requiredHeadCount = 0;
  let hasAbsencePenalty = false;

  const hasAbsencePenaltyOptions = [
    {
      option: "Yes",
      value: true
    },
    {
      option: "No",
      value: false
    }
  ];

  const dispatch = createEventDispatcher();
  let listOfBranches;

  listOfBranches = branches.map((edge) => {
    const { node } = edge;
    return {
      option: `${node.code} - ${node.name}`,
      value: node.code
    };
  });

  listOfBranches.unshift({ option: "Choose Branch", value: null });

  const requiredFieldsFilled = () => {
    return !!branchCode;
  };

  const submitForm = () => {
    if (requiredFieldsFilled()) {
      dispatch("validBranchAttendanceContext", {
        branchCode,
        requiredHeadCount,
        hasAbsencePenalty
      });
    } else {
      toastNotifier.warning(
        "Required fields.",
        "Please fill in required fields",
        7000
      );
    }
  };
</script>

<form on:submit|preventDefault={submitForm}>
  <div class="p-4 w-full">
    <SelectField
      bind:value={branchCode}
      options={listOfBranches}
      label="Branch" />
  </div>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={requiredHeadCount}
      placeholder="Required Head Count..."
      type="number"
      label="Required Head Count" />
  </div>
  <div class="p-4 w-full">
    <SelectField
      bind:value={hasAbsencePenalty}
      options={hasAbsencePenaltyOptions}
      label="Has Absence Penalty?" />
  </div>

  <div class="w-full flex flex-row-reverse justify-evenly pb-2 mt-8 px-4">
    <Button text={buttonLabel} type="submit" color="primary" {loading} />
    <div class="w-25" />
    <div class="text-center">
      <EmptyButton text="Cancel" color="text" on:click={cancelAction} />
    </div>
  </div>
</form>
