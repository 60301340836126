<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, query, mutate } from "svelte-apollo";

  import {
    EDIT_CHAIN,
    GET_CHAIN,
    INACTIVATE_CHAIN,
    ACCOUNTS
  } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import ChainForm from "./ChainForm.svelte";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  const cancelUpdate = () => {
    navigateTo("chains");
  };

  const client = getClient();

  let loading = false;

  const chainOp = query(client, {
    query: GET_CHAIN,
    variables: {
      code: decodeURIComponent(id)
    }
  });

  const accountsOp = query(client, {
    query: ACCOUNTS
  });

  const inactivateChain = async (event) => {
    const chainData = { ...event.detail };
    loading = true;
    try {
      await mutate(client, {
        mutation: INACTIVATE_CHAIN,
        variables: {
          code: chainData.code
        }
      });
      toastNotifier.success(
        "Success!",
        `Chain ${chainData.code} has been deactivated`,
        7000
      );
      navigateTo("chains");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };

  const editChain = async (event) => {
    try {
      const chainData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: EDIT_CHAIN,
        variables: {
          editChainInput: {
            code: chainData.code,
            name: chainData.name,
            version: chainData.version,
            accountCode: chainData.accountCode
          }
        }
      });
      toastNotifier.success(
        "Success!",
        `Chain ${chainData.name} Updated`,
        7000
      );
      navigateTo("chains");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Update Chain">
    {#await $chainOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      {#await $accountsOp}
        <div class="p-10 w-full" style="min-height: 500px">
          <LoadingContent />
        </div>
      {:then accountsResult}
        <ChainForm
          mode="edit"
          accounts={accountsResult.data.accounts.edges}
          code={result.data.chain.code}
          name={result.data.chain.name}
          accountCode={result.data.chain.account.code}
          version={result.data.chain.version}
          on:validChain={editChain}
          on:inactivateChain={inactivateChain}
          cancelAction={cancelUpdate}
          {loading}
          buttonLabel="Update" />
      {/await}
    {/await}

  </Panel>
</div>
