<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier } from "@saztrek/firefly";
  import { getClient, mutate } from "svelte-apollo";

  import { ADD_EDGAR_SHEET_SUMMARY_CONTEXT } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import SheetContextForm from "./EDGARSheetSummaryContextForm.svelte";

  const cancelUpdate = () => {
    navigateTo("/edgar/reportssetup");
  };

  const client = getClient();

  let loading = false;

  const addSheetSummaryContext = async (event) => {
    try {
      const sheetContextData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: ADD_EDGAR_SHEET_SUMMARY_CONTEXT,
        variables: {
          addEDGARSheetSummaryContextInput: {
            ...sheetContextData
          }
        }
      });
      toastNotifier.success("Success!", `Sheet Summary Context Added`, 7000);
      navigateTo("/edgar/reportssetup");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add SheetContext">

    <SheetContextForm
      mode="add"
      on:validSheetContext={addSheetSummaryContext}
      cancelAction={cancelUpdate}
      {loading}
      buttonLabel="Add" />

  </Panel>
</div>
