const skuMap = [
  {
    skuCode: "8850006340578",
    size: "20g",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006327463",
    size: "175g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006327470",
    size: "120g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006933947",
    size: "195g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006933961",
    size: "175g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354826542",
    size: "175g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354826559",
    size: "120g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354823985",
    size: "175g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354824104",
    size: "120g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006343241",
    size: "175g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888190082",
    size: "110g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004587",
    size: "15mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004594",
    size: "15mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888005096",
    size: "15mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004334",
    size: "12mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004341",
    size: "12mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004358",
    size: "12mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004938",
    size: "12ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004242",
    size: "10mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004259",
    size: "10mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004266",
    size: "22mL",
    gsSellingUnit: "    ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004860",
    size: "13.5ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004846",
    size: "13.5ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004853",
    size: "13.5ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888005171",
    size: "13.5mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031096006",
    size: "550g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031096433",
    size: "550g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031310188",
    size: "550g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888801070",
    size: "350g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888801087",
    size: "350g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103327",
    size: "24g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103358",
    size: "140mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354825484",
    size: "100g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888801155",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888801124",
    size: "190mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888801131",
    size: "190mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888801148",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006320495",
    size: "214g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006320501",
    size: "140g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006320518",
    size: "74g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006321133",
    size: "37g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006321522",
    size: "145mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006321539",
    size: "95mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006325049",
    size: "50mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006321546",
    size: "25mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354814457",
    size: "75mL",
    gsSellingUnit: "    ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006321720",
    size: "150g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354806780",
    size: "150g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354806773",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354814402",
    size: "150g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354814419",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354814860",
    size: "35g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354823596",
    size: "120g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006342305",
    size: "110g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006342312",
    size: "65g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006322963",
    size: "193g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006322956",
    size: "126g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006322949",
    size: "66g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006322932",
    size: "193g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006322925",
    size: "126g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006322918",
    size: "66g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006323694",
    size: "193g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006323687",
    size: "126g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006323670",
    size: "66g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354817106",
    size: "140mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354817090",
    size: "90mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354811852",
    size: "100g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354811920",
    size: "40g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354825972",
    size: "50g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354825989",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006327357",
    size: "40g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006344200",
    size: "40g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006342213",
    size: "40g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354817724",
    size: "40g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "7891024123430",
    size: "110g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031093616",
    size: "30g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006341858",
    size: "114g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8714789255019",
    size: "120g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006342916",
    size: "180g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006342909",
    size: "132g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006342893",
    size: "35g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006303979",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006301081",
    size: "500mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006302088",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006304556",
    size: "60mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006303986",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006301135",
    size: "500mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006302132",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006304563",
    size: "60mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006306178",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006306901",
    size: "500mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006303863",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006304570",
    size: "60mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006306819",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006306246",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006306772",
    size: "60mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006305409",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006304952",
    size: "500mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006304709",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006304945",
    size: "60mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006306048",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006306185",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006306918",
    size: "500ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006305751",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006306956",
    size: "60ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332023",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332405",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006934432",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006932513",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4892368661704",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006331866",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332467",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332269",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006264027",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8935236000703",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006265178",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8935236000864",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8935236000734",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8935236000093",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006330012",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201115",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888320106",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332306",
    size: "Youth",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031209857",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031209864",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888200033",
    size: "Youth",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888200583",
    size: "Youth",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "35000459558",
    size: "Baby",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888200668",
    size: "50m",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888442457",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006930519",
    size: "600mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006490945",
    size: "400mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888411705",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888411583",
    size: "90mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006930526",
    size: "600mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4892368072166",
    size: "400mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888413709",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888413594",
    size: "90mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006930502",
    size: "600mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006493939",
    size: "400mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888431444",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888431222",
    size: "90mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888417806",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888400495",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480731",
    size: "15mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888400426",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006536001",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480755",
    size: "15mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006491997",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480762",
    size: "15mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006493755",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480724",
    size: "15mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480748",
    size: "15mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480779",
    size: "15mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480786",
    size: "15mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481578",
    size: "600mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481547",
    size: "340ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480410",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481585",
    size: "600mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481554",
    size: "340ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480403",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480472",
    size: "12mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480465",
    size: "12mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480489",
    size: "12mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006932377",
    size: "12ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481592",
    size: "600mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481561",
    size: "340ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480427",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006932384",
    size: "170ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006494462",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006494448",
    size: "10mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006494455",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006494431",
    size: "10mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481417",
    size: "12mL",
    gsSellingUnit: " 72 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481424",
    size: "12mL",
    gsSellingUnit: " 72 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006494424",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006492598",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006494417",
    size: "13.5ml",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006536056",
    size: "13.5ml",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006536063",
    size: "13.5ml",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006933060",
    size: "13.5ml",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006536094",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006492604",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006933077",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006494905",
    size: "20mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006494912",
    size: "20mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006494929",
    size: "22mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888444000",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481806",
    size: "12mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888428017",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888428024",
    size: "90mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888426013",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888439013",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006491782",
    size: "12mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006491775",
    size: "12mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006491799",
    size: "12mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006493762",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006493748",
    size: "12mL",
    gsSellingUnit: " 6 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006930373",
    size: "340mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480441",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006930366",
    size: "340mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480434",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006930380",
    size: "340mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480458",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006932407",
    size: "170ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480502",
    size: "12mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480496",
    size: "12mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480519",
    size: "12mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006932391",
    size: "12ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480526",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480533",
    size: "70mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006480540",
    size: "30mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006930809",
    size: "15mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031063305",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031084119",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031063312",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031084126",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031076596",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031084133",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481370",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006492291",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006493885",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006493878",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006494707",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006494691",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031063367",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031063343",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031084164",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031063374",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031063350",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006933565",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593523",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593677",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004907",
    size: "3.7oz",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004914",
    size: "3.7oz",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004921",
    size: "3.7oz",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006493953",
    size: "90mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006493946",
    size: "90mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593608",
    size: "380mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593592",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593585",
    size: "500mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593578",
    size: "200mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593660",
    size: "500mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "35000269188",
    size: "18oz",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "35000269195",
    size: "18oz",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "35000269201",
    size: "18oz",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006590348",
    size: "100g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006590355",
    size: "50g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006590362",
    size: "100g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006590379",
    size: "50g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593356",
    size: "100g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593363",
    size: "50g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593639",
    size: "100g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593622",
    size: "50g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006604793",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006600474",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9300632016211",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006535028",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006490105",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9300632016761",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9300632011995",
    size: "500ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006493557",
    size: "500ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006536513",
    size: "1000ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006536520",
    size: "1000ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004822",
    size: "500ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006935279",
    size: "55ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888843216",
    size: "500mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888843414",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888843643",
    size: "190mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888844008",
    size: "50mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031097058",
    size: "20mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888842417",
    size: "250mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888842646",
    size: "190mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888842806",
    size: "50mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888821122",
    size: "350g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888821221",
    size: "190g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888821405",
    size: "350g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888822129",
    size: "350g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888822327",
    size: "190g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888822402",
    size: "350g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031206306",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031206313",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031206320",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031206337",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354816581",
    size: "122mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354820915",
    size: "100g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103297",
    size: "120g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354814792",
    size: "150g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103143",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103303",
    size: "110g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354824029",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888102672",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006936337",
    size: "133g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006936283",
    size: "155g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6920354826245",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103457",
    size: "500mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103549",
    size: "500mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103556",
    size: "500mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103419",
    size: "500ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103402",
    size: "500ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103563",
    size: "500mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103662",
    size: "500ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103679",
    size: "500ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103686",
    size: "500ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103693",
    size: "500ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103228",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103235",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103570",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103587",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103792",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103785",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103778",
    size: "250ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103761",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888103754",
    size: "1L",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201474",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201009",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332122",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332030",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8693495052931",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6910021200010",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201535",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006932827",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "6910021200034",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4892368661711",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006331873",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006331972",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006331699",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332474",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332276",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006330678",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201436",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "7891024185551",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888200972",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332436",
    size: "Ad",
    gsSellingUnit: "    ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332559",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8935236000857",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888200989",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888202723",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888210117",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8935236000895",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8935236000819",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8935102111335",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006332504",
    size: "Youth",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201382",
    size: "Junior",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201399",
    size: "Youth",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201177",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201337",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201542",
    size: "Ad",
    gsSellingUnit: "    ",
    storageLoc: "DSA"
  },
  {
    skuCode: "9556031089626",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006331620",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201184",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004051",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004044",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201481",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201504",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201498",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888201511",
    size: "Youth",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004297",
    size: "Youth",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004303",
    size: "Ad",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004686",
    size: "400mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004693",
    size: "400mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004709",
    size: "400mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004815",
    size: "400ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888005140",
    size: "400ml",
    gsSellingUnit: " 11 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004839",
    size: "400ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002934",
    size: "400mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002941",
    size: "400mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002958",
    size: "400mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004655",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004662",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004679",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888005089",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002897",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004273",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004280",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888003252",
    size: "10mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888003245",
    size: "10mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004617",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004624",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004419",
    size: "170mL",
    gsSellingUnit: "    ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004211",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004228",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004730",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004747",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004754",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004761",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002903",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002880",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888005164",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888005225",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888005218",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888005201",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888003399",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888003412",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888003405",
    size: "180mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004778",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004785",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004792",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004808",
    size: "170mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004983",
    size: "12ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004969",
    size: "12ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888003078",
    size: "200mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002989",
    size: "200mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888003467",
    size: "200mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888003450",
    size: "200mL",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004372",
    size: "200ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004365",
    size: "180ml",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481387",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888000213",
    size: "115g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888001999",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888363226",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006481608",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888000206",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888402468",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888001500",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006934166",
    size: "80g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593615",
    size: "65g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593653",
    size: "65g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "35000141088",
    size: "3.7oz",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "35000141095",
    size: "3.7oz",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "35000141163",
    size: "3.7oz",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "35000141170",
    size: "3.7oz",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "35000141255",
    size: "3.7oz",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "58000302730",
    size: "90g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002347",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002545",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002354",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002606",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888002620",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004631",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888004648",
    size: "55g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593547",
    size: "200g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593554",
    size: "200g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593561",
    size: "200g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006593646",
    size: "200g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888005072",
    size: "50g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "4808888005065",
    size: "50g",
    gsSellingUnit: " 1 ",
    storageLoc: "DSA"
  },
  {
    skuCode: "8850006325216",
    size: "24g",
    gsSellingUnit: "6",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006493014",
    size: "15mL",
    gsSellingUnit: "6",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006493038",
    size: "15mL",
    gsSellingUnit: "6",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006325223",
    size: "22g",
    gsSellingUnit: "6",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006325230",
    size: "22g",
    gsSellingUnit: "6",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006493045",
    size: "15mL",
    gsSellingUnit: "6",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006327425",
    size: "195g",
    gsSellingUnit: "1",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006327432",
    size: "132g",
    gsSellingUnit: "1",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006344361",
    size: "24g",
    gsSellingUnit: "1",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006327449",
    size: "175g",
    gsSellingUnit: "1",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006327456",
    size: "120g",
    gsSellingUnit: "1",
    storageLoc: "WH"
  },
  {
    skuCode: "4808888004167",
    size: "15ml",
    gsSellingUnit: "1",
    storageLoc: "WH"
  },
  {
    skuCode: "4808888004174",
    size: "15ml",
    gsSellingUnit: "1",
    storageLoc: "WH"
  },
  {
    skuCode: "4808888004181",
    size: "15ml",
    gsSellingUnit: "1",
    storageLoc: "WH"
  },
  {
    skuCode: "4808888892177",
    size: "22g",
    gsSellingUnit: "1",
    storageLoc: "WH"
  },
  {
    skuCode: "4808888892566",
    size: "22g",
    gsSellingUnit: "1",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006927618",
    size: "24g",
    gsSellingUnit: "1",
    storageLoc: "WH"
  },
  {
    skuCode: "8850006344361",
    size: "24g",
    gsSellingUnit: "1",
    storageLoc: "WH"
  }
];

export { skuMap };
