<script>
  import { onMount } from "svelte";

  export let currentRoute;

  let headerTitle;

  onMount(() => {
    const { name } = currentRoute;
    headerTitle = name === "/" ? "Home" : name.substring(name.indexOf("/") + 1);
  });
</script>

<div class="capitalize">{headerTitle}</div>
