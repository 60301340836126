<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier } from "@saztrek/firefly";
  import { getClient, mutate } from "svelte-apollo";

  import { ADD_TENANT_CONFIG } from "../../../gql";

  import Panel from "../../components/Panel.svelte";
  import TenantConfigForm from "./TenantConfigForm.svelte";

  const cancelUpdate = () => {
    navigateTo("/tenantconfigs");
  };

  const client = getClient();

  let loading = false;

  const addTenantConfig = async (event) => {
    try {
      const tenantConfigData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: ADD_TENANT_CONFIG,
        variables: {
          addTenantConfigurationInput: {
            code: tenantConfigData.code,
            description: tenantConfigData.description,
            tenantVariableInput: tenantConfigData.tenantVariables,
            featureFlagInput: tenantConfigData.featureFlags
          }
        }
      });

      toastNotifier.success(
        "Success!",
        `Account ${tenantConfigData.name} Added`,
        7000
      );
      navigateTo("/tenantconfigs");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add Tenant Config">

    <TenantConfigForm
      mode="add"
      on:validTenantConfig={addTenantConfig}
      cancelAction={cancelUpdate}
      {loading}
      buttonLabel="Add" />

  </Panel>
</div>
