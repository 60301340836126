<script>
  import { Gauge } from "@saztrek/firefly";

  export let code;
  export let name;
  export let complete;
  export let totalSKUCount;
  export let carriedSKUCount;
  export let availableCount;
  export let availablePercentage;
  export let oosCount;
  export let oosPercentage;
  export let notCarriedCount;
  export let notCarriedPercentage;
  export let dateCreated;
  export let timeCreated;
  export let dateSync;
  export let timeSync;

  const availablePercentageRounded =
    availablePercentage !== 0 ? (availablePercentage * 100).toFixed(2) : 0;

  const oosPercentageRounded =
    oosPercentage !== 0 ? (oosPercentage * 100).toFixed(2) : 0;

  const notCarriedPercentageRounded =
    notCarriedPercentage !== 0 ? (notCarriedPercentage * 100).toFixed(2) : 0;
</script>

<div class="w-full flex flex-row py-2 px-6">
  <div class="w-full shadow rounded">
    <div class="pb-2 h-full border-l-8 rounded" class:border-secondary={true}>
      <div class="border-b border-darkerGray" style="height: 5.5rem;">
        <div class="flex flex-row justify-between items-start py-2 px-4">
          <div class="flex flex-col items-start pr-3">
            <div class="text-xs font-bold italic">{code}</div>
            <div class="text-sm">{name}</div>
          </div>
          <div class="flex flex-col">
            <Gauge
              value={availablePercentage}
              size="70"
              borderWidth="5"
              valueText="{availablePercentageRounded}%"
              valueFontSize={12}
              valueFontWeight={400}
              valueTextColor="#219643"
              borderColor="#219643" />
          </div>
        </div>

      </div>
      <div class="h-full">
        <div
          class="flex flex-row pt-1 mx-3 pr-4 bg-white text-tableText
          justify-center">
          <table class="w-full table-auto">
            <tbody>
              <tr>
                <td>Complete</td>
                <td>{complete ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>Total SKU Count</td>
                <td>{totalSKUCount}</td>
              </tr>
              <tr>
                <td>Carried SKU Count</td>
                <td>{carriedSKUCount}</td>
              </tr>
              <tr>
                <td>Available Count</td>
                <td>{availableCount}</td>
              </tr>
              <tr>
                <td>Available Percentage</td>
                <td>{availablePercentageRounded}%</td>
              </tr>
              <tr>
                <td>OOS Count</td>
                <td>{oosCount}</td>
              </tr>
              <tr>
                <td>OOS Percentage</td>
                <td>{oosPercentageRounded}%</td>
              </tr>
              <tr>
                <td>Not Carried Count</td>
                <td>{notCarriedCount}%</td>
              </tr>
              <tr>
                <td>Not Carried Percentage</td>
                <td>{notCarriedPercentageRounded}%</td>
              </tr>
              <tr>
                <td>Date Created</td>
                <td>{dateCreated}</td>
              </tr>
              <tr>
                <td>Time Created</td>
                <td>{timeCreated}</td>
              </tr>
              <tr>
                <td>Last Updated Date</td>
                <td>{dateSync}</td>
              </tr>
              <tr>
                <td>Last Updated Time</td>
                <td>{timeSync}</td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>
  </div>
</div>
