<script>
  import { LoadingContent } from "@saztrek/firefly";

  export let loadingSKUs;
  export let skus;
</script>

{#if skus && skus.length > 0}
  <div
    class="overflow-auto flex w-full px-4 border border-darkerGray rounded "
    style="max-height: 600px; height: 600px;">
    <table class="table-auto leading-5 w-full">
      <thead class="font-bold text-xs">
        <tr>
          <td class="px-1">Out of Stock?</td>
          <td class="px-1">Code</td>
          <td class="px-1 w-1/6">Name</td>
          <td class="px-1">Unit</td>
          <td class="px-1">Core</td>
          <td class="px-1">S.O.</td>
          <td class="px-1">Brand Code</td>
          <td class="px-1">Brand Name</td>
          <td class="px-1">Category Code</td>
          <td class="px-1">Category Name</td>
          <td class="px-1">Branch Code</td>
          <td class="px-1">Branch Name</td>
          <td class="px-1">Inventory Date</td>
        </tr>
      </thead>
      {#each skus as sku, i}
        <tr
          class="border-b-darkerGray border-b text-xs h-10"
          class:bg-dangerLight={sku.oos === 'Yes'}>
          <td class="px-1">{sku.oos}</td>
          <td class="px-1">{sku.code}</td>
          <td class="px-1">{sku.name}</td>
          <td class="px-1">{sku.unitCount}</td>
          <td class="px-1">{sku.core}</td>
          <td class="px-1">{sku.suggestedOrder}</td>
          <td class="px-1">{sku.brandCode}</td>
          <td class="px-1">{sku.brandName}</td>
          <td class="px-1">{sku.categoryCode}</td>
          <td class="px-1">{sku.categoryName}</td>
          <td class="px-1">{sku.branchCode}</td>
          <td class="px-1">{sku.branchName}</td>
          <td class="px-1">{sku.inventoryDate}</td>
        </tr>
      {/each}
    </table>
  </div>
{:else if loadingSKUs}
  <div class="w-full pt-2 h-full px-4 ">
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
  </div>
{/if}
