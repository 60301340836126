<script>
  import { getClient, query } from "svelte-apollo";
  import { navigateTo } from "svelte-router-spa";

  import {
    Button,
    LoadingContent,
    EmptyButton,
    Spinner,
    Icon
  } from "@saztrek/firefly";

  import { APPROVAL_REQUESTS } from "../../../gql";

  const client = getClient();

  const approvalRequestsOp = query(client, {
    query: APPROVAL_REQUESTS,
    variables: {
      findApprovalRequestInput: {
        requestTypeCodes: ["EDGARCORRECTION"],
        statuses: ["PENDING"]
      }
    },
    pollInterval: 60000
  });

  const getKeyValueFromData = (edge, key) =>
    edge.node.data.find((datum) => datum.key === key).value;

  const employeeCache = [];

  const getEmployeeObjectFromEdge = (edge) => {
    const nodeId = edge.node.id;
    if (employeeCache[nodeId]) {
      return employeeCache[nodeId];
    }

    const empDataRaw = edge.node.data.find((datum) => datum.key === "employee");
    if (empDataRaw) {
      const jsonEmpData = JSON.parse(empDataRaw.value);
      employeeCache[nodeId] = jsonEmpData;
      return jsonEmpData;
    }

    return {};
  };

  const actionStatus = [];

  const reviewCorrection = (code) => {
    navigateTo(`/edgar/corrections/${code}`);
  };

  const refreshCorrections = () => {
    approvalRequestsOp.refetch();
  };
</script>

<div
  class="rounded overflow-y-auto border border-darkerGray w-full"
  style="max-height: 360px; min-height: 360px;">
  <div class="px-6 pt-2 pb-2 sticky top-0 bg-white">
    <div class="flex flex-row justify-between items-center w-full">
      <div class="w-full">
        <div class="font-bold text-lg">Approvals</div>
        <div class="italic text-sm">EDGAR Corrections</div>
      </div>
      <div class="w-8 h-8">
        <Button icon="refresh" text="" size="s" on:click={refreshCorrections} />
      </div>
    </div>
  </div>

  {#await $approvalRequestsOp}
    <div class="px-6">
      <LoadingContent />
      <div class="pt-5" />
      <LoadingContent />
      <div class="pt-5" />
      <LoadingContent />
      <div class="pt-5" />
    </div>
  {:then resultApprovalRequests}
    <div class="list pb-2 pl-6 pr-4">
      <!-- TODO: Check for empty state -->
      {#if resultApprovalRequests.data.approvalRequests.edges.length > 0}
        {#each resultApprovalRequests.data.approvalRequests.edges as edge}
          <div
            class="flex flex-row items-start justify-between py-2
            border-b-darkerGray border-b">
            <div class="flex flex-row w-6/12 items-center">
              <div class="flex flex-col">
                <div class="text-primary text-sm">
                  <!-- {getEmployeeObjectFromEdge(edge).name.lastName}, {getEmployeeObjectFromEdge(edge).name.firstName} -->
                  {getKeyValueFromData(edge, 'edgarSheetCode')}
                </div>
                <div class="text-xs ">
                  Requested By: {getEmployeeObjectFromEdge(edge).name.lastName},
                  {getEmployeeObjectFromEdge(edge).name.firstName}
                </div>

              </div>
              <!-- <div class="text-xs font-bold self-start">
              {getKeyValueFromData(edge, 'date')}
            </div> -->
            </div>

            <div class="flex flex-row w-3/12 items-center pt-2">
              {#if !actionStatus[edge.node.id]}
                <EmptyButton
                  text="Review"
                  color="primary"
                  size="tiny"
                  on:click={reviewCorrection(edge.node.code)} />
              {:else}
                <div
                  class="flex flex-row self-center text-center w-full
                  justify-center">
                  <Spinner size="large" />
                </div>
              {/if}

            </div>

          </div>
        {/each}
      {:else}
        <div
          class="w-full h-full flex flex-col items-center justify-center pt-20">
          <Icon size="l" type="table" />
          <div class="pt-2">No Correction Requests</div>
        </div>
      {/if}
    </div>
  {/await}
</div>
