<script>
  import { Table, Link } from "@saztrek/firefly";

  export let edges;

  const columns = [
    {
      field: "sheetCode",
      name: "Sheet Code",
      renderComponent: Link,
      resolveProps: (data) => {
        return {
          href: `edit/${data.sheetCode}`
        };
      }
    },
    {
      field: "branchCode",
      name: "Branch Code",
      dataType: "string"
    },
    {
      field: "date",
      name: "Date",
      dataType: "date"
    }
  ];
</script>

<div>
  <div class="pt-3 pl-3 pr-3 pb-8">
    <Table {columns} items={edges} />
  </div>
</div>
