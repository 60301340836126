<script>
  import { createEventDispatcher } from "svelte";
  import { navigateTo } from "svelte-router-spa";
  import { Link } from "@saztrek/firefly";
  import { EDGAR_SHEET_SUMMARY_CONTEXTS } from "../../../../gql";
  import { dateFormatToUI } from "../../../../utilities/dateFormatter";

  import CRUDPage from "../../../components/CRUDPage.svelte";

  const columns = [
    {
      field: "startDate",
      name: "Start Date",
      renderComponent: Link,
      resolveProps: (data) => {
        return {
          href: `reportssetup/edit/${data.startDate},${data.endDate}`
        };
      }
    },
    {
      field: "endDate",
      name: "End Date",
      dataType: "date"
    },
    {
      field: "sheetSummaryType",
      name: "Sheet Summary Type"
    }
  ];

  const dispatch = createEventDispatcher();

  const tableActions = [
    {
      icon: "pencil",
      title: "Edit",
      action: (data) => {
        navigateTo(`edgar/reportssetup/edit/${data.startDate},${data.endDate}`);
      }
    },
    {
      icon: "trash",
      title: "Inactivate",
      action: (data) => {
        dispatch("inactivateSheetContext", { code: data.code });
      }
    }
  ];

  const processResponse = (response) => {
    const processEdges = (edges) => {
      return edges.map((edge) => {
        const { node } = edge;
        return {
          startDate: dateFormatToUI(node.startDate),
          endDate: node.endDate,
          sheetSummaryType: node.sheetSummaryType
        };
      });
    };

    const { data } = response;
    const { edgarSheetSummaryContexts } = data;
    const { edges } = edgarSheetSummaryContexts;

    return {
      items: processEdges(edges),
      currentData: edgarSheetSummaryContexts
    };
  };

  const gotoAddSheetContext = () => {
    navigateTo("/edgar/reportssetup/new");
  };
</script>

<div class="w-full px-10">
  <CRUDPage
    gqlQuery={EDGAR_SHEET_SUMMARY_CONTEXTS}
    {columns}
    {tableActions}
    {processResponse}
    goToAdd={gotoAddSheetContext} />
</div>
