<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, mutate, query } from "svelte-apollo";

  import {
    BRANCHES,
    BRANCH_ATTENDANCE_CONTEXTS,
    UPDATE_BRANCH_ATTENDANCE_CONTEXT
  } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import BranchAttendanceContextForm from "./BranchAttendanceContextForm.svelte";

  const cancelAdd = () => {
    navigateTo("/calendar/branchattendance");
  };

  const client = getClient();

  // TODO: Make this into a lookup field so we don't get everything

  const branchesOp = query(client, {
    query: BRANCHES
  });

  const branchAttendanceContextOp = query(client, {
    query: BRANCH_ATTENDANCE_CONTEXTS,
    variables: {
      findCalendarBranchAttendanceContextInput: {}
    }
  });

  let loading = false;

  const addBranchAttendanceContext = async (event) => {
    try {
      const branchAttendanceContextData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: UPDATE_BRANCH_ATTENDANCE_CONTEXT,
        variables: {
          setCalendarBranchAttendanceContextInput: {
            branchCode: branchAttendanceContextData.branchCode,
            policy: {
              requiredHeadCount: branchAttendanceContextData.requiredHeadCount,
              hasAbsencePenalty: branchAttendanceContextData.hasAbsencePenalty
            },
            employeeCodes: []
          }
        }
      });
      toastNotifier.success(
        "Success!",
        `Context for ${branchAttendanceContextData.branchCode} Added`,
        7000
      );
      navigateTo("/calendar/branchattendance");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to add.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };

  const removeExistingContexts = (branchEdges, contextEdges) => {
    return branchEdges.filter(
      (branchEdge) =>
        contextEdges.findIndex(
          (contextEdge) => contextEdge.node.branch.code === branchEdge.node.code
        ) === -1
    );
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add Branch Attendance Context">
    {#await $branchesOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      {#await $branchAttendanceContextOp}
        <div class="p-10 w-full" style="min-height: 500px">
          <LoadingContent />
        </div>
      {:then branchContextResult}
        <BranchAttendanceContextForm
          cancelAction={cancelAdd}
          on:validBranchAttendanceContext={addBranchAttendanceContext}
          branches={removeExistingContexts(result.data.branches.edges, branchContextResult.data.calendarBranchAttendanceContexts.edges)}
          {loading}
          buttonLabel="Add" />
      {/await}
    {/await}
  </Panel>
</div>
