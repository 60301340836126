<script>
  import { Route, navigateTo } from "svelte-router-spa";
  import ApolloClient from "apollo-client";
  import { setClient, query } from "svelte-apollo";
  import { createHttpLink } from "apollo-link-http";
  import { setContext } from "apollo-link-context";
  import { InMemoryCache } from "apollo-cache-inmemory";

  import {
    SideNav,
    Button,
    // TextField,
    ContextMenu,
    DancingMermade
  } from "@saztrek/firefly";

  import { TENANT_CONFIGURATION } from "../../../gql";

  import { currentUser } from "../../../stores/current_user";
  import { currentTenant } from "../../../stores/current_tenant";

  import { AdminAppMap, AppMap } from "../../../utilities/appsMap";

  import AppHeaderTitle from "./AppHeaderTitle.svelte";
  import EditProfileView from "./EditProfileView.svelte";

  export let currentRoute;
  export let params;

  /* svelte router force feeds these parameters (see above).
     Console warns if we don't do the exports.
     Svelte warns if they are unused, therefore putting them down here, in case code needs it.
  */
  // eslint-disable-next-line no-unused-vars
  const paramsFromRouter = params;

  let tenantConfigurationOp;

  let showPage = false;
  let avatar;

  $: if (!$currentUser || $currentUser.id === undefined) {
    showPage = false;
  } else if ($currentUser.id === 0) {
    navigateTo("login");
  } else {
    avatar = {
      avatar: {
        firstName: $currentUser.employee.firstName,
        lastName: $currentUser.employee.lastName
      }
    };
    showPage = true;
  }

  let sideNavItems = [];

  $: if ($currentUser.roles) {
    let adminApps = [];

    for (let i = 0; i < AdminAppMap.length; i += 1) {
      const appInRoles = $currentUser.roles.find(
        (app) => app.appCode === AdminAppMap[i].id
      );
      if (appInRoles.isAdmin === "admin") {
        adminApps.push(AdminAppMap[i]);
      }
    }

    const mermadeApps = [];

    for (let i = 0; i < AppMap.length; i += 1) {
      const appInRoles = $currentUser.roles.find(
        (app) => app.appCode === AppMap[i].id
      );

      if (
        appInRoles &&
        (appInRoles.isAdmin === "admin" || appInRoles.isAdmin === "employee")
      ) {
        mermadeApps.push(AppMap[i]);
      }
    }

    if (adminApps.length > 0) {
      if ($currentUser.superAdmin) {
        adminApps = [
          {
            icon: "robin",
            id: "tenant",
            name: "Tenant Config",
            href: "/tenantconfigs"
          },
          ...adminApps
        ];
      }

      sideNavItems = [
        {
          icon: "robin",
          id: "Administration",
          name: "Administration",
          items: adminApps
        }
      ];
    }

    if (mermadeApps.length > 0) {
      sideNavItems = [
        ...sideNavItems,
        {
          icon: "mermade",
          id: "Applications",
          name: "Applications",
          items: mermadeApps
        }
      ];
    }
  }
  let client;

  $: if (showPage && !client) {
    // eslint-disable-next-line new-cap
    const httpLink = new createHttpLink({
      // uri: "https://mermade-gateway-dev-siamtjzfua-an.a.run.app/graphql"
      uri: $currentUser.gqlEndpoint
      // uri: "http://localhost:4000/graphql"
      // fetch
    });

    const authLink = setContext((_, { headers }) => {
      // TODO: check if we need to refresh token
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${$currentUser.idToken}`
        }
      };
    });

    client = new ApolloClient({
      credentials: "include",
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      onError: ({ networkError, graphQLErrors }) => {
        // eslint-disable-next-line no-console
        console.log("graphQLErrors", graphQLErrors);
        // eslint-disable-next-line no-console
        console.log("networkError", networkError);
      }
    });

    setClient(client);

    tenantConfigurationOp = query(client, {
      query: TENANT_CONFIGURATION,
      variables: {
        code: $currentUser.tenantId
      }
    });

    $tenantConfigurationOp.then((response) => {
      const { data } = response;
      const { tenantConfiguration } = data;

      currentTenant.set(tenantConfiguration);
    });
  }

  // const isSessionActive = () => {
  //   const csst = getCookie("csst");
  //   console.log("checking", csst);
  // };

  // setInterval(isSessionActive, 1000);

  const toggleSideNav = () => {
    const sideNav = document.getElementById("sideNav");

    if (sideNav.classList.contains("sideNavHidden")) {
      sideNav.classList.remove("sideNavHidden");
      sideNav.classList.add("sideNavShow");
    } else {
      sideNav.classList.remove("sideNavShow");
      sideNav.classList.add("sideNavHidden");
    }
  };
</script>

{#if !showPage}
  <div class="w-full h-full flex items-center justify-center flex-col">
    <DancingMermade />
  </div>
{:else}

  <!-- <div class="flex flex-row h-full">
    <div class="pt-4 pl-4 h-full w-64" style="border-right-color: #D3DAE6;">
      <SideNav items={sideNavItems} />
    </div>
    <div class="px-4 pt-1 w-full">
      <Header {sections} />
      <Route {currentRoute} />
    </div>

  </div> -->

  <!-- <div class="flex flex-col min-h-screen body">
    <div class="px-4 pt-1 sticky top-0 z-50">
      <Header {sections} />
    </div>
    <div class="flex flex-1 pt-4 px-4 content">
      <div class="flex-1">
        <Route {currentRoute} />
      </div>
      <div class="nav" style="flex: 0 0 12em; order: -1;">
        <SideNav items={sideNavItems} />
      </div>
    </div>
  </div> -->

  <div class="leading-normal tracking-normal mt-12">
    <nav
      class="pt-2 md:pt-1 pb-1 px-2 mt-9 h-auto fixed w-full z-20 top-2
      shadow-lg bg-white">
      <!-- <Header {sections} /> -->
      <div class="flex flex-wrap items-center justify-between">
        <div class="left flex flex-row items-center">
          <div class="flex flex-row items-center">
            <Button appIcon="mermade" on:click={toggleSideNav} />
            <AppHeaderTitle {currentRoute} />
          </div>
        </div>
        <div class="right flex flex-row items-center">
          <div>
            <ContextMenu
              triggerComponent={Button}
              triggerProps={avatar}
              renderComponent={EditProfileView}
              resolveProps={() => {}} />
          </div>

        </div>

      </div>

    </nav>
    <div class="flex flex-col md:flex-row">
      <div
        id="sideNav"
        style="max-width: 250px;"
        class="fixed top-12 px-2 mt-12 pt-2 overflow-hidden transition-all
        duration-75 ease-in md:w-4/12 lg:w-2/12 w-full h-full sideNavHidden
        md:left-0 bg-white z-50 break-words">
        <SideNav items={sideNavItems} />

      </div>
      <div class="md:ml-64 mt-2 md:mt-2 pt-2 pb-24 md:pb-5 md:w-10/12">
        <Route {currentRoute} />
      </div>
    </div>
  </div>
{/if}
