const createLine = ({
  lineID,
  initLeftX,
  initLeftY,
  initRightX,
  initRightY,
  createKonvaLine,
  createKonvaText,
  initDetails,
  initReference
}) => {
  let leftX = initLeftX;
  let leftY = initLeftY;
  let rightX = initRightX;
  let rightY = initRightY;

  let details = initDetails;
  const reference = initReference;

  if (!initDetails) {
    details = { strokeColor: "#0000ff", skuLength: "", sku: "" };
  }

  const skuLengthTextID = `${lineID}-skuLengthText`;
  let konvaLineText;

  const konvaLine = createKonvaLine({
    lineID,
    initLeftX,
    initLeftY,
    initRightX,
    initRightY,
    details
  });

  if (details.skuLength) {
    if (konvaLineText) {
      konvaLineText.destroy();
    }
    konvaLineText = createKonvaText({
      leftX,
      leftY,
      rightX,
      rightY,
      details: {
        ...details,
        skuLengthTextID
      }
    });
  }

  const update = ({ newLeftX, newLeftY, newRightX, newRightY, newDetails }) => {
    if (newLeftX) {
      leftX = newLeftX;
    }
    if (newLeftY) {
      leftY = newLeftY;
    }

    if (newRightX) {
      rightX = newRightX;
    }

    if (newRightX) {
      rightY = newRightY;
    }

    if (newDetails) {
      details = newDetails;

      if (details.skuLength) {
        if (konvaLineText) {
          konvaLineText.destroy();
        }
        konvaLineText = createKonvaText({
          leftX,
          leftY,
          rightX,
          rightY,
          details: {
            ...details,
            skuLengthTextID
          }
        });
      }
    }
  };

  const destroy = () => {
    konvaLine.destroy();
    if (konvaLineText) {
      konvaLineText.destroy();
    }
  };

  const getLine = () => {
    return {
      lineID,
      leftX,
      leftY,
      rightX,
      rightY,
      konvaLine,
      destroy,
      update,
      details,
      konvaLineText,
      reference
    };
  };

  const toJSON = () => {
    return {
      lineID,
      leftX,
      leftY,
      rightX,
      rightY,
      details,
      reference
    };
  };

  return {
    lineID,
    leftX,
    leftY,
    rightX,
    rightY,
    konvaLine,
    update,
    getLine,
    destroy,
    details,
    toJSON,
    konvaLineText,
    reference
  };
};

module.exports = createLine;
