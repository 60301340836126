<script>
  import { Table, Link } from "@saztrek/firefly";

  export let edges;
  const columns = [
    {
      field: "id",
      name: "ID",
      hidden: true
    },
    {
      field: "name",
      name: "Name",
      renderComponent: Link,
      resolveProps: (data) => {
        return {
          href: `/jobs/view/${data.id}`
        };
      }
    },
    {
      field: "createdOn",
      name: "Date",
      type: "date"
    },
    {
      field: "completed",
      name: "Completed?"
    },
    {
      field: "expectedRecords",
      name: "Expected #",
      type: "number"
    },
    {
      field: "processedRecords",
      name: "Processed #",
      type: "number"
    },
    {
      field: "successfulRecords",
      name: "Successful #",
      type: "number"
    },
    {
      field: "failedRecords",
      name: "Failed #",
      type: "number"
    }
  ];
</script>

<div>
  <div class="pt-3 pl-3 pr-3 pb-8">
    <Table {columns} items={edges} />
  </div>
</div>
