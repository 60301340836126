<script>
  import papa from "papaparse";
  import { navigateTo } from "svelte-router-spa";
  import { Button } from "@saztrek/firefly";

  import { currentUser } from "../../../stores/current_user";

  const buildRoles = (user) => {
    const {
      isDONUser,
      isEDGARUser,
      isCalendarUser,
      isNEMOUser,
      isPS3User
    } = user;

    const resolveFlag = (flag) => (flag === "1" ? "employee" : false);

    const rolesArray = [
      {
        appCode: "don",
        appName: "DON",
        isAdmin: resolveFlag(isDONUser)
      },
      {
        appCode: "edgar",
        appName: "EDGAR",
        isAdmin: resolveFlag(isEDGARUser)
      },
      {
        appCode: "users",
        appName: "Users",
        isAdmin: false
      },
      {
        appCode: "branches",
        appName: "Branches",
        isAdmin: false
      },
      {
        appCode: "skus",
        appName: "SKUs",
        isAdmin: false
      },
      {
        appCode: "calendar",
        appName: "Calendar",
        isAdmin: resolveFlag(isCalendarUser)
      },
      {
        appCode: "nemo",
        appName: "NEMO",
        isAdmin: resolveFlag(isNEMOUser)
      },
      {
        appCode: "ps3",
        appName: "PS3",
        isAdmin: resolveFlag(isPS3User)
      }
    ];
    return rolesArray;
  };

  const validateUser = (user, listOfUsers) => {
    const {
      email,
      initialPassword,
      firstName,
      middleName,
      lastName,
      employeeNumber,
      roleGroup,
      isDONUser,
      isEDGARUser,
      isCalendarUser
    } = user;
    let errors = [];

    if (
      !email ||
      !initialPassword ||
      !firstName ||
      !middleName ||
      !lastName ||
      !employeeNumber ||
      !roleGroup
    ) {
      errors = [
        ...errors,
        "No email, password, firstName, middleName, lastName, employeeNumber or roleGroup"
      ];
    }

    if (
      listOfUsers.filter((userToFilter) => userToFilter.email === email)
        .length > 1
    ) {
      errors = [...errors, "Duplicate email address"];
    }

    if (
      listOfUsers.filter(
        (userToFilter) => userToFilter.employeeNumber === employeeNumber
      ).length > 1
    ) {
      errors = [...errors, "Duplicate employee number"];
    }

    if (isDONUser === "1" && user.donBranches.length === 0) {
      errors = [...errors, "DON User but no assignments"];
    }

    if (isEDGARUser === "1" && user.edgarBranches.length === 0) {
      errors = [...errors, "EDGAR User but no assignments"];
    }

    if (isCalendarUser === "1" && user.calendarBranches.length === 0) {
      errors = [...errors, "Calendar User but no assignments"];
    }

    const roles = buildRoles(user);

    return {
      ...user,
      // isSupervisor,
      roles,
      errors
    };
  };

  const validateUsersData = (data) => {
    // const { data } = usersToImport;
    return data.map((user) => {
      return validateUser(user, data);
    });
  };

  const flattenData = (usersToImport) => {
    const { data } = usersToImport;

    const listOfEmails = data.map((user) => user.email);

    const uniqueEmails = Array.from(new Set(listOfEmails));

    return uniqueEmails.map((uEmail) => {
      const listOfRows = data.filter((datum) => datum.email === uEmail);

      const firstData = listOfRows[0];

      const {
        email,
        tenantId,
        initialPassword,
        firstName,
        middleName,
        lastName,
        employeeNumber,
        roleGroup,
        supervisorCode,
        isDONUser,
        isEDGARUser,
        isCalendarUser,
        isNEMOUser,
        isPS3User
      } = firstData;

      const donBranches = listOfRows
        .filter((userRow) => userRow.donBranch)
        .map((userRow) => userRow.donBranch);
      const edgarBranches = listOfRows
        .filter((userRow) => userRow.edgarBranch)
        .map((userRow) => userRow.edgarBranch);
      const calendarBranches = listOfRows
        .filter((userRow) => userRow.calendarBranch)
        .map((userRow) => userRow.calendarBranch);

      return {
        email,
        tenantId,
        initialPassword,
        firstName,
        middleName,
        lastName,
        employeeNumber,
        roleGroup,
        supervisorCode,
        isDONUser,
        isEDGARUser,
        isCalendarUser,
        isNEMOUser,
        isPS3User,
        donBranches,
        edgarBranches,
        calendarBranches
      };
    });
  };

  let hasError = false;
  let currentImportSet = [];

  const csvParseDone = (data) => {
    const flattenedData = flattenData(data);

    const validatedData = validateUsersData(flattenedData);

    hasError = !!validatedData.find(
      (validatedUser) => validatedUser.errors.length > 0
    );

    currentImportSet = validatedData;
  };

  // eslint-disable-next-line func-names
  const csvUploaded = function () {
    currentImportSet = [];
    const inputCSV = this.files[0];
    papa.parse(inputCSV, {
      complete: csvParseDone,
      header: true,
      skipEmptyLines: true
    });
  };
  let loading = false;

  const uploadData = async () => {
    loading = true;
    let url = "";

    if (
      $currentUser.gqlEndpoint ===
      "https://prod-mermade-gateway-siamtjzfua-an.a.run.app/graphql"
    ) {
      url = "https://prod-manatee-siamtjzfua-an.a.run.app//importUsers";
    } else {
      url = "https://dev-manatee-siamtjzfua-an.a.run.app/importUsers";
    }
    // const url = "http://localhost:4069/importUsers";

    await fetch(url, {
      method: "POST",
      body: JSON.stringify({ data: currentImportSet }),
      cache: "no-cache",
      mode: "cors",
      Origin: "http://localhost",
      // "Access-Control-Allow-Credentials": true,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer: ${$currentUser.idToken}`
      }
    });
    loading = false;

    navigateTo(`jobs`);
  };
</script>

<div class="w-full h-full">
  <div class="flex-row w-full">
    <input id="usersCSV" type="file" on:change={csvUploaded} />
  </div>

  <div>
    {#if currentImportSet.length > 0}
      <div
        class="py-8 flex flex-row w-full"
        class:justify-center={hasError}
        class:justify-end={!hasError}>
        {#if hasError}
          <div class="rounded-md bg-dangerLight p-8 text-sm">
            Errors found in current import. Please fix prior to uploading.
          </div>
        {:else}
          <div>
            <Button
              text="Upload"
              on:click={uploadData}
              {loading}
              color="secondary" />
          </div>
        {/if}
      </div>
      <div class="w-full border border-darkerGray rounded py-4 px-4">
        <table class="table-auto text-sm w-full">
          <thead class="font-bold text-xs">
            <tr>
              <th class="px-4 py-2">Tenant ID</th>
              <th class="px-4 py-2">Email</th>
              <th class="px-4 py-2">Employee Number</th>
              <th class="px-4 py-2">First Name</th>
              <th class="px-4 py-2">Last Name</th>
              <th class="px-4 py-2">Middle Name</th>
              <th class="px-4 py-2">Supervisor</th>
              <th class="px-4 py-2">Role Group</th>
              <th class="px-4 py-2">DON User?</th>
              <th class="px-4 py-2">EDGAR User?</th>
              <th class="px-4 py-2">Calendar User?</th>
              <th class="px-4 py-2">DON Branches</th>
              <th class="px-4 py-2">EDGAR Branches</th>
              <th class="px-4 py-2">Calendar Branches</th>
              <th class="px-4 py-2">Validity</th>
            </tr>
          </thead>
          <tbody>
            {#each currentImportSet as user}
              <tr class="text-xs h-10">
                <td class="border border-darkerGray px-4 py-2">
                  {user.tenantId}
                </td>
                <td class="border border-darkerGray px-4 py-2">{user.email}</td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.employeeNumber}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.firstName}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.lastName}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.middleName}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.supervisorCode}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.roleGroup}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.isDONUser === '1' ? 'Yes' : 'No'}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.isEDGARUser === '1' ? 'Yes' : 'No'}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.isCalendarUser === '1' ? 'Yes' : 'No'}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.donBranches.join(',')}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.edgarBranches.join(',')}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {user.calendarBranches.join(',')}
                </td>
                <td class="border border-darkerGray px-4 py-2">
                  {#if user.errors.length > 0}
                    {#each user.errors as error}
                      <div class="text-danger font-bold">{error}</div>
                    {/each}
                  {:else}
                    <div class="text-secondary font-bold">Ok</div>
                  {/if}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    {/if}

  </div>

</div>
