<script>
  import { createEventDispatcher } from "svelte";
  import {
    TextField,
    Button,
    EmptyButton,
    SelectField,
    toastNotifier,
    Tabs,
    TabList,
    TabPanel,
    Tab
  } from "@saztrek/firefly";

  import DeactivateModal from "./DeactivateModal.svelte";
  import DNDList from "../../components/DNDList.svelte";

  export let mode;
  export let cancelAction;

  export let code;
  export let name;
  export let baseAddress;
  export let zip;
  export let city;
  export let province;
  export let regionCode;
  export let latitude;
  export let longitude;
  export let regionName;

  export let chains;

  export let version;

  export let buttonLabel;

  export let loading = false;

  export let chainCode;

  export let edgarBranchCarryTemplates;
  export let carryTemplates;
  export let weekSupplyConfigurations;

  carryTemplates = carryTemplates
    .filter(
      (carryTemplate) =>
        edgarBranchCarryTemplates.findIndex(
          (edgarCarryTemplate) =>
            edgarCarryTemplate.code === carryTemplate.node.code
        ) < 0
    )
    .map((edge) => edge.node);

  let currentSKUs;

  // TODO: temporarily just get first carry template from edgarBranch

  currentSKUs =
    edgarBranchCarryTemplates.length > 0
      ? edgarBranchCarryTemplates[0].skus.edges.map((skuEdge) => {
          const weekSupplyConfigForSKU = weekSupplyConfigurations.find(
            (weekSupplyConfiguration) =>
              weekSupplyConfiguration.sku.code === skuEdge.node.code
          );

          return {
            id: skuEdge.node.id,
            code: skuEdge.node.code,
            name: skuEdge.node.name,
            weekSupply: weekSupplyConfigForSKU
              ? weekSupplyConfigForSKU.weekSupply
              : ""
          };
        })
      : [];

  const dispatch = createEventDispatcher();
  let listOfChains;

  listOfChains = chains.map((edge) => {
    const { node } = edge;
    return {
      option: `${node.code} - ${node.name}`,
      value: node.code
    };
  });

  listOfChains.unshift({ option: "Choose Chain", value: null });

  const requiredFieldsFilled = () => {
    return !!(code && name && chainCode && baseAddress);
  };

  const submitForm = () => {
    if (requiredFieldsFilled()) {
      if (mode === "add") {
        dispatch("validBranch", {
          code: code.trim(),
          name: name.trim(),
          baseAddress: baseAddress.trim(),
          chainCode: chainCode.trim(),
          province: province.trim(),
          zip: zip.trim(),
          city: city.trim(),
          regionCode: regionCode.trim(),
          regionName: regionName.trim(),
          coordinates: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude)
          },
          carryTemplateCodes: edgarBranchCarryTemplates.map(
            (edgarBranchCarryTemplate) => edgarBranchCarryTemplate.code
          )
        });
      } else {
        dispatch("validBranch", {
          code: code.trim(),
          name: name.trim(),
          baseAddress: baseAddress.trim(),
          version,
          chainCode: chainCode.trim(),
          province: province.trim(),
          zip: zip.trim(),
          city: city.trim(),
          regionCode: regionCode.trim(),
          regionName: regionName.trim(),
          coordinates: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude)
          },
          carryTemplateCodes: edgarBranchCarryTemplates.map(
            (edgarBranchCarryTemplate) => edgarBranchCarryTemplate.code
          ),
          weekSupplyConfiguration: currentSKUs
            .filter((sku) => sku.weekSupply !== "")
            .map((sku) => {
              return {
                skuCode: sku.code,
                weekSupply: sku.weekSupply
              };
            })
        });
      }
    } else {
      toastNotifier.warning(
        "Required fields.",
        "Please fill in required fields",
        7000
      );
    }
  };

  const inactivateBranch = () => {
    dispatch("inactivateBranch", { code });
  };

  const resolveReadOnly = () =>
    mode === "edit" ? { readonly: true } : undefined;

  const updateEdgarBranchCarryTemplates = (event) => {
    const items = { ...event.detail };
    edgarBranchCarryTemplates = items.items;
  };
</script>

{#if mode === 'edit'}
  <div class="w-full text-right pr-12">
    <DeactivateModal branchCode={code} confirmEvent={inactivateBranch} />
  </div>
{/if}
<form on:submit|preventDefault={submitForm}>
  <Tabs>
    <TabList>
      <Tab>Details</Tab>
      <Tab>Carry Templates</Tab>
    </TabList>
    <TabPanel>
      <div class="p-4 w-full">
        <TextField
          bind:textValue={code}
          {...resolveReadOnly()}
          placeholder="Code..."
          type="text"
          label="Code" />
      </div>
      <div class="p-4 w-full">
        <TextField
          bind:textValue={name}
          placeholder="Name..."
          type="text"
          label="Name" />
      </div>
      <div class="p-4 w-full">
        <SelectField
          bind:value={chainCode}
          options={listOfChains}
          label="Manufacturer" />
      </div>
      <div class="p-4 w-full">
        <TextField
          bind:textValue={baseAddress}
          placeholder="Base Address..."
          type="text"
          label="Base Address" />
      </div>
      <div class="p-4 w-full">
        <TextField
          bind:textValue={zip}
          placeholder="Zip Code..."
          type="text"
          label="Zip Code" />
      </div>
      <div class="p-4 w-full">
        <TextField
          bind:textValue={city}
          placeholder="City..."
          type="text"
          label="City" />
      </div>
      <div class="p-4 w-full">
        <TextField
          bind:textValue={province}
          placeholder="Province..."
          type="text"
          label="Province" />
      </div>
      <div class="p-4 w-full">
        <TextField
          bind:textValue={regionCode}
          placeholder="Region Code..."
          type="text"
          label="Region Code" />
      </div>
      <div class="p-4 w-full">
        <TextField
          bind:textValue={regionName}
          placeholder="Region Name..."
          type="text"
          label="Region Name" />
      </div>
      <div class="p-4 w-full">
        <TextField
          bind:textValue={latitude}
          placeholder="Latitude..."
          type="text"
          label="Latitude" />
      </div>
      <div class="p-4 w-full">
        <TextField
          bind:textValue={longitude}
          placeholder="Longitude..."
          type="text"
          label="Longitude" />
      </div>
    </TabPanel>
    <TabPanel>
      <div style="max-width: 90%; min-width: 90%;" class="mx-5">
        <div class="flex flex-row pt-2 w-full ">DON Assignments</div>
        <div class="flex flex-row w-full">
          <div class="w-full p-2">
            <DNDList
              items={carryTemplates}
              title="Carry Templates"
              type="carryTemplate" />
          </div>
          <div class="w-full p-2">
            <DNDList
              items={edgarBranchCarryTemplates}
              on:changedItems={updateEdgarBranchCarryTemplates}
              title="Assigned"
              type="carryTemplate" />
          </div>

        </div>
        <div
          class="rounded px-5 mx-2 shadow border-darkerGray py-2 overflow-y-auto
          w-full"
          style="min-height: 300px; max-height: 300px; max-width: 99%">
          <div class="w-full">
            {#each currentSKUs as sku (sku.code)}
              <div
                class="flex flex-row justify-between w-full px-5
                border-darkerGray rounded shadow h-20 my-2 pt-1">
                <div class="w-10/12 flex flex-col">
                  <div class="text-sm pr-2">{sku.name}</div>
                  <div class="text-xs">{sku.code}</div>
                </div>

                <div class="w-2/12">
                  <TextField
                    bind:textValue={sku.weekSupply}
                    label="Week Supply"
                    compressed={true}
                    type="number" />
                </div>
              </div>
            {/each}
          </div>
        </div>
      </div>
    </TabPanel>
  </Tabs>

  {#if mode === 'edit'}
    <input type="hidden" bind:value={version} />
  {/if}
  <div class="w-full flex flex-row-reverse justify-evenly pb-2 mt-8 px-4">
    <Button text={buttonLabel} color="primary" type="submit" {loading} />
    <div class="w-25" />
    <div class="text-center">
      <EmptyButton text="Cancel" color="text" on:click={cancelAction} />
    </div>
  </div>
</form>
