<script>
  import { getClient, query } from "svelte-apollo";

  import {
    BRANCHES,
    DON_ASSIGNMENT,
    EDGAR_ASSIGNMENT,
    CALENDAR_ASSIGNMENT
  } from "../../../gql";

  import LoadingIndicator from "../../components/LargeLoadingIndicator.svelte";

  import DNDList from "../../components/DNDList.svelte";

  export let user;
  let assignedDonBranches = [];
  let assignedEdgarBranches = [];
  let assignedCalendarBranches = [];

  const client = getClient();

  // let loading = true;

  const branchesOp = query(client, {
    query: BRANCHES
  });

  const donBranchAssignmentOp = query(client, {
    query: DON_ASSIGNMENT,
    fetchPolicy: "network-only",
    variables: {
      employeeCode: user.id
    }
  });

  const edgarBranchAssignmentOp = query(client, {
    query: EDGAR_ASSIGNMENT,
    fetchPolicy: "network-only",
    variables: {
      employeeCode: user.id
    }
  });

  const calendarBranchAssignmentOp = query(client, {
    query: CALENDAR_ASSIGNMENT,
    fetchPolicy: "network-only",
    variables: {
      findCalendarBranchAttendanceContextInput: {
        employeeCodes: [user.id]
      }
    }
  });

  // let calendarBranchAssignmentOp;

  let masterBranches = [];
  let donBranches = [];
  let edgarBranches = [];
  let calendarBranches = [];
  let originalAssignedCalendarBranches = [];

  // let assignedCalendarBranches = [];

  let assignedDonBranchesLoading = true;
  let assignedEdgarBranchesLoading = true;
  let assignedCalendarBranchesLoading = true;

  $branchesOp.then((response) => {
    const { data } = response;
    masterBranches = data.branches.edges.map((edge) => {
      const { node } = edge;
      return { ...node };
    });

    donBranches = [...masterBranches];
    edgarBranches = [...masterBranches];
    calendarBranches = [...masterBranches];

    if (user !== null && user.id) {
      $donBranchAssignmentOp
        .then((responseDonBranchAssignment) => {
          const { donEmployeeAssignment } = responseDonBranchAssignment.data;
          const branches = donEmployeeAssignment
            ? donEmployeeAssignment.branches
            : [];

          assignedDonBranches = branches.map((branch) => {
            return { code: branch.code, name: branch.name, id: branch.id };
          });
          donBranches = donBranches.filter(
            (donBranch) =>
              assignedDonBranches.findIndex(
                (assignedDonBranch) => assignedDonBranch.code === donBranch.code
              ) < 0
          );
          user.assignedDonBranches = assignedDonBranches;
          assignedDonBranchesLoading = false;
        })
        .catch((e) => console.error(e));

      $edgarBranchAssignmentOp
        .then((responseEdgarBranchAssignment) => {
          const {
            edgarEmployeeAssignment
          } = responseEdgarBranchAssignment.data;
          const branches = edgarEmployeeAssignment
            ? edgarEmployeeAssignment.branches
            : [];

          assignedEdgarBranches = branches.map((branch) => {
            return { code: branch.code, name: branch.name, id: branch.id };
          });
          edgarBranches = edgarBranches.filter(
            (edgarBranch) =>
              assignedEdgarBranches.findIndex(
                (assignedEdgarBranch) =>
                  assignedEdgarBranch.code === edgarBranch.code
              ) < 0
          );
          user.assignedEdgarBranches = assignedEdgarBranches;
          assignedEdgarBranchesLoading = false;
        })
        .catch((e) => console.error(e));

      $calendarBranchAssignmentOp
        .then((responseCalendarBranchAssignment) => {
          const {
            calendarBranchAttendanceContexts
          } = responseCalendarBranchAssignment.data;

          assignedCalendarBranches = calendarBranchAttendanceContexts.edges.map(
            (edge) => {
              const { branch } = edge.node;
              const { code, name, id } = branch;
              return {
                id,
                code,
                name
              };
            }
          );

          user.assignedCalendarBranches = assignedCalendarBranches;
          originalAssignedCalendarBranches = [...assignedCalendarBranches];

          calendarBranches = calendarBranches.filter(
            (calendarBranch) =>
              assignedCalendarBranches.findIndex(
                (assignedCalendarBranch) =>
                  assignedCalendarBranch.code === calendarBranch.code
              ) < 0
          );

          assignedCalendarBranchesLoading = false;
        })
        .catch((e) => console.error(e));
    } else {
      assignedDonBranchesLoading = false;
      assignedEdgarBranchesLoading = false;
      assignedCalendarBranchesLoading = false;
    }
  });

  const appAssignments = {};

  const isUserOfApp = (role) =>
    role.isAdmin === "employee" || role.isAdmin === "admin";

  if (user.roles) {
    const { roles } = user;

    roles.forEach((role) => {
      if (role.appCode === "don" && isUserOfApp(role)) {
        appAssignments.don = true;
      } else if (role.appCode === "edgar" && isUserOfApp(role)) {
        appAssignments.edgar = true;
      } else if (role.appCode === "calendar" && isUserOfApp(role)) {
        appAssignments.calendar = true;
      }
    });
  }

  const updateDONAssignments = (event) => {
    const items = { ...event.detail };
    user.assignedDonBranches = items.items;
  };

  const updateEDGARAssignments = (event) => {
    const items = { ...event.detail };
    user.assignedEdgarBranches = items.items;
  };

  const updateCalendarAssignments = (event) => {
    const { items } = { ...event.detail };

    user.assignedCalendarBranches = items;

    user.unassignedCalendarBranches = originalAssignedCalendarBranches.filter(
      (originalBranch) =>
        items.findIndex(
          (currentBranch) => currentBranch.code === originalBranch.code
        ) < 0
    );
  };

  const assignToDon = (event) => {
    const { item } = { ...event.detail };
    assignedDonBranches = [...assignedDonBranches, item];
    donBranches = donBranches.filter(
      (donBranch) => donBranch.code !== item.code
    );
    user.assignedDonBranches = assignedDonBranches;
  };

  const unassignDon = (event) => {
    const { item } = { ...event.detail };
    donBranches = [...donBranches, item];
    assignedDonBranches = assignedDonBranches.filter(
      (assignedDonBranch) => assignedDonBranch.code !== item.code
    );
    user.assignedDonBranches = assignedDonBranches;
  };

  const assignToEdgar = (event) => {
    const { item } = { ...event.detail };
    assignedEdgarBranches = [...assignedEdgarBranches, item];
    edgarBranches = edgarBranches.filter(
      (edgarBranch) => edgarBranch.code !== item.code
    );
    user.assignedEdgarBranches = assignedEdgarBranches;
  };

  const unassignEdgar = (event) => {
    const { item } = { ...event.detail };
    edgarBranches = [...edgarBranches, item];
    assignedEdgarBranches = assignedEdgarBranches.filter(
      (assignedEdgarBranch) => assignedEdgarBranch.code !== item.code
    );
    user.assignedEdgarBranches = assignedEdgarBranches;
  };

  const assignToCalendar = (event) => {
    const { item } = { ...event.detail };
    assignedCalendarBranches = [...assignedCalendarBranches, item];
    calendarBranches = calendarBranches.filter(
      (calendarBranch) => calendarBranch.code !== item.code
    );
    user.assignedCalendarBranches = assignedCalendarBranches;
  };

  const unassignCalendar = (event) => {
    const { item } = { ...event.detail };
    calendarBranches = [...calendarBranches, item];
    assignedCalendarBranches = assignedCalendarBranches.filter(
      (assignedCalendarBranch) => assignedCalendarBranch.code !== item.code
    );
    user.assignedCalendarBranches = assignedCalendarBranches;

    user.unassignedCalendarBranches = originalAssignedCalendarBranches.filter(
      (originalBranch) =>
        assignedCalendarBranches.findIndex(
          (currentBranch) => currentBranch.code === originalBranch.code
        ) < 0
    );
  };
</script>

<div class="w-full">

  {#if appAssignments.don === true}
    <div class="flex flex-row p-2">DON Assignments</div>
    <div class="flex flex-row">
      <div class="w-full p-2">

        {#if assignedDonBranchesLoading}
          <LoadingIndicator />
        {:else}
          <DNDList
            items={donBranches}
            title="Branches"
            type="don"
            side="left"
            on:transferItem={assignToDon} />
        {/if}
      </div>
      <div class="w-full p-2">

        {#if assignedDonBranchesLoading}
          <LoadingIndicator />
        {:else}
          <DNDList
            items={assignedDonBranches}
            title="Assigned"
            on:changedItems={updateDONAssignments}
            type="don"
            side="right"
            on:transferItem={unassignDon} />
        {/if}
      </div>
    </div>
  {/if}
  {#if appAssignments.edgar === true}
    <div class="flex flex-row p-2">EDGAR Assignments</div>
    <div class="flex flex-row">
      <div class="w-full p-2">
        {#if assignedEdgarBranchesLoading}
          <LoadingIndicator />
        {:else}
          <DNDList
            items={edgarBranches}
            title="Branches"
            type="edgar"
            side="left"
            on:transferItem={assignToEdgar} />
        {/if}
      </div>
      <div class="w-full p-2">

        {#if assignedEdgarBranchesLoading}
          <LoadingIndicator />
        {:else}
          <DNDList
            items={assignedEdgarBranches}
            on:changedItems={updateEDGARAssignments}
            title="Assigned"
            type="edgar"
            side="right"
            on:transferItem={unassignEdgar} />
        {/if}
      </div>
    </div>
  {/if}

  {#if appAssignments.calendar === true}
    <div class="flex flex-row p-2">Calendar Assignments</div>
    <div class="flex flex-row">
      <div class="w-full p-2">
        {#if assignedCalendarBranchesLoading}
          <LoadingIndicator />
        {:else}
          <DNDList
            bind:items={calendarBranches}
            title="Branches"
            type="calendar"
            side="left"
            on:transferItem={assignToCalendar} />
        {/if}
      </div>
      <div class="w-full p-2">

        {#if assignedCalendarBranchesLoading}
          <LoadingIndicator />
        {:else}
          <DNDList
            bind:items={assignedCalendarBranches}
            title="Assigned"
            type="calendar"
            side="right"
            on:changedItems={updateCalendarAssignments}
            on:transferItem={unassignCalendar} />
        {/if}
      </div>
    </div>
  {/if}

</div>
