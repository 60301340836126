<script>
  import { createEventDispatcher } from "svelte";
  import { navigateTo } from "svelte-router-spa";
  import { Link, TextField, SelectField } from "@saztrek/firefly";

  import { UsersToReset } from "../../../../middleware/database/usersToReset";
  import CRUDPageFirebase from "../../../components/CRUDPageFirebase.svelte";
  import { selectOptions } from "../../../components/defaultObjects";

  import { Functions } from "../../../../config/firebase";

  import { currentUser } from "../../../../stores/current_user";

  const dispatch = createEventDispatcher();

  const columns = [
    {
      field: "id",
      name: "Email",
      renderComponent: Link,
      resolveProps: (data) => {
        return {
          href: `/accounts/edit/${data.code}`
        };
      }
    },
    {
      field: "tenantId",
      name: "Tenant ID",
      dataType: "string"
    }
  ];

  const tableActions = [
    {
      icon: "pencil",
      title: "Edit",
      action: (data) => {
        navigateTo(`accounts/edit/${data.code}`);
      }
    },
    {
      icon: "trash",
      title: "Inactivate",
      action: (data) => {
        dispatch("inactivateAccount", { code: data.code });
      }
    }
  ];

  const findObjectInput = {
    findAccountInput: {
      code: undefined,
      name: undefined,
      version: undefined
    }
  };

  let code;
  let name;
  let isActiveList;

  const resetSearch = () => {
    code = "";
    name = "";

    return {
      findAccountInput: {
        code: undefined,
        name: undefined
      }
    };
  };

  const updateSearch = () => {
    return {
      findAccountInput: {
        code: code !== "" ? code : undefined,
        name: name !== "" ? name : undefined
      }
    };
  };

  const goToAddResetUser = () => {
    // navigateTo("accounts/new");

    const resetUserPassword = Functions.httpsCallable("allowPasswordReset");

    resetUserPassword({ email: "jomelinovejas@gmail.com" })
      .then(() => {
        console.log("reset password here");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const processResponse = (response) => {
    return response.map((data) => {
      return {
        id: data.id,
        tenantId: data.tenantId
      };
    });
  };

  const firebaseQuery = UsersToReset.findAll({
    tenantId: $currentUser.tenantId
  });
</script>

<div class="w-full px-10">
  <CRUDPageFirebase
    {firebaseQuery}
    {columns}
    {tableActions}
    {processResponse}
    {findObjectInput}
    {resetSearch}
    {updateSearch}
    goToAdd={goToAddResetUser}>
    <div slot="searchform" class="w-full grid grid-cols-1 md:grid-cols-3 gap-2">
      <TextField type="search" placeholder="Code" bind:textValue={code} />
      <TextField type="search" placeholder="Name" bind:textValue={name} />
      <SelectField options={selectOptions} bind:value={isActiveList} />
    </div>
  </CRUDPageFirebase>
</div>
