<script>
  import { LoadingContent } from "@saztrek/firefly";

  import { getClient, query } from "svelte-apollo";

  import { EDGAR_SHEET_CONTEXT_LITE } from "../../../gql";

  import BranchList from "../edgar/components/EDGARBranchList.svelte";

  export let startDate;
  export let endDate;
  export let subtitle = "";

  const client = getClient();

  const sheetsOp = query(client, {
    query: EDGAR_SHEET_CONTEXT_LITE,
    // fetchPolicy: "cache-and-network",
    variables: {
      startDate,
      endDate
    }
  });

  const processBranches = (sheetsResult) => {
    if (!sheetsResult.data.edgarSheetContext) {
      return [];
    }

    const returnData = sheetsResult.data.edgarSheetContext.branchSheets.map(
      (branchSheet) => {
        const hasSheet = branchSheet.edgarSheet !== null;
        return {
          code: branchSheet.branch.code,
          name: branchSheet.branch.name,
          skusCovered: hasSheet
            ? Math.floor(branchSheet.edgarSheet.meta.inputPercentage * 100)
            : 0,
          edgarSheet: branchSheet.edgarSheet,
          generated: hasSheet ? branchSheet.edgarSheet.meta.generated : false
        };
      }
    );

    return returnData;
  };
</script>

<div
  class="w-full h-full"
  style="min-height: 360px; max-height: 360px; height: 360px;">
  {#await $sheetsOp}
    <div class="p-6 border border-darkerGray rounded h-full overflow-y-auto">
      <LoadingContent />
      <div class="pt-10" />
      <LoadingContent />
      <div class="pt-10" />
      <LoadingContent />
    </div>
  {:then sheetsResult}
    <BranchList
      branches={processBranches(sheetsResult)}
      title="EDGAR Sheets"
      {subtitle} />
  {/await}
</div>
