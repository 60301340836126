import { FirebaseUsersToReset } from "./index";

const usersToResetDb = () => {
  const findOne = (email) => {
    return FirebaseUsersToReset.doc(email).get();
  };

  const findAll = ({ tenantId }) => {
    // TODO: Find for alternative ways to make this better.

    let query = FirebaseUsersToReset;

    query = FirebaseUsersToReset.where("tenantId", "==", tenantId);

    return query;
  };

  return Object.freeze({
    findOne,
    findAll
  });
};

const UsersToReset = usersToResetDb();

export { UsersToReset };
