const storeCodes = [
  {
    branchCode: "EASYMART",
    accountCode: "EASYMART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006387",
    accountCode: "JELOUSIL",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021018131",
    accountCode: "JESILSAM GEN.MERCH",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006378",
    accountCode: "JERISILS",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006383",
    accountCode: "3L SAN MATEO",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006386",
    accountCode: "BON BON MINIMART, INC.",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021011825",
    accountCode: "MONSAMAT",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006366",
    accountCode: "SUAVE MONTALBAN",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021007274",
    accountCode: "DECO GENERAL MERCHANDISE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021007275",
    accountCode: "DECOLORES GENERAL MDSE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021007276",
    accountCode: "SOUTHVILLE MART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021007279",
    accountCode: "DECOLORES GEN. MDSE 2",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021007273",
    accountCode: "TOPHOME GROCERY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006366",
    accountCode: "SUAVE MONTALBAN",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006371",
    accountCode: "BELMART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006377",
    accountCode: "RICHIEMART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006373",
    accountCode: "FLORLYDS",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006372-M1",
    accountCode: "SNAP SHOP",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006372-M2",
    accountCode: "SNAP SHOP 2",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000041402387-M2",
    accountCode: "CGM",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006381",
    accountCode: "SMART GROCERS",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006378",
    accountCode: "JERISILS",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006372",
    accountCode: "SNAP SHOP",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006370",
    accountCode: "SHEDANVIES",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000041402386-M1",
    accountCode: "MOFAR COGEO",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006381",
    accountCode: "SMART GROCERS",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006382-M1",
    accountCode: "BIG S",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006382-M2",
    accountCode: "BIG S BINAN",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021007364",
    accountCode: "EASY MART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006374",
    accountCode: "WISELY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006388",
    accountCode: "TWG MART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006388",
    accountCode: "TWG MART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021020729",
    accountCode: "SRS PASIG",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000041409011-M1",
    accountCode: "DIVIMART POBLACION",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021018889",
    accountCode: "SRS 5 (MONTALBAN)",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021021486",
    accountCode: "SRS SAN ISIDRO",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006368",
    accountCode: "TRIPLE D",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006370",
    accountCode: "SHEDANVIES",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006373",
    accountCode: "FLORLYDS",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000041402361",
    accountCode: "SRS 3 (SANDOVAL)",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006370",
    accountCode: "SHEDANVIES",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021010325",
    accountCode: "JAKS SMART CHOICE TRADING",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006367",
    accountCode: "JAKSMART 2",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021013407",
    accountCode: "JAKSHINE MART INC.",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000071702705",
    accountCode: "KIKO MART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000071705782",
    accountCode: "EASYLANE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000071700724",
    accountCode: "MEAD MARKETING",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006379",
    accountCode: "KWIK B",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006384",
    accountCode: "EASYMART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021006377",
    accountCode: "RICHIEMART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021018833",
    accountCode: "UNISAVE SUPERMARKET",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000041402372",
    accountCode: "MOFAR SAN MATEO",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000041402384",
    accountCode: "MOFAR MARIKINA",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000021013519",
    accountCode: "DIVIMART SANDOVAL",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-PASIG"
  },
  {
    branchCode: "1000041402386-M2",
    accountCode: "MOFAR COGEO",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402387-M1",
    accountCode: "CGM",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402386",
    accountCode: "MOFAR COGEO",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402387-M2",
    accountCode: "CGM",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402359",
    accountCode: "SRS 2 (F. MANALO/EASTMART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402377",
    accountCode: "THRIFTMART TANAY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041409011-M2",
    accountCode: "CRES J STORE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402375",
    accountCode: "REMPSON  BINANGONAN",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402376",
    accountCode: "THRIFTMART TERESA",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402358",
    accountCode: "SRS 1 (ML QUEZON/GEMS)",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041418974",
    accountCode: "CORAL",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041419565",
    accountCode: "GAISANO CAPITAL",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402385",
    accountCode: "RETAIL MASTER",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402368",
    accountCode: "EASY LANE SUPERMART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041413673",
    accountCode: "CENTERA MART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402383",
    accountCode: "SALLY ESTER",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402369",
    accountCode: "SRS 4 (RECARTE)",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402367",
    accountCode: "RAMITCHZ  ( RECARTE )",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402370",
    accountCode: "THEUSMART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402373",
    accountCode: "JAIRA & LOVELY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402374",
    accountCode: "RICKY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402366",
    accountCode: "RAMITCHZ ( WHITE PALACE)",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402365",
    accountCode: "RAMITCHZ  ( MAIN)",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402378",
    accountCode: "THRIFTMART MORONG",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041402363",
    accountCode: "THRIFTMART BINANGONAN",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041418971",
    accountCode: "BASTA ANTIPOLO",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000041413078",
    accountCode: "DIVIMART 2",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-RIZAL"
  },
  {
    branchCode: "1000071705143",
    accountCode: "ILA NANAY STORE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705044",
    accountCode: "MARCO JIMMY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705153",
    accountCode: "SRS   (NOVALICHES)",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705041",
    accountCode: "AMAVAL COMMERCIAL CORP.",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705042",
    accountCode: "ABEDNEGO STORE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705044",
    accountCode: "MARCO JIMMY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705143",
    accountCode: "ILA NANAY STORE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705143",
    accountCode: "ILA NANAY STORE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705043",
    accountCode: "YANKI GENERAL MDSE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705040",
    accountCode: "JS SMART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071706063",
    accountCode: "POPMOM GROCERY STORE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071723852",
    accountCode: "SMR",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071707133",
    accountCode: "JC PLAZA",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071706968",
    accountCode: "GL DEL MONTE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705153",
    accountCode: "SRS   (NOVALICHES)",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071716258",
    accountCode: "OMIE GEN. MERCH.",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705042",
    accountCode: "ABEDNEGO STORE",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071703548",
    accountCode: "GARCIA S/M",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071700222",
    accountCode: "TOP ONE SM",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071700222",
    accountCode: "TOP ONE SM",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705041",
    accountCode: "AMAVAL COMMERCIAL CORP.",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071711229",
    accountCode: "CALDE ZAMS ENTERPRISES",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705154",
    accountCode: "EIGHT CIRCLE MINIMART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071707233",
    accountCode: "QUINCY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071704844",
    accountCode: "WELCOME S/M",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071707234",
    accountCode: "DEE GR888T",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071704280",
    accountCode: "IVECH MKTG.",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071704279",
    accountCode: "COME N SHOP",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071704304",
    accountCode: "EVERGREEN 168 GROCERY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705044",
    accountCode: "MARCO JIMMY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071705154",
    accountCode: "EIGHT CIRCLE MINIMART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071707238",
    accountCode: "DAILY S/M",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071707011",
    accountCode: "ANSON S/M",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071707011",
    accountCode: "ANSON S/M",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071707545",
    accountCode: "SUNLIKE MART",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071790147",
    accountCode: "LUCKY SWERTE 88",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071703547",
    accountCode: "METRO SUPERMARKET",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071703547",
    accountCode: "METRO SUPERMARKET",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071701380",
    accountCode: "WELL PLATINUM 168 GROCERY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071790787",
    accountCode: "SNAPSHOP GROCERY MALANDAY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071790792",
    accountCode: "ASR",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071790788",
    accountCode: "SNAP SHOP BALAGTAS",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071790789",
    accountCode: "SNAP SHOP GROCERY (STA,M)",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071790791",
    accountCode: "A3SR GROCERY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "1000071790790",
    accountCode: "ONESNAP GROCERY",
    aor: "GMA",
    region: "NCR",
    adp: "DELTA-QC"
  },
  {
    branchCode: "2000062619687",
    accountCode: "CASANUEVA (SAN MIGUEL)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-UPPER BULACAN"
  },
  {
    branchCode: "2000062619688",
    accountCode: "PERLITA (SAN MIGUEL)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-UPPER BULACAN"
  },
  {
    branchCode: "2000062619732",
    accountCode: "JOJO GROCERY 1",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-UPPER BULACAN"
  },
  {
    branchCode: "2000062619733",
    accountCode: "JOJO GROCERY 2",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-UPPER BULACAN"
  },
  {
    branchCode: "2000062738738",
    accountCode: "PRINCECENTRAL INC (SAN MI",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-UPPER BULACAN"
  },
  {
    branchCode: "2000062619632-M1",
    accountCode: "NESABEL (SAN RAFAEL)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-UPPER BULACAN"
  },
  {
    branchCode: "2000062619632-M2",
    accountCode: "DIVIMART SAN MIGUEL",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-UPPER BULACAN"
  },
  {
    branchCode: "2000062619712",
    accountCode: "GAB MINIMART (SAPANG PALA",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062619711",
    accountCode: "QUTE GROCERY (SAMPOL MARK",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062619751",
    accountCode: "CHAZE SHOPPERS MART (SAMP",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062620991",
    accountCode: "FIYING 2 (FRANCISCO HOMES",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062619641",
    accountCode: "SIMPLICITY (MUZON)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062619699",
    accountCode: "DBCKLD (MUZON)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062620310",
    accountCode: "JC JULY GROCERY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062625364",
    accountCode: "BUDGET KING (MEYCAUAYAN)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062603011",
    accountCode: "NESABEL",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062621821",
    accountCode: "ANDREA (BANGA I)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062612699",
    accountCode: "ACE MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062603002",
    accountCode: "ADDIES",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062603030",
    accountCode: "JANETSH GENERAL MERCHANDI",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062603036",
    accountCode: "J.C.H.S 1",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062621457",
    accountCode: "BERT MARIE 2",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062603006",
    accountCode: "BERT  MARIE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062621714",
    accountCode: "EFRELEE HIPOLITO 2",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062603012",
    accountCode: "LME STAR MARKETING",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062603029",
    accountCode: "JENS",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062603025",
    accountCode: "U-GAIN GENERAL MERCHANDIS",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062603018",
    accountCode: "ANTONIOS MINIMART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062603024",
    accountCode: "EFRELEE HIPOLITO GEN. MER",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062603017",
    accountCode: "D.C. MENDOZA TRADING (C.D",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062612700",
    accountCode: "C. MENDOZA TRADING",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062612699",
    accountCode: "ACE MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062607679",
    accountCode: "DHEL S SUPERSTORE INC",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062623424",
    accountCode: "I MART GENERAL MERCHANDIS",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062612232",
    accountCode: "UNITOP SUPERMARKET",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062612778",
    accountCode: "JCGS MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062743908",
    accountCode: "UNITOP GENERAL MERCH.INC.",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-UPPER BULACAN"
  },
  {
    branchCode: "2000062738319",
    accountCode: "DIVI MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062623958",
    accountCode: "BUDGET KING (NORZAGARAY)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062619641",
    accountCode: "SIMPLICITY (MUZON)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062739827",
    accountCode: "SIMPLE CITY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062619719",
    accountCode: "WETTAS (BIGTE)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062619720",
    accountCode: "GM TUYA (BIGTE)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062740616",
    accountCode: "PRINCENTRAL INC (ANGAT)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062740617",
    accountCode: "BIDA MART CORP.",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062607251",
    accountCode: "A  A SAVERS MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-UPPER BULACAN"
  },
  {
    branchCode: "2000062619591",
    accountCode: "WANBEE CONVENIENCE STORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-UPPER BULACAN"
  },
  {
    branchCode: "2000062621254",
    accountCode: "BUDGET KING",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000062627533",
    accountCode: "DIVI-MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-LOWER BULACAN"
  },
  {
    branchCode: "2000032004280",
    accountCode: "JUMBO JENRA A.C",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032004274",
    accountCode: "BUAN S 1",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "",
    accountCode: "STM ARAYAT",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000187",
    accountCode: "CHAN GIOC MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032033896",
    accountCode: "BLACK HORSE CONSUMER GOOD",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032004262",
    accountCode: "GARBES-DIZON SUPERMARKET",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032004268",
    accountCode: "SUNSHINE SUPERSTORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000166",
    accountCode: "TOTEM DRUGSTORE  SUPERMA",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032004279",
    accountCode: "ONG SIN SIU GROCERY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000185",
    accountCode: "JOHNNY S SUPERMARKET",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000210",
    accountCode: "C.J.C CANDY STORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032033227",
    accountCode: "JCA TOYS AND CANDIES",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000163",
    accountCode: "GNO SHOP N CARRY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032008247",
    accountCode: "JCS SMKT(BUYMAXX STA RITA)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032004265",
    accountCode: "MARKET PRICE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000220",
    accountCode: "CALULUT CONV. STORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032004259",
    accountCode: "CG MART INC.",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000217",
    accountCode: "VES MART II",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032004281",
    accountCode: "ST. MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032024562",
    accountCode: "BUY ONE AND MORE MART INC",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000143",
    accountCode: "KEITHJEM MARKETING",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000202",
    accountCode: "3 SISTER (DAPDAP)",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000212",
    accountCode: "FREEWAY COMMERCIAL",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000214",
    accountCode: "SOSE S MARKETING",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032004259",
    accountCode: "CG MART INC.",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000211",
    accountCode: "THREE ES TOYS  CANDY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032004269",
    accountCode: "A.A. SAVER S MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032021661",
    accountCode: "CASHWISE SUPERMART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000183",
    accountCode: "AA GROCERY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "",
    accountCode: "STM ARAYAT",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000200",
    accountCode: "SHOPPER S LAND MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032004264",
    accountCode: "MR. JJ",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000032000144",
    accountCode: "DANZA MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-PAMPANGA"
  },
  {
    branchCode: "2000172171470",
    accountCode: "STM CABIAO",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172116964",
    accountCode: "STM  GAPAN",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172127475",
    accountCode: "R Q VARON",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172176099",
    accountCode: "NORIE  ROD GROCERY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172176098",
    accountCode: "G  V GROCERY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172171521",
    accountCode: "EVELYN",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172176100",
    accountCode: "A  S DRUGMART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172124931",
    accountCode: "STM PENARANDA",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172171645",
    accountCode: "G STAR",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172171646",
    accountCode: "PROSPERITY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172171510-M1",
    accountCode: "ROWENA",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172179072",
    accountCode: "LANINGNINGALEX SORIANO ST",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172171510-M2",
    accountCode: "ROWENA 2",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172171620",
    accountCode: "GVL SARISARI STORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172171619",
    accountCode: "CONCHITA",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172171615",
    accountCode: "GERRY  YVONNE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172171613",
    accountCode: "MYRA",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172127673",
    accountCode: "MFAS GROCERY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172127212",
    accountCode: "STM SAN ANTONIO",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000042014318",
    accountCode: "PRINCENTRAL RETAIL INC",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172176512",
    accountCode: "UPENG STORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172127417",
    accountCode: "PRINCENTRAL RETAIL INC",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000042004377",
    accountCode: "JM ABC STORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000172176097",
    accountCode: "R.G DE GUZMAN STORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-STA ROSA"
  },
  {
    branchCode: "2000042000008",
    accountCode: "MONCADA SM",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042002612",
    accountCode: "TAN HOK SUPERMARKET",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042004427",
    accountCode: "MIDTOWN SUPERMARKET",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042000040",
    accountCode: "MAY ANN STORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042000028",
    accountCode: "VHIA STORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042000474",
    accountCode: "TRIPLE S SM",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042000029",
    accountCode: "CAMILENIA STORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042000458",
    accountCode: "SILVER GOLD",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042000092",
    accountCode: "JUNS MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042005799",
    accountCode: "ABC LC MART TARLAC",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042000089",
    accountCode: "MADAM STORE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042000453",
    accountCode: "AMD SM",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000042000457",
    accountCode: "SBPN GROCERY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-TARLAC"
  },
  {
    branchCode: "2000052501403",
    accountCode: "MIKE LEN",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052501435",
    accountCode: "ELIZABETH 1 SUPERMARKET",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052501414",
    accountCode: "ELIZABETH BODEGA",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052501415",
    accountCode: "ELIZABETH SM1",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052501406",
    accountCode: "BERZON",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052501413",
    accountCode: "LIMAY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052501416",
    accountCode: "ELIZABETH BAKERY GROCERY",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052501417",
    accountCode: "ELIZABETH CONVIENCE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052501412",
    accountCode: "20 AOKS",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052501410",
    accountCode: "767 GENERAL MERCHANDISE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052501408",
    accountCode: "CORAS GRO",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052503879",
    accountCode: "GOLDEN KING SUPERMARKET",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052503884",
    accountCode: "SHOP  CARRY EXPRESS MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052504001",
    accountCode: "OSCAR",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052503883",
    accountCode: "PABLEO",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052503886",
    accountCode: "SUBIC SAVERS MART",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052503994",
    accountCode: "JELEEBEE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052503993",
    accountCode: "CIRCLE",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052503972",
    accountCode: "JHAP S GENERAL MERCHANDIZ",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052509372",
    accountCode: "RODRIGUEZ",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052503939",
    accountCode: "EVERLASTING",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "2000052503940",
    accountCode: "RAM ROD",
    aor: "NOL",
    region: "R3B",
    adp: "TPCI-OLONGAPO"
  },
  {
    branchCode: "3000023001343",
    accountCode: "TOP CHOICE SUPERMARKET",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023001343",
    accountCode: "TOP CHOICE SUPERMARKET",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023001349",
    accountCode: "MS MART",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023001368",
    accountCode: "LAUDATO",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023203589",
    accountCode: "HBR GROCERY",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023001348",
    accountCode: "LOLOS DAD",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023001350",
    accountCode: "N & M GROCERY",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "",
    accountCode: "VELAMART",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023001337",
    accountCode: "VIRMAN",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023001379",
    accountCode: "SME MINIMART",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023001365",
    accountCode: "G.E. GROCERY LANGKAAN",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023204549",
    accountCode: "MANGGAHAN GENTRISUPERMRKT",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023205195",
    accountCode: "DIVI MART",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023001341",
    accountCode: "NEEDS GROCERY",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000023001338",
    accountCode: "CELBERTS STORE",
    aor: "SOL",
    region: "R4A",
    adp: "TRIANGELEN-UPPER CAVITE"
  },
  {
    branchCode: "3000013107671",
    accountCode: "L. ABAD SUPERMARKET",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013107671",
    accountCode: "L. ABAD SUPERMARKET",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "",
    accountCode: "IMART BACOOR",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000014113192",
    accountCode: "CITYMART ENTERPRISE - BWS",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100016",
    accountCode: "CANTIMBUHAN SUPERMARKET",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100030",
    accountCode: "EMILUS RFC( MOLINO 2 )",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100029",
    accountCode: "EMILUS MART INC ( MOLINO",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013107677",
    accountCode: "R & A MALVAR",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013119252",
    accountCode: "ANCHORWAY MART",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100025",
    accountCode: "EMILUS ROSARIO",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100026",
    accountCode: "BINAKAYAN MART INC.",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000014113192",
    accountCode: "CITYMART ENTERPRISE - BWS",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013107675",
    accountCode: "SRS IMUS CORP.",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013121871",
    accountCode: "SRS MOLINO 3",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013107678",
    accountCode: "BEA ALLIESON",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100012",
    accountCode: "CONSORIO",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100034",
    accountCode: "ALAVER S GROCERY",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100013",
    accountCode: "ABANTO",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100036",
    accountCode: "RS BAUTISTA",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100032",
    accountCode: "ABRI",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013121011",
    accountCode: "HUGE CART GROCERY",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013107651",
    accountCode: "HBR (BASILLO)",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013107661",
    accountCode: "SPK",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013107662",
    accountCode: "EN-EN ENTERPRISE",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100016",
    accountCode: "CANTIMBUHAN SUPERMARKET",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013100018",
    accountCode: "REACH WAY",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3.00001E+12",
    accountCode: "SAVE EVERY DAY",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013125038",
    accountCode: "UNITOP GENERAL MERCHANDIS",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013125800",
    accountCode: "MANNY  V GEN. MERCHANDIS",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "",
    accountCode: "DIVIMART PANAPAAN",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000013125671",
    accountCode: "DIVIMART MAMBOG",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  },
  {
    branchCode: "3000053201563",
    accountCode: "WE-SERVE GROCER INC.",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201089",
    accountCode: "GOODWILL AREZA",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053207723",
    accountCode: "GW MART SAMBAT",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201559",
    accountCode: "GOODWILL STA. CRUZ",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053202293",
    accountCode: "NIKIDOK CANDY COMMERCIAL",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201570",
    accountCode: "RITA ANNIE",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053202316",
    accountCode: "SOUTH EMERALD SINILOAN",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053202294",
    accountCode: "WISE MERCHANT SINILOAN",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201091-M1",
    accountCode: "WISE MERCHANT STA. MARIA",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201091-M2",
    accountCode: "MW PAKIL",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201553",
    accountCode: "MW PAETE",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053210367",
    accountCode: "WISE MERCHANT KALAYAAN",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201554",
    accountCode: "MW LUMBAN",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053214622",
    accountCode: "SHOPPERS GW SAN PABLO INC",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201567",
    accountCode: "CAS HYPERMARKET",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053210572",
    accountCode: "SOUTH EMARALD CALAUAN",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201390",
    accountCode: "TCK GROCERY",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201389",
    accountCode: "108 DAILYMART",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053210210",
    accountCode: "MW BAY",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053208747",
    accountCode: "ARSE BAYCEL",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053213409",
    accountCode: "MW PILA",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053202298",
    accountCode: "SOUTH EMERALD PILA",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201745",
    accountCode: "SOUTH EMERALD 2",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201090",
    accountCode: "RO8 GROCERY",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201744",
    accountCode: "SOUTH EMERALD 1",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201557",
    accountCode: "JACKPOT",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201555",
    accountCode: "CANDY CITY MART",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053201556",
    accountCode: "CANDY WORLD",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000053211438",
    accountCode: "JACKPOT 888",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA CRUZ"
  },
  {
    branchCode: "3000223001690",
    accountCode: "ABANTO 888 ENTERPRISES",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001691",
    accountCode: "REYSAL SUPERMARKET BINAN",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001850",
    accountCode: "REYSAL VALUE PLUS",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001691",
    accountCode: "REYSAL SUPERMARKET BINAN",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001850",
    accountCode: "REYSAL VALUE PLUS",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001279",
    accountCode: "SME MINIMART",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223000784",
    accountCode: "UNITOP GEN. MDSE., INC.",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001189",
    accountCode: "HERNANDEZ",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223309786",
    accountCode: "GREAT HEART 1",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000033001278",
    accountCode: "SABOSA GROCERY-GREENSTAR",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223306475",
    accountCode: "GREAT HEART",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223000487",
    accountCode: "2020",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223000495",
    accountCode: "630 MART",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223000488",
    accountCode: "QUEEN ESTER",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223000488",
    accountCode: "QUEEN ESTER",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223000489",
    accountCode: "R  S SUPERMART PACITA 1",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223000491",
    accountCode: "SAN ROQUE SUPERMARKET",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001853",
    accountCode: "REYSAL SHOPRITE, INC.",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001185-M1",
    accountCode: "ABADIANO",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223300028",
    accountCode: "JOLIZA",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001185-M2",
    accountCode: "ABADIANO 1 OGROCERY",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223304471",
    accountCode: "SOUTH EMERALD CALAMBA",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223300029",
    accountCode: "TORRES TRADING CO., INC.",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001281",
    accountCode: "VILLEGAS GEN. MDSE.",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001282",
    accountCode: "NANIE  VIOLY",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001278",
    accountCode: "SABOSA GREENSTAR",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001189",
    accountCode: "HERNANDEZ",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001187",
    accountCode: "AMOR",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "",
    accountCode: "GEN IKAY",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "",
    accountCode: "SABOSA MDSE",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001273",
    accountCode: "TRES ELAZEGUIS ANGEL CO.",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223007608",
    accountCode: "AMOR 2",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223001198",
    accountCode: "LANDICHO",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223000004",
    accountCode: "GINA COMMERCIAL",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223300055",
    accountCode: "A CIRCLES GROCERY",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223303946",
    accountCode: "TOLENTINO",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223000009",
    accountCode: "STAR COMM",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223306617",
    accountCode: "SOUTH EMERALD MAMATID",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223300017",
    accountCode: "SARAH HAZEL",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000223300017",
    accountCode: "SARAH HAZEL",
    aor: "SOL",
    region: "R4A",
    adp: "SPARK-STA ROSA"
  },
  {
    branchCode: "3000063600436",
    accountCode: "BATANGAS CITIMART SHOP ON",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600434",
    accountCode: "BAYSTAR SALES INC",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600435",
    accountCode: "TJ MARC SALES CORPORATION",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600437",
    accountCode: "F-ONE MART INC",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600440",
    accountCode: "TJ MARC SALES CORP. (TANA",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600445",
    accountCode: "NEW RC ALVAREZ SUPERMART",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600449",
    accountCode: "KING AND QUEEN SUPERMART",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600438",
    accountCode: "WESTERN PINNACLE SALES IN",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600437",
    accountCode: "F-ONE MART INC",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600459",
    accountCode: "PIC N  SAVE",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600460",
    accountCode: "SID C MAIN WAREHOUSE",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600456",
    accountCode: "FORMOSA COMMERCIAL",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063603924",
    accountCode: "SIDC PALLOCAN",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "",
    accountCode: "UNITOP BATANGAS",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600430",
    accountCode: "BALAYAN COMMERCIAL CENTER",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063604950",
    accountCode: "SAVE IT SUPERMART",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600426",
    accountCode: "MIC MAC MIG (LIAN)",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "",
    accountCode: "MIC MAC MIG(NASUGBU)",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600425",
    accountCode: "SUPERMAC INC.",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600428",
    accountCode: "SHOPPER NEST GRO",
    aor: "SOL",
    region: "R4A",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063600439",
    accountCode: "CITIMART ISLAND MALL CORP",
    aor: "SOL",
    region: "R4B",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000063604067",
    accountCode: "WELLBEST MSDE SALES CO",
    aor: "SOL",
    region: "R4B",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "",
    accountCode: "GAISANO CALAPAN",
    aor: "SOL",
    region: "R4B",
    adp: "CONSUMERPLUS-WEST BATANGAS"
  },
  {
    branchCode: "3000103100012",
    accountCode: "WRAP AND CARRY",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000103100011",
    accountCode: "TSM",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000103100002",
    accountCode: "SOUTHERN TWIN I",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000103100003",
    accountCode: "SOUTHERN TWIN BII",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000103100016",
    accountCode: "SOUTH EMERALD ROSARIO",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000063603866",
    accountCode: "SIDC ROSARIO",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "",
    accountCode: "ONGVILLE PADRE GARCIA",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000103100021",
    accountCode: "TORANO",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000103100005",
    accountCode: "SAMPAGUITA MART",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000103100004",
    accountCode: "SOUTHERN TWIN III",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000103100019-M1",
    accountCode: "MS SUPERMARKET",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000103100019-M2",
    accountCode: "MS TAGUMPAY",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000103100019-M3",
    accountCode: "EBR",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "",
    accountCode: "MC GROCERY",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000103100020",
    accountCode: "SOUTH KING SUPERMARKET",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-EAST BATANGAS"
  },
  {
    branchCode: "3000233170118",
    accountCode: "GE MART",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170009",
    accountCode: "FREDDIE CALAPAN",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170114",
    accountCode: "CABALLERO MARKETING",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170005",
    accountCode: "3 KINGS",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170006",
    accountCode: "CATHY MARK",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170019",
    accountCode: "VT  SON",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170004",
    accountCode: "ROGER SY",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170002",
    accountCode: "GMD",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "",
    accountCode: "ALMART",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "",
    accountCode: "PRINCEMART",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170017",
    accountCode: "VINCE MART",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170018",
    accountCode: "TGT",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170039",
    accountCode: "K MARIE",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170123",
    accountCode: "GAISANO CAPITAL CALAPAN",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000233170121",
    accountCode: "UNITOP SUPERMARKET",
    aor: "SOL",
    region: "R4B",
    adp: "MANGO REPUBLIC-CALAPAN"
  },
  {
    branchCode: "3000183180946",
    accountCode: "HALLMART",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180035-M1",
    accountCode: "DELMER",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180035-M2",
    accountCode: "MANG IPE",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180042",
    accountCode: "BARCANCEL",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180027",
    accountCode: "SABLAYAN MERCHANDISING",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180031",
    accountCode: "RDM MINIMART",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180974",
    accountCode: "EMI ENTERPRISES",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "",
    accountCode: "SABRIMIL",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "",
    accountCode: "SAMVEMCO",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180068",
    accountCode: "UNIK GEN MDSE",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180063",
    accountCode: "KROBINS MART",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "",
    accountCode: "CATAPANG",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180062",
    accountCode: "SUMMER STORE",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "",
    accountCode: "RS MARCELO",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "",
    accountCode: "FE SAGANA",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180017",
    accountCode: "GAISANO CAPITAL SAN JOSE",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180064",
    accountCode: "NEW HAPSENG SUKIMART",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "3000183180065",
    accountCode: "NEW HAPSENG SUKIMART  2",
    aor: "SOL",
    region: "R4B",
    adp: "KIMLEDA-SAN JOSE"
  },
  {
    branchCode: "4000221001954",
    accountCode: "GAISANO GRAND MALL  ANT",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ANTIQUE"
  },
  {
    branchCode: "4000221001951",
    accountCode: "CPT MART ANTIQUE(SAN JOSE",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ANTIQUE"
  },
  {
    branchCode: "4000221001937",
    accountCode: "BUGASONG ENT",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ANTIQUE"
  },
  {
    branchCode: "4000221002051",
    accountCode: "UNITOP-ANTIQUE",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ANTIQUE"
  },
  {
    branchCode: "4000221001936",
    accountCode: "NIGNA(POBLACION)(BUGASONG",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ANTIQUE"
  },
  {
    branchCode: "4000221001948",
    accountCode: "LVS COMMERCIAL",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ANTIQUE"
  },
  {
    branchCode: "4000221001947",
    accountCode: "LICANDAS II",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ANTIQUE"
  },
  {
    branchCode: "",
    accountCode: "CITY SQUARE",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ANTIQUE"
  },
  {
    branchCode: "4000221001946",
    accountCode: "LICANDAS DALIPE",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ANTIQUE"
  },
  {
    branchCode: "4000221001941",
    accountCode: "LICANDAS SIBALOM",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ANTIQUE"
  },
  {
    branchCode: "4000191008596",
    accountCode: "IS ATRIUM SUPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005267",
    accountCode: "ILOILO GQ ENTERPRISE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005271",
    accountCode: "IS MANDURRIAO SUPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005274",
    accountCode: "GAISANO CITY",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005270",
    accountCode: "GAISANO GUANCO",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005269",
    accountCode: "GAISANO PASSI",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191007755",
    accountCode: "GAISANO OTON",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005272",
    accountCode: "LEDI JARO SUPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191007553",
    accountCode: "LEDI MAIN SUPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191007881",
    accountCode: "UNITOP 20 LEDESMA",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191007878",
    accountCode: "UNITOP GENERAL MERCHANDIS",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005273",
    accountCode: "GOLDEN HARVEST",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191016361",
    accountCode: "LONGWIN MOLO",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191000354",
    accountCode: "IS JARO SUPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191017980",
    accountCode: "GAISANO ICC MALL",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191017983",
    accountCode: "IS TAGBAK TERMINAL",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191002687",
    accountCode: "IS AREVALO SUPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191007922",
    accountCode: "IS MOLO SUPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191000353",
    accountCode: "WASHINGTON SUPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191000355",
    accountCode: "WASHINGTON COMMERCIAL",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191017807",
    accountCode: "IS UNGKA",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008081",
    accountCode: "LONGWIN JARO GROCERY STOR",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005273",
    accountCode: "GOLDEN HARVEST",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008048",
    accountCode: "NEW MASTER BAZAR",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005266",
    accountCode: "ALDEGUER SUPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000231000721",
    accountCode: "GAITAN ISLAND MART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "",
    accountCode: "PESC",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191013081",
    accountCode: "KAYE  KIM",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191000957",
    accountCode: "HANZEL MAE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191000762",
    accountCode: "ANA/ANDRIE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191000777",
    accountCode: "AJM(2K)",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191000808",
    accountCode: "JHON REY (BANADERO (POB.)",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191000770",
    accountCode: "BUENA COOP",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191016824",
    accountCode: "PRINCEPAN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191016411",
    accountCode: "TIN MART JANIUAY",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191014764",
    accountCode: "TIN MART CALINOG",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005496",
    accountCode: "RONKEN MART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191016412",
    accountCode: "NONO BELUSO",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005610",
    accountCode: "CARISMA STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191011904",
    accountCode: "XISHAN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191013141",
    accountCode: "PRINCEPAN POTOTAN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191010407",
    accountCode: "PRINCEPAN CABATUAN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "",
    accountCode: "GAISANO METRO ILOILO",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191007749",
    accountCode: "EL VINGELS STA.BARBARA",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005434",
    accountCode: "BLUE FIELDS",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191013602",
    accountCode: "BLUEFIELDS MAASIN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191011886",
    accountCode: "LVS CABATUAN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "",
    accountCode: "BLUEFIELDS STA.BARBARA WAREHOUSE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "",
    accountCode: "ELJOHN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008167",
    accountCode: "CPT IZNART ILOILO",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008158",
    accountCode: "ILOILO SOCIETY RIZAL",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008080",
    accountCode: "TAGBAK PIONEER INC BODEGA",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008045",
    accountCode: "CPT MART-OTON",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005435-M1",
    accountCode: "GREEN RIBBON",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005169",
    accountCode: "LVS TIGBAUAN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191001862",
    accountCode: "OPEN TILL LATE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191009733-M1",
    accountCode: "NULADAS TRADING 7",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005435-M2",
    accountCode: "GREEN RIBBON TIGBAUAN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "",
    accountCode: "DLS TAGBAK",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008082",
    accountCode: "D-GOLS STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191019435",
    accountCode: "TIN MART ALIMODIAN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191016361",
    accountCode: "LONGWIN MOLO",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008081",
    accountCode: "LONGWIN JARO GROCERY STOR",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191000863",
    accountCode: "LVS- SAN MIGUEL",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008163",
    accountCode: "WVSU HOSP. COOP",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008168",
    accountCode: "FORTUNE STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008048",
    accountCode: "NEW MASTER BAZAR",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008154",
    accountCode: "CITY SQUARE ILOILO",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008153",
    accountCode: "ILOILO MARISON (OTANI)",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191002169",
    accountCode: "GDITH",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008167",
    accountCode: "CPT IZNART ILOILO",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005169",
    accountCode: "LVS TIGBAUAN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005435-M3",
    accountCode: "GREEN RIBBON (MIAG-AO)",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191009734-M1",
    accountCode: "K ZEL (MIAG-AO)",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005499",
    accountCode: "LVS (MIAG-AO)",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191009734-M2",
    accountCode: "K ZEL (SAN JUAQUIN)",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191009733-M2",
    accountCode: "NULADAS TRADING 12",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005614",
    accountCode: "NT SUPERMART/NULADAS CORP",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191009736",
    accountCode: "3 STAR",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191001862",
    accountCode: "OPEN TILL LATE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005435-M4",
    accountCode: "GREEN RIBBON (GUIMBAL)",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005526",
    accountCode: "ESTANCIA SUPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191009684",
    accountCode: "TFA STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005522",
    accountCode: "RHENZ STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005602",
    accountCode: "JOEY SUPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005132",
    accountCode: "NEMS HYPERMART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191007915",
    accountCode: "TOTO JO STORE (JENNYBERT)",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191010579",
    accountCode: "GAISANO BALASAN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005523",
    accountCode: "REL MARKETING",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191010522",
    accountCode: "DSD VENTURES",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191020530",
    accountCode: "GAISANO GRAND ESTANCIA",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191021696",
    accountCode: "GAISANO GRAND SARA",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191020522",
    accountCode: "PRINCEPAN CORP ESTANCIA",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191020205",
    accountCode: "PRINCEPAN CORP SARA",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "",
    accountCode: "JUNBEE TRADING",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005601",
    accountCode: "DOS PHARMACY",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005603",
    accountCode: "MELANIE ROSE STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005598",
    accountCode: "NIEVAS STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191007532",
    accountCode: "RCB GROCERY",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005599",
    accountCode: "ETING STORE (PEDROS)",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008171",
    accountCode: "DLS JANIUAY",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191008174",
    accountCode: "DLS-POTOTAN",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000041008173",
    accountCode: "CPT - PASSI",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191007754",
    accountCode: "CECILE MART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191007753",
    accountCode: "PFMPCI COOP",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191007752",
    accountCode: "DONNA JADE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000041008173",
    accountCode: "CPT - PASSI",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005596",
    accountCode: "BEDURIAS MART",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191010408",
    accountCode: "TJ STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005595",
    accountCode: "NELMART STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191009781",
    accountCode: "SANTIMAR SNR",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005608",
    accountCode: "COLEENISA STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005607",
    accountCode: "NEMAN STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005609",
    accountCode: "ANGGO STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005498",
    accountCode: "ROSLIM STORE",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191000247",
    accountCode: "VIC MARKETING",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000191005593",
    accountCode: "EJA TRADING DUMANGAS",
    aor: "VIS",
    region: "R6",
    adp: "TRIPLE FIVE-ILO-ILO"
  },
  {
    branchCode: "4000201001495",
    accountCode: "INJAP (T.A.T.C)(ROXAS PRO",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "",
    accountCode: "MERRYMART ROXAS AVENUE",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001496",
    accountCode: "INJAP BURGOS",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001500",
    accountCode: "CITY SQUARE-ROXAS(ROXAS P",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201000398",
    accountCode: "UNITOP (ROXAS CITY)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001495",
    accountCode: "INJAP (T.A.T.C)(ROXAS PRO",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001496",
    accountCode: "INJAP (BURGOS)(ROXAS PROP",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001502",
    accountCode: "GAISANO MARKET PLACE(ROXA",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001501",
    accountCode: "GAISANO CITY ROXAS(ROXAS",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001020",
    accountCode: "ARIEL COMMERCIAL(PONTEVED",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001022",
    accountCode: "RUTH WONG(PRES.ROXAS)(POB",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001023",
    accountCode: "DLEGACY(PRES.ROXAS)(POB.P",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001024",
    accountCode: "MARIBERT(PRES.ROXAS)(POB.",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000081001024-M1",
    accountCode: "MARIBERT 2",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000081001024-M2",
    accountCode: "MARIBERT 3",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000081001024-M3",
    accountCode: "MARIBERT 4",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001021",
    accountCode: "CHINLEX(PONTEVEDRA)(BAILA",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001020",
    accountCode: "ARIEL COMMERCIAL(PONTEVED",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000201001018",
    accountCode: "ROMJA(PONTEVEDRA)(POB.PON",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-ROXAS"
  },
  {
    branchCode: "4000211000627",
    accountCode: "GAISANO CAPITAL KALIBO",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000619",
    accountCode: "ALLENS MART(KALIBO)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000616",
    accountCode: "KH MARKETING(KALIBO)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211003449",
    accountCode: "UNITOP GENERAL MERCH.INC.",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000616",
    accountCode: "KH MARKETING(KALIBO)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000618",
    accountCode: "RC SUPERMART(KALIBO)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000621",
    accountCode: "AKLAN VISAYAN(KALIBO)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000612",
    accountCode: "FARMACIA GOMEZ(KALIBO)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000761-M2",
    accountCode: "FARMACIA RESCATE 2",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000761-M1",
    accountCode: "FARMACIA RESCATE 1",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211001319",
    accountCode: "S-MART",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211001213",
    accountCode: "ST. PATRICK STORE(NEW WAS",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "",
    accountCode: "AMBITO",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000610",
    accountCode: "CITIZEN BAZAR(KALIBO)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000620",
    accountCode: "MARIANING SUPERSTORE(KALI",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000316",
    accountCode: "IBAJAY MINIMART(IBAJAY)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "",
    accountCode: "GREENERY",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000323",
    accountCode: "AYOS LANG STORE (IBAJAY)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000310",
    accountCode: "TAY-ING(IBAJAY, AKLAN)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211001494",
    accountCode: "PANDAN COOP(PANDAN)",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211001093",
    accountCode: "APHIES MINIMART (CATICLAN",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000211000286",
    accountCode: "MZM MARKETING STORE(NABAS",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "",
    accountCode: "GAISANO CAPITAL BORACAY",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "4000134000421",
    accountCode: "SANDYS MINIMART",
    aor: "VIS",
    region: "R6",
    adp: "XCEED GLOBAL-KALIBO"
  },
  {
    branchCode: "5000075000001",
    accountCode: "ER SUPERMALL",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000075000002",
    accountCode: "ER TRADING",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000075001942",
    accountCode: "NCCC MATI",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000095000055",
    accountCode: "DCWC MATI",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000075000009",
    accountCode: "GLP MARKETING",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000075000020",
    accountCode: "E.R MANAY",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000075000015",
    accountCode: "ER SUPERSTORE-LUPON",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000075000019",
    accountCode: "E.R BAGANGA",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000075000001",
    accountCode: "ER SUPERMALL",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000075000008",
    accountCode: "DONA MARKETING",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000075000012",
    accountCode: "SAN ROQUE MINI MART",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000075000003",
    accountCode: "BUENO CENTRAL",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000075000004",
    accountCode: "MATI MERCANTILE",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-MATI"
  },
  {
    branchCode: "5000105000004",
    accountCode: "NCCC SUPERMARKET NABUNTUR",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105015027",
    accountCode: "PLS MARIOGA BWS",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105001896",
    accountCode: "NICO COOPERATIVE",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105001893",
    accountCode: "SHOP AND SAVE MORE GROCER",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105014844",
    accountCode: "MABUHAY 1 COMPOSTELA",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105014845",
    accountCode: "MABUHAY 2 COMPOSTELA",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000017",
    accountCode: "NCCC CHOICE MONKAYO",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105001891",
    accountCode: "DURIAN COMMERCIAL",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105001889",
    accountCode: "GOLDEN DRAGON",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "",
    accountCode: "ANTOFEL STORE",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "",
    accountCode: "CMJ STORE",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "",
    accountCode: "LJMA STORE",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "",
    accountCode: "MAGROW MPC",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105001895",
    accountCode: "MABUHAY WAREHOUSE",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105001901",
    accountCode: "MABUHAY SHOPPING CENTER",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000095000057",
    accountCode: "DCWC NABUNTURAN",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000012",
    accountCode: "GAISANO GRAND MALL OF TAG",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000013",
    accountCode: "GAISANO MALL OF TAGUM",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000008",
    accountCode: "NCCC SUPERMARKET TAGUM",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000020",
    accountCode: "PIONEER BUENAS",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000032",
    accountCode: "V.S ENTERPRISES",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000027",
    accountCode: "588TH MINI MART",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105015443",
    accountCode: "PITRADE CENTRAL",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000029",
    accountCode: "JS GAISANO- STO.TOMAS",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000030",
    accountCode: "PADILLO TRADING",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000031",
    accountCode: "RUBERT STORE",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000036",
    accountCode: "JS GAISANO-KAPALONG",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000038",
    accountCode: "RDA STORE",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000037",
    accountCode: "MERLIAH",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105007803",
    accountCode: "TWINSIS GEN. MERCH.",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "",
    accountCode: "DARLUZ STORE",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000075000015",
    accountCode: "ER SUPERSTORE-LUPON",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105001890",
    accountCode: "ACCM",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000095000056",
    accountCode: "DCWC LUPON",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000006",
    accountCode: "NCCC CHOICE PANTUKAN",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000047",
    accountCode: "JS BANAYBANAY",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105006369",
    accountCode: "JS GAISANO -PANTUKAN",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000007",
    accountCode: "NCCC SUPERMARKET STO TOMA",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000003",
    accountCode: "NCCC CHOICE KAPALONG",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000008",
    accountCode: "NCCC SUPERMARKET TAGUM",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "",
    accountCode: "KM STORE - KAPALONG",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000010",
    accountCode: "GAISANO GRAND MALL OF NAB",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000043",
    accountCode: "UNITOP GENERAL MERCHANDIS",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "",
    accountCode: "MABUHAY KAPALONG",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "",
    accountCode: "KM STORE - KAPALONG",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000009",
    accountCode: "GAISANO CENTER TAGUM",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "",
    accountCode: "GAISANO MALL TAGUM",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000019-M1",
    accountCode: "JS GAISANO - MAWAB",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000019-M2",
    accountCode: "JS GAISANO - TAGUM",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000011",
    accountCode: "GAISANO GRAND MALL OF PAN",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000095000058",
    accountCode: "DCWC PANABO",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000045",
    accountCode: "LESS IS MORE GENERAL MERC",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000023",
    accountCode: "HERLYNS STORE",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000041",
    accountCode: "5H GENERAL MDSE.",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000014-M1",
    accountCode: "NCCC SUPERMAKET SAMAL",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000014-M2",
    accountCode: "IGACUS MULTIMART",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105002244",
    accountCode: "FERNANDEZ MINIMART",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000095000054",
    accountCode: "SAMAL DCWC",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000005",
    accountCode: "NCCC CHOICE PANABO",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000002",
    accountCode: "NCCC CHOICE CARMEN",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "",
    accountCode: "LOTS FOR LESS PANABO",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105000034",
    accountCode: "JUNBIS STORE",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000105010488",
    accountCode: "NCCC SUPERMARKET PANABO",
    aor: "MIN",
    region: "R11",
    adp: "CIMEM-TAGUM"
  },
  {
    branchCode: "5000095017548",
    accountCode: "GAISANO GRAND CITY GATE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095017353",
    accountCode: "NCCC MILAN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000010",
    accountCode: "NCCC PANACAN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000026",
    accountCode: "NCCC MAIN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000026",
    accountCode: "NCCC MAIN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000031",
    accountCode: "PARK N SHOP / NCCC VP",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000043",
    accountCode: "GREATWALL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000043",
    accountCode: "GREATWALL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000041",
    accountCode: "WVP LOPSON",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000047",
    accountCode: "SAVELOT MARKETING",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000031",
    accountCode: "PARK N SHOP / NCCC VP",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000026",
    accountCode: "NCCC MAIN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000010",
    accountCode: "NCCC PANACAN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000014",
    accountCode: "GAISANO GRAND-TIBUNGCO",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095017550",
    accountCode: "CHOICEMART DAMOSA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095008568",
    accountCode: "DCWC LANANG",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000024",
    accountCode: "CHOICEMART R.CASTILLO",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095011744",
    accountCode: "CHOICEMART MANDUG",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000017",
    accountCode: "CHOICEMART TIGATTO DMMA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000012",
    accountCode: "LOTS FOR LESS - PAMPANGA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "",
    accountCode: "LOTS FOR LESS - PAMPANGA 2",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095017551",
    accountCode: "CHOICEMART CARMELITE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000012",
    accountCode: "LOTS FOR LESS - PAMPANGA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095009199",
    accountCode: "LOTS FOR LESS - PAMPANGA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095009199",
    accountCode: "LOTS FOR LESS - PAMPANGA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095009193",
    accountCode: "LOTS FOR LESS - PAMPANGA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000020",
    accountCode: "LOTS FOR LESS - PAMPANGA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000020",
    accountCode: "LOTS FOR LESS - PAMPANGA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000009",
    accountCode: "CHOICEMART LANDMARK",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095016192",
    accountCode: "LOTS FOR LESS LIZADA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "",
    accountCode: "LOTS FOR LESS OBRERO",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000015",
    accountCode: "CHOICEMART NHA BUHANGIN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "",
    accountCode: "FELCRIS SALES",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095016192",
    accountCode: "LOTS FOR LESS LIZADA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000045",
    accountCode: "DCWC AGDAO",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095008518",
    accountCode: "HK SAMULCO",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000059",
    accountCode: "FELCRIS SUPERSTORE CENTRA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000048",
    accountCode: "FELCRIS MAGSAYSAY",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095008377",
    accountCode: "DCCS GRANDLAND",
    aor: "MIN",
    region: "R11",
    adp: "SOUTHMIN-DAVAO"
  },
  {
    branchCode: "5000095000002",
    accountCode: "NCCC CENTERPOINT",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000002",
    accountCode: "NCCC CENTERPOINT",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006321",
    accountCode: "SUNSCOR GROCERAMA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000030",
    accountCode: "GAISANO MALL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000030",
    accountCode: "GAISANO MALL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000040",
    accountCode: "GAISANO SOUTH CITIMALL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006357-M1",
    accountCode: "GAISANO GRAND-TORIL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095017090",
    accountCode: "NCCC CALINAN 2",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000022",
    accountCode: "NCCC SETRO MAA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095017542",
    accountCode: "GAISANO CENTER QUIRINO",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006357-M2",
    accountCode: "GAISANO GRAND TORIL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000005",
    accountCode: "CHOICEMART BINUGAO",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000061",
    accountCode: "FELCRIS TORIL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006331",
    accountCode: "LOTS PUAN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006330",
    accountCode: "ASTRO SHOPPERS",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095015680",
    accountCode: "TRIPLE N ENT. INC",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006323-M1",
    accountCode: "ONG KAO STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095009197-M1",
    accountCode: "2MS TALOMO",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006322",
    accountCode: "SAVE O MATIC",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006323-M2",
    accountCode: "L.A GROCERS MART",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006324",
    accountCode: "2RJB STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095009197-M2",
    accountCode: "CHOICEMART PUAN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000034",
    accountCode: "CHOICEMART CAT. PEQUENO",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006330",
    accountCode: "ASTRO SHOPPERS",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "",
    accountCode: "GAISANO CAPITAL MINTAL ",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006332",
    accountCode: "GAISANO GRAND-CALINAN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006335",
    accountCode: "D4D ENTERPRISE STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006336",
    accountCode: "LCF MINIMART",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006336",
    accountCode: "LCF MINIMART",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006337",
    accountCode: "DOMSAT SUPER STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006338",
    accountCode: "SEVEN TEN MERCHANDISE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000039",
    accountCode: "CHOICEMART MAGALLANES",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095011183",
    accountCode: "UNICITY GENERAL MERCHANDI",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "",
    accountCode: "HB1 SANDAWA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000046",
    accountCode: "FELCRIS QUIRINO",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000046",
    accountCode: "FELCRIS QUIRINO",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000019",
    accountCode: "CHOICEMART WOODLANE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095016849",
    accountCode: "LOTS FOR LESS - MAA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095017420",
    accountCode: "LOTS FOR LESS - MATINA AP",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000036",
    accountCode: "HB1 MINTAL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006333",
    accountCode: "FJAD",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006333",
    accountCode: "FJAD",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006342",
    accountCode: "MULTIPLE 8 MERCH STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006342",
    accountCode: "MULTIPLE 8 MERCH STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "",
    accountCode: "SULIT VALUE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000062",
    accountCode: "FELCRIS CALINAN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000002",
    accountCode: "NCCC CENTERPOINT",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000037",
    accountCode: "CHOICEMART BOLTON",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095000032",
    accountCode: "CHOICEMART CAT. GRANDE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095017641",
    accountCode: "NCCC CAT. GRANDE 3",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006325",
    accountCode: "SAVER PLAZA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006325",
    accountCode: "SAVER PLAZA",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000095006357",
    accountCode: "GAISANO MALL TORIL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DAVAO"
  },
  {
    branchCode: "5000145000008",
    accountCode: "GAISANO CENTER",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145002643",
    accountCode: "GAISANO MARKET PLACE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145003322",
    accountCode: "NCCC STA CRUZ",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000014",
    accountCode: "GAISANO MALL OF DIGOS",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000007",
    accountCode: "GAISANO GRAND",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000078",
    accountCode: "GOLDEN 5 STAR",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "",
    accountCode: "JJ PHINEL 2",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000077-M1",
    accountCode: "JJ PHINEL STORE 1",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000077-M2",
    accountCode: "JJ PHINEL STORE 4",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000029",
    accountCode: "CANTEROS STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000034",
    accountCode: "BBY STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000035",
    accountCode: "DC CENTRAL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000037",
    accountCode: "ANG STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000063",
    accountCode: "LISEN STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000064",
    accountCode: "MYRNA DRUG AND MINI GROCE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000026",
    accountCode: "RVM GEN MERCHANDISE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000041",
    accountCode: "MERCADO STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000032",
    accountCode: "D AND A STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145002646",
    accountCode: "ABBES",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000071",
    accountCode: "LM STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145008186",
    accountCode: "DCWC-BANSALAN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000051",
    accountCode: "ULCEN STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000028",
    accountCode: "FISH COME",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000021",
    accountCode: "SOUTH GREEN",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145003372",
    accountCode: "ALT COMMERCIAL",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000048",
    accountCode: "PENAROYA STORE",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000145000060",
    accountCode: "JK SALAZAR CORP",
    aor: "MIN",
    region: "R11",
    adp: "SOUTMIN-DIGOS"
  },
  {
    branchCode: "5000115000022-M1",
    accountCode: "SUPERAMA-COTABATO",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000023",
    accountCode: "SUPERAMA-HYPERMARKET",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000022-M2",
    accountCode: "SUPERAMA EXP.1",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000022-M3",
    accountCode: "SUPERAMA EXP.2",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000018",
    accountCode: "AFCES",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000005",
    accountCode: "COTABATO FOOD CENTER",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000015",
    accountCode: "SECOND WAVE",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000033",
    accountCode: "LUCKY PRECIUM",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000021",
    accountCode: "COTABATO ALLIED MERCHANDI",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000011",
    accountCode: "LANSH ENTERPRISES",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000001",
    accountCode: "VKO MKTG",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000024",
    accountCode: "LEY-AN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000019",
    accountCode: "COTABATO FISH TRADING",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000013",
    accountCode: "ACH",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "",
    accountCode: "SUGNI SUPER PLAZA",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000008",
    accountCode: "DE ROSE",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000060",
    accountCode: "J ONDOY",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000048",
    accountCode: "FRA",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000058",
    accountCode: "SERDENA",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000041",
    accountCode: "7R",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "",
    accountCode: "DE ROSE SAVERS",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000059-M1",
    accountCode: "J ONDOY MIDSAYAP QUALITY",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000027",
    accountCode: "UNI GLOW MASTER",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000020",
    accountCode: "DEROSE MAIN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000009",
    accountCode: "RAQUEL STORE",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000055",
    accountCode: "ONE STOP MINI",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000059-M2",
    accountCode: "MIDSAYAP QUALITY",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000038-M1",
    accountCode: "SURVIVE 1 kABACAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000044-M1",
    accountCode: "AMPLAYO",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000056",
    accountCode: "3R GEN MERCHANDISE",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000034",
    accountCode: "SUPERAMA- KABACAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000053",
    accountCode: "MAE JOY ARIES",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000043",
    accountCode: "PARTNERS GROCERAMA",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000057",
    accountCode: "M AND J TABELLA",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000044-M2",
    accountCode: "AMPALAYO2",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000044-M3",
    accountCode: "AMPALAYO AVENUE",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000036",
    accountCode: "KMCC",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000038-M2",
    accountCode: "SURVIVE 1 DAWAY",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000035-M1",
    accountCode: "SURVIVE MKTG 2",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000035-M2",
    accountCode: "SURVIVE PEREZ",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000039",
    accountCode: "KASAPID  ",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000054-M1",
    accountCode: "0610 GROCERY",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000054-M2",
    accountCode: "SHAN-C",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000145008181",
    accountCode: "DCWCKIDAPAWAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000115000042",
    accountCode: "GAISANO GRAND",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-KIDAPAWAN"
  },
  {
    branchCode: "5000085002999",
    accountCode: "KCC MALL OF GENSAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002999",
    accountCode: "KCC MALL OF GENSAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002999",
    accountCode: "KCC MALL OF GENSAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002999",
    accountCode: "KCC MALL OF GENSAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002999",
    accountCode: "KCC MALL OF GENSAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002999",
    accountCode: "KCC MALL OF GENSAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003007",
    accountCode: "GAISANO GRAND POLOMOLOK",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003018",
    accountCode: "PRICE MART",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002996",
    accountCode: "FITMART GENSAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002998",
    accountCode: "GAISANO MALL GENSAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002994",
    accountCode: "SUNTRADING",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003021-M1",
    accountCode: "ANOCHES01",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003021-M2",
    accountCode: "ANOCHES 02",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002995",
    accountCode: "RENGEL MART",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003002",
    accountCode: "KIMSAN PLAZA",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003000",
    accountCode: "FITMART TACURONG",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003023",
    accountCode: "ADOFELS SUPERMART",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003028",
    accountCode: "AMIGO MARBEL",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002972",
    accountCode: "RONALDS GROCERY MARBEL",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003008",
    accountCode: "FRIENDLY MART SURALLAH",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085008283",
    accountCode: "MAUNLAD SHOPPING PLAZA",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002976",
    accountCode: "ALOCADA",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002977",
    accountCode: "OCTAVIANO STORE",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003001",
    accountCode: "SWANA",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "",
    accountCode: "RONALDS GROCERY ISULAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002971-M1",
    accountCode: "NIKKI GROCERY",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002969-M1",
    accountCode: "LAPIDEZ",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002969-M2",
    accountCode: "MARY ANN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002971-M2",
    accountCode: "NEW VALENCIA",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002967",
    accountCode: "CENTRAL PLAZA",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003003",
    accountCode: "ACE CENTERPOINT",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003004",
    accountCode: "KCC MALL OF MARBEL",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003004",
    accountCode: "KCC MALL OF MARBEL",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003004",
    accountCode: "KCC MALL OF MARBEL",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003005",
    accountCode: "GAISANO GRAND KORONADAL",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003004",
    accountCode: "KCC MALL OF MARBEL",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085003015",
    accountCode: "JEWI MART",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "",
    accountCode: "FRIENDLY MART POLOMOLOK",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "5000085002997",
    accountCode: "SUNMART CALUMPANG",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "",
    accountCode: "FRIENDLY MART-GENSAN",
    aor: "MIN",
    region: "R12",
    adp: "DAVAO REACH-GENSAN"
  },
  {
    branchCode: "3000013124302",
    accountCode: "SAVE EVERY DAY",
    aor: "SOL",
    region: "R4A",
    adp: "MSA-DASMA-BACOOR"
  }

  // special branches
];

module.exports = { storeCodes };
