<script>
  import { Modal, Button } from "@saztrek/firefly";

  const triggerComponent = Button;
  const triggerProps = { icon: "trash" };

  export let cancelEvent;
  export let confirmEvent;

  export let brandCode;
</script>

<Modal
  {triggerComponent}
  {triggerProps}
  title="Inactivate Brand?"
  on:cancel={cancelEvent}
  on:confirm={confirmEvent}
  cancelButtonText="Cancel"
  confirmButtonText="Inactivate"
  buttonColor="danger">
  <p class="mb-6">Your are about to inactivate {brandCode}</p>
</Modal>
