<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, query, mutate } from "svelte-apollo";

  import { dateFormatToServer } from "../../../../utilities/dateFormatter";

  import {
    SIMPLE_EDGAR_SHEET_SUMMARY_CONTEXT,
    EDIT_EDGAR_SHEET_SUMMARY_CONTEXT
  } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import SheetContextForm from "./EDGARSheetSummaryContextForm.svelte";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  const cancelUpdate = () => {
    navigateTo("/edgar/reportssetup");
  };

  const client = getClient();

  let loading = false;

  const sheetContextOp = query(client, {
    query: SIMPLE_EDGAR_SHEET_SUMMARY_CONTEXT,
    fetchPolicy: "network-only",
    variables: {
      startDate: dateFormatToServer(decodeURIComponent(id).split(",")[0]),
      endDate: decodeURIComponent(id).split(",")[1],
      sheetSummaryType: "WEEKLY"
    }
  });

  //   const inactivateSheetContext = async event => {
  //     const sheetContextData = { ...event.detail };
  //     loading = true;
  //     try {
  //       await mutate(client, {
  //         mutation: INACTIVATE_CATEGORY,
  //         variables: {
  //           code: sheetContextData.code
  //         }
  //       });
  //       toastNotifier.success(
  //         "Success!",
  //         `SheetContext ${sheetContextData.code} has been deactivated`,
  //         7000
  //       );
  //       navigateTo("/sheetContexts");
  //     } catch (error) {
  //       loading = false;
  //       toastNotifier.danger("Failed to update.", `${error}`, 7000);
  //       // eslint-disable-next-line no-console
  //       console.error("cannot mutate", error.message);
  //     }
  //   };

  const editSheetContext = async (event) => {
    try {
      const sheetContextData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: EDIT_EDGAR_SHEET_SUMMARY_CONTEXT,
        variables: {
          editEDGARSheetSummaryContextInput: {
            ...sheetContextData
          }
        }
      });
      toastNotifier.success("Success!", `Sheet Summary Context Updated`, 7000);
      navigateTo("/edgar/reportssetup");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Update SheetContext">
    {#await $sheetContextOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      <SheetContextForm
        mode="edit"
        startDate={result.data.edgarSheetSummaryContext.startDate}
        endDate={result.data.edgarSheetSummaryContext.endDate}
        sheetSummaryType={result.data.edgarSheetSummaryContext.sheetSummaryType}
        version={result.data.edgarSheetSummaryContext.version}
        on:validSheetContext={editSheetContext}
        cancelAction={cancelUpdate}
        {loading}
        buttonLabel="Update" />
    {/await}

  </Panel>
</div>
