<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, query, mutate } from "svelte-apollo";

  import {
    EDIT_CATEGORY,
    GET_CATEGORY,
    INACTIVATE_CATEGORY
  } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import CategoryForm from "./CategoryForm.svelte";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  const cancelUpdate = () => {
    navigateTo("/categories");
  };

  const client = getClient();

  let loading = false;

  const categoryOp = query(client, {
    query: GET_CATEGORY,
    variables: {
      code: decodeURIComponent(id)
    }
  });

  const inactivateCategory = async (event) => {
    const categoryData = { ...event.detail };
    loading = true;
    try {
      await mutate(client, {
        mutation: INACTIVATE_CATEGORY,
        variables: {
          code: categoryData.code
        }
      });
      toastNotifier.success(
        "Success!",
        `Category ${categoryData.code} has been deactivated`,
        7000
      );
      navigateTo("/categories");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };

  const editCategory = async (event) => {
    try {
      const categoryData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: EDIT_CATEGORY,
        variables: {
          editCategoryInput: {
            code: categoryData.code,
            name: categoryData.name,
            version: categoryData.version
          }
        }
      });
      toastNotifier.success(
        "Success!",
        `Category ${categoryData.name} Updated`,
        7000
      );
      navigateTo("/categories");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Update Category">
    {#await $categoryOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      <CategoryForm
        mode="edit"
        code={result.data.category.code}
        name={result.data.category.name}
        version={result.data.category.version}
        on:validCategory={editCategory}
        on:inactivateCategory={inactivateCategory}
        cancelAction={cancelUpdate}
        {loading}
        buttonLabel="Update" />
    {/await}

  </Panel>
</div>
