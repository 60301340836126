<script>
  import dayjs from "dayjs";
  import {
    Icon,
    LoadingContent,
    // TextField,
    Button,
    EmptyButton,
    toastNotifier
  } from "@saztrek/firefly";
  import { getClient, query } from "svelte-apollo";

  import {
    SHEETS,
    DON_SHEETS_LITE,
    BRANCHES,
    GET_SHEET
  } from "../../../../gql";
  import {
    dateFormatToServer,
    dateFormatToUI
  } from "../../../../utilities/dateFormatter";

  import { exportToCSV } from "../../../../utilities/export";

  import { currentUser } from "../../../../stores/current_user";

  import { nearestEndDay } from "../../edgar/edgarUtils";

  import BranchList from "../components/DONBranchList.svelte";

  const isDaily = true;
  const daysToIncrement = 1;

  //   import CardView from "./CardView.svelte";
  import TableView from "../components/TableView.svelte";

  import { getTag } from "../common";

  let currentStartDay = dayjs();
  let currentEndDay = nearestEndDay;

  const client = getClient();

  const sheetsOp = query(client, {
    query: DON_SHEETS_LITE,
    // fetchPolicy: "cache-and-network",
    variables: {
      findDONSheetInput: {
        inventoryDate: dateFormatToServer(currentStartDay.toDate())
      }
    }
  });

  const branchesOp = query(client, {
    query: BRANCHES
  });

  let singleSheetOp;
  let allSheetsOp;

  let skus = [];

  let currentSheetCode = "";
  let currentBranch;

  const processBranches = (sheetsResult, branchList) => {
    if (!branchList.data.branches) {
      return [];
    }

    const branchData = branchList.data;
    const sheetsData = sheetsResult.data;

    const { branches } = branchData;
    const { donSheets } = sheetsData;

    const returnData = branches.edges.map((edge) => {
      const { node } = edge;
      const branchCode = node.code;

      const donSheet = donSheets.edges.find(
        (donEdge) => donEdge.node.branch.code === branchCode
      );

      return {
        code: node.code,
        name: node.name,
        skusCovered:
          donSheet && donSheet.node.meta
            ? Math.floor(donSheet.node.meta.availablePercentage * 100)
            : 0,
        donSheet
      };
    });

    return returnData;
  };

  const processSKUs = (response) => {
    return response.donSheet.skus.edges.map((skuEdge) => {
      const { node } = skuEdge;
      const { details, data } = node;
      const { timestamps, branch } = response.donSheet;
      const { tags } = details;

      const hasTimestamps = timestamps && timestamps.length > 0;

      const segment = getTag(tags, "segment");

      const dateCreated = response.donSheet.inventoryDate;
      const timeCreated = hasTimestamps
        ? dayjs(timestamps[0]).format("HH:mm:ss")
        : "";

      const lastTimestamp = timestamps[timestamps.length - 1];

      const dateSync = hasTimestamps
        ? dateFormatToServer(dayjs(lastTimestamp).toDate())
        : "";
      const timeSync = hasTimestamps
        ? dayjs(lastTimestamp).format("HH:mm:ss")
        : "";

      return {
        code: details.code,
        name: details.name,
        unitCount: details.unitCount,
        core: details.core ? "Core" : "Regular",
        oos: data.oos ? "Yes" : "No",
        suggestedOrder: data.suggestedOrder,
        branchCode: branch.code,
        branchName: branch.name,
        brandCode: details.brand.code,
        brandName: details.brand.name,
        categoryCode: details.category.code,
        categoryName: details.category.name,
        inventoryDate: dateCreated,
        chainCode: branch.chain.code,
        chainName: branch.chain.name,
        segment: segment ? segment.value : "",
        dateCreated,
        timeCreated,

        dateSync,
        timeSync
      };
    });
  };

  let originalSKUs;
  let loadingSKUs = false;

  const sheetsSKUCache = new Map();

  const branchSelected = (event) => {
    const { branch } = { ...event.detail };

    skus = [];
    loadingSKUs = true;

    if (!branch.donSheet) {
      currentSheetCode = null;

      loadingSKUs = false;
    } else {
      currentSheetCode = branch.donSheet.node.code;
      currentBranch = branch;
      const cachedSKUs = sheetsSKUCache.get(currentSheetCode);

      if (cachedSKUs) {
        skus = cachedSKUs;
        originalSKUs = skus;
        loadingSKUs = false;
      } else {
        singleSheetOp = query(client, {
          query: GET_SHEET,
          variables: {
            code: currentSheetCode
          }
        });

        $singleSheetOp.then((response) => {
          skus = processSKUs(response.data);
          originalSKUs = skus;
          loadingSKUs = false;
          sheetsSKUCache.set(currentSheetCode, [...skus]);
        });
      }
    }
  };

  let searchSKUCode;
  let searchSKUName;

  $: if (searchSKUCode || searchSKUName) {
    if (searchSKUCode) {
      skus = skus.filter(
        (sku) =>
          searchSKUCode !== "" &&
          sku.code.toUpperCase().indexOf(searchSKUCode.toUpperCase()) > -1
      );
    }

    if (searchSKUName) {
      skus = skus.filter(
        (sku) =>
          searchSKUName !== "" &&
          sku.name.toUpperCase().indexOf(searchSKUName.toUpperCase()) > -1
      );
    }
  } else if (searchSKUCode === "" && searchSKUName === "") {
    skus = originalSKUs;
  }

  const previousWeek = () => {
    currentSheetCode = "";
    skus = [];
    currentStartDay = currentStartDay.subtract(daysToIncrement, "day");
    currentEndDay = currentEndDay.subtract(daysToIncrement, "day");
  };

  const nextWeek = () => {
    currentSheetCode = "";
    skus = [];
    currentStartDay = currentStartDay.add(daysToIncrement, "day");
    currentEndDay = currentEndDay.add(daysToIncrement, "day");
  };

  $: sheetsOp.refetch({
    findDONSheetInput: {
      inventoryDate: dateFormatToServer(currentStartDay.toDate()),
      branch: $currentUser.employee.defaultChain
        ? {
            chain: {
              code: $currentUser.employee.defaultChain
            }
          }
        : undefined
    }
  });

  $: if ($currentUser.employee.defaultChain) {
    branchesOp.refetch({
      findBranchInput: {
        chainCode: $currentUser.employee.defaultChain
      }
    });
  }

  //   let isCardView = true;

  //   const toggleView = () => {
  //     isCardView = !isCardView;
  //   };

  const exportCurrentSheet = () => {
    const cols = [
      `"OOS"`,
      `"SKU Code"`,
      `"SKU Name"`,
      `"UOM"`,
      `"Core"`,
      `"Suggested Order"`,
      `"Brand Code"`,
      `"Brand Name"`,
      `"Category Code"`,
      `"Category Name"`,
      `"Segment"`,
      `"Branch Code"`,
      `"Branch Name"`,
      `"Chain Code"`,
      `"Chain Name"`,
      `"Date Created"`,
      `"Date Sync"`,
      `"Time Created"`,
      `"Time Sync"`
    ]
      .join(",")
      .concat("\n");

    const rows = skus
      .map(
        (node) =>
          `"${node.oos}","${node.code}","${node.name}","${node.unitCount}","${node.core}","${node.suggestedOrder}","${node.brandCode}","${node.brandName}","${node.categoryCode}","${node.categoryName}","${node.segment}","${node.branchCode}","${node.branchName}","${node.chainCode}","${node.chainName}","${node.dateCreated}","${node.timeCreated}","${node.dateSync}","${node.timeSync}"`
      )
      .join("\r\n");

    exportToCSV(cols, rows, `${currentSheetCode}`);
  };

  let exportAllLoading = false;

  const exportAll = () => {
    exportAllLoading = true;
    allSheetsOp = query(client, {
      query: SHEETS,
      variables: {
        findDONSheetInput: {
          inventoryDate: dateFormatToServer(currentStartDay.toDate()),
          branch: $currentUser.employee.defaultChain
            ? {
                chain: {
                  code: $currentUser.employee.defaultChain
                }
              }
            : undefined
        }
      }
    });

    $allSheetsOp
      .then((response) => {
        let allSheets = [];

        const { data } = response;
        const { donSheets } = data;
        const { edges } = donSheets;

        edges.forEach((branchSheet) => {
          allSheets = [
            ...allSheets,
            processSKUs({ donSheet: branchSheet.node })
          ];
        });

        if (allSheets.length === 0) {
          exportAllLoading = false;
          toastNotifier.danger(
            "No Sheets Found!",
            `There are no EDGAR Sheets in this coverage`,
            7000
          );
        } else {
          const cols = [
            `"Branch Code"`,
            `"Branch Name"`,
            `"Chain Code"`,
            `"Chain Name"`,
            `"OOS"`,
            `"SKU Code"`,
            `"SKU Name"`,
            `"UOM"`,
            `"Core"`,
            `"Suggested Order"`,
            `"Brand Code"`,
            `"Brand Name"`,
            `"Category Code"`,
            `"Category Name"`,
            `"Segment"`,
            `"Date Created"`,
            `"Date Sync"`,
            `"Time Created"`,
            `"Time Sync"`
          ]
            .join(",")
            .concat("\n");

          const flattenedSheets = allSheets.flat();

          const rows = flattenedSheets
            .map(
              (node) =>
                `"${node.branchCode}","${node.branchName}","${node.chainCode}","${node.chainName}","${node.oos}","${node.code}","${node.name}","${node.unitCount}","${node.core}","${node.suggestedOrder}","${node.brandCode}","${node.brandName}","${node.categoryCode}","${node.categoryName}","${node.segment}","${node.dateCreated}","${node.timeCreated}","${node.dateSync}","${node.timeSync}"`
            )
            .join("\r\n");

          exportToCSV(
            cols,
            rows,
            `All DON Sheets for ${dateFormatToUI(currentStartDay)}`
          );

          exportAllLoading = false;

          toastNotifier.success(
            "Success!",
            `Export successful, waiting for download.`,
            7000
          );
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        toastNotifier.danger(
          "Something went wrong!",
          `Export failed, please contact the administrator`,
          7000
        );
      });
  };
</script>

<div class="m-0 pb-3 text-base flex flex-row items-center justify-center">
  <div class="w-2/12">
    <Button
      text="Export All"
      on:click={exportAll}
      color="secondary"
      loading={exportAllLoading} />
  </div>
  <div class="justify-center w-8/12 flex flex-row">
    <div>
      <EmptyButton
        icon="arrowLeft"
        text=""
        size="tiny"
        on:click={previousWeek} />
    </div>
    <div class="w-56 text-center">
      {#if !isDaily}
        {dateFormatToUI(currentStartDay.toDate())} to {dateFormatToUI(currentEndDay.toDate())}
      {:else}{dateFormatToUI(currentStartDay.toDate())}{/if}
    </div>
    <div>
      <EmptyButton icon="arrowRight" text="" size="tiny" on:click={nextWeek} />
    </div>
  </div>
  <div
    class="w-1/12 flex flex-row justify-end"
    class:justify-between={currentSheetCode !== '' && !loadingSKUs}>
    <!-- <Button
      text={isCardView ? 'List View' : 'Card View'}
      on:click={toggleView} /> -->
    {#if currentSheetCode !== '' && !loadingSKUs}
      <Button color="secondary" text="Export" on:click={exportCurrentSheet} />
    {/if}
  </div>
</div>

<div
  class="flex flex-row w-full md:-mx-2 justify-between"
  style="min-height: 945px; max-height: 945px; height: 945px;">
  <div class="w-3/12 lg:3/12 px-2 h-full">
    {#await $sheetsOp}
      <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
      </div>
    {:then sheetsResult}
      {#if sheetsResult.loading}
        <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
        </div>
      {:else}
        {#await $branchesOp}
          <div
            class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
          </div>
        {:then branchesResult}
          <BranchList
            branches={processBranches(sheetsResult, branchesResult)}
            on:branchSelected={branchSelected} />
        {/await}
      {/if}

    {/await}
  </div>
  <div class="w-9/12 lg:4/12 pt-2 px-2 h-full border-lightGray rounded border">
    {#if currentSheetCode}
      <div class="w-full flex flex-row pb-4 px-2 h-24">
        <div class="flex flex-col justify-between w-1/2">
          <div class="flex flex-col font-bold">
            <div class="text-sm">{currentSheetCode}</div>
            <div class="text-xs">
              {currentBranch.code} - {currentBranch.name}
            </div>
          </div>
        </div>
        <div class="flex-row flex w-1/2">
          <!-- <div class="flex flex-col w-1/2 text-xs">
            <div class="font-bold">Inventory Dates</div>
            <div class="text-xs h-12 overflow-y-auto">
              {#each currentBranch.edgarSheet.inventoryDates as inventoryDate}
                <div>{dateFormatToUI(inventoryDate)}</div>
              {/each}
            </div>
          </div> -->
          <!-- <div class="flex flex-col w-1/2 text-xs">
            <div class="font-bold ">Delivery Dates</div>
            <div class="text-xs h-12 overflow-y-auto">
              {#each currentBranch.edgarSheet.deliveryDates as deliveryDate}
                <div>{dateFormatToUI(deliveryDate)}</div>
              {/each}
            </div>
          </div> -->

        </div>
      </div>
    {/if}
    <!-- {#if isCardView}
      <CardView {skus} {loadingSKUs} />
    {:else}
      <TableView {skus} {loadingSKUs} />
    {/if} -->

    <TableView {skus} {loadingSKUs} />

    <div class="h-full w-full flex flex-col items-center justify-center">
      {#if currentSheetCode === ''}
        <Icon size="l" type="table" />
        <div class="pt-2">
          {#if currentSheetCode === ''}Choose an EDGAR Sheet on the left.{/if}
          <!-- {:else}No Sheet created yet for this branch.{/if} -->
        </div>
      {/if}
    </div>

  </div>
</div>
