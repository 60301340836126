<script>
  import { LoadingContent } from "@saztrek/firefly";

  import { getClient, query } from "svelte-apollo";

  import { DON_SHEETS_LITE, BRANCHES } from "../../../gql";

  import BranchList from "../don/components/DONBranchList.svelte";

  export let inventoryDate;

  export let subtitle = "";

  const client = getClient();

  const sheetsOp = query(client, {
    query: DON_SHEETS_LITE,
    // fetchPolicy: "cache-and-network",
    variables: {
      findDONSheetInput: {
        inventoryDate
      }
    }
  });

  const branchesOp = query(client, {
    query: BRANCHES
  });

  const processBranches = (sheetsResult, branchList) => {
    if (!branchList.data.branches) {
      return [];
    }

    const branchData = branchList.data;
    const sheetsData = sheetsResult.data;

    const { branches } = branchData;
    const { donSheets } = sheetsData;

    const returnData = branches.edges.map((edge) => {
      const { node } = edge;
      const branchCode = node.code;

      const donSheet = donSheets.edges.find(
        (donEdge) => donEdge.node.branch.code === branchCode
      );

      return {
        code: node.code,
        name: node.name,
        skusCovered:
          donSheet && donSheet.node.meta
            ? Math.floor(donSheet.node.meta.availablePercentage * 100)
            : 0,
        donSheet
      };
    });

    return returnData;
  };
</script>

<div
  class="w-full h-full"
  style="min-height: 360px; max-height: 360px; height: 360px;">
  {#await $sheetsOp}
    <div class="p-6 border border-darkerGray rounded h-full overflow-y-auto">
      <LoadingContent />
      <div class="pt-10" />
      <LoadingContent />
      <div class="pt-10" />
      <LoadingContent />
    </div>
  {:then sheetsResult}
    {#await $branchesOp}
      <div class="p-6 border border-darkerGray rounded h-full overflow-y-auto">
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
      </div>
    {:then branchesResult}
      <BranchList
        branches={processBranches(sheetsResult, branchesResult)}
        title="DON Sheets"
        {subtitle} />

    {/await}
  {/await}
</div>
