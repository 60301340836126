<script>
  import { createEventDispatcher } from "svelte";
  import {
    TextField,
    Button,
    EmptyButton,
    SelectField,
    toastNotifier
  } from "@saztrek/firefly";

  // import DeactivateModal from "./DeactivateModal.svelte";

  export let mode;
  export let cancelAction;

  export let branchList;

  let fromDate;
  let toDate;
  let branchCode;
  const branchOptions = branchList.map((branchEdge) => {
    const { node } = branchEdge;
    return {
      option: node.name,
      value: node.code
    };
  });

  branchOptions.unshift({ value: null, option: "Choose a Branch" });

  export let buttonLabel;

  export let loading = false;

  const dispatch = createEventDispatcher();

  const requiredFieldsFilled = () => {
    return !!(fromDate && toDate && branchCode);
  };

  const submitForm = () => {
    if (requiredFieldsFilled()) {
      if (mode === "add") {
        dispatch("validSheetContext", {
          fromDate,
          toDate,
          branchCode
        });
      } else {
        dispatch("validSheetContext", {
          fromDate,
          toDate,
          branchCode
        });
      }
    } else {
      toastNotifier.warning(
        "Required fields.",
        "Please fill in required fields",
        7000
      );
    }
  };

  const resolveReadOnly = () =>
    mode === "edit" ? { readonly: true } : undefined;
</script>

{#if mode === 'edit'}
  <div class="w-full text-right pr-12">
    <!-- <DeactivateModal sheetContextCode={code} confirmEvent={inactivateSheetContext} /> -->
  </div>
{/if}
<form on:submit|preventDefault={submitForm}>
  <div class="p-4 w-full">
    <SelectField
      bind:value={branchCode}
      options={branchOptions}
      label="Summary Type" />
  </div>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={fromDate}
      {...resolveReadOnly()}
      type="date"
      label="Start Date" />
  </div>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={toDate}
      {...resolveReadOnly()}
      type="date"
      label="End Date" />
  </div>

  <div class="w-full flex flex-row-reverse justify-evenly pb-2 mt-8 px-4">
    <Button text={buttonLabel} type="submit" color="primary" {loading} />
    <div class="w-25" />
    <div class="text-center">
      <EmptyButton text="Cancel" color="text" on:click={cancelAction} />
    </div>
  </div>
</form>
