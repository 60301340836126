import { gql } from "apollo-boost";

// QUERIES
const CATEGORIES = gql`
  query categories(
    $findCategoryInput: FindCategoryInput
    $connectionArgs: ConnectionArgs
  ) {
    categories(
      findCategoryInput: $findCategoryInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        cursor
        node {
          id
          code
          name
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const GET_CATEGORY = gql`
  query category($code: String!) {
    category(code: $code) {
      id
      code
      name
      active
      version
    }
  }
`;

// MUTATIONS

const ADD_CATEGORY = gql`
  mutation addCategory($addCategoryInput: AddCategoryInput!) {
    addCategory(addCategoryInput: $addCategoryInput) {
      id
      code
      name
      active
      version
    }
  }
`;

const EDIT_CATEGORY = gql`
  mutation editCategory($editCategoryInput: EditCategoryInput!) {
    editCategory(editCategoryInput: $editCategoryInput) {
      id
      code
      name
      active
      version
    }
  }
`;

const INACTIVATE_CATEGORY = gql`
  mutation inactivateCategory($code: String!) {
    inactivateCategory(code: $code) {
      id
      code
      name
      active
      version
    }
  }
`;

const ACTIVATE_CATEGORY = gql`
  mutation activateCategory($code: String!) {
    activateCategory(code: $code) {
      id
      code
      name
      active
      version
    }
  }
`;

export {
  CATEGORIES,
  GET_CATEGORY,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  INACTIVATE_CATEGORY,
  ACTIVATE_CATEGORY
};
