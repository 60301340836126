<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier } from "@saztrek/firefly";
  import { getClient, mutate } from "svelte-apollo";

  import { ADD_MANUFACTURER } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import ManufacturerForm from "./ManufacturerForm.svelte";

  const cancelAdd = () => {
    navigateTo("manufacturers");
  };

  const client = getClient();

  let loading = false;

  const addManufacturer = async (event) => {
    try {
      const manufacturerData = { ...event.detail };
      loading = true;
      await mutate(client, {
        mutation: ADD_MANUFACTURER,
        variables: {
          addManufacturerInput: {
            code: manufacturerData.code,
            name: manufacturerData.name
          }
        }
      });
      toastNotifier.success(
        "Success!",
        `Manufacturer ${manufacturerData.name} Added`,
        7000
      );
      navigateTo("manufacturers");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to add.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add Manufacturer">
    <ManufacturerForm
      mode="add"
      on:validManufacturer={addManufacturer}
      cancelAction={cancelAdd}
      {loading}
      buttonLabel="Add" />
  </Panel>
</div>
