const createBay = require("./bays");

const createPS3Manager = ({
  createKonvaStage,
  createKonvaLayer,
  createKonvaLine,
  createKonvaText,
  createKonvaImage,
  baysData,
  processedSheet,
  containers,
  wrapAnchors
}) => {
  const bays = baysData.map((bayData, i) => {
    let processedBay;
    if (processedSheet) {
      processedBay = processedSheet.find((sheet) => sheet.bayID === bayData.id);
    }

    return createBay({
      createKonvaStage,
      createKonvaLayer,
      createKonvaLine,
      createKonvaText,
      createKonvaImage,
      data: bayData,
      container: containers[i],
      wrapAnchors,
      processedBay
    });
  });

  let selectedBay;

  const getBays = () => bays;

  const getBay = (bayId) => bays.find((bay) => bay.id === bayId);

  const setCurrentBay = (id) => {
    const bayToSelect = bays.find((bay) => bay.id === id);

    if (bayToSelect) {
      selectedBay = bayToSelect;
    } else {
      throw new Error("bayID Not found in bays array");
    }
  };

  const getCurrentBay = () => {
    return selectedBay;
  };

  const getReport = () => {
    return bays.map((bay) => {
      return { id: bay.id, bayReport: bay.getReport() };
    });
  };

  const toJSON = () => {
    return {
      jsonData: bays.map((bay) => bay.toJSON())
    };
  };

  return {
    getBays,
    setCurrentBay,
    getCurrentBay,
    getReport,
    getBay,
    toJSON
  };
};

module.exports = createPS3Manager;
