<script>
  export let name;
  export let code;
  export let offtakeUnits;
  export let totalBeginningUnits;
  export let noData;
</script>

<div class="border-b border-darkerGray" style="height: 6.5rem;">
  <div class="flex flex-row justify-between items-start py-2 px-4">
    <div class="flex flex-col items-start pr-3">
      <div class="text-xs font-bold italic">{code}</div>
      <div class="text-xs">{name}</div>
    </div>
    <div class="flex flex-col">
      <div class="text-right">
        <div class="text-xs">Offtake</div>
        <div class="text-lg font-bold">{offtakeUnits}</div>
      </div>
      <div class="text-right">
        <div class="text-xs">Begin</div>
        <div class="text-lg font-bold">
          {totalBeginningUnits !== null ? totalBeginningUnits : 'N/A'}
        </div>
      </div>
    </div>
  </div>

</div>
