<script>
  import { KeypadMenu } from "@saztrek/firefly";
  import { navigateTo } from "svelte-router-spa";

  const items = [
    {
      label: "SKUs",
      action: () => navigateTo("/skus/list"),
      app: "mermade"
    },
    {
      label: "Brands",
      action: () => navigateTo("/brands"),
      app: "don"
    },
    {
      label: "Manufacturers",
      action: () => navigateTo("/manufacturers"),
      app: "edgar"
    },
    {
      label: "Categories",
      action: () => navigateTo("/categories"),
      app: "edgar"
    },
    {
      label: "Carry Templates",
      action: () => navigateTo("/carrytemplates"),
      app: "edgar"
    }
  ];
</script>

<div class="flex justify-center pt-20">
  <KeypadMenu {items} size="l" />
</div>
