<script>
  import { KeypadMenu } from "@saztrek/firefly";
  import { navigateTo } from "svelte-router-spa";
  import { currentUser } from "../../../stores/current_user";

  const roleTypeForDON = $currentUser.roles.find(
    (role) => role.appCode === "don"
  ).isAdmin;

  let items = [
    {
      label: "Sheets",
      action: () => navigateTo("/don/sheets"),
      app: "mermade"
    },
    {
      label: "Compliance",
      action: () => navigateTo("/don/compliance"),
      app: "mermade"
    },
    {
      label: "Reports",
      action: () => navigateTo("/don/reports"),
      app: "mermade"
    }
  ];

  if (roleTypeForDON === "admin") {
    items = [
      ...items,
      {
        label: "Carry Templates",
        action: () => navigateTo("/carrytemplates"),
        app: "don"
      },
      {
        label: "Reports2",
        action: () => navigateTo("/don/reports2"),
        app: "mermade"
      },
      {
        label: "Reports3",
        action: () => navigateTo("/don/reports3"),
        app: "mermade"
      }
    ];
  }
</script>

<div class="flex justify-center pt-20">
  <KeypadMenu {items} size="l" />
</div>
