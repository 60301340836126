<script>
  import { createEventDispatcher } from "svelte";
  import {
    TextField,
    Button,
    EmptyButton,
    toastNotifier
  } from "@saztrek/firefly";

  import DeactivateModal from "./DeactivateModal.svelte";

  export let mode;
  export let cancelAction;

  export let code;
  export let name;

  export let version;

  export let buttonLabel;

  export let loading = false;

  const dispatch = createEventDispatcher();

  const requiredFieldsFilled = () => {
    return !!(code && name);
  };

  const submitForm = () => {
    if (requiredFieldsFilled()) {
      if (mode === "add") {
        dispatch("validAccount", { code: code.trim(), name: name.trim() });
      } else {
        dispatch("validAccount", {
          code: code.trim(),
          name: name.trim(),
          version
        });
      }
    } else {
      toastNotifier.warning(
        "Required fields.",
        "Please fill in required fields",
        7000
      );
    }
  };

  const inactivateAccount = () => {
    dispatch("inactivateAccount", { code });
  };

  const resolveReadOnly = () =>
    mode === "edit" ? { readonly: true } : undefined;
</script>

{#if mode === 'edit'}
  <div class="w-full text-right pr-12">
    <DeactivateModal manufacturerCode={code} confirmEvent={inactivateAccount} />
  </div>
{/if}
<form on:submit|preventDefault={submitForm}>
  <div class="p-6 w-full">
    <TextField
      bind:textValue={code}
      {...resolveReadOnly()}
      placeholder="Code..."
      type="text"
      required="true"
      label="Code" />
  </div>
  <div class="p-6 w-full">
    <TextField
      bind:textValue={name}
      placeholder="Name..."
      type="text"
      required="true"
      label="Name" />
  </div>
  {#if mode === 'edit'}
    <input type="hidden" bind:value={version} />
  {/if}
  <div class="w-full flex flex-row-reverse justify-evenly pb-2 mt-8 px-4">
    <Button text={buttonLabel} color="primary" {loading} />
    <div class="w-25" />
    <div class="text-center">
      <EmptyButton text="Cancel" color="text" on:click={cancelAction} />
    </div>
  </div>
</form>
