<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, mutate, query } from "svelte-apollo";

  import { ADD_CHAIN, ACCOUNTS } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import ChainForm from "./ChainForm.svelte";

  const cancelAdd = () => {
    navigateTo("chains");
  };

  const client = getClient();

  // TODO: Make this into a lookup field so we don't get everything

  const accountsOp = query(client, {
    query: ACCOUNTS
  });

  let loading = false;

  const addChain = async (event) => {
    try {
      const chainData = { ...event.detail };
      loading = true;
      await mutate(client, {
        mutation: ADD_CHAIN,
        variables: {
          addChainInput: {
            code: chainData.code,
            name: chainData.name,
            accountCode: chainData.accountCode
          }
        }
      });
      toastNotifier.success("Success!", `Chain ${chainData.name} Added`, 7000);
      navigateTo("chains");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to add.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add Chain">
    {#await $accountsOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      <ChainForm
        mode="add"
        on:validChain={addChain}
        cancelAction={cancelAdd}
        accounts={result.data.accounts.edges}
        {loading}
        buttonLabel="Add" />
    {/await}
  </Panel>
</div>
