<script>
  /* eslint-disable no-param-reassign */
  /* eslint-disable no-underscore-dangle */
  /* eslint-disable no-loop-func */
  import {
    // Icon,
    LoadingContent,
    // TextField,
    Button,
    EmptyButton
    // toastNotifier
  } from "@saztrek/firefly";
  import { getClient, query } from "svelte-apollo";

  import dayjs from "dayjs";
  import weekOfYear from "dayjs/plugin/weekOfYear";

  dayjs.extend(weekOfYear);

  import ExcelJS from "exceljs";

  import {
    SHEETS,
    BRANCHES,
    SKUS_LITE,
    EDGAR_SHEET_CONTEXT
  } from "../../../../gql";
  import {
    dateFormatToServer,
    dateFormatToUI
  } from "../../../../utilities/dateFormatter";

  import { storeCodes } from "../../../../utilities/storeCodes";
  import { skuMap } from "../../../../utilities/skuMap";

  import { currentTenant } from "../../../../stores/current_tenant";

  import { exportToXLSX } from "../../../../utilities/export";

  import {
    nearestStartDay,
    nearestEndDay,
    getNearestStartDay,
    getNearestEndDay
  } from "../../edgar/edgarUtils";

  let currentStartDay = nearestStartDay;
  let currentEndDay = nearestEndDay;

  $: if ($currentTenant.tenantVariables) {
    currentStartDay = getNearestStartDay();
    currentEndDay = getNearestEndDay();
    // currentStartDay = dayjs("2021-01-28");
    // currentEndDay = dayjs("2021-02-03");
  }

  // import BranchList from "../components/DONBranchList.svelte";

  const isDaily = false;
  const daysToIncrement = 7;

  //   import CardView from "./CardView.svelte";
  // import TableView from "../components/TableView.svelte";
  // import MetaView from "./MetaView.svelte";

  const client = getClient();

  const sheetsOp = query(client, {
    query: SHEETS,
    // fetchPolicy: "cache-and-network",
    variables: {
      findDONSheetInput: {
        fromInventoryDate: dateFormatToServer(currentStartDay),
        toInventoryDate: dateFormatToServer(currentEndDay)
      }
    }
  });

  const edgarSheetOp = query(client, {
    query: EDGAR_SHEET_CONTEXT,
    // fetchPolicy: "cache-and-network",
    variables: {
      startDate: dateFormatToServer(currentStartDay),
      endDate: dateFormatToServer(currentEndDay)
    }
  });

  const branchesOp = query(client, {
    query: BRANCHES
  });

  let skuMasterList = [];

  const skuMasterListOp = query(client, {
    query: SKUS_LITE
  });

  // let branchSheetsOp;
  // let allSheetsOp;

  let skus = [];

  let currentSheetCode = "";
  // let currentBranch;

  let categoryCodes = [];
  let skusByCatCode = [];

  let currentBranchesByADP;
  let currentBranchesByAOR;

  const processBranches = (sheetsResult, branchList, skuList, edgarResult) => {
    skuMasterList = skuList.data.skus.edges.map((skuEdge) => {
      const fromSKUMap = skuMap.find(
        (skuItem) => skuItem.itemBarCode === skuEdge.node.code
      );

      if (!fromSKUMap) {
        console.log(skuEdge.node.code);
      }

      return {
        skuCode: skuEdge.node.code,
        skuName: fromSKUMap ? fromSKUMap.shortName : "No SKU NAME Found",
        brandCode: skuEdge.node.brand.code,
        catCode: skuEdge.node.category.code
      };
    });

    categoryCodes = Array.from(
      new Set(skuMasterList.map((sku) => sku.catCode))
    );

    skusByCatCode = categoryCodes.map((catCode) => {
      const skusByCurrentCategory = skuMasterList.filter(
        (sku) => sku.catCode === catCode
      );
      const uniqueBrands = Array.from(
        new Set(skusByCurrentCategory.map((sku) => sku.brandCode))
      );

      return {
        catCode,
        brands: uniqueBrands.map((brand) => {
          return {
            brandCode: brand,
            skus: skusByCurrentCategory.filter((sku) => sku.brandCode === brand)
          };
        })
      };
    });

    if (!branchList.data.branches) {
      return [];
    }

    const branchData = branchList.data;
    const sheetsData = sheetsResult.data;

    const { branches } = branchData;
    const { donSheets } = sheetsData;

    const adps = storeCodes.map((storeCode) => {
      return { adp: storeCode.adp, aor: storeCode.aor };
    });

    const returnData = branches.edges.map((edge) => {
      const { node } = edge;
      const branchCode = node.code;

      const donSheetsPerBranch = donSheets.edges.filter(
        (donEdge) => donEdge.node.branch.code === branchCode
      );

      const donSKUsForTheWeek = [];

      for (let i = 0; i < donSheetsPerBranch.length; i += 1) {
        const donSheetPerBranch = donSheetsPerBranch[i];

        donSheetPerBranch.node.skus.edges.forEach((skuCurrent) => {
          let donSKU = donSKUsForTheWeek.find(
            (donSKUForTheWeek) =>
              donSKUForTheWeek.code === skuCurrent.node.details.code
          );

          if (!donSKU) {
            donSKU = {
              code: skuCurrent.node.details.code,
              oosCount: 0,
              catCode: skuCurrent.node.details.category.code
            };
            donSKUsForTheWeek.push(donSKU);
          }

          donSKU.oosCount += skuCurrent.node.data.oos ? 1 : 0;
        });
      }

      const donSKUsByCatCode = skusByCatCode.map((category) => {
        const brands = category.brands.map((brand) => {
          return {
            brandCode: brand.brandCode,
            skus: brand.skus.map((sku) => {
              const skuFromDON = donSKUsForTheWeek.find(
                (donSKU) => donSKU.code === sku.skuCode
              );
              let oosCount = 0;
              if (skuFromDON) {
                oosCount = skuFromDON.oosCount;
              }
              return {
                skuCode: sku.skuCode,
                oosCount
              };
            })
          };
        });

        let donTotal = 0;
        let totalSKUs = 0;

        for (let i = 0; i < brands.length; i += 1) {
          totalSKUs += brands[i].skus.length;
          for (let j = 0; j < brands[i].skus.length; j += 1) {
            donTotal += brands[i].skus[j].oosCount > 0 ? 1 : 0;
          }
        }

        donTotal /= totalSKUs;

        donTotal = (donTotal * 100).toFixed(0);
        return {
          catCode: category.catCode,
          brands,
          donTotal
        };
      });

      const edgarSKUsByCatCode = skusByCatCode.map((category) => {
        const branchObject = edgarResult.data.edgarSheetContext.branchSheets.find(
          (branchSheet) => branchSheet.branch.code === branchCode
        );
        const branchSheet = branchObject.edgarSheet;
        let edgarAverage = 0.0;

        if (branchSheet) {
          branchSheet.skus.edges.forEach((sku) => {
            if (sku.node.details.category.code === category.catCode) {
              edgarAverage += sku.node.computed.offtakeContainers;
            }
          });
          let totalSKUsForCat = 0;
          for (let i = 0; i < category.brands.length; i += 1) {
            totalSKUsForCat += category.brands[i].skus.length;
          }

          edgarAverage /= totalSKUsForCat;
          edgarAverage = edgarAverage.toFixed(2);
        }
        return {
          catCode: category.catCode,
          edgarAverage,
          brands: category.brands.map((brand) => {
            return {
              brandCode: brand.brandCode,
              skus: brand.skus.map((sku) => {
                let offtake = 0;

                if (branchSheet) {
                  const skuEDGAR = branchSheet.skus.edges.find(
                    (skuEdge) => skuEdge.node.details.code === sku.skuCode
                  );
                  if (skuEDGAR) {
                    offtake = skuEDGAR.node.computed.offtakeContainers;
                  } else {
                    offtake = "NC";
                  }
                }

                return {
                  skuCode: sku.skuCode,
                  offtake
                };
              })
            };
          })
        };
      });

      const lsopSKUsByCatCode = skusByCatCode.map((category) => {
        const catObject = {
          catCode: category.catCode,
          lsopAverage: 0.0,
          lsopSum: 0.0,
          brands: category.brands.map((brand) => {
            return {
              brandCode: brand.brandCode,
              skus: brand.skus.map((sku) => {
                let lsop = 0.0;

                const edgarSKU = edgarSKUsByCatCode
                  .find(
                    (locCategory) => locCategory.catCode === category.catCode
                  )
                  .brands.find(
                    (locBrand) => locBrand.brandCode === brand.brandCode
                  )
                  .skus.find((locSKU) => locSKU.skuCode === sku.skuCode);

                const donSKU = donSKUsByCatCode
                  .find(
                    (locCategory) => locCategory.catCode === category.catCode
                  )
                  .brands.find(
                    (locBrand) => locBrand.brandCode === brand.brandCode
                  )
                  .skus.find((locSKU) => locSKU.skuCode === sku.skuCode);

                if (edgarSKU && donSKU) {
                  const offtake = parseFloat(edgarSKU.offtake);
                  const oosCountForTheWeek = parseFloat(donSKU.oosCount);
                  // TODO: Change this to item bar code on final test
                  const skuForLSOP = skuMap.find(
                    (skuItem) => skuItem.itemBarCode === sku.skuCode
                  );

                  if (skuForLSOP) {
                    lsop =
                      ((offtake / (7 - oosCountForTheWeek)) * 7 - offtake) *
                      skuForLSOP.pricePerCS;

                    // eslint-disable-next-line no-restricted-globals
                    if (isNaN(lsop)) {
                      lsop = 0.0;
                    }

                    lsop = lsop.toFixed(2);
                  }
                }

                return {
                  skuCode: sku.skuCode,
                  lsop
                };
              })
            };
          })
        };

        let lsopAverage = 0.0;
        let totalSKUs = 0;

        catObject.brands.forEach((brand) => {
          brand.skus.forEach((sku) => {
            lsopAverage += parseFloat(sku.lsop);
            totalSKUs += 1;
          });
        });

        catObject.lsopSum = lsopAverage;

        lsopAverage /= totalSKUs;

        catObject.lsopAverage = lsopAverage.toFixed(2);

        return catObject;
      });

      let overallLSOP = 0.0;

      lsopSKUsByCatCode.forEach((cat) => {
        overallLSOP += cat.lsopSum;
      });

      const storeFromMap = storeCodes.find(
        (storeCode) => storeCode.branchCode === node.code
      );

      let accountCode = "";
      let adp = "GMA"; // TODO: change the store codes

      if (storeFromMap) {
        accountCode = storeFromMap.accountCode;
        adp = storeFromMap.adp;
      }

      return {
        code: node.code,
        name: node.name,
        // accountCode: storeCodes.find(
        //   storeCode => storeCode.branchCode === node.code
        // ).accountCode,
        accountCode,
        // adp: storeCodes.find(storeCode => storeCode.branchCode === node.code)
        //   .adp,
        adp,
        donSKUsByCatCode,
        edgarSKUsByCatCode,
        lsopSKUsByCatCode,
        overallLSOP
      };
    });

    const uniqueADP = Array.from(new Set(adps.map((adp) => adp.adp)));

    const branchesByADP = uniqueADP.map((adp) => {
      const locBranches = returnData.filter((datum) => datum.adp === adp);

      const donSKUPerADP = skuMasterList.map((sku) => {
        let totalDON = 0;

        if (locBranches.length > 0) {
          for (let i = 0; i < locBranches.length; i += 1) {
            const branch = locBranches[i];
            const locSKU = branch.donSKUsByCatCode
              .find((skuByCatCode) => skuByCatCode.catCode === sku.catCode)
              .brands.find((brand) => brand.brandCode === sku.brandCode)
              .skus.find((brandSKU) => brandSKU.skuCode === sku.skuCode);
            if (locSKU) {
              totalDON += locSKU.oosCount > 0 ? 1 : 0;
            }
          }

          totalDON /= locBranches.length;
        }

        totalDON = (totalDON * 100).toFixed(0);

        return {
          skuCode: sku.skuCode,
          totalDON
        };
      });

      const donSKUPerADPPerCat = skusByCatCode.map((category) => {
        return {
          catCode: category.catCode,
          brands: category.brands.map((brand) => {
            return {
              brand: brand.brandCode,
              skus: brand.skus.map((brandSKU) => {
                return {
                  skuCode: brandSKU.skuCode,
                  totalDON: donSKUPerADP.find(
                    (donSKU) => donSKU.skuCode === brandSKU.skuCode
                  ).totalDON
                };
              })
            };
          })
        };
      });

      const donAveragePerCat = skusByCatCode.map((skuByCatCode) => {
        return {
          donAverage: 0,
          catCode: skuByCatCode.catCode
        };
      });

      locBranches.forEach((branch) => {
        branch.donSKUsByCatCode.forEach((donByCat) => {
          donAveragePerCat.find(
            (donAvePerCat) => donAvePerCat.catCode === donByCat.catCode
          ).donAverage += parseInt(donByCat.donTotal, 10);
        });
      });

      const edgarSKUPerADP = skuMasterList.map((sku) => {
        let averageEDGAR = 0.0;

        if (locBranches.length > 0) {
          for (let i = 0; i < locBranches.length; i += 1) {
            const branch = locBranches[i];
            const locSKU = branch.edgarSKUsByCatCode
              .find((skuByCatCode) => skuByCatCode.catCode === sku.catCode)
              .brands.find((brand) => brand.brandCode === sku.brandCode)
              .skus.find((brandSKU) => brandSKU.skuCode === sku.skuCode);
            if (locSKU) {
              averageEDGAR += locSKU.offtake !== "NC" ? locSKU.offtake : 0;
            }
          }

          averageEDGAR /= locBranches.length;
        }

        averageEDGAR = averageEDGAR.toFixed(2);

        return {
          skuCode: sku.skuCode,
          averageEDGAR
        };
      });

      const edgarSKUPerADPPerCat = skusByCatCode.map((category) => {
        return {
          catCode: category.catCode,
          brands: category.brands.map((brand) => {
            return {
              brand: brand.brandCode,
              skus: brand.skus.map((brandSKU) => {
                return {
                  skuCode: brandSKU.skuCode,
                  averageEDGAR: edgarSKUPerADP.find(
                    (edgarSKU) => edgarSKU.skuCode === brandSKU.skuCode
                  ).averageEDGAR
                };
              })
            };
          })
        };
      });

      const edgarAveragePerCat = skusByCatCode.map((skuByCatCode) => {
        return {
          edgarAverage: 0.0,
          catCode: skuByCatCode.catCode
        };
      });

      locBranches.forEach((branch) => {
        branch.edgarSKUsByCatCode.forEach((edgarByCat) => {
          edgarAveragePerCat.find(
            (edgarAvePerCat) => edgarAvePerCat.catCode === edgarByCat.catCode
          ).edgarAverage += parseFloat(edgarByCat.edgarAverage);
        });
      });

      const lsopSKUPerADP = skuMasterList.map((sku) => {
        let averageLSOP = 0.0;
        let sumLSOP = 0.0;

        if (locBranches.length > 0) {
          for (let i = 0; i < locBranches.length; i += 1) {
            const branch = locBranches[i];
            const locSKU = branch.lsopSKUsByCatCode
              .find((skuByCatCode) => skuByCatCode.catCode === sku.catCode)
              .brands.find((brand) => brand.brandCode === sku.brandCode)
              .skus.find((brandSKU) => brandSKU.skuCode === sku.skuCode);
            if (locSKU) {
              averageLSOP += parseFloat(locSKU.lsop);
            }
          }
          sumLSOP = averageLSOP;
          averageLSOP /= locBranches.length;
        }

        averageLSOP = averageLSOP.toFixed(2);

        return {
          skuCode: sku.skuCode,
          averageLSOP,
          sumLSOP
        };
      });

      const lsopSKUPerADPPerCat = skusByCatCode.map((category) => {
        return {
          catCode: category.catCode,
          brands: category.brands.map((brand) => {
            return {
              brand: brand.brandCode,
              skus: brand.skus.map((brandSKU) => {
                return {
                  skuCode: brandSKU.skuCode,
                  averageLSOP: lsopSKUPerADP.find(
                    (lsopSKU) => lsopSKU.skuCode === brandSKU.skuCode
                  ).averageLSOP,
                  sumLSOP: lsopSKUPerADP.find(
                    (lsopSKU) => lsopSKU.skuCode === brandSKU.skuCode
                  ).sumLSOP
                };
              })
            };
          })
        };
      });

      const lsopAveragePerCat = skusByCatCode.map((skuByCatCode) => {
        return {
          lsopAverage: 0.0,
          lsopSum: 0.0,
          catCode: skuByCatCode.catCode
        };
      });

      let overallLSOP = 0.0;

      locBranches.forEach((branch) => {
        overallLSOP += branch.overallLSOP;
        branch.lsopSKUsByCatCode.forEach((lsopByCat) => {
          lsopAveragePerCat.find(
            (lsopAvePerCat) => lsopAvePerCat.catCode === lsopByCat.catCode
          ).lsopAverage += parseFloat(lsopByCat.lsopAverage);
          lsopAveragePerCat.find(
            (lsopAvePerCat) => lsopAvePerCat.catCode === lsopByCat.catCode
          ).lsopSum += parseFloat(lsopByCat.lsopSum);
        });
      });

      return {
        adp,
        tpa: "ECR",
        aor: adps.find((locADP) => locADP.adp === adp).aor,
        branches: locBranches,
        donSKUPerADPPerCat,
        donAveragePerCat: donAveragePerCat.map((donPerCat) => {
          return {
            catCode: donPerCat.catCode,
            donAverage: (donPerCat.donAverage / locBranches.length).toFixed(0)
          };
        }),
        edgarSKUPerADPPerCat,
        edgarAveragePerCat: edgarAveragePerCat.map((edgarPerCat) => {
          return {
            catCode: edgarPerCat.catCode,
            edgarAverage: (
              edgarPerCat.edgarAverage / locBranches.length
            ).toFixed(2)
          };
        }),
        lsopSKUPerADPPerCat,
        lsopAveragePerCat: lsopAveragePerCat.map((lsopPerCat) => {
          return {
            catCode: lsopPerCat.catCode,
            lsopSum: lsopPerCat.lsopSum,
            lsopAverage: (lsopPerCat.lsopAverage / locBranches.length).toFixed(
              2
            )
          };
        }),
        overallLSOP
      };
    });

    // currentBranchesByADP = branchesByADP;

    const uniqueAOR = Array.from(new Set(adps.map((adp) => adp.aor)));
    console.log(branchesByADP);

    const adpByRegion = uniqueAOR.map((aor) => {
      const branchesByAOR = branchesByADP.filter(
        (branchByADP) => branchByADP.aor === aor
      );

      const donAvePerAOR = skusByCatCode.map((category) => {
        return {
          catCode: category.catCode,
          brands: category.brands.map((brand) => {
            return {
              brand: brand.brandCode,
              skus: brand.skus.map((brandSKU) => {
                let totalDON = 0;
                let branchCount = 0;
                branchesByAOR.forEach((branch) => {
                  const locDONCat = branch.donSKUPerADPPerCat.find(
                    (donCat) => donCat.catCode === category.catCode
                  );
                  const locDONBrand = locDONCat.brands.find(
                    (donBrand) => donBrand.brand === brand.brandCode
                  );
                  const locDONSKU = locDONBrand.skus.find(
                    (donSKU) => donSKU.skuCode === brandSKU.skuCode
                  );

                  if (locDONSKU && locDONSKU.totalDON !== "0") {
                    branchCount += 1;
                    totalDON += parseInt(locDONSKU.totalDON, 10);
                  }
                });

                return {
                  skuCode: brandSKU.skuCode,
                  totalDON: branchCount > 0 ? totalDON / branchCount : 0
                };
              })
            };
          })
        };
      });

      const edgarAvePerAOR = skusByCatCode.map((category) => {
        return {
          catCode: category.catCode,
          brands: category.brands.map((brand) => {
            return {
              brand: brand.brandCode,
              skus: brand.skus.map((brandSKU) => {
                let totalEDGAR = 0;

                branchesByAOR.forEach((branch) => {
                  const locEDGARCat = branch.edgarSKUPerADPPerCat.find(
                    (edgarCat) => edgarCat.catCode === category.catCode
                  );
                  const locEDGARBrand = locEDGARCat.brands.find(
                    (edgarBrand) => edgarBrand.brand === brand.brandCode
                  );
                  const locEDGARSKU = locEDGARBrand.skus.find(
                    (edgarSKU) => edgarSKU.skuCode === brandSKU.skuCode
                  );

                  if (locEDGARSKU) {
                    totalEDGAR += parseFloat(locEDGARSKU.averageEDGAR);
                  }
                });

                return {
                  skuCode: brandSKU.skuCode,
                  averageEDGAR:
                    branchesByAOR.length > 0
                      ? totalEDGAR / branchesByAOR.length
                      : 0
                };
              })
            };
          })
        };
      });

      const lsopSumPerAOR = skusByCatCode.map((category) => {
        return {
          catCode: category.catCode,
          brands: category.brands.map((brand) => {
            return {
              brand: brand.brandCode,
              skus: brand.skus.map((brandSKU) => {
                let totalLSOP = 0;

                branchesByAOR.forEach((branch) => {
                  const locLSOPCat = branch.lsopSKUPerADPPerCat.find(
                    (lsopCat) => lsopCat.catCode === category.catCode
                  );
                  const locLSOPBrand = locLSOPCat.brands.find(
                    (lsopBrand) => lsopBrand.brand === brand.brandCode
                  );
                  const locLSOPSKU = locLSOPBrand.skus.find(
                    (lsopSKU) => lsopSKU.skuCode === brandSKU.skuCode
                  );

                  if (locLSOPSKU) {
                    totalLSOP += locLSOPSKU.sumLSOP;
                  }
                });

                return {
                  skuCode: brandSKU.skuCode,
                  sumLSOP: totalLSOP
                };
              })
            };
          })
        };
      });

      const donAveragePerCat = categoryCodes.map((category) => {
        let branchCount = 0;
        let donAverage = 0;

        branchesByAOR.forEach((branch) => {
          const locDONAveragePerCat = branch.donAveragePerCat.find(
            (donCat) => donCat.catCode === category
          );

          if (
            locDONAveragePerCat &&
            locDONAveragePerCat.donAverage !== "NaN" &&
            locDONAveragePerCat.donAverage !== "0"
          ) {
            branchCount += 1;
            donAverage += parseInt(locDONAveragePerCat.donAverage, 10);
          }
        });

        return {
          catCode: category,
          donAverage: branchCount > 0 ? donAverage / branchCount : 0
        };
      });

      const edgarAveragePerCat = categoryCodes.map((category) => {
        let edgarAverage = 0;

        branchesByAOR.forEach((adp) => {
          const locEDGARAveragePerCat = adp.edgarAveragePerCat.find(
            (edgarCat) => edgarCat.catCode === category
          );

          if (
            locEDGARAveragePerCat &&
            locEDGARAveragePerCat.edgarAverage !== "NaN"
          ) {
            edgarAverage += parseFloat(locEDGARAveragePerCat.edgarAverage);
          }
        });

        return {
          catCode: category,
          edgarAverage:
            branchesByAOR.length > 0 ? edgarAverage / branchesByAOR.length : 0
        };
      });

      const lsopAveragePerCat = categoryCodes.map((category) => {
        let lsopSum = 0;

        branchesByAOR.forEach((adp) => {
          const locLSOPAveragePerCat = adp.lsopAveragePerCat.find(
            (edgarCat) => edgarCat.catCode === category
          );

          if (
            locLSOPAveragePerCat &&
            locLSOPAveragePerCat.edgarAverage !== "NaN"
          ) {
            lsopSum += locLSOPAveragePerCat.lsopSum;
          }
        });

        return {
          catCode: category,
          lsopSum
        };
      });

      return {
        aor,
        branchesByAOR,
        donAvePerAOR,
        donAveragePerCat,
        edgarAvePerAOR,
        edgarAveragePerCat,
        lsopSumPerAOR,
        lsopAveragePerCat
      };
    });

    // console.log(adpByRegion);

    currentBranchesByADP = branchesByADP;
    currentBranchesByAOR = adpByRegion;

    return currentBranchesByADP;
  };

  // const processSKUs = edgeNode => {
  //   const { inventoryDate, branch } = edgeNode;

  //   return edgeNode.skus.edges.map(skuEdge => {
  //     const { node } = skuEdge;
  //     const { details, data } = node;

  //     return {
  //       code: details.code,
  //       name: details.name,
  //       unitCount: details.unitCount,
  //       core: details.core ? "Core" : "Regular",
  //       oos: data.oos ? "Yes" : "No",
  //       suggestedOrder: data.suggestedOrder ? data.suggestedOrder : 0,
  //       branchCode: branch.code,
  //       branchName: branch.name,
  //       brandCode: details.brand.code,
  //       brandName: details.brand.name,
  //       categoryCode: details.category.code,
  //       categoryName: details.category.name,
  //       inventoryDate
  //     };
  //   });
  // };

  // const branchSelected = event => {
  //   const { branch } = { ...event.detail };

  //   skus = [];
  //   loadingSKUs = true;

  //   if (!branch.donSheet) {
  //     currentSheetCode = null;

  //     loadingSKUs = false;
  //   } else {
  //     // currentSheetCode = branch.donSheet.node.code;
  //     currentBranch = branch;
  //     const cacheKey = `${branch.code}-${dateFormatToServer(currentStartDay)}`;
  //     const cachedSKUs = sheetsSKUCache.get(cacheKey);

  //     if (cachedSKUs) {
  //       skus = cachedSKUs;
  //       originalSKUs = skus;
  //       loadingSKUs = false;
  //     } else {
  //       branchSheetsOp = query(client, {
  //         query: SHEETS_META,
  //         fetchPolicy: "network-only",
  //         variables: {
  //           findDONSheetInput: {
  //             branch: {
  //               code: currentBranch.code
  //             },
  //             fromInventoryDate: dateFormatToServer(currentStartDay),
  //             toInventoryDate: dateFormatToServer(currentEndDay)
  //           }
  //         }
  //       });

  //       $branchSheetsOp.then(response => {
  //         const { data } = response;
  //         const { donSheets } = data;
  //         const { edges } = donSheets;
  //         originalSKUs = [];
  //         skus = [];
  //         edges.forEach(edge => {
  //           skus = [...skus, ...processSKUs(edge.node)];
  //           branchSummaries = [
  //             ...branchSummaries,
  //             processBranchSummary(edge.node)
  //           ];
  //         });

  //         // skus = processBranc(response.data);
  //         originalSKUs = skus;
  //         loadingSKUs = false;
  //         sheetsSKUCache.set(cacheKey, [...skus]);
  //       });
  //     }
  //   }
  // };

  const previousWeek = () => {
    currentSheetCode = "";
    skus = [];
    currentStartDay = currentStartDay.subtract(daysToIncrement, "day");
    currentEndDay = currentEndDay.subtract(daysToIncrement, "day");
  };

  const nextWeek = () => {
    currentSheetCode = "";
    skus = [];
    currentStartDay = currentStartDay.add(daysToIncrement, "day");
    currentEndDay = currentEndDay.add(daysToIncrement, "day");
  };

  $: sheetsOp.refetch({
    findDONSheetInput: {
      fromInventoryDate: dateFormatToServer(currentStartDay),
      toInventoryDate: dateFormatToServer(currentEndDay)
    }
  });

  $: edgarSheetOp.refetch({
    startDate: dateFormatToServer(currentStartDay),
    endDate: dateFormatToServer(currentEndDay)
  });

  //   let isCardView = true;

  //   const toggleView = () => {
  //     isCardView = !isCardView;
  //   };

  const exportCurrentSheet = () => {
    const cols = [
      `"OOS"`,
      `"SKU Code"`,
      `"SKU Name"`,
      `"UOM"`,
      `"Core"`,
      `"Brand Code"`,
      `"Brand Name"`,
      `"Category Code"`,
      `"Category Name"`,
      `"Branch Code"`,
      `"Branch Name"`
    ]
      .join(",")
      .concat("\n");

    const rows = skus
      .map(
        (node) =>
          `"${node.oos}","${node.code}","${node.name}","${node.unitCount}","${node.core}","${node.brandCode}","${node.brandName}","${node.categoryCode}","${node.categoryName}","${node.branchCode}","${node.branchName}"`
      )
      .join("\r\n");
    // `"${node.code}","${node.name}","${node.unitCount}","${node.weekSupply}","${node.totalBeginningUnits}","${node.offtakeUnits}","${node.suggestedOrderUnits}","${node.deliveryContainers}","${node.deliveryUnits}","${node.totalDeliveryUnits}","${node.endingContainers}","${node.endingUnits}","${node.totalEndingUnits}","${node.badOrderContainers}","${node.badOrderUnits}","${node.totalBadOrderUnits}","${node.transferOutContainers}","${node.transferOutUnits}","${node.totalTransferOutUnits}","${node.transferInContainers}","${node.transferInUnits}","${node.totalTransferInUnits}","${node.adjustmentContainers}","${node.adjustmentUnits}","${node.totalAdjustmentUnits}","${node.branchCode}","${node.branchName}"`
    // const csvContent = csvHeader + cols + rows;

    // const encodedUri = encodeURI(csvContent);
    // window.open(encodedUri);
    const csvContent = cols + rows;

    const reportBlob = new Blob([csvContent], { type: "text/csv" });

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    const url = window.URL.createObjectURL(reportBlob);
    a.href = url;
    a.download = `${currentSheetCode}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const exportAllLoading = false;

  const exportAll = () => {
    console.log(currentBranchesByAOR);

    let reportColumns = [
      { header: "TPA", key: "tpa", width: 11.25 },
      { header: "AOR", key: "aor", width: 11.25 },
      { header: "ADP", key: "adp", width: 25 },
      { header: "ACCOUNT NAME", key: "accountCode", width: 26.5 },
      {
        header: "STORE CODE",
        key: "branchCode",
        width: 26.5,
        style: { numFmt: "@" }
      }
    ];

    for (let i = 0; i < skusByCatCode.length; i += 1) {
      reportColumns = [
        ...reportColumns,
        ...skusByCatCode[i].brands
          .map((brand) =>
            brand.skus.map((sku) => {
              return {
                header: sku.skuName,
                key: `${sku.skuCode}_oos`,
                width: 8.75
              };
            })
          )
          .flat()
      ];

      reportColumns = [
        ...reportColumns,
        {
          header: `OOS ${skusByCatCode[i].catCode}`,
          key: `${skusByCatCode[i].catCode}_totalOOS`,
          style: { numFmt: "0%" },
          width: 8.75
        }
      ];
    }

    reportColumns = [...reportColumns, { header: "", width: 10 }];

    for (let i = 0; i < skusByCatCode.length; i += 1) {
      reportColumns = [
        ...reportColumns,
        ...skusByCatCode[i].brands
          .map((brand) =>
            brand.skus.map((sku) => {
              return {
                header: sku.skuName,
                key: `${sku.skuCode}_offtake`,
                style: { numFmt: "0.00" },
                width: 8.75
              };
            })
          )
          .flat()
      ];

      reportColumns = [
        ...reportColumns,
        {
          header: `Offtake ${skusByCatCode[i].catCode}`,
          key: `${skusByCatCode[i].catCode}_totalOfftake`,
          style: { numFmt: "0.00" },
          width: 11.25
        }
      ];
    }

    reportColumns = [...reportColumns, { header: "", width: 10 }];

    for (let i = 0; i < skusByCatCode.length; i += 1) {
      reportColumns = [
        ...reportColumns,
        ...skusByCatCode[i].brands
          .map((brand) =>
            brand.skus.map((sku) => {
              return {
                header: sku.skuName,
                key: `${sku.skuCode}_lsop`,
                style: { numFmt: '"₱"#,##0.00' },
                width: 11.25
              };
            })
          )
          .flat()
      ];

      reportColumns = [
        ...reportColumns,
        {
          header: `LSOP ${skusByCatCode[i].catCode}`,
          key: `${skusByCatCode[i].catCode}_totalLSOP`,
          style: {
            numFmt: '_("₱"* #,##0.00_);_("₱"* (#,##0.00);_("₱"* "-"??_);_(@_)'
          },
          width: 11.25
        }
      ];
    }

    reportColumns = [
      ...reportColumns,
      {
        header: "EFC",
        key: "overallLSOP",
        width: 20,
        style: {
          numFmt: '_("₱"* #,##0.00_);_("₱"* (#,##0.00);_("₱"* "-"??_);_(@_)'
        }
      }
    ];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      `Week ${dayjs(currentEndDay).week()}`
    );

    // worksheet.addRows(reportColumns);
    worksheet.columns = reportColumns;

    let currentAOR = "";

    for (let i = 0; i < currentBranchesByADP.length; i += 1) {
      const adpRow = currentBranchesByADP[i];

      if (currentAOR !== adpRow.aor) {
        if (currentAOR !== "") {
          const aorRowToAdd = {
            tpa: "",
            aor: "",
            adp: `TOTAL ${currentAOR}`,
            overallLSOP: 0
          };

          const aorRow = currentBranchesByAOR.find(
            (aor) => aor.aor === currentAOR
          );

          aorRow.branchesByAOR.forEach((aorBranch) => {
            aorRowToAdd.overallLSOP += aorBranch.overallLSOP;
          });

          aorRow.donAvePerAOR.forEach((cat) => {
            cat.brands.forEach((brand) => {
              brand.skus.forEach((sku) => {
                aorRowToAdd[`${sku.skuCode}_oos`] =
                  parseInt(sku.totalDON, 10) / 100;
              });
            });
          });

          aorRow.edgarAvePerAOR.forEach((cat) => {
            cat.brands.forEach((brand) => {
              brand.skus.forEach((sku) => {
                aorRowToAdd[`${sku.skuCode}_offtake`] = parseFloat(
                  sku.averageEDGAR
                );
              });
            });
          });

          aorRow.lsopSumPerAOR.forEach((cat) => {
            cat.brands.forEach((brand) => {
              brand.skus.forEach((sku) => {
                aorRowToAdd[`${sku.skuCode}_lsop`] = parseFloat(sku.sumLSOP);
              });
            });
          });

          aorRow.donAveragePerCat.forEach((cat) => {
            // eslint-disable-next-line no-restricted-globals
            aorRowToAdd[`${cat.catCode}_totalOOS`] = !isNaN(cat.donAverage)
              ? parseFloat(cat.donAverage / 100)
              : 0.0;
          });

          aorRow.edgarAveragePerCat.forEach((cat) => {
            // eslint-disable-next-line no-restricted-globals
            aorRowToAdd[`${cat.catCode}_totalOfftake`] = !isNaN(
              cat.edgarAverage
            )
              ? parseFloat(cat.edgarAverage)
              : 0.0;
          });

          aorRow.lsopAveragePerCat.forEach((cat) => {
            // eslint-disable-next-line no-restricted-globals
            aorRowToAdd[`${cat.catCode}_totalLSOP`] = !isNaN(cat.lsopSum)
              ? parseFloat(cat.lsopSum)
              : 0.0;
          });

          const currentRows = worksheet.addRows([aorRowToAdd]);

          currentRows.forEach((row) => {
            for (let j = 1; j < reportColumns.length + 1; j += 1) {
              const currentCell = row.getCell(j);

              if (currentCell._column._key) {
                if (currentCell._column._key.includes("_oos")) {
                  currentCell.numFmt = "0%";
                }

                currentCell.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" }
                };

                currentCell.alignment = { horizontal: "center" };
                currentCell.font = {
                  name: "Calibri",
                  size: "8",
                  bold: true,
                  color: { argb: "FFFFFFFF" }
                };

                if (
                  currentCell._column._key.includes("_totalOOS") ||
                  currentCell._column._key.includes("_totalLSOP") ||
                  currentCell._column._key.includes("_totalOfftake")
                ) {
                  currentCell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFBED7EE" },
                    bgColor: { argb: "FFBED7EE" }
                  };
                } else if (currentCell._column._key === "overallLSOP") {
                  currentCell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFA9D08E" },
                    bgColor: { argb: "FFA9D08E" }
                  };
                } else {
                  currentCell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FF375623" },
                    bgColor: { argb: "FF375623" }
                  };
                }
              }
            }
          });
        }

        currentAOR = adpRow.aor;
      }

      const adpRows = adpRow.branches.map((adpBranch) => {
        const branchRow = {
          tpa: adpRow.tpa,
          aor: adpRow.aor,
          adp: adpRow.adp,
          accountCode: adpBranch.accountCode,
          branchCode: adpBranch.code,
          overallLSOP: adpBranch.overallLSOP
        };

        adpBranch.donSKUsByCatCode.forEach((cat) => {
          cat.brands.forEach((brand) => {
            brand.skus.forEach((sku) => {
              branchRow[`${sku.skuCode}_oos`] = parseInt(sku.oosCount, 10);
            });
          });

          branchRow[`${cat.catCode}_totalOOS`] =
            parseInt(cat.donTotal, 10) / 100;
        });

        adpBranch.edgarSKUsByCatCode.forEach((cat) => {
          cat.brands.forEach((brand) => {
            brand.skus.forEach((sku) => {
              branchRow[`${sku.skuCode}_offtake`] = sku.offtake;
            });
          });

          branchRow[`${cat.catCode}_totalOfftake`] = parseFloat(
            cat.edgarAverage
          );
        });

        adpBranch.lsopSKUsByCatCode.forEach((cat) => {
          cat.brands.forEach((brand) => {
            brand.skus.forEach((sku) => {
              branchRow[`${sku.skuCode}_lsop`] = parseFloat(sku.lsop);
            });
          });

          branchRow[`${cat.catCode}_totalLSOP`] = parseFloat(cat.lsopSum);
        });

        return branchRow;
      });

      const dataRows = worksheet.addRows(adpRows);

      dataRows.forEach((row) => {
        for (let j = 1; j < reportColumns.length + 1; j += 1) {
          const currentCell = row.getCell(j);

          if (currentCell._column._key) {
            currentCell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" }
            };

            currentCell.alignment = { horizontal: "center" };
            currentCell.font = { name: "Calibri", size: "8" };

            if (
              currentCell._column._key.includes("_totalOOS") ||
              currentCell._column._key.includes("_totalLSOP") ||
              currentCell._column._key.includes("_totalOfftake")
            ) {
              currentCell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFBED7EE" },
                bgColor: { argb: "FFBED7EE" }
              };
            }
          }
        }
      });

      const adpRowToAdd = {
        tpa: adpRow.tpa,
        aor: adpRow.aor,
        adp: `TOTAL ${adpRow.adp}`,
        overallLSOP: adpRow.overallLSOP
      };

      adpRow.donSKUPerADPPerCat.forEach((cat) => {
        cat.brands.forEach((brand) => {
          brand.skus.forEach((sku) => {
            adpRowToAdd[`${sku.skuCode}_oos`] =
              parseInt(sku.totalDON, 10) / 100;
          });
        });
      });

      adpRow.edgarSKUPerADPPerCat.forEach((cat) => {
        cat.brands.forEach((brand) => {
          brand.skus.forEach((sku) => {
            adpRowToAdd[`${sku.skuCode}_offtake`] = parseFloat(
              sku.averageEDGAR
            );
          });
        });
      });

      adpRow.lsopSKUPerADPPerCat.forEach((cat) => {
        cat.brands.forEach((brand) => {
          brand.skus.forEach((sku) => {
            adpRowToAdd[`${sku.skuCode}_lsop`] = parseFloat(sku.sumLSOP);
          });
        });
      });

      adpRow.donAveragePerCat.forEach((cat) => {
        // eslint-disable-next-line no-restricted-globals
        adpRowToAdd[`${cat.catCode}_totalOOS`] = !isNaN(cat.donAverage)
          ? parseFloat(cat.donAverage / 100)
          : 0.0;
      });

      adpRow.edgarAveragePerCat.forEach((cat) => {
        // eslint-disable-next-line no-restricted-globals
        adpRowToAdd[`${cat.catCode}_totalOfftake`] = !isNaN(cat.edgarAverage)
          ? parseFloat(cat.edgarAverage)
          : 0.0;
      });

      adpRow.lsopAveragePerCat.forEach((cat) => {
        // eslint-disable-next-line no-restricted-globals
        adpRowToAdd[`${cat.catCode}_totalLSOP`] = !isNaN(cat.lsopSum)
          ? parseFloat(cat.lsopSum)
          : 0.0;
      });

      const currentRows = worksheet.addRows([adpRowToAdd]);
      currentRows.forEach((row) => {
        for (let j = 1; j < reportColumns.length + 1; j += 1) {
          const currentCell = row.getCell(j);

          if (currentCell._column._key) {
            if (currentCell._column._key.includes("_oos")) {
              currentCell.numFmt = "0%";
            }

            currentCell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" }
            };

            currentCell.alignment = { horizontal: "center" };
            currentCell.font = { name: "Calibri", size: "8", bold: true };

            if (
              currentCell._column._key.includes("_totalOOS") ||
              currentCell._column._key.includes("_totalLSOP") ||
              currentCell._column._key.includes("_totalOfftake")
            ) {
              currentCell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFBED7EE" },
                bgColor: { argb: "FFBED7EE" }
              };
            } else if (currentCell._column._key === "overallLSOP") {
              currentCell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFA9D08E" },
                bgColor: { argb: "FFA9D08E" }
              };
            } else {
              currentCell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFFF00" },
                bgColor: { argb: "FFFFFF00" }
              };
            }
          }
        }
      });
    }

    const header = worksheet.getRow(1);
    header.height = 36;
    for (let i = 1; i < reportColumns.length + 1; i += 1) {
      header.getCell(i).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true
      };

      header.getCell(i).font = {
        name: "Calibri",
        size: "8",
        bold: true
      };
      header.getCell(i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
    }

    workbook.xlsx
      .writeBuffer({
        base64: true
      })
      .then((xls64) => {
        exportToXLSX(
          xls64,
          `OOS Report Week ${dayjs(currentEndDay).week()} National`
        );
      });
  };
</script>

<div class="m-0 pb-3 text-base flex flex-row items-center justify-center">
  <div class="w-2/12">
    <Button
      text="Export All"
      on:click={exportAll}
      color="secondary"
      loading={exportAllLoading} />
  </div>
  <div class="justify-center w-8/12 flex flex-row">
    <div>
      <EmptyButton
        icon="arrowLeft"
        text=""
        size="tiny"
        on:click={previousWeek} />
    </div>
    <div class="w-56 text-center">
      {#if !isDaily}
        {dateFormatToUI(currentStartDay.toDate())} to {dateFormatToUI(currentEndDay.toDate())}
      {:else}{dateFormatToUI(currentStartDay.toDate())}{/if}
    </div>
    <div>
      <EmptyButton icon="arrowRight" text="" size="tiny" on:click={nextWeek} />
    </div>
  </div>
  <div
    class="w-1/12 flex flex-row justify-end"
    class:justify-between={currentSheetCode !== ''}>
    <!-- <Button
      text={isCardView ? 'List View' : 'Card View'}
      on:click={toggleView} /> -->
    {#if currentSheetCode !== ''}
      <Button color="secondary" text="Export" on:click={exportCurrentSheet} />
    {/if}
  </div>
</div>

<div
  class="flex flex-row w-full md:-mx-2 justify-between"
  style="min-height: 945px; max-height: 945px; height: 945px;">
  <div class="w-3/12 lg:3/12 px-2 h-full">
    {#await $branchesOp}
      <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
      </div>
    {:then branchesResult}

      {#await $skuMasterListOp}
        <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
        </div>
      {:then skuMasterResults}
        {#await $sheetsOp}

          <div
            class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
          </div>
        {:then sheetsResult}
          {#if sheetsResult.loading}
            <div
              class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
              <LoadingContent />
              <div class="pt-10" />
              <LoadingContent />
              <div class="pt-10" />
              <LoadingContent />
              <div class="pt-10" />
              <LoadingContent />
              <div class="pt-10" />
              <LoadingContent />
              <div class="pt-10" />
              <LoadingContent />
            </div>
          {:else}
            {#await $edgarSheetOp}
              <div
                class="w-full h-full pt-10 px-4 border border-darkerGray rounded
                ">
                <LoadingContent />
                <div class="pt-10" />
                <LoadingContent />
                <div class="pt-10" />
                <LoadingContent />
                <div class="pt-10" />
                <LoadingContent />
                <div class="pt-10" />
                <LoadingContent />
                <div class="pt-10" />
                <LoadingContent />
              </div>
            {:then edgarResult}
              {#if edgarResult.loading}
                <div
                  class="w-full h-full pt-10 px-4 border border-darkerGray
                  rounded ">
                  <LoadingContent />
                  <div class="pt-10" />
                  <LoadingContent />
                  <div class="pt-10" />
                  <LoadingContent />
                  <div class="pt-10" />
                  <LoadingContent />
                  <div class="pt-10" />
                  <LoadingContent />
                  <div class="pt-10" />
                  <LoadingContent />
                </div>
              {:else}
                <div class="w-full">
                  <table class="w-full table-auto text-xs text-center">
                    <thead>
                      <tr class="font-bold">
                        <td class="px-4">TPA</td>
                        <td class="px-4">AOR</td>
                        <td class="px-16">ADP</td>
                        <td class="px-10">ACCOUNT NAME</td>
                        <td class="px-5">STORE CODE</td>
                        {#each categoryCodes as categoryCode}
                          {#each skusByCatCode.find((locSKU) => locSKU.catCode === categoryCode).brands as brand}
                            {#each brand.skus as sku}
                              <td class="px-4">{sku.skuName}</td>
                            {/each}
                          {/each}
                          <td class="px-4">Total OOS {categoryCode}</td>
                        {/each}

                        <td class="px-4" />

                        {#each categoryCodes as categoryCode}
                          {#each skusByCatCode.find((locSKU) => locSKU.catCode === categoryCode).brands as brand}
                            {#each brand.skus as sku}
                              <td class="px-4">{sku.skuName}</td>
                            {/each}
                          {/each}
                          <td class="px-4">Ave {categoryCode}</td>
                        {/each}

                        <td class="px-4" />

                        {#each categoryCodes as categoryCode}
                          {#each skusByCatCode.find((locSKU) => locSKU.catCode === categoryCode).brands as brand}
                            {#each brand.skus as sku}
                              <td class="px-4">{sku.skuName}</td>
                            {/each}
                          {/each}
                          <td class="px-4">Ave {categoryCode}</td>
                        {/each}

                      </tr>
                    </thead>
                    <tbody>
                      {#each processBranches(sheetsResult, branchesResult, skuMasterResults, edgarResult) as adp}
                        {#each adp.branches as branch}
                          <tr>
                            <td class="border-darkerGray border">{adp.tpa}</td>
                            <td class="border-darkerGray border">{adp.aor}</td>
                            <td class="border-darkerGray border">{adp.adp}</td>
                            <td class="border-darkerGray border">
                              {branch.accountCode}
                            </td>
                            <td class="border-darkerGray border">
                              {branch.code}
                            </td>
                            {#each branch.donSKUsByCatCode as donByCatCode}
                              {#each donByCatCode.brands as brand}
                                {#each brand.skus as sku}
                                  <td class="border-darkerGray border">
                                    {sku.oosCount}
                                  </td>
                                {/each}
                              {/each}
                              <td class="border-darkerGray border">
                                {donByCatCode.donTotal}%
                              </td>
                            {/each}
                            <td class="px-4" />

                            {#each branch.edgarSKUsByCatCode as edgarByCatCode}
                              {#each edgarByCatCode.brands as brand}
                                {#each brand.skus as sku}
                                  <td class="border-darkerGray border">
                                    {sku.offtake}
                                  </td>
                                {/each}
                              {/each}
                              <td class="border-darkerGray border">
                                {edgarByCatCode.edgarAverage}
                              </td>
                            {/each}

                            <td class="px-4" />

                            {#each branch.lsopSKUsByCatCode as lsopByCatCode}
                              {#each lsopByCatCode.brands as brand}
                                {#each brand.skus as sku}
                                  <td class="border-darkerGray border">
                                    {sku.lsop}
                                  </td>
                                {/each}
                              {/each}
                              <td class="border-darkerGray border">
                                {lsopByCatCode.lsopAverage}
                              </td>
                            {/each}

                          </tr>
                        {/each}
                        {#if adp.branches.length > 0}
                          <tr class="font-bold">
                            <td class="border-darkerGray border">{adp.tpa}</td>
                            <td class="border-darkerGray border">{adp.aor}</td>
                            <td class="border-darkerGray border">
                              TOTAL {adp.adp}
                            </td>
                            <td class="border-darkerGray border" />
                            <td class="border-darkerGray border" />
                            {#each adp.donSKUPerADPPerCat as donPerADP, i}
                              {#each donPerADP.brands as brand}
                                {#each brand.skus as sku}
                                  <td class="border-darkerGray border">
                                    {sku.totalDON}%
                                  </td>
                                {/each}
                              {/each}
                              <td class="border-darkerGray border">
                                {adp.donAveragePerCat[i].donAverage}%
                              </td>
                            {/each}
                            <td class="px-4" />

                            {#each adp.edgarSKUPerADPPerCat as edgarPerADP, i}
                              {#each edgarPerADP.brands as brand}
                                {#each brand.skus as sku}
                                  <td class="border-darkerGray border">
                                    {sku.averageEDGAR}
                                  </td>
                                {/each}
                              {/each}
                              <td class="border-darkerGray border">
                                {adp.edgarAveragePerCat[i].edgarAverage}
                              </td>
                            {/each}

                            <td class="px-4" />

                            {#each adp.lsopSKUPerADPPerCat as lsopPerADP, i}
                              {#each lsopPerADP.brands as brand}
                                {#each brand.skus as sku}
                                  <td class="border-darkerGray border">
                                    {sku.averageLSOP}
                                  </td>
                                {/each}
                              {/each}
                              <td class="border-darkerGray border">
                                {adp.lsopAveragePerCat[i].lsopAverage}
                              </td>
                            {/each}

                          </tr>
                        {/if}
                      {/each}
                    </tbody>
                  </table>

                </div>
              {/if}
            {/await}
          {/if}
        {/await}
      {/await}
    {/await}
  </div>

</div>
