const selectOptions = [
  {
    option: "Active",
    value: "true"
  },
  {
    option: "Inactive",
    value: "false"
  }
];

export { selectOptions };
