<script>
  import { onDestroy } from "svelte";
  // import { navigateTo } from "svelte-router-spa";

  import LoadingIndicator from "../../components/LargeLoadingIndicator.svelte";

  import NodeTable from "./NodeTable.svelte";

  import { PS3Db } from "../../../middleware/database/ps3";
  import { FirebaseResults } from "../../../middleware/database";

  import { currentUser } from "../../../stores/current_user";

  export let currentRoute;
  export let params;

  let loading = true;

  /* svelte router force feeds these parameters (see above).
     Console warns if we don't do the exports.
     Svelte warns if they are unused, therefore putting them down here, in case code needs it.
  */
  // eslint-disable-next-line no-unused-vars
  const paramsFromRouter = params;
  // eslint-disable-next-line no-unused-vars
  const currentRouteFromRouter = currentRoute;

  let ps3RawSheetsDB;
  let unsubscribeQuery;
  let rawSheets;

  const cleanUpRawSheets = (dataArray) =>
    dataArray.map((data) => {
      const { sheetCode, branchCode, date } = data;
      return {
        sheetCode,
        branchCode,
        date
      };
    });

  const fetchSheets = (tenantId) => {
    if (unsubscribeQuery) {
      unsubscribeQuery();
    }

    if (!tenantId) return;
    if (!ps3RawSheetsDB) {
      ps3RawSheetsDB = PS3Db(tenantId);
    }
    unsubscribeQuery = ps3RawSheetsDB.findAllIncomplete().onSnapshot((docs) => {
      rawSheets = cleanUpRawSheets(FirebaseResults.map(docs));
      loading = false;
    });
  };

  $: fetchSheets($currentUser.tenantId);

  onDestroy(() => {
    unsubscribeQuery();
  });
</script>

<div class="flex flex-col items-center pt-8">

  <div class="pt-4 w-full">
    <div class="w-full">

      <div class="w-full px-10">
        <div class="w-full rounded shadow px-10" style="min-height: 520px">
          {#if loading}
            <LoadingIndicator />
          {:else}
            <div class="pb-2">
              <NodeTable edges={rawSheets} />
            </div>
          {/if}
        </div>
      </div>
      <div class="pt-2 px-10">
        <!-- disabled={!result.data.accounts.pageInfo.hasPreviousPage}-->
        <!--  <Button
            icon="arrowLeft"
            color="primary"
            on:click={() => {
              goToPreviousPage(result.data.accounts.pageInfo.startCursor);
            }} /> -->
        <!-- disabled={!result.data.accounts.pageInfo.hasNextPage} -->
        <!--  <Button
            icon="arrowRight"
            color="primary"
            on:click={() => {
              goToNextPage(result.data.accounts.pageInfo.endCursor);
            }} /> -->

      </div>

    </div>
  </div>
</div>
