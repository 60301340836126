<script>
  import { createEventDispatcher } from "svelte";
  import {
    TextField,
    Button,
    EmptyButton,
    toastNotifier
  } from "@saztrek/firefly";

  import DeactivateModal from "./DeactivateModal.svelte";

  export let mode;
  export let cancelAction;

  export let code;
  export let name;
  export let description;

  export let version;

  export let buttonLabel;

  export let loading = false;

  export let skusFromCarryTemplate = [];
  export let allSKUs;

  const dispatch = createEventDispatcher();

  const requiredFieldsFilled = () => {
    return !!(code && name && description && skusFromCarryTemplate.length > 0);
  };

  const submitForm = () => {
    if (requiredFieldsFilled()) {
      if (mode === "add") {
        dispatch("validCarryTemplate", {
          code: code.trim(),
          name: name.trim(),
          description: description.trim(),
          skus: skusFromCarryTemplate.map((sku) => sku.node.code.trim())
        });
      } else {
        dispatch("validCarryTemplate", {
          code: code.trim(),
          name: name.trim(),
          description: description.trim(),
          skus: skusFromCarryTemplate.map((sku) => sku.node.code.trim()),
          version
        });
      }
    } else {
      toastNotifier.warning(
        "Required fields.",
        "Please fill in required fields",
        7000
      );
    }
  };

  const inactivateBranch = () => {
    dispatch("inactivateCarryTemplate", { code });
  };

  const resolveReadOnly = () =>
    mode === "edit" ? { readonly: true } : undefined;

  const addToTemplate = (item) => {
    allSKUs = allSKUs.filter((sku) => sku.node.code !== item.node.code);
    skusFromCarryTemplate = [...skusFromCarryTemplate, item];
  };

  const removeFromTemplate = (item) => {
    skusFromCarryTemplate = skusFromCarryTemplate.filter(
      (sku) => sku.node.code !== item.node.code
    );
    allSKUs = [...allSKUs, item];
  };

  if (mode === "edit") {
    allSKUs = allSKUs.filter(
      (sku) =>
        skusFromCarryTemplate.findIndex(
          (skuFromTemplate) => skuFromTemplate.node.code === sku.node.code
        ) < 0
    );
  }
</script>

{#if mode === 'edit'}
  <div class="w-full text-right pr-12">
    <DeactivateModal branchCode={code} confirmEvent={inactivateBranch} />
  </div>
{/if}
<form on:submit|preventDefault={submitForm}>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={code}
      {...resolveReadOnly()}
      placeholder="Code..."
      type="text"
      label="Code" />
  </div>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={name}
      placeholder="Name..."
      type="text"
      label="Name" />
  </div>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={description}
      placeholder="Description..."
      type="text"
      label="Description" />
  </div>

  {#if mode === 'edit'}
    <input type="hidden" bind:value={version} />
  {/if}

  <div class="flex flex-row items-center w-full p-4">
    <div class="flex flex-col mr-1 ">
      <h2 class="font-bold">SKUs</h2>
      <div class="shadow h-48 overflow-y-auto rounded" style="min-width: 200px">
        {#each allSKUs as sku}
          <div class="text-sm flex flex-row justify-between">
            <div>{sku.node.code}</div>
            <div class="pr-1">
              <a href="#!" class="text-primary" on:click={addToTemplate(sku)}>
                +
              </a>
            </div>

          </div>
        {/each}
      </div>
    </div>
    <div class="flex flex-col">
      <h2 class="font-bold">Current SKUs</h2>
      <div class="shadow h-48 overflow-y-auto ml-1 " style="min-width: 200px">

        {#each skusFromCarryTemplate as sku}
          <div class="text-sm flex flex-row justify-between">
            <div class="pl-1">
              <a
                href="#!"
                class="text-primary"
                on:click={removeFromTemplate(sku)}>
                -
              </a>
            </div>
            <div>{sku.node.code}</div>

          </div>
        {/each}
      </div>
    </div>
  </div>

  <div class="w-full flex flex-row-reverse justify-evenly pb-2 mt-8 px-4">
    <Button text={buttonLabel} color="primary" type="submit" {loading} />
    <div class="w-25" />
    <div class="text-center">
      <EmptyButton text="Cancel" color="text" on:click={cancelAction} />
    </div>
  </div>
</form>
