<script>
  import { Gauge } from "@saztrek/firefly";

  import { COMPLIANCE_STRINGS } from "./StringConstants";

  export let code;
  export let name;
  export let compliant;
  export let inputPercentage;
  export let complianceThreshold;
  export let complete;
  export let noDelivery;
  export let noEnding;
  export let withAdjustment;
  export let latestCollaborator;

  const inputPercentageRounded =
    inputPercentage !== 0 ? (inputPercentage * 100).toFixed(2) : 0;
</script>

<div class="w-full flex flex-row py-2 px-6">
  <div class="w-full shadow rounded">
    <div class="pb-2 h-full border-l-8 rounded" class:border-secondary={true}>
      <div class="border-b border-darkerGray" style="height: 5.5rem;">
        <div class="flex flex-row justify-between items-start py-2 px-4">
          <div class="flex flex-col items-start pr-3">
            <div class="text-xs font-bold italic">{code}</div>
            <div class="text-sm">{name}</div>
          </div>
          <div class="flex flex-col">
            <Gauge
              value={inputPercentage}
              size="70"
              borderWidth="5"
              valueText="{inputPercentageRounded}%"
              valueFontSize={12}
              valueFontWeight={400}
              valueTextColor="#219643"
              borderColor="#219643" />
          </div>
        </div>

      </div>
      <div class="h-full">
        <div
          class="flex flex-row pt-1 mx-3 pr-4 bg-white text-tableText
          justify-center">
          <table class="w-full table-auto">
            <tbody>
              <tr>
                <td>{COMPLIANCE_STRINGS.COMPLIANT}</td>
                <td>{compliant}</td>
              </tr>
              <tr>
                <td>{COMPLIANCE_STRINGS.INPUT_PERCENTAGE}</td>
                <td>{inputPercentageRounded}%</td>
              </tr>
              <tr>
                <td>{COMPLIANCE_STRINGS.COMPLIANCE_THRESHOLD}</td>
                <td>{complianceThreshold}</td>
              </tr>
              <tr>
                <td>{COMPLIANCE_STRINGS.COMPLETE}</td>
                <td>{complete}</td>
              </tr>
              <tr>
                <td>{COMPLIANCE_STRINGS.NO_DELIVERY}</td>
                <td>{noDelivery}</td>
              </tr>
              <tr>
                <td>{COMPLIANCE_STRINGS.NO_ENDING}</td>
                <td>{noEnding}</td>
              </tr>
              <tr>
                <td>{COMPLIANCE_STRINGS.WITH_ADJUSTMENT}</td>
                <td>{withAdjustment}</td>
              </tr>
              <tr>
                <td>{COMPLIANCE_STRINGS.LAST_UPDATED_BY}</td>
                <td>{latestCollaborator}</td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>
  </div>
</div>
