import { gql } from "apollo-boost";

// QUERIES
const MANUFACTURERS = gql`
  query manufacturers(
    $findManufacturerInput: FindManufacturerInput
    $connectionArgs: ConnectionArgs
  ) {
    manufacturers(
      findManufacturerInput: $findManufacturerInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        cursor
        node {
          id
          code
          name
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;
const MANUFACTURERS_WITH_BRANDS = gql`
  query manufacturers(
    $findManufacturerInput: FindManufacturerInput
    $connectionArgs: ConnectionArgs
  ) {
    manufacturers(
      findManufacturerInput: $findManufacturerInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        cursor
        node {
          id
          code
          name
          brands {
            edges {
              cursor
              node {
                id
                code
                name
                manufacturer {
                  name
                }
                active
              }
            }
            pageInfo {
              hasPreviousPage
              hasNextPage
              startCursor
              endCursor
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const GET_MANUFACTURER = gql`
  query manufacturer($code: String!) {
    manufacturer(code: $code) {
      id
      code
      name
      active
      version
    }
  }
`;

// MUTATIONS

const ADD_MANUFACTURER = gql`
  mutation addManufacturer($addManufacturerInput: AddManufacturerInput!) {
    addManufacturer(addManufacturerInput: $addManufacturerInput) {
      id
      code
      name
      active
      version
    }
  }
`;

const EDIT_MANUFACTURER = gql`
  mutation editManufacturer($editManufacturerInput: EditManufacturerInput!) {
    editManufacturer(editManufacturerInput: $editManufacturerInput) {
      id
      code
      name
      active
      version
    }
  }
`;

const INACTIVATE_MANUFACTURER = gql`
  mutation inactivateManufacturer($code: String!) {
    inactivateManufacturer(code: $code) {
      id
      code
      name
      active
      version
    }
  }
`;

const ACTIVATE_MANUFACTURER = gql`
  mutation activateManufacturer($code: String!) {
    activateManufacturer(code: $code) {
      id
      code
      name
      active
      version
    }
  }
`;

export {
  MANUFACTURERS,
  MANUFACTURERS_WITH_BRANDS,
  GET_MANUFACTURER,
  ADD_MANUFACTURER,
  EDIT_MANUFACTURER,
  INACTIVATE_MANUFACTURER,
  ACTIVATE_MANUFACTURER
};
