<script>
  import { KeypadMenu } from "@saztrek/firefly";
  import { navigateTo } from "svelte-router-spa";

  export let currentRoute;
  export let params;

  /* svelte router force feeds these parameters (see above).
     Console warns if we don't do the exports.
     Svelte warns if they are unused, therefore putting them down here, in case code needs it.
  */
  // eslint-disable-next-line no-unused-vars
  const paramsFromRouter = params;
  // eslint-disable-next-line no-unused-vars
  const currentRouteFromRouter = currentRoute;

  const items = [
    {
      label: "Users",
      action: () => navigateTo("/users/list"),
      app: "mermade"
    },
    {
      label: "Password Reset",
      action: () => navigateTo("/resetpasswordlist"),
      app: "mermade"
    },
    {
      label: "Import Users",
      action: () => navigateTo("/users/import"),
      app: "mermade"
    }
  ];
</script>

<div class="flex justify-center pt-20">
  <KeypadMenu {items} size="l" />
</div>
