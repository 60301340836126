<script>
  import { createEventDispatcher } from "svelte";
  import { Table, Button, Icon } from "@saztrek/firefly";
  import LoadingIndicator from "./LargeLoadingIndicator.svelte";

  export let columns;
  export let items;
  export let actions;
  export let loading = false;
  export let isError = false;

  const dispatch = createEventDispatcher();

  const nextPage = () => {
    dispatch("nextPage");
  };

  const previousPage = () => {
    dispatch("previousPage");
  };
</script>

<div class="w-full p-10 rounded shadow" style="min-height: 520px;">
  {#if loading}
    <LoadingIndicator />
  {:else if isError}
    <div class="flex flex-col items-center">
      <div class="pb-2">
        <Icon type="table" size="l" />
      </div>
      <div>
        Something went wrong, Please try refreshing the page.
        <br />
        If the problem persists, please contact your administrator.
      </div>
    </div>
  {:else}
    <Table {columns} {items} {actions} />
  {/if}
</div>
<div class="pt-2">
  <Button icon="arrowLeft" color="primary" on:click={previousPage} />
  <Button icon="arrowRight" color="primary" on:click={nextPage} />
</div>
