<script>
  import dayjs from "dayjs";
  import {
    Icon,
    LoadingContent,
    // TextField,
    Button,
    EmptyButton,
    toastNotifier,
    SelectField
  } from "@saztrek/firefly";
  import { getClient, query } from "svelte-apollo";

  import {
    EDGAR_SHEET_CONTEXT_LITE,
    GET_EDGAR_SHEET_LITE,
    EDGAR_SHEET_CONTEXT
  } from "../../../../gql";
  import {
    dateFormatToServer,
    dateFormatToUI
  } from "../../../../utilities/dateFormatter";

  import { exportToCSV } from "../../../../utilities/export";

  import {
    nearestEndDay,
    nearestStartDay,
    getNearestEndDay,
    getNearestStartDay
  } from "../edgarUtils";

  import { currentTenant } from "../../../../stores/current_tenant";

  import BranchList from "../components/EDGARBranchList.svelte";

  import CardView from "./CardView.svelte";
  import TableView from "./TableView.svelte";

  import { formatAndExportCPReport } from "./formatCPReport";

  let currentStartDay = nearestStartDay;
  let currentEndDay = nearestEndDay;
  let daysToIncrement = 7;
  let isDaily = false;
  let tenantVarsLoaded = false;

  $: if ($currentTenant.tenantVariables && !tenantVarsLoaded) {
    daysToIncrement = $currentTenant.featureFlags.find(
      (flag) => flag.code === "EDGAR_isDaily"
    ).value
      ? 1
      : 7;

    isDaily = $currentTenant.featureFlags.find(
      (flag) => flag.code === "EDGAR_isDaily"
    ).value;
    currentStartDay = !isDaily ? getNearestStartDay() : dayjs();
    currentEndDay = !isDaily ? getNearestEndDay() : currentStartDay;
    tenantVarsLoaded = true;
  }

  const useRawOfftake = false;

  const client = getClient();

  const sheetsOp = query(client, {
    query: EDGAR_SHEET_CONTEXT_LITE,
    // fetchPolicy: "cache-and-network",
    variables: {
      startDate: dateFormatToServer(currentStartDay.toDate()),
      endDate: !isDaily
        ? dateFormatToServer(currentEndDay.toDate())
        : dateFormatToServer(currentStartDay.toDate())
    }
  });

  let singleSheetOp;
  let allSheetsOp;

  let skus = [];

  let currentSheetCode = "";
  let currentBranch;
  let chains = [];
  let currentChain = "";

  const processBranches = (sheetsResult) => {
    if (!sheetsResult.data.edgarSheetContext) {
      return [];
    }
    chains = [];
    const returnData = sheetsResult.data.edgarSheetContext.branchSheets.map(
      (branchSheet) => {
        const hasSheet = branchSheet.edgarSheet !== null;

        if (
          !chains.find((chain) => chain.value === branchSheet.branch.chain.code)
        ) {
          chains.push({
            option: branchSheet.branch.chain.name,
            value: branchSheet.branch.chain.code
          });
        }

        return {
          code: branchSheet.branch.code,
          name: branchSheet.branch.name,
          skusCovered: hasSheet
            ? Math.floor(branchSheet.edgarSheet.meta.inputPercentage * 100)
            : 0,
          edgarSheet: branchSheet.edgarSheet,
          generated: hasSheet ? branchSheet.edgarSheet.meta.generated : false
        };
      }
    );

    chains.unshift({
      option: "All Chains",
      value: ""
    });

    return returnData;
  };

  const processSKUs = (response) => {
    return response.edgarSheet.skus.edges.map((skuEdge) => {
      const { node } = skuEdge;
      const { details, data, computed, meta } = node;
      return {
        code: details.code,
        name: details.name,
        unitCount: details.unitCount,
        weekSupply: details.weekSupply !== null ? details.weekSupply : "N/A",
        offtakeUnits: useRawOfftake
          ? computed.rawOfftakeUnits.toFixed(2)
          : computed.offtakeUnits.toFixed(2),
        // rawOfftakeUnits: computed.rawOfftakeUnits.toFixed(2),
        categoryCode: details.category.code,
        categoryName: details.category.name,
        deliveryContainers: data.deliveryContainers,
        deliveryUnits: data.deliveryUnits,
        totalDeliveryUnits: computed.totalDeliveryUnits,
        endingContainers: data.endingContainers,
        endingUnits: data.endingUnits,
        totalEndingUnits: computed.totalEndingUnits,
        badOrderContainers: data.badOrderContainers,
        badOrderUnits: data.badOrderUnits,
        totalBadOrderUnits: computed.totalBadOrderUnits,
        transferOutContainers: data.transferOutContainers,
        transferOutUnits: data.transferOutUnits,
        totalTransferOutUnits: computed.totalTransferOutUnits,
        transferInContainers: data.transferInContainers,
        transferInUnits: data.transferInUnits,
        totalTransferInUnits: computed.totalTransferInUnits,
        adjustmentContainers: data.adjustmentContainers,
        adjustmentUnits: data.adjustmentUnits,
        totalAdjustmentUnits: computed.totalAdjustmentUnits,
        suggestedOrderUnits: computed.suggestedOrderUnits,
        beginningContainers: meta.beginningContainers,
        beginningUnits: meta.beginningUnits,
        offtakeContainers: computed.offtakeContainers,
        totalBeginningUnits: meta.totalBeginningUnits
          ? meta.totalBeginningUnits
          : "N/A",

        noData:
          computed.totalDeliveryUnits +
            computed.totalEndingUnits +
            computed.totalBadOrderUnits +
            computed.totalTransferOutUnits +
            computed.totalTransferInUnits +
            computed.totalAdjustmentUnits ===
          0,
        branchCode: response.edgarSheet.branch.code,
        branchName: response.edgarSheet.branch.name,
        chainCode: response.edgarSheet.branch.chain
          ? response.edgarSheet.branch.chain.code
          : "No Chain Code found"
      };
    });
  };

  let originalSKUs;
  let loadingSKUs = false;

  const sheetsSKUCache = new Map();

  const branchSelected = (event) => {
    const { branch } = { ...event.detail };

    skus = [];
    loadingSKUs = true;

    if (!branch.edgarSheet) {
      currentSheetCode = null;

      loadingSKUs = false;
    } else {
      currentSheetCode = branch.edgarSheet.code;
      currentBranch = branch;
      const cachedSKUs = sheetsSKUCache.get(currentSheetCode);

      if (cachedSKUs) {
        skus = cachedSKUs;
        originalSKUs = skus;
        loadingSKUs = false;
      } else {
        singleSheetOp = query(client, {
          query: GET_EDGAR_SHEET_LITE,
          variables: {
            code: currentSheetCode
          }
        });

        $singleSheetOp.then((response) => {
          skus = processSKUs(response.data);
          originalSKUs = skus;
          loadingSKUs = false;
          sheetsSKUCache.set(currentSheetCode, [...skus]);
        });
      }
    }
  };

  let searchSKUCode;
  let searchSKUName;

  $: if (searchSKUCode || searchSKUName) {
    if (searchSKUCode) {
      skus = skus.filter(
        (sku) =>
          searchSKUCode !== "" &&
          sku.code.toUpperCase().indexOf(searchSKUCode.toUpperCase()) > -1
      );
    }

    if (searchSKUName) {
      skus = skus.filter(
        (sku) =>
          searchSKUName !== "" &&
          sku.name.toUpperCase().indexOf(searchSKUName.toUpperCase()) > -1
      );
    }
  } else if (searchSKUCode === "" && searchSKUName === "") {
    skus = originalSKUs;
  }

  // $: filterByChain(currentChain);

  const previousWeek = () => {
    currentSheetCode = "";
    skus = [];
    currentStartDay = currentStartDay.subtract(daysToIncrement, "day");
    currentEndDay = currentEndDay.subtract(daysToIncrement, "day");
  };

  const nextWeek = () => {
    currentSheetCode = "";
    skus = [];
    currentStartDay = currentStartDay.add(daysToIncrement, "day");
    currentEndDay = currentEndDay.add(daysToIncrement, "day");
  };

  $: sheetsOp.refetch({
    startDate: dateFormatToServer(currentStartDay.toDate()),
    endDate: !isDaily
      ? dateFormatToServer(currentEndDay.toDate())
      : dateFormatToServer(currentStartDay.toDate())
  });

  let isCardView = true;

  const toggleView = () => {
    isCardView = !isCardView;
  };

  const exportCurrentSheet = () => {
    const cols = [
      `"SKU Code"`,
      `"SKU Name"`,
      `"UOM"`,
      `"Week Supply"`,
      `"Total Beginning Units"`,
      `"Offtake Units"`,
      `"Suggested Order"`,
      `"Delivery Containers"`,
      `"Delivery Units"`,
      `"Total Delivery Units"`,
      `"Ending Containers"`,
      `"Ending Units"`,
      `"Total Ending Units"`,
      `"Bad Order Containers"`,
      `"Bad Order Units"`,
      `"Total Bad Order Units"`,
      `"Stock Transfer Out Containers"`,
      `"Stock Transfer Out Units"`,
      `"Total Stock Transfer Out Units"`,
      `"Stock Transfer In Containers"`,
      `"Stock Transfer In Units"`,
      `"Total Stock Transfer In Units"`,
      `"Adjustment Containers"`,
      `"Adjustment Units"`,
      `"Total Adjustment Units"`,
      `"Branch Code"`,
      `"Branch Name"`
    ]
      .join(",")
      .concat("\n");

    const rows = skus
      .map(
        (node) =>
          `"${node.code}","${node.name}","${node.unitCount}","${node.weekSupply}","${node.totalBeginningUnits}","${node.offtakeUnits}","${node.suggestedOrderUnits}","${node.deliveryContainers}","${node.deliveryUnits}","${node.totalDeliveryUnits}","${node.endingContainers}","${node.endingUnits}","${node.totalEndingUnits}","${node.badOrderContainers}","${node.badOrderUnits}","${node.totalBadOrderUnits}","${node.transferOutContainers}","${node.transferOutUnits}","${node.totalTransferOutUnits}","${node.transferInContainers}","${node.transferInUnits}","${node.totalTransferInUnits}","${node.adjustmentContainers}","${node.adjustmentUnits}","${node.totalAdjustmentUnits}","${node.branchCode}","${node.branchName}"`
      )
      .join("\r\n");

    exportToCSV(
      cols,
      rows,
      `EDGAR Sheet for ${currentBranch.name} - ${dateFormatToServer(
        currentStartDay
      )} to ${dateFormatToServer(currentEndDay)}`
    );
  };

  let exportAllLoading = false;

  const exportAll = () => {
    exportAllLoading = true;
    allSheetsOp = query(client, {
      query: EDGAR_SHEET_CONTEXT,
      variables: {
        startDate: dateFormatToServer(currentStartDay.toDate()),
        endDate: dateFormatToServer(currentEndDay.toDate())
      }
    });

    $allSheetsOp
      .then((response) => {
        let allSheets = [];

        const { data } = response;
        const { edgarSheetContext } = data;
        const { branchSheets } = edgarSheetContext;

        branchSheets.forEach((branchSheet) => {
          if (
            branchSheet.edgarSheet &&
            (currentChain === "" ||
              currentChain === branchSheet.branch.chain.code)
          ) {
            allSheets = [...allSheets, processSKUs(branchSheet)];
          }
        });

        if (allSheets.length === 0) {
          exportAllLoading = false;
          toastNotifier.danger(
            "No Sheets Found!",
            `There are no EDGAR Sheets in this coverage`,
            7000
          );
        } else {
          const cols = [
            `"Branch Code"`,
            `"Branch Name"`,
            `"Chain Code"`,
            `"SKU Code"`,
            `"SKU Name"`,
            `"Category Code"`,
            `"Category Name"`,
            `"UOM"`,
            `"Week Supply"`,
            `"Total Beginning Units"`,
            `"Offtake Units"`,
            `"Suggested Order"`,
            `"Delivery Containers"`,
            `"Delivery Units"`,
            `"Total Delivery Units"`,
            `"Ending Containers"`,
            `"Ending Units"`,
            `"Total Ending Units"`,
            `"Bad Order Containers"`,
            `"Bad Order Units"`,
            `"Total Bad Order Units"`,
            `"Stock Transfer Out Containers"`,
            `"Stock Transfer Out Units"`,
            `"Total Stock Transfer Out Units"`,
            `"Stock Transfer In Containers"`,
            `"Stock Transfer In Units"`,
            `"Total Stock Transfer In Units"`,
            `"Adjustment Containers"`,
            `"Adjustment Units"`,
            `"Total Adjustment Units"`
          ]
            .join(",")
            .concat("\n");

          const flattenedSheets = allSheets.flat();

          if (
            $currentTenant.code === "colpal" ||
            $currentTenant.code === "bopis"
          ) {
            formatAndExportCPReport(
              flattenedSheets,
              dateFormatToServer(currentStartDay),
              dateFormatToServer(currentEndDay),
              currentChain
            );
            exportAllLoading = false;
          } else {
            const rows = flattenedSheets
              .map(
                (node) =>
                  `"${node.branchCode}","${node.branchName}","${node.chainCode}","${node.code}","${node.name}","${node.categoryCode}","${node.categoryName}","${node.unitCount}","${node.weekSupply}","${node.totalBeginningUnits}","${node.offtakeUnits}","${node.suggestedOrderUnits}","${node.deliveryContainers}","${node.deliveryUnits}","${node.totalDeliveryUnits}","${node.endingContainers}","${node.endingUnits}","${node.totalEndingUnits}","${node.badOrderContainers}","${node.badOrderUnits}","${node.totalBadOrderUnits}","${node.transferOutContainers}","${node.transferOutUnits}","${node.totalTransferOutUnits}","${node.transferInContainers}","${node.transferInUnits}","${node.totalTransferInUnits}","${node.adjustmentContainers}","${node.adjustmentUnits}","${node.totalAdjustmentUnits}"`
              )
              .join("\r\n");

            const csvContent = cols + rows;

            const reportBlob = new Blob([csvContent], { type: "text/csv" });

            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            const url = window.URL.createObjectURL(reportBlob);
            a.href = url;
            a.download = `All EDGAR Sheets - ${dateFormatToServer(
              currentStartDay
            )} to ${dateFormatToServer(currentEndDay)}.csv`;
            a.click();
            window.URL.revokeObjectURL(url);

            exportAllLoading = false;

            toastNotifier.success(
              "Success!",
              `Export successful, waiting for download.`,
              7000
            );
          }
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        toastNotifier.danger(
          "Something went wrong!",
          `Export failed, please contact the administrator`,
          7000
        );
      });
  };
</script>

<div class="m-0 pb-3 text-base flex flex-row items-center justify-center px-2">
  <div class="w-2/12">
    <Button
      text="Export All"
      on:click={exportAll}
      color="secondary"
      loading={exportAllLoading} />
  </div>
  <div class="justify-center w-8/12 flex flex-row">
    <div>
      <EmptyButton
        icon="arrowLeft"
        text=""
        size="tiny"
        on:click={previousWeek} />
    </div>
    <div class="w-56 text-center">
      {#if !isDaily}
        {dateFormatToUI(currentStartDay.toDate())} to {dateFormatToUI(currentEndDay.toDate())}
      {:else}{dateFormatToUI(currentStartDay.toDate())}{/if}
    </div>
    <div>
      <EmptyButton icon="arrowRight" text="" size="tiny" on:click={nextWeek} />
    </div>
  </div>
  <div
    class="w-2/12 flex flex-row justify-end"
    class:justify-between={currentSheetCode !== '' && !loadingSKUs}>
    <SelectField options={chains} bind:value={currentChain} />
    <Button
      text={isCardView ? 'List View' : 'Card View'}
      on:click={toggleView} />
    {#if currentSheetCode !== '' && !loadingSKUs}
      <Button color="secondary" text="Export" on:click={exportCurrentSheet} />
    {/if}
  </div>
</div>

<div
  class="flex flex-row w-full md:-mx-2 justify-between"
  style="min-height: 945px; max-height: 945px; height: 945px;">
  <div class="w-3/12 lg:3/12 px-2 h-full">
    {#await $sheetsOp}
      <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
      </div>
    {:then sheetsResult}
      {#if sheetsResult.loading}
        <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
        </div>
      {:else}
        <BranchList
          branches={processBranches(sheetsResult)}
          on:branchSelected={branchSelected} />
      {/if}

    {/await}
  </div>
  <div class="w-9/12 lg:4/12 pt-2 px-2 h-full border-lightGray rounded border">
    {#if currentSheetCode}
      <div class="w-full flex flex-row pb-4 px-2 h-24">
        <div class="flex flex-col justify-between w-1/2">
          <div class="flex flex-col font-bold">
            <div class="text-sm">{currentSheetCode}</div>
            <div class="text-xs">
              {currentBranch.code} - {currentBranch.name}
            </div>
          </div>
        </div>
        <div class="flex-row flex w-1/2">
          <div class="flex flex-col w-1/2 text-xs">
            <div class="font-bold">Inventory Dates</div>
            <div class="text-xs h-12 overflow-y-auto">
              {#each currentBranch.edgarSheet.inventoryDates as inventoryDate}
                <div>{dateFormatToUI(inventoryDate)}</div>
              {/each}
            </div>
          </div>
          <div class="flex flex-col w-1/2 text-xs">
            <div class="font-bold ">Delivery Dates</div>
            <div class="text-xs h-12 overflow-y-auto">
              {#each currentBranch.edgarSheet.deliveryDates as deliveryDate}
                <div>{dateFormatToUI(deliveryDate)}</div>
              {/each}
            </div>
          </div>

        </div>
      </div>
    {/if}
    {#if isCardView}
      <CardView {skus} {loadingSKUs} />
    {:else}
      <TableView {skus} {loadingSKUs} />
    {/if}
    <div class="h-full w-full flex flex-col items-center justify-center">
      <Icon size="l" type="table" />
      <div class="pt-2">
        {#if currentSheetCode === ''}
          Choose an EDGAR Sheet on the left.
        {:else}No Sheet created yet for this branch.{/if}
      </div>
    </div>

  </div>
</div>
