<script>
  export let header;
</script>

<div class="flex items-center flex-col justify-between shadow rounded w-2/3">

  <div class="pt-4">
    <h2>{header}</h2>
  </div>
  <slot />
</div>
