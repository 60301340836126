<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, query, mutate } from "svelte-apollo";

  import {
    EDIT_SKU,
    GET_SKU,
    INACTIVATE_SKU,
    CATEGORIES,
    BRANDS
  } from "../../../gql";

  import Panel from "../../components/Panel.svelte";
  import SKUForm from "./SKUForm.svelte";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  const cancelUpdate = () => {
    navigateTo("/skus/list");
  };

  const client = getClient();

  let loading = false;

  const skuOp = query(client, {
    query: GET_SKU,
    variables: {
      code: decodeURIComponent(id)
    }
  });

  const categoriesOp = query(client, {
    query: CATEGORIES
  });

  const brandsOp = query(client, {
    query: BRANDS
  });

  const inactivateSKU = async (event) => {
    const skuData = { ...event.detail };
    loading = true;
    try {
      await mutate(client, {
        mutation: INACTIVATE_SKU,
        variables: {
          code: skuData.code
        }
      });
      toastNotifier.success(
        "Success!",
        `SKU ${skuData.code} has been deactivated`,
        7000
      );
      navigateTo("/skus/list");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };

  const editSKU = async (event) => {
    try {
      const skuData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: EDIT_SKU,
        variables: {
          editSKUInput: {
            code: skuData.code,
            name: skuData.name,
            unitCount: skuData.unitCount,
            weekSupply: skuData.weekSupply,
            brandCode: skuData.brandCode,
            categoryCode: skuData.categoryCode,
            core: skuData.core,
            version: skuData.version
          }
        }
      });
      toastNotifier.success("Success!", `SKU ${skuData.name} Updated`, 7000);
      navigateTo("/skus/list");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Update SKU">
    {#await $skuOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      {#await $categoriesOp}
        <div class="p-10 w-full" style="min-height: 500px">
          <LoadingContent />
        </div>
      {:then categoriesResult}
        {#await $brandsOp}
          <div class="p-10 w-full" style="min-height: 500px">
            <LoadingContent />
          </div>
        {:then brandsResult}
          <SKUForm
            mode="edit"
            code={result.data.sku.code}
            name={result.data.sku.name}
            categories={categoriesResult.data.categories.edges}
            brands={brandsResult.data.brands.edges}
            brandCode={result.data.sku.brand.code}
            categoryCode={result.data.sku.category.code}
            unitCount={result.data.sku.unitCount}
            weekSupply={result.data.sku.weekSupply}
            core={result.data.sku.core}
            version={result.data.sku.version}
            on:validSKU={editSKU}
            on:inactivateSKU={inactivateSKU}
            cancelAction={cancelUpdate}
            {loading}
            buttonLabel="Update" />
        {/await}
      {/await}
    {/await}

  </Panel>
</div>
