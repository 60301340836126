<script>
  import { Table } from "@saztrek/firefly";

  export let edges;

  const columns = [
    {
      field: "name",
      name: "Category",
      dataType: "string"
    },
    {
      field: "universeLength",
      name: "Universe Length (cm)",
      dataType: "number"
    },
    {
      field: "sumOfTotalCategoryLengths",
      name: "Total Category (cm)",
      dataType: "number"
    },

    {
      field: "percentSOS",
      name: "% SOS"
    },
    {
      field: "sumOfCompetitorCategoryLengths",
      name: "Competitor Total Category (cm)",
      dataType: "number"
    },

    {
      field: "percentSOSCompetitor",
      name: "% SOS Competitor"
    }
  ];
</script>

<div>
  <div class="pt-3 pl-3 pr-3 pb-8">
    <Table {columns} items={edges} />
  </div>
</div>
