<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, query, mutate } from "svelte-apollo";

  import {
    EDIT_BRANCH,
    GET_BRANCH,
    INACTIVATE_BRANCH,
    CHAINS,
    EDGAR_CARRY_TEMPLATE,
    CARRYTEMPLATES_WITHSKUS,
    EDIT_EDGAR_CARRY_TEMPLATE,
    EDIT_DON_CARRYTEMPLATE
  } from "../../../gql";

  import Panel from "../../components/Panel.svelte";
  import BranchForm from "./BranchForm.svelte";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  const cancelUpdate = () => {
    navigateTo("/branches/list");
  };

  const client = getClient();

  let loading = false;

  const branchOp = query(client, {
    query: GET_BRANCH,
    fetchPolicy: "network-only",
    variables: {
      code: decodeURIComponent(id)
    }
  });

  const chainsOp = query(client, {
    query: CHAINS
  });

  const edgarBranchCarryTemplateOp = query(client, {
    query: EDGAR_CARRY_TEMPLATE,
    fetchPolicy: "network-only",
    variables: {
      branchCode: decodeURIComponent(id)
    }
  });

  const carryTemplatesOp = query(client, {
    query: CARRYTEMPLATES_WITHSKUS
  });

  const inactivateBranch = async (event) => {
    const branchData = { ...event.detail };
    loading = true;
    try {
      await mutate(client, {
        mutation: INACTIVATE_BRANCH,
        variables: {
          code: branchData.code
        }
      });
      toastNotifier.success(
        "Success!",
        `Branch ${branchData.code} has been deactivated`,
        7000
      );
      navigateTo("/branches/list");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };

  const editBranch = async (event) => {
    try {
      const branchData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: EDIT_BRANCH,
        variables: {
          editBranchInput: {
            code: branchData.code,
            name: branchData.name,
            version: branchData.version,
            chainCode: branchData.chainCode,
            baseAddress: branchData.baseAddress,
            zip: branchData.zip,
            city: branchData.city,
            province: branchData.province,
            regionCode: branchData.regionCode,
            regionName: branchData.regionName,
            coordinates: branchData.coordinates
          }
        }
      });

      await mutate(client, {
        mutation: EDIT_EDGAR_CARRY_TEMPLATE,
        variables: {
          setEDGARBranchCarryTemplateInput: {
            branchCode: branchData.code,
            carryTemplateCodes: branchData.carryTemplateCodes,
            weekSupplyConfigurations: branchData.weekSupplyConfiguration
          }
        }
      });

      await mutate(client, {
        mutation: EDIT_DON_CARRYTEMPLATE,
        variables: {
          setDONBranchCarryTemplateInput: {
            branchCode: branchData.code,
            carryTemplateCodes: branchData.carryTemplateCodes
          }
        }
      });

      toastNotifier.success(
        "Success!",
        `Branch ${branchData.name} Updated`,
        7000
      );
      navigateTo("/branches/list");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Update Branch">
    {#await $branchOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      {#await $chainsOp}
        <div class="p-10 w-full" style="min-height: 500px">
          <LoadingContent />
        </div>
      {:then chainsResult}
        {#await $edgarBranchCarryTemplateOp}
          <div class="p-10 w-full" style="min-height: 500px">
            <LoadingContent />
          </div>
        {:then edgarBranchCarryTemplateResult}
          {#await $carryTemplatesOp}
            <div class="p-10 w-full" style="min-height: 500px">
              <LoadingContent />
            </div>
          {:then carryTemplatesResult}
            <BranchForm
              mode="edit"
              chains={chainsResult.data.chains.edges}
              code={result.data.branch.code}
              name={result.data.branch.name}
              chainCode={result.data.branch.chain.code}
              version={result.data.branch.version}
              baseAddress={result.data.branch.baseAddress}
              zip={result.data.branch.zip}
              city={result.data.branch.city}
              province={result.data.branch.province}
              regionCode={result.data.branch.regionCode}
              regionName={result.data.branch.regionName}
              latitude={result.data.branch.coordinates.latitude}
              longitude={result.data.branch.coordinates.longitude}
              edgarBranchCarryTemplates={edgarBranchCarryTemplateResult.data.edgarBranchCarryTemplate ? edgarBranchCarryTemplateResult.data.edgarBranchCarryTemplate.carryTemplates : []}
              weekSupplyConfigurations={edgarBranchCarryTemplateResult.data.edgarBranchCarryTemplate ? edgarBranchCarryTemplateResult.data.edgarBranchCarryTemplate.weekSupplyConfigurations : []}
              carryTemplates={carryTemplatesResult.data.carryTemplates.edges}
              on:validBranch={editBranch}
              on:inactivateBranch={inactivateBranch}
              cancelAction={cancelUpdate}
              {loading}
              buttonLabel="Update" />
          {/await}
        {/await}
      {/await}
    {/await}

  </Panel>
</div>
