import { gql } from "apollo-boost";

const NEMO_SHEETS_LITE = gql`
  query nemoSheets(
    $findNEMOSheetInput: FindNEMOSheetInput
    $connectionArgs: ConnectionArgs
  ) {
    nemoSheets(
      findNEMOSheetInput: $findNEMOSheetInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        cursor
        node {
          id
          code
          sheetContext {
            startDate
            endDate
            gracePeriodDate
            tags {
              key
              value
            }
          }
          complete
          branch {
            code
            name
            chain {
              code
              name
              account {
                code
                name
              }
            }
            tags {
              key
              value
            }
          }
        }
      }
    }
  }
`;

const NEMO_SHEETS = gql`
  query nemoSheets(
    $findNEMOSheetInput: FindNEMOSheetInput
    $connectionArgs: ConnectionArgs
  ) {
    nemoSheets(
      findNEMOSheetInput: $findNEMOSheetInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        cursor
        node {
          id
          code
          sheetContext {
            startDate
            endDate
            gracePeriodDate
            tags {
              key
              value
            }
          }
          complete
          branch {
            code
            name
            chain {
              code
              name
              account {
                code
                name
              }
            }
            tags {
              key
              value
            }
          }
          skus {
            edges {
              cursor
              node {
                details {
                  code
                  name
                  core
                  unitCount
                  brand {
                    code
                    name
                    manufacturer {
                      code
                      name
                    }
                  }
                  category {
                    code
                    name
                  }
                  tags {
                    key
                    value
                  }
                }
                entries {
                  data {
                    expiryDate
                    nearExpiryContainers
                    nearExpiryUnits
                  }
                  computed {
                    totalNearExpiryUnits
                  }
                }
              }
            }
          }
          latestCollaborator {
            code
            name {
              firstName
              lastName
            }
          }
          timestamps
        }
      }
    }
  }
`;

const GET_NEMO_SHEET = gql`
  query nemoSheet($code: String!) {
    nemoSheet(code: $code) {
      id
      code
      sheetContext {
        startDate
        endDate
        gracePeriodDate
        tags {
          key
          value
        }
      }
      complete
      branch {
        code
        name

        chain {
          code
          name
          account {
            code
            name
          }
        }
        tags {
          key
          value
        }
      }
      carryTemplate {
        code
        name
      }
      skus {
        edges {
          cursor
          node {
            details {
              code
              name
              core
              unitCount
              brand {
                code
                name
                manufacturer {
                  code
                  name
                }
              }
              category {
                code
                name
              }
              tags {
                key
                value
              }
            }
            entries {
              data {
                expiryDate
                nearExpiryContainers
                nearExpiryUnits
              }
              computed {
                totalNearExpiryUnits
              }
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
      collaborators {
        code
        name {
          firstName
          lastName
        }
      }
      latestCollaborator {
        code
        name {
          firstName
          lastName
        }
      }
      timestamps
      active
      version
    }
  }
`;

export { NEMO_SHEETS_LITE, GET_NEMO_SHEET, NEMO_SHEETS };
