<script>
  import { getClient, query, mutate } from "svelte-apollo";
  import dayjs from "dayjs";
  import {
    Button,
    LoadingContent,
    toastNotifier,
    AvatarIcon,
    formatDateToUI,
    EmptyButton,
    Spinner
  } from "@saztrek/firefly";

  import { currentUser } from "../../../stores/current_user";

  import {
    APPROVAL_REQUESTS,
    APPROVE_REQUEST,
    REJECT_REQUEST
  } from "../../../gql";

  const client = getClient();

  const approvalRequestsOp = query(client, {
    query: APPROVAL_REQUESTS,
    variables: {
      findApprovalRequestInput: {
        requestTypeCodes: ["CALENDARLEAVE"],
        statuses: ["PENDING"]
      }
    },
    pollInterval: 60000
  });

  const getKeyValueFromData = (edge, key) =>
    edge.node.data.find((datum) => datum.key === key).value;

  const employeeCache = [];

  const getEmployeeObjectFromEdge = (edge) => {
    const nodeId = edge.node.id;
    if (employeeCache[nodeId]) {
      return employeeCache[nodeId];
    }

    const empDataRaw = edge.node.data.find((datum) => datum.key === "employee");
    if (empDataRaw) {
      const jsonEmpData = JSON.parse(empDataRaw.value);
      employeeCache[nodeId] = jsonEmpData;
      return jsonEmpData;
    }

    return {};
  };

  const actionStatus = [];

  const approveRequest = async (edge) => {
    const { code } = edge.node;
    const employeeData = getEmployeeObjectFromEdge(edge);
    actionStatus[edge.node.id] = true;
    try {
      await mutate(client, {
        mutation: APPROVE_REQUEST,
        variables: {
          approverEmployeeCode: $currentUser.id,
          code
        }
      });
      toastNotifier.success(
        "Approved!",
        `Leave Request for ${employeeData.name.lastName}, ${
          employeeData.name.firstName
        } on ${formatDateToUI(getKeyValueFromData(edge, "date"), dayjs)}`,
        7000
      );

      approvalRequestsOp.refetch();
    } catch (error) {
      toastNotifier.danger("Failed to approve", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }

    actionStatus[edge.node.id] = false;
  };

  const rejectRequest = async (edge) => {
    const { code } = edge.node;
    const employeeData = getEmployeeObjectFromEdge(edge);
    actionStatus[edge.node.id] = true;

    try {
      await mutate(client, {
        mutation: REJECT_REQUEST,
        variables: {
          approverEmployeeCode: $currentUser.id,
          code
        }
      });
      toastNotifier.success(
        "Rejected",
        `Leave Request for ${employeeData.name.lastName}, ${
          employeeData.name.firstName
        } on ${formatDateToUI(getKeyValueFromData(edge, "date"), dayjs)}`,
        7000
      );

      approvalRequestsOp.refetch();
    } catch (error) {
      toastNotifier.danger("Failed to Reject", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
    actionStatus[edge.node.id] = false;
  };

  const refreshApprovals = () => {
    approvalRequestsOp.refetch();
  };
</script>

<div
  class="rounded overflow-y-auto border border-darkerGray w-full"
  style="max-height: 360px; min-height: 360px; height: 360px;">
  <div class="px-6 pt-2 pb-2 sticky top-0 bg-white">
    <div class="flex flex-row justify-between items-center w-full">
      <div class="w-full">
        <div class="font-bold text-lg">Approvals</div>
        <div class="italic text-sm">Leaves</div>
      </div>
      <div class="w-8 h-8">
        <Button icon="refresh" text="" size="s" on:click={refreshApprovals} />
      </div>
    </div>
  </div>

  {#await $approvalRequestsOp}
    <div class="px-6">
      <LoadingContent />
      <div class="pt-5" />
      <LoadingContent />
      <div class="pt-5" />
      <LoadingContent />
      <div class="pt-5" />
    </div>
  {:then resultApprovalRequests}
    <div class="list pb-2 pl-6 pr-4">
      <!-- TODO: Check for empty state -->
      {#each resultApprovalRequests.data.approvalRequests.edges as edge}
        <div
          class="flex flex-row items-start justify-between py-2
          border-b-darkerGray border-b">
          <div class="flex flex-row w-6/12 items-center">
            <div class="pr-2">
              <AvatarIcon
                size="l"
                firstName={getEmployeeObjectFromEdge(edge).name.firstName}
                lastName={getEmployeeObjectFromEdge(edge).name.lastName} />
            </div>
            <div class="flex flex-col">
              <div class="text-primary">
                {getEmployeeObjectFromEdge(edge).name.lastName}, {getEmployeeObjectFromEdge(edge).name.firstName}
              </div>
              <div class="text-xs ">
                <!-- {getEmployeeObjectFromEdge(edge).employeeNumber} -->
                {formatDateToUI(getKeyValueFromData(edge, 'date'), dayjs)}
              </div>
              <div class="text-xs italic">
                <!-- {getEmployeeObjectFromEdge(edge).employeeNumber} -->
                {getKeyValueFromData(edge, 'remarks')}
              </div>

            </div>
            <!-- <div class="text-xs font-bold self-start">
              {getKeyValueFromData(edge, 'date')}
            </div> -->
          </div>

          <div class="flex flex-row w-4/12 items-center pt-2">
            {#if !actionStatus[edge.node.id]}
              <EmptyButton
                text="Approve"
                color="primary"
                size="tiny"
                on:click={approveRequest(edge)} />

              <EmptyButton
                text="Reject"
                color="danger"
                size="tiny"
                on:click={rejectRequest(edge)} />
            {:else}
              <div
                class="flex flex-row self-center text-center w-full
                justify-center">
                <Spinner size="large" />
              </div>
            {/if}

          </div>

        </div>
      {/each}

    </div>
  {/await}
</div>
