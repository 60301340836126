import { gql } from "apollo-boost";

// QUERIES

const BRANCHES = gql`
  query branches($findBranchInput: FindBranchInput) {
    branches(findBranchInput: $findBranchInput) {
      edges {
        node {
          id
          code
          name
          chain {
            code
            name
          }
        }
      }
    }
  }
`;

const BRANCHES_PAGED = gql`
  query branches(
    $connectionArgs: ConnectionArgs
    $findBranchInput: FindBranchInput
  ) {
    branches(
      connectionArgs: $connectionArgs
      findBranchInput: $findBranchInput
    ) {
      edges {
        cursor
        node {
          code
          name
          chain {
            code
            name
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const BRANCHES_DETAILED = gql`
  query branches(
    $connectionArgs: ConnectionArgs
    $findBranchInput: FindBranchInput
  ) {
    branches(
      connectionArgs: $connectionArgs
      findBranchInput: $findBranchInput
    ) {
      edges {
        cursor
        node {
          id
          code
          name
          baseAddress
          zip
          city
          province
          regionCode
          regionName
          coordinates {
            latitude
            longitude
          }
          chain {
            id
            code
            name
            account {
              id
              code
              name
              active
              version
            }
            active
            version
          }
          active
          version
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const GET_BRANCH = gql`
  query branch($code: String!) {
    branch(code: $code) {
      id
      code
      name
      baseAddress
      zip
      city
      province
      regionCode
      regionName
      coordinates {
        latitude
        longitude
      }
      chain {
        id
        code
        name
        account {
          id
          code
          name
          active
          version
        }
        active
        version
      }
      active
      version
    }
  }
`;

// MUTATIONS

const ADD_BRANCH = gql`
  mutation addBranch($addBranchInput: AddBranchInput!) {
    addBranch(addBranchInput: $addBranchInput) {
      id
      code
      name
      baseAddress
      zip
      city
      province
      regionCode
      regionName
      coordinates {
        latitude
        longitude
      }
      chain {
        id
        code
        name
        account {
          id
          code
          name
          active
          version
        }
        active
        version
      }
      active
      version
    }
  }
`;

const EDIT_BRANCH = gql`
  mutation editBranch($editBranchInput: EditBranchInput!) {
    editBranch(editBranchInput: $editBranchInput) {
      id
      code
      name
      baseAddress
      zip
      city
      province
      regionCode
      regionName
      coordinates {
        latitude
        longitude
      }
      chain {
        id
        code
        name
        account {
          id
          code
          name
          active
          version
        }
        active
        version
      }
      active
      version
    }
  }
`;

const INACTIVATE_BRANCH = gql`
  mutation inactivateBranch($code: String!) {
    inactivateBranch(code: $code) {
      id
      code
      name
      baseAddress
      zip
      city
      province
      regionCode
      regionName
      coordinates {
        latitude
        longitude
      }
      chain {
        id
        code
        name
        account {
          id
          code
          name
          active
          version
        }
        active
        version
      }
      active
      version
    }
  }
`;

const ACTIVATE_BRANCH = gql`
  mutation activateBranch($code: String!) {
    activateBranch(code: $code) {
      id
      code
      name
      baseAddress
      zip
      city
      province
      regionCode
      regionName
      coordinates {
        latitude
        longitude
      }
      chain {
        id
        code
        name
        account {
          id
          code
          name
          active
          version
        }
        active
        version
      }
      active
      version
    }
  }
`;

export {
  BRANCHES,
  GET_BRANCH,
  ADD_BRANCH,
  EDIT_BRANCH,
  INACTIVATE_BRANCH,
  ACTIVATE_BRANCH,
  BRANCHES_DETAILED,
  BRANCHES_PAGED
};
