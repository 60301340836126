import { gql } from "apollo-boost";

// Queries

const SHEETS = gql`
  query donSheets(
    $findDONSheetInput: FindDONSheetInput
    $connectionArgs: ConnectionArgs
  ) {
    donSheets(
      findDONSheetInput: $findDONSheetInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        node {
          code
          inventoryDate
          complete
          weekNumber
          monthNumber
          branch {
            code
            name
            chain {
              code
              name
            }
          }
          carryTemplate {
            description
          }
          skus {
            edges {
              node {
                details {
                  tags {
                    key
                    value
                  }
                  code
                  name
                  core
                  unitCount
                  brand {
                    code
                    name
                  }
                  category {
                    code
                    name
                  }
                }
                data {
                  oos
                  suggestedOrder
                }
              }
            }
          }
          timestamps
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

const SHEETS_META = gql`
  query donSheets(
    $findDONSheetInput: FindDONSheetInput
    $connectionArgs: ConnectionArgs
  ) {
    donSheets(
      findDONSheetInput: $findDONSheetInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        node {
          code
          inventoryDate
          complete
          weekNumber
          monthNumber
          branch {
            code
            name
            chain {
              code
              name
            }
          }
          carryTemplate {
            description
          }
          skus {
            edges {
              node {
                details {
                  code
                  name
                  core
                  unitCount
                  brand {
                    code
                    name
                  }
                  category {
                    code
                    name
                  }
                }
                data {
                  oos
                  suggestedOrder
                }
              }
            }
          }
          meta {
            totalSKUCount
            carriedSKUCount
            availableCount
            availablePercentage
            oosCount
            oosPercentage
            notCarriedCount
            notCarriedPercentage
          }
          latestCollaborator {
            name {
              firstName
              lastName
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

const DON_SHEETS_LITE = gql`
  query donSheets($findDONSheetInput: FindDONSheetInput) {
    donSheets(findDONSheetInput: $findDONSheetInput) {
      edges {
        node {
          code
          complete
          timestamps
          inventoryDate
          branch {
            code
            name
            chain {
              code
              name
            }
          }
          meta {
            totalSKUCount
            carriedSKUCount
            availableCount
            availablePercentage
            oosCount
            oosPercentage
            notCarriedCount
            notCarriedPercentage
          }
        }
      }
    }
  }
`;

const GET_SHEET = gql`
  query donSheet($code: String!) {
    donSheet(code: $code) {
      code
      inventoryDate
      complete
      weekNumber
      monthNumber
      branch {
        code
        name
        active
        version
        chain {
          code
          name
        }
      }
      carryTemplate {
        code
        name
        description
        active
        version
      }
      skus {
        edges {
          cursor
          node {
            details {
              code
              name
              core
              unitCount
              brand {
                code
                name
              }
              category {
                code
                name
              }
              tags {
                key
                value
              }
            }
            data {
              oos
              suggestedOrder
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
      active
      version
      timestamps
    }
  }
`;

const GET_DON_SHEET_META = gql`
  query donSheet($code: String!) {
    donSheet(code: $code) {
      code
      inventoryDate
      complete
      weekNumber
      monthNumber
      branch {
        code
        name
        active
        version
      }
      carryTemplate {
        code
        name
        description
        active
        version
      }
      skus {
        edges {
          cursor
          node {
            details {
              code
              name
              core
              unitCount
              brand {
                code
                name
              }
              category {
                code
                name
              }
            }
            data {
              oos
              suggestedOrder
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
      meta {
        totalSKUCount
        carriedSKUCount
        availableCount
        availablePercentage
        oosCount
        oosPercentage
        notCarriedCount
        notCarriedPercentage
      }
      active
      version
      timestamps
    }
  }
`;

const CARRYTEMPLATES = gql`
  query carryTemplates(
    $findCarryTemplateInput: FindCarryTemplateInput
    $connectionArgs: ConnectionArgs
  ) {
    carryTemplates(
      findCarryTemplateInput: $findCarryTemplateInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        node {
          name
          code
          description
        }
      }
    }
  }
`;

const EDIT_DON_CARRYTEMPLATE = gql`
  mutation setDONBranchCarryTemplate(
    $setDONBranchCarryTemplateInput: SetDONBranchCarryTemplateInput!
  ) {
    setDONBranchCarryTemplate(
      setDONBranchCarryTemplateInput: $setDONBranchCarryTemplateInput
    ) {
      branch {
        id
        code
        name
        baseAddress
        zip
        city
        province
        regionCode
        regionName
        coordinates {
          latitude
          longitude
        }
        chain {
          id
          code
          name
          account {
            id
            code
            name
            active
            version
          }
          active
          version
        }
        active
        version
      }
      carryTemplates {
        id
        code
        name
        description
        skus {
          edges {
            cursor
            node {
              id
              code
              name
              core
              unitCount
              brand {
                id
                code
                name
              }
              category {
                id
                code
                name
              }
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
        active
        version
      }
      version
    }
  }
`;

export {
  SHEETS,
  GET_SHEET,
  CARRYTEMPLATES,
  DON_SHEETS_LITE,
  GET_DON_SHEET_META,
  SHEETS_META,
  EDIT_DON_CARRYTEMPLATE
};
