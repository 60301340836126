import { gql } from "apollo-boost";

// QUERIES

const GET_EMPLOYEE = gql`
  query employee($code: String!) {
    employee(code: $code) {
      id
      code
      name {
        firstName
        middleName
        lastName
        suffix
      }
      employeeNumber
      roleGroup
      supervisor {
        code
      }
      subordinates {
        code
        name {
          firstName
          lastName
          middleName
          suffix
        }
      }
      version
    }
  }
`;

// MUTATIONS

const ADD_EMPLOYEE = gql`
  mutation addEmployee(
    $addEmployeeInput: AddEmployeeInput!
    $tenantIdOverride: String
  ) {
    addEmployee(
      addEmployeeInput: $addEmployeeInput
      tenantIdOverride: $tenantIdOverride
    ) {
      id
      code
      name {
        firstName
        lastName
        middleName
        suffix
      }
      employeeNumber
      roleGroup
    }
  }
`;

const EDIT_EMPLOYEE = gql`
  mutation editEmployee($editEmployeeInput: EditEmployeeInput!) {
    editEmployee(editEmployeeInput: $editEmployeeInput) {
      id
      code
      name {
        firstName
        lastName
        middleName
        suffix
      }
      employeeNumber
      roleGroup
      version
    }
  }
`;

const DON_ASSIGNMENT = gql`
  query donEmployeeAssignment($employeeCode: String!) {
    donEmployeeAssignment(employeeCode: $employeeCode) {
      employee {
        id
        code
        name {
          firstName
          lastName
        }
        version
      }
      branches {
        id
        code
        name
      }
      version
    }
  }
`;

const EDGAR_ASSIGNMENT = gql`
  query edgarEmployeeAssignment($employeeCode: String!) {
    edgarEmployeeAssignment(employeeCode: $employeeCode) {
      employee {
        id
        code
        name {
          firstName
          lastName
        }
        version
      }
      branches {
        id
        code
        name
      }
      version
    }
  }
`;

const ASSIGN_TO_DON = gql`
  mutation setDONEmployeeAssignment(
    $setDONEmployeeAssignmentInput: SetDONEmployeeAssignmentInput!
  ) {
    setDONEmployeeAssignment(
      setDONEmployeeAssignmentInput: $setDONEmployeeAssignmentInput
    ) {
      employee {
        id
        code
        name {
          firstName
          lastName
        }
        version
      }
      branches {
        id
        code
      }
      version
    }
  }
`;

const ASSIGN_TO_EDGAR = gql`
  mutation setEDGAREmployeeAssignment(
    $setEDGAREmployeeAssignmentInput: SetEDGAREmployeeAssignmentInput!
  ) {
    setEDGAREmployeeAssignment(
      setEDGAREmployeeAssignmentInput: $setEDGAREmployeeAssignmentInput
    ) {
      employee {
        id
        code
        name {
          firstName
          lastName
        }
        version
      }
      branches {
        id
        code
      }
      version
    }
  }
`;

const CALENDAR_ASSIGNMENT = gql`
  query calendarBranchAttendanceContexts(
    $findCalendarBranchAttendanceContextInput: FindCalendarBranchAttendanceContextInput
  ) {
    calendarBranchAttendanceContexts(
      findCalendarBranchAttendanceContextInput: $findCalendarBranchAttendanceContextInput
    ) {
      edges {
        cursor
        node {
          branch {
            id
            code
            name
          }
        }
      }
    }
  }
`;

const ASSIGN_TO_CALENDAR = gql`
  mutation addEmployeesToCalendarBranchAttendanceContext(
    $branchCode: String!
    $employeeCodes: [String!]!
  ) {
    addEmployeesToCalendarBranchAttendanceContext(
      branchCode: $branchCode
      employeeCodes: $employeeCodes
    ) {
      branch {
        id
        code
      }
    }
  }
`;

const UNASSIGN_FROM_CALENDAR = gql`
  mutation removeEmployeesToCalendarBranchAttendanceContext(
    $branchCode: String!
    $employeeCodes: [String!]!
  ) {
    removeEmployeesToCalendarBranchAttendanceContext(
      branchCode: $branchCode
      employeeCodes: $employeeCodes
    ) {
      branch {
        id
        code
      }
    }
  }
`;

const GET_CALENDAR_EMPLOYEE_CONTEXT = gql`
  query calendarEmployeeContext($employeeCode: String!) {
    calendarEmployeeContext(employeeCode: $employeeCode) {
      employee {
        id
        code
        name {
          firstName
          lastName
        }
        version
      }
      restDays
      stationary
      version
    }
  }
`;

const SET_CALENDAR_EMPLOYEE_CONTEXT = gql`
  mutation setCalendarEmployeeContext(
    $setCalendarEmployeeContextInput: SetCalendarEmployeeContextInput!
  ) {
    setCalendarEmployeeContext(
      setCalendarEmployeeContextInput: $setCalendarEmployeeContextInput
    ) {
      employee {
        id
        code
        name {
          firstName
          lastName
        }
        version
      }
      restDays
      stationary
      version
    }
  }
`;

export {
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  GET_EMPLOYEE,
  DON_ASSIGNMENT,
  EDGAR_ASSIGNMENT,
  ASSIGN_TO_DON,
  ASSIGN_TO_EDGAR,
  CALENDAR_ASSIGNMENT,
  ASSIGN_TO_CALENDAR,
  UNASSIGN_FROM_CALENDAR,
  GET_CALENDAR_EMPLOYEE_CONTEXT,
  SET_CALENDAR_EMPLOYEE_CONTEXT
};
