<script>
  import { createEventDispatcher } from "svelte";
  import {
    TextField,
    Button,
    EmptyButton,
    SelectField,
    toastNotifier
  } from "@saztrek/firefly";

  // import DeactivateModal from "./DeactivateModal.svelte";

  export let mode;
  export let cancelAction;

  export let startDate;
  export let endDate;
  export let sheetSummaryType;

  export let version;

  export let buttonLabel;

  export let loading = false;

  const listOfSummaryTypes = [
    { option: "Choose a Summary Type", value: null },
    { option: "Weekly", value: "WEEKLY" },
    { option: "Monthly", value: "MONTHLY" }
  ];

  const dispatch = createEventDispatcher();

  const requiredFieldsFilled = () => {
    return !!(startDate && endDate && sheetSummaryType);
  };

  const submitForm = () => {
    if (requiredFieldsFilled()) {
      if (mode === "add") {
        dispatch("validSheetContext", {
          startDate,
          endDate,
          sheetSummaryType
        });
      } else {
        dispatch("validSheetContext", {
          startDate,
          endDate,
          sheetSummaryType,
          version
        });
      }
    } else {
      toastNotifier.warning(
        "Required fields.",
        "Please fill in required fields",
        7000
      );
    }
  };

  //   const inactivateSheetContext = () => {
  //     dispatch("inactivateSheetContext", { code });
  //   };

  const resolveReadOnly = () =>
    mode === "edit" ? { readonly: true } : undefined;
</script>

{#if mode === 'edit'}
  <div class="w-full text-right pr-12">
    <!-- <DeactivateModal sheetContextCode={code} confirmEvent={inactivateSheetContext} /> -->
  </div>
{/if}
<form on:submit|preventDefault={submitForm}>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={startDate}
      {...resolveReadOnly()}
      type="date"
      label="Start Date" />
  </div>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={endDate}
      {...resolveReadOnly()}
      type="date"
      label="End Date" />
  </div>
  <div class="p-4 w-full">
    <SelectField
      bind:value={sheetSummaryType}
      options={listOfSummaryTypes}
      label="Summary Type" />
  </div>

  {#if mode === 'edit'}
    <input type="hidden" bind:value={version} />
  {/if}
  <div class="w-full flex flex-row-reverse justify-evenly pb-2 mt-8 px-4">
    <Button text={buttonLabel} type="submit" color="primary" {loading} />
    <div class="w-25" />
    <div class="text-center">
      <EmptyButton text="Cancel" color="text" on:click={cancelAction} />
    </div>
  </div>
</form>
