import { gql } from "apollo-boost";

const APPROVAL_REQUESTS = gql`
  query approvalRequests($findApprovalRequestInput: FindApprovalRequestInput) {
    approvalRequests(findApprovalRequestInput: $findApprovalRequestInput) {
      edges {
        cursor
        node {
          id
          code
          requestType {
            code
            shortDescription
            description
            active
            version
          }
          data {
            key
            value
          }
          status
          requestTimestamp
          approverRoleGroups
          approverEmployees {
            code
            name {
              firstName
              middleName
              lastName
              suffix
            }
            employeeNumber
            roleGroup
            version
          }
          version
        }
      }
    }
  }
`;

const GET_APPROVAL_REQUEST = gql`
  query approvalRequest($code: String!) {
    approvalRequest(code: $code) {
      id
      code
      requestType {
        code
        shortDescription
        description
        active
        version
      }
      data {
        key
        value
      }
      status
      requestTimestamp
      approverRoleGroups
      approverEmployees {
        code
        name {
          firstName
          middleName
          lastName
          suffix
        }
        employeeNumber
        roleGroup
        version
      }
      version
    }
  }
`;

const APPROVE_REQUEST = gql`
  mutation approveStatusApprovalRequest(
    $approverEmployeeCode: String!
    $code: String!
    $remarks: String
  ) {
    approveStatusApprovalRequest(
      approverEmployeeCode: $approverEmployeeCode
      code: $code
      remarks: $remarks
    ) {
      id
      code
      requestType {
        code
        shortDescription
        description
        active
        version
      }
      data {
        key
        value
      }
      status
      requestTimestamp
      approverRoleGroups
      approverEmployees {
        code
        name {
          firstName
          middleName
          lastName
          suffix
        }
        employeeNumber
        roleGroup
        version
      }
      approver {
        code
        name {
          firstName
          middleName
          lastName
          suffix
        }
        employeeNumber
        roleGroup
        version
      }
      approverRemarks
      version
    }
  }
`;

const REJECT_REQUEST = gql`
  mutation denyStatusApprovalRequest(
    $approverEmployeeCode: String!
    $code: String!
    $remarks: String
  ) {
    denyStatusApprovalRequest(
      approverEmployeeCode: $approverEmployeeCode
      code: $code
      remarks: $remarks
    ) {
      id
      code
      requestType {
        code
        shortDescription
        description
        active
        version
      }
      data {
        key
        value
      }
      status
      requestTimestamp
      approverRoleGroups
      approverEmployees {
        code
        name {
          firstName
          middleName
          lastName
          suffix
        }
        employeeNumber
        roleGroup
        version
      }
      approver {
        code
        name {
          firstName
          middleName
          lastName
          suffix
        }
        employeeNumber
        roleGroup
        version
      }
      approverRemarks
      version
    }
  }
`;

const REWORK_REQUEST = gql`
  mutation reworkStatusApprovalRequest(
    $approverEmployeeCode: String!
    $code: String!
    $remarks: String
  ) {
    reworkStatusApprovalRequest(
      approverEmployeeCode: $approverEmployeeCode
      code: $code
      remarks: $remarks
    ) {
      id
      code
      requestType {
        code
        shortDescription
        description
        active
        version
      }
      data {
        key
        value
      }
      status
      requestTimestamp
      approverRoleGroups
      approverEmployees {
        code
        name {
          firstName
          middleName
          lastName
          suffix
        }
        employeeNumber
        roleGroup
        version
      }
      approver {
        code
        name {
          firstName
          middleName
          lastName
          suffix
        }
        employeeNumber
        roleGroup
        version
      }
      approverRemarks
      version
    }
  }
`;

export {
  APPROVAL_REQUESTS,
  APPROVE_REQUEST,
  REJECT_REQUEST,
  REWORK_REQUEST,
  GET_APPROVAL_REQUEST
};
