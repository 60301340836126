<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, mutate, query } from "svelte-apollo";

  import {
    EDIT_CARRYTEMPLATE,
    SKUS,
    GET_CARRYTEMPLATE,
    INACTIVATE_CARRYTEMPLATE
  } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import CarryTemplateForm from "./CarryTemplateForm.svelte";

  const cancelAdd = () => {
    navigateTo("carryTemplates");
  };

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  const client = getClient();

  // TODO: Check why this errors out on the first call
  const carryTemplateOp = query(client, {
    query: GET_CARRYTEMPLATE,
    fetchPolicy: "cache-and-network",
    variables: {
      code: decodeURIComponent(id)
    }
  });

  // TODO: Make this into a lookup field so we don't get everything

  const skusOp = query(client, {
    query: SKUS
  });

  let loading = false;

  const editCarryTemplate = async (event) => {
    try {
      const carryTemplateData = { ...event.detail };
      loading = true;
      await mutate(client, {
        mutation: EDIT_CARRYTEMPLATE,
        variables: {
          editCarryTemplateInput: {
            code: carryTemplateData.code,
            name: carryTemplateData.name,
            description: carryTemplateData.description,
            skuCodes: carryTemplateData.skus,
            version: carryTemplateData.version
          }
        }
      });
      toastNotifier.success(
        "Success!",
        `CarryTemplate ${carryTemplateData.name} Updated`,
        7000
      );
      navigateTo("carryTemplates");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };

  const inactivateCarryTemplate = async (event) => {
    const carryTemplateData = { ...event.detail };
    loading = true;
    try {
      await mutate(client, {
        mutation: INACTIVATE_CARRYTEMPLATE,
        variables: {
          code: carryTemplateData.code
        }
      });
      toastNotifier.success(
        "Success!",
        `Brand ${carryTemplateData.code} has been deactivated`,
        7000
      );
      navigateTo("carrytemplates");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Edit CarryTemplate">
    {#await $carryTemplateOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then carryTemplateResult}
      {#await $skusOp}
        <div class="p-10 w-full" style="min-height: 500px">
          <LoadingContent />
        </div>
      {:then skusResult}
        <CarryTemplateForm
          mode="edit"
          on:validCarryTemplate={editCarryTemplate}
          on:inactivateCarryTemplate={inactivateCarryTemplate}
          cancelAction={cancelAdd}
          allSKUs={skusResult.data.skus.edges}
          skusFromCarryTemplate={carryTemplateResult.data.carryTemplate.skus.edges}
          code={carryTemplateResult.data.carryTemplate.code}
          name={carryTemplateResult.data.carryTemplate.name}
          description={carryTemplateResult.data.carryTemplate.description}
          version={carryTemplateResult.data.carryTemplate.version}
          {loading}
          buttonLabel="Update" />
      {/await}
    {/await}
  </Panel>
</div>
