import { gql } from "apollo-boost";

// QUERIES
const ACCOUNTS = gql`
  query accounts(
    $connectionArgs: ConnectionArgs
    $findAccountInput: FindAccountInput
  ) {
    accounts(
      connectionArgs: $connectionArgs
      findAccountInput: $findAccountInput
    ) {
      edges {
        cursor
        node {
          id
          code
          name
          active
          version
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const GET_ACCOUNT = gql`
  query account($code: String!) {
    account(code: $code) {
      id
      code
      name
      active
      version
    }
  }
`;

// MUTATIONS

const ADD_ACCOUNT = gql`
  mutation addAccount($addAccountInput: AddAccountInput!) {
    addAccount(addAccountInput: $addAccountInput) {
      id
      code
      name
      active
      version
    }
  }
`;

const EDIT_ACCOUNT = gql`
  mutation editAccount($editAccountInput: EditAccountInput!) {
    editAccount(editAccountInput: $editAccountInput) {
      id
      code
      name
      active
      version
    }
  }
`;

const INACTIVATE_ACCOUNT = gql`
  mutation inactivateAccount($code: String!) {
    inactivateAccount(code: $code) {
      code
      name
      active
      version
    }
  }
`;

const ACTIVATE_ACCOUNT = gql`
  mutation activateAccount($code: String!) {
    activateAccount(code: $code) {
      code
      name
      active
      version
    }
  }
`;

export {
  ACCOUNTS,
  GET_ACCOUNT,
  ADD_ACCOUNT,
  EDIT_ACCOUNT,
  INACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT
};
