<script>
  /* eslint-disable no-param-reassign */
  /* eslint-disable no-underscore-dangle */
  import dayjs from "dayjs";
  import weekOfYear from "dayjs/plugin/weekOfYear";

  dayjs.extend(weekOfYear);

  import {
    LoadingContent,
    // TextField,
    Button,
    EmptyButton,
    toastNotifier,
    Tabs,
    TabList,
    TabPanel,
    Tab
  } from "@saztrek/firefly";
  import { getClient, query } from "svelte-apollo";

  import ExcelJS from "exceljs";

  import { exportToXLSX } from "../../../../utilities/export";
  import {
    EDGAR_SHEET_CONTEXT_LITE,
    GET_EDGAR_SHEET_LITE_WITH_BRAND_AND_CATEGORY,
    EDGAR_SHEET_CONTEXT
  } from "../../../../gql";
  import {
    dateFormatToServer,
    dateFormatToUI
  } from "../../../../utilities/dateFormatter";

  import {
    nearestEndDay,
    nearestStartDay,
    getNearestEndDay,
    getNearestStartDay
  } from "../edgarUtils";

  import { storeCodes } from "./storeCodes";

  import { currentTenant } from "../../../../stores/current_tenant";

  import BranchList from "../components/EDGARBranchList.svelte";

  import months from "../../../../utilities/months";

  let currentStartDay = nearestStartDay;
  let currentEndDay = nearestEndDay;
  let daysToIncrement = 7;
  let isDaily = false;
  let tenantVarsLoaded = false;

  $: if ($currentTenant.tenantVariables && !tenantVarsLoaded) {
    daysToIncrement = $currentTenant.featureFlags.find(
      (flag) => flag.code === "EDGAR_isDaily"
    ).value
      ? 1
      : 7;

    isDaily = $currentTenant.featureFlags.find(
      (flag) => flag.code === "EDGAR_isDaily"
    ).value;
    currentStartDay = !isDaily ? getNearestStartDay() : dayjs();
    currentEndDay = !isDaily ? getNearestEndDay() : currentStartDay;
    tenantVarsLoaded = true;
  }

  const useRawOfftake = false;

  const client = getClient();

  const sheetsOp = query(client, {
    query: EDGAR_SHEET_CONTEXT_LITE,
    // fetchPolicy: "cache-and-network",
    variables: {
      startDate: dateFormatToServer(currentStartDay.toDate()),
      endDate: !isDaily
        ? dateFormatToServer(currentEndDay.toDate())
        : dateFormatToServer(currentStartDay.toDate())
    }
  });

  let singleSheetOp;
  let allSheetsOp;

  let skus = [];

  let currentSheetCode = "";
  let currentBranch;

  const processBranches = (sheetsResult) => {
    if (!sheetsResult.data.edgarSheetContext) {
      return [];
    }

    const returnData = sheetsResult.data.edgarSheetContext.branchSheets.map(
      (branchSheet) => {
        const hasSheet = branchSheet.edgarSheet !== null;
        return {
          code: branchSheet.branch.code,
          name: branchSheet.branch.name,
          skusCovered: hasSheet
            ? Math.floor(branchSheet.edgarSheet.meta.inputPercentage * 100)
            : 0,
          edgarSheet: branchSheet.edgarSheet,
          generated: hasSheet ? branchSheet.edgarSheet.meta.generated : false
        };
      }
    );

    return returnData;
  };

  const categoryCodes = ["V1", "V2", "V3"];

  const processSKUs = (response) => {
    const cleanSKUs = response.edgarSheet.skus.edges.map((skuEdge) => {
      const { node } = skuEdge;
      const branchCode = response.edgarSheet.branch.code;
      const { details, data, computed, meta } = node;
      const branchFromMap = storeCodes.find(
        (store) => store.branchCode === branchCode
      );
      const { sheetContext } = response.edgarSheet;

      const startDate = dayjs(sheetContext.startDate);
      const endDate = dayjs(sheetContext.endDate);

      return {
        tpa: "ABC",
        accountGroup: "GT",
        aor: branchFromMap ? branchFromMap.aor : "",
        region: branchFromMap ? branchFromMap.region : "",
        adp: branchFromMap ? branchFromMap.adp : "",
        customerCode: branchCode,
        accountName: branchFromMap ? branchFromMap.accountCode : "",
        product: details.brand.code,
        month: months[sheetContext.monthNumber - 1],
        week: `Wk${endDate.week()}`,
        period: `${startDate.format("MMMDD")}-${endDate.format("MMMDD")}`,
        code: details.code,
        name: details.name,
        brandCode: details.brand.code,
        categoryCode: details.category.code,
        unitCount: details.unitCount,
        weekSupply: details.weekSupply !== null ? details.weekSupply : "N/A",
        offtakeContainers: computed.offtakeContainers,
        idl:
          computed.offtakeContainers > 0
            ? data.endingContainers / computed.offtakeContainers / 7
            : 0.0,
        offtakeUnits: useRawOfftake
          ? computed.rawOfftakeUnits.toFixed(2)
          : computed.offtakeUnits.toFixed(2),
        // rawOfftakeUnits: computed.rawOfftakeUnits.toFixed(2),
        deliveryContainers: data.deliveryContainers,
        deliveryUnits: data.deliveryUnits,
        totalDeliveryUnits: computed.totalDeliveryUnits,
        totalDeliveryContainers:
          computed.totalDeliveryUnits / details.unitCount,
        endingContainers: data.endingContainers,
        endingUnits: data.endingUnits,
        totalEndingUnits: computed.totalEndingUnits,
        totalEndingContainers: computed.totalEndingUnits / details.unitCount,
        badOrderContainers: data.badOrderContainers,
        badOrderUnits: data.badOrderUnits,
        totalBadOrderUnits: computed.totalBadOrderUnits,
        totalBadOrderContainers:
          computed.totalBadOrderUnits / details.unitCount,
        transferOutContainers: data.transferOutContainers,
        transferOutUnits: data.transferOutUnits,
        totalTransferOutUnits: computed.totalTransferOutUnits,
        totalTransferOutContainers:
          computed.totalTransferOutUnits / details.unitCount,
        transferInContainers: data.transferInContainers,
        transferInUnits: data.transferInUnits,
        totalTransferInUnits: computed.totalTransferInUnits,
        totalTransferInContainers:
          computed.totalTransferInUnits / details.unitCount,
        adjustmentContainers: data.adjustmentContainers,
        adjustmentUnits: data.adjustmentUnits,
        totalAdjustmentUnits: computed.totalAdjustmentUnits,
        totalAdjustmentContainers:
          computed.totalAdjustmentUnits / details.unitCount,
        suggestedOrderUnits: computed.suggestedOrderUnits,
        beginningContainers: meta.beginningContainers,
        beginningUnits: meta.beginningUnits,
        totalBeginningContainers: meta.totalBeginningUnits
          ? meta.totalBeginningUnits / details.unitCount
          : "N/A",
        totalBeginningUnits: meta.totalBeginningUnits
          ? meta.totalBeginningUnits
          : "N/A",
        noData:
          computed.totalDeliveryUnits +
            computed.totalEndingUnits +
            computed.totalBadOrderUnits +
            computed.totalTransferOutUnits +
            computed.totalTransferInUnits +
            computed.totalAdjustmentUnits ===
          0,
        branchCode: response.edgarSheet.branch.code,
        branchName: response.edgarSheet.branch.name
      };
    });

    const skuByCategory = categoryCodes.map((catCode) => {
      const skusByCurrentCategory = cleanSKUs.filter(
        (sku) => sku.categoryCode === catCode
      );
      const allBrands = skusByCurrentCategory.map((sku) => sku.brandCode);
      const uniqueBrands = Array.from(new Set(allBrands));

      return {
        categoryCode: catCode,
        brands: uniqueBrands.map((brand) => {
          return {
            brandCode: brand,
            skus: skusByCurrentCategory.filter((sku) => sku.brandCode === brand)
          };
        })
      };
    });

    return skuByCategory;
  };

  // let loadingSKUs = false;

  const sheetsSKUCache = new Map();

  // let currentSheetContext;

  const branchSelected = (event) => {
    const { branch } = { ...event.detail };

    skus = [];
    // loadingSKUs = true;

    if (!branch.edgarSheet) {
      currentSheetCode = null;

      // loadingSKUs = false;
    } else {
      currentSheetCode = branch.edgarSheet.code;
      currentBranch = branch;

      const cachedSKUs = sheetsSKUCache.get(currentSheetCode);

      if (cachedSKUs) {
        skus = cachedSKUs;
        // originalSKUs = skus;
        // loadingSKUs = false;
      } else {
        singleSheetOp = query(client, {
          query: GET_EDGAR_SHEET_LITE_WITH_BRAND_AND_CATEGORY,
          variables: {
            code: currentSheetCode
          }
        });

        $singleSheetOp.then((response) => {
          skus = processSKUs(response.data);
          // originalSKUs = skus;
          // loadingSKUs = false;
          sheetsSKUCache.set(currentSheetCode, [...skus]);
        });
      }
    }
  };

  const previousWeek = () => {
    currentSheetCode = "";
    skus = [];
    currentStartDay = currentStartDay.subtract(daysToIncrement, "day");
    currentEndDay = currentEndDay.subtract(daysToIncrement, "day");
  };

  const nextWeek = () => {
    currentSheetCode = "";
    skus = [];
    currentStartDay = currentStartDay.add(daysToIncrement, "day");
    currentEndDay = currentEndDay.add(daysToIncrement, "day");
  };

  $: sheetsOp.refetch({
    startDate: dateFormatToServer(currentStartDay.toDate()),
    endDate: !isDaily
      ? dateFormatToServer(currentEndDay.toDate())
      : dateFormatToServer(currentStartDay.toDate())
  });

  const reportColumns = [
    { header: "TPA", key: "tpa", width: 11.25 },
    { header: "ACCOUNT GROUP", key: "accountGroup", width: 11.25 },
    { header: "AOR", key: "aor", width: 8.75 },
    { header: "Region", key: "region", width: 8.75 },
    { header: "ADP", key: "adp", width: 26.5 },
    { header: "CUSTOMER CODE", key: "branchCode", width: 16.25 },
    {
      header: "ACCOUNT NAME BRANCH/SYSTEM NAME",
      key: "accountName",
      width: 26.5
    },
    { header: "PRODUCT", key: "product", width: 8.75 },
    { header: "SKU CODE", key: "code", width: 11 },
    { header: "ITEM DESCRIPTION (SKU)", key: "name", width: 35.75 },
    { header: "MONTH", key: "month", width: 8.75 },
    { header: "WEEK", key: "week", width: 8.75 },
    { header: "PERIOD", key: "period", width: 15 },
    { header: "BEGINNING (CS)", key: "totalBeginningContainers", width: 15 },
    { header: "DELIVERY (CS)", key: "totalDeliveryContainers", width: 15 },
    {
      header: "STOCK TRANSFER IN (CS)",
      key: "totalTransferInContainers",
      width: 15
    },
    { header: "ADJUSTMENT (CS)", key: "totalAdjustmentContainers", width: 15 },
    {
      header: "TRANSFER OUT (CS)",
      key: "totalTransferOutContainers",
      width: 15
    },
    { header: "BAD ORDER (CS)", key: "totalBadOrderContainers", width: 15 },
    { header: "ENDING Inv. (CS)", key: "endingContainers", width: 15 },
    { header: "OFFTAKE (CS)", key: "offtakeContainers", width: 15 },
    { header: "IDL", key: "idl", width: 15 }
  ];

  let exportAllLoading = false;

  const exportAll = () => {
    exportAllLoading = true;
    allSheetsOp = query(client, {
      query: EDGAR_SHEET_CONTEXT,
      variables: {
        startDate: dateFormatToServer(currentStartDay.toDate()),
        endDate: dateFormatToServer(currentEndDay.toDate()),
        connectionArgs: {
          first: 200
        }
      }
    });

    $allSheetsOp
      .then((response) => {
        let allSheets = [];

        const { data } = response;
        const { edgarSheetContext } = data;
        const { branchSheets } = edgarSheetContext;

        branchSheets.forEach((branchSheet) => {
          if (branchSheet.edgarSheet) {
            allSheets = [...allSheets, processSKUs(branchSheet)];
          }
        });

        if (allSheets.length === 0) {
          exportAllLoading = false;
          toastNotifier.danger(
            "No Sheets Found!",
            `There are no EDGAR Sheets in this coverage`,
            7000
          );
        } else {
          categoryCodes.forEach((catCode) => {
            const workbook = new ExcelJS.Workbook();

            const allSheetsByCatCode = allSheets.map((sheet) =>
              sheet.find((sheetByCat) => sheetByCat.categoryCode === catCode)
            );

            allSheetsByCatCode.forEach((sheetByCatCode) => {
              const { brands } = sheetByCatCode;

              brands.forEach((brand) => {
                let worksheet = workbook.getWorksheet(brand.brandCode);

                if (!worksheet) {
                  worksheet = workbook.addWorksheet(brand.brandCode);

                  worksheet.columns = reportColumns;

                  const header = worksheet.getRow(1);
                  header.height = 36;
                  for (let i = 1; i < reportColumns.length + 1; i += 1) {
                    header.getCell(i).alignment = {
                      horizontal: "center",
                      vertical: "middle",
                      wrapText: true
                    };

                    header.getCell(i).fill = {
                      type: "pattern",
                      pattern: "solid",
                      fgColor: { argb: "FFFFFF00" },
                      bgColor: { argb: "FFFFFF00" }
                    };
                    header.getCell(i).font = {
                      name: "Calibri",
                      size: "8",
                      bold: true
                    };
                    header.getCell(i).border = {
                      top: { style: "thin" },
                      left: { style: "thin" },
                      bottom: { style: "thin" },
                      right: { style: "thin" }
                    };
                  }
                }

                const skuRows = worksheet.addRows(brand.skus);

                skuRows.forEach((row) => {
                  for (let i = 1; i < reportColumns.length + 1; i += 1) {
                    const currentCell = row.getCell(i);

                    currentCell.border = {
                      top: { style: "thin" },
                      left: { style: "thin" },
                      bottom: { style: "thin" },
                      right: { style: "thin" }
                    };

                    currentCell.alignment = { horizontal: "center" };
                    currentCell.font = { name: "Calibri", size: "8" };

                    if (i >= 13) {
                      currentCell.numFmt = "0.00";
                    }

                    if (
                      currentCell._column._key.includes(
                        "totalAdjustmentContainers"
                      ) &&
                      parseFloat(currentCell.value) > 0
                    ) {
                      currentCell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "FFFFFF00" },
                        bgColor: { argb: "FFFFFF00" }
                      };
                    }
                  }
                });
              });
            });

            workbook.xlsx
              .writeBuffer({
                base64: true
              })
              .then((xls64) => {
                exportToXLSX(
                  xls64,
                  `EDGAR Sheets ${catCode} Week ${dayjs(
                    currentEndDay
                  ).week()} National`
                );
              });
          });

          exportAllLoading = false;

          toastNotifier.success(
            "Success!",
            `Export successful, waiting for download.`,
            7000
          );
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        toastNotifier.danger(
          "Something went wrong!",
          `Export failed, please contact the administrator`,
          7000
        );
      });
  };

  const exportCurrentCategory = (catCode, skusByBrand) => {
    const workbook = new ExcelJS.Workbook();

    skusByBrand.forEach((brand) => {
      const worksheet = workbook.addWorksheet(brand.brandCode);

      worksheet.columns = reportColumns;

      const header = worksheet.getRow(1);
      header.height = 36;
      for (let i = 1; i < reportColumns.length + 1; i += 1) {
        header.getCell(i).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true
        };

        header.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FFFFFF00" }
        };
        header.getCell(i).font = { name: "Calibri", size: "8", bold: true };
        header.getCell(i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
      }
      const skuRows = worksheet.addRows(brand.skus);

      skuRows.forEach((row) => {
        for (let i = 1; i < reportColumns.length + 1; i += 1) {
          row.getCell(i).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };

          row.getCell(i).alignment = { horizontal: "center" };
          row.getCell(i).font = { name: "Calibri", size: "8" };
        }
      });
    });

    workbook.xlsx
      .writeBuffer({
        base64: true
      })
      .then((xls64) => {
        exportToXLSX(
          xls64,
          `EDGAR Sheets ${catCode} Week ${dayjs(currentEndDay).week()} - ${
            currentBranch.code
          }`
        );
      });
  };
</script>

<div class="m-0 pb-3 text-base flex flex-row items-center justify-center px-2">
  <div class="w-2/12">
    <Button
      text="Export All"
      on:click={exportAll}
      color="secondary"
      loading={exportAllLoading} />
  </div>
  <div class="justify-center w-8/12 flex flex-row">
    <div>
      <EmptyButton
        icon="arrowLeft"
        text=""
        size="tiny"
        on:click={previousWeek} />
    </div>
    <div class="w-56 text-center">
      {#if !isDaily}
        {dateFormatToUI(currentStartDay.toDate())} to {dateFormatToUI(currentEndDay.toDate())}
      {:else}{dateFormatToUI(currentStartDay.toDate())}{/if}
    </div>
    <div>
      <EmptyButton icon="arrowRight" text="" size="tiny" on:click={nextWeek} />
    </div>
  </div>

</div>

<div
  class="flex flex-row w-full md:-mx-2 justify-between"
  style="min-height: 945px; max-height: 945px; height: 945px;">
  <div class="w-3/12 lg:3/12 px-2 h-full">
    {#await $sheetsOp}
      <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
      </div>
    {:then sheetsResult}
      {#if sheetsResult.loading}
        <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
        </div>
      {:else}
        <BranchList
          branches={processBranches(sheetsResult)}
          on:branchSelected={branchSelected} />
      {/if}

    {/await}
  </div>
  <div class="w-9/12 lg:4/12 pt-2 px-2 h-full border-lightGray rounded border">
    {#if currentSheetCode}
      <div class="w-full flex flex-row pb-4 px-2 h-24">
        <div class="flex flex-col justify-between w-1/2">
          <div class="flex flex-col font-bold">
            <div class="text-sm">{currentSheetCode}</div>
            <div class="text-xs">
              {currentBranch.code} - {currentBranch.name}
            </div>
          </div>
        </div>

      </div>
    {/if}

    <!-- <div class="h-full w-full flex flex-col items-center justify-center">
      <Icon size="l" type="table" />
      <div class="pt-2">
        {#if currentSheetCode === ''}
          Choose an EDGAR Sheet on the left.
        {:else}No Sheet created yet for this branch.{/if}
      </div>
    </div> -->
    {#if skus.length > 0}
      <div class="w-full px-4">

        <Tabs>
          <TabList>
            {#each categoryCodes as categoryCode}
              <Tab>{categoryCode}</Tab>
            {/each}
          </TabList>
          {#each skus as skuByCategory}
            <TabPanel>
              <div class="w-full flex-row flex justify-end">
                <Button
                  text="Export"
                  color="primary"
                  on:click={exportCurrentCategory(skuByCategory.categoryCode, skuByCategory.brands)} />
              </div>

              <Tabs>
                <TabList>
                  {#each skuByCategory.brands as brand}
                    <Tab>{brand.brandCode}</Tab>
                  {/each}
                </TabList>

                {#each skuByCategory.brands as brand}
                  <TabPanel>

                    <table class="table-auto text-sm w-full">
                      <thead class="font-bold text-xs">
                        <tr>
                          <th class="px-4 py-2">SKU Code</th>
                          <th class="px-4 py-2">SKU</th>
                          <th class="px-4 py-2">Delivery</th>
                          <th class="px-4 py-2">Ending (CS)</th>
                          <th class="px-4 py-2">Offtake (CS)</th>
                          <th class="px-4 py-2">IDL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {#each brand.skus as sku}
                          <tr>
                            <td class="border border-darkerGray px-4 py-2">
                              {sku.code}
                            </td>
                            <td class="border border-darkerGray px-4 py-2">
                              {sku.name}
                            </td>
                            <td class="border border-darkerGray px-4 py-2">
                              {sku.deliveryContainers}
                            </td>
                            <td class="border border-darkerGray px-4 py-2">
                              {sku.endingContainers}
                            </td>
                            <td class="border border-darkerGray px-4 py-2">
                              {sku.offtakeContainers}
                            </td>
                            <td class="border border-darkerGray px-4 py-2">
                              {sku.idl}
                            </td>
                          </tr>
                        {/each}
                      </tbody>
                    </table>

                  </TabPanel>
                {/each}
              </Tabs>
            </TabPanel>
          {/each}
        </Tabs>

      </div>
    {/if}
  </div>
</div>
