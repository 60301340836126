<script>
  import { createEventDispatcher } from "svelte";
  import {
    TextField,
    Button,
    EmptyButton,
    toastNotifier,
    SelectField,
    LoadingContent,
    Tabs,
    TabList,
    TabPanel,
    Tab
  } from "@saztrek/firefly";

  import DeactivateModal from "./DeactivateModal.svelte";
  import AppAssignments from "./AppAssignments.svelte";
  import RestDays from "./RestDays.svelte";

  export let mode;
  export let cancelAction;

  export let user;
  export let superAdmin;

  export let buttonLabel;

  export let userRolesLoading = false;
  export let savingStatus = false;

  const roleOptions = [
    {
      option: "None",
      value: "none"
    },
    {
      option: "Employee",
      value: "employee"
    },
    {
      option: "Admin",
      value: "admin"
    }
  ];

  const superAdminOptions = [
    {
      option: "No",
      value: false
    },
    {
      option: "Yes",
      value: true
    }
  ];

  const dispatch = createEventDispatcher();

  const requiredFieldsFilled = () => {
    return !!(user.firstName && user.lastName);
  };

  const submitForm = () => {
    if (requiredFieldsFilled()) {
      if (mode === "add") {
        dispatch("validUser", {
          user
        });
      } else {
        dispatch("validUser", {
          user
        });
      }
    } else {
      toastNotifier.warning(
        "Required fields.",
        "Please fill in required fields",
        7000
      );
    }
  };

  const inactivateUser = () => {
    dispatch("inactivateUser", { user });
  };

  const resolveReadOnly = () =>
    mode === "edit" ? { readonly: true } : undefined;
</script>

{#if mode === 'edit'}
  <div class="w-full text-right pr-12">
    <DeactivateModal username={user.username} confirmEvent={inactivateUser} />
  </div>
{/if}
<form on:submit|preventDefault={submitForm}>
  {#if mode !== 'edit'}
    <div class="p-6 w-full">
      <TextField
        bind:textValue={user.email}
        {...resolveReadOnly()}
        placeholder="User email..."
        type="email"
        label="Email" />
    </div>
    <div class="p-6 w-full">
      <TextField
        bind:textValue={user.password}
        placeholder="User password..."
        type="password"
        label="Password" />
    </div>
  {:else}
    <input type="hidden" bind:value={user.version} />
  {/if}
  <div class="p-6 w-full flex flex-row justify-between">

    <div class="px-2">
      <TextField
        bind:textValue={user.firstName}
        placeholder="First Name..."
        type="text"
        label="First Name" />
    </div>
    <div class="px-2">
      <TextField
        bind:textValue={user.middleName}
        placeholder="Middle Name..."
        type="text"
        label="Middle Name" />
    </div>
    <div class="px-2">
      <TextField
        bind:textValue={user.lastName}
        placeholder="Last Name..."
        type="text"
        label="Last Name" />
    </div>
  </div>

  <div class="p-6 w-full flex flex-row justify-between">
    <div class="px-2">
      <TextField
        bind:textValue={user.employeeNumber}
        placeholder="Employee Number..."
        type="text"
        label="Employee Number" />
    </div>
    <div class="px-2">
      <TextField
        bind:textValue={user.roleGroup}
        placeholder="Role Group..."
        type="text"
        label="Role Group" />
    </div>
    <div class="px-2">
      <TextField
        bind:textValue={user.supervisorCode}
        placeholder="Supervisor Code..."
        type="text"
        label="Supervisor Code" />
    </div>
  </div>

  {#if superAdmin}
    <div class="p-6 w-full">
      <!-- TODO: Change this to dropdown of tenants -->
      <TextField
        bind:textValue={user.tenantId}
        placeholder="Tenant ID..."
        type="text"
        label="Tenant ID" />
    </div>
    <div class="p-6 w-full">

      <SelectField
        options={superAdminOptions}
        bind:value={user.superAdmin}
        id="superAdmin"
        label="Super Admin User?" />
    </div>
  {/if}

  <!-- <div class="p-6 w-full">
    <div class="flex flex-col w-full">
      <div class="header flex flex-row items-start">
        <div class="w-1/2">Module Permission</div>
        <div class="w-1/2">Access</div>
      </div>
      <div class="content flex-col flex items-start pt-8">
        {#if userRolesLoading}
          <div class="w-full pt-10">
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
          </div>
        {:else}
          {#each user.roles as role}
            <div class="flex flex-row w-full pb-4">
              <div class="flex flex-col w-1/2">{role.appName}</div>
              <div class="flex flex-col w-1/2">
                <SelectField
                  id={role.appCode}
                  label=""
                  options={roleOptions}
                  bind:value={role.isAdmin} />
              </div>
            </div>
          {/each}
        {/if}
      </div>
    </div>

  </div> -->

  <div class="w-full pb-4 mt-8 px-4">
    <Tabs>
      <TabList>
        <Tab>Access</Tab>
        <Tab>Assignments</Tab>
        <Tab>Rest Days</Tab>
      </TabList>
      <TabPanel>
        <div>
          <div class="content flex-col flex items-start pt-8">
            {#if userRolesLoading}
              <div class="w-full pt-10">
                <LoadingContent />
                <div class="pt-10" />
                <LoadingContent />
              </div>
            {:else}
              {#each user.roles as role}
                <div class="flex flex-row w-full pb-4">
                  <div class="flex flex-col w-1/2">{role.appName}</div>
                  <div class="flex flex-col w-1/2">
                    <SelectField
                      id={role.appCode}
                      label=""
                      options={roleOptions}
                      bind:value={role.isAdmin} />
                  </div>
                </div>
              {/each}
            {/if}
          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <AppAssignments {mode} {user} />
      </TabPanel>
      <TabPanel>
        <RestDays {user} />
      </TabPanel>
    </Tabs>
  </div>

  <div class="w-full flex flex-row-reverse justify-evenly pb-4 mt-8 px-4">
    <Button
      text={buttonLabel}
      type="submit"
      color="primary"
      loading={savingStatus} />
    <div class="w-25" />
    <div class="text-center">
      <EmptyButton text="Cancel" color="text" on:click={cancelAction} />
    </div>
  </div>

</form>
