<script>
  import { getClient, query } from "svelte-apollo";

  import { CheckboxGroup } from "@saztrek/firefly";

  import { GET_CALENDAR_EMPLOYEE_CONTEXT } from "../../../gql";

  import LoadingIndicator from "../../components/LargeLoadingIndicator.svelte";

  export let user;

  const client = getClient();

  const calendarEmployeeContextOp = query(client, {
    query: GET_CALENDAR_EMPLOYEE_CONTEXT,
    fetchPolicy: "network-only",
    variables: {
      employeeCode: user.id
    }
  });

  let restDays = [];
  let originalRestDays = [];

  const options = [
    { value: 0, option: "Sunday" },
    { value: 1, option: "Monday" },
    { value: 2, option: "Tuesday" },
    { value: 3, option: "Wednesday" },
    { value: 4, option: "Thursday" },
    { value: 5, option: "Friday" },
    { value: 6, option: "Saturday" }
  ];
  let loading = true;
  $calendarEmployeeContextOp
    .then((response) => {
      const { data } = response;
      const { calendarEmployeeContext } = data;
      restDays = calendarEmployeeContext.restDays;

      for (let i = 0; i < restDays.length; i += 1) {
        originalRestDays = [...originalRestDays, restDays[i]];
      }
      loading = false;
    })
    .catch((e) => {
      loading = false;
      console.error(e);
    });

  const hasRestDaysChanges = (originalDays, newDays) => {
    if (originalDays.length !== newDays.length) {
      return true;
    }

    for (let i = 0; i < originalDays.length; i += 1) {
      if (newDays.findIndex((newDay) => newDay === originalDays[i]) === -1) {
        return true;
      }
    }

    for (let j = 0; j < newDays.length; j += 1) {
      if (
        originalDays.findIndex((originalDay) => originalDay === newDays[j]) ===
        -1
      ) {
        return true;
      }
    }

    return false;
  };

  $: if (hasRestDaysChanges(originalRestDays, restDays)) {
    user.restDays = restDays;
  } else {
    user.restDays = null;
  }
</script>

<div>
  {#if loading}
    <LoadingIndicator />
  {:else}
    <CheckboxGroup
      label="Chosen Days"
      {options}
      bind:selectedValues={restDays} />
  {/if}
</div>
