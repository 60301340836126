<script>
  import SKUHeader from "./EDGARSKUHeader.svelte";

  export let sku;
  export let showUnits = true;
  export let showBadOrderUnits = true;
  export let showBadOrderContainers = true;
  export let hasDeliveryChanges = false || sku.hasDeliveryChanges;
  export let hasEndingChanges = false || sku.hasEndingChanges;
  export let hasBadOrderChanges = false || sku.hasBadOrderChanges;
  export let hasTransferInChanges = false || sku.hasTransferInChanges;
  export let hasTransferOutChanges = false || sku.hasTransferOutChanges;
  export let hasAdjustmentChanges = false || sku.hasAdjustmentChanges;
  export let hasOfftakeChanges = false || sku.hasOfftakeChanges;
</script>

<div
  class="pb-2 h-full border-l-8 rounded"
  class:border-secondary={!sku.noData}
  class:border-warning={sku.noData}>
  <div class="h-full">
    <SKUHeader
      code={sku.code}
      name={sku.name}
      offtakeUnits={sku.offtakeUnits}
      totalBeginningUnits={sku.totalBeginningUnits}
      noData={sku.noData} />

    <div class="h-full">
      <div
        class="flex flex-row pt-1 mx-3 bg-white text-tableText justify-center">
        <table class="text-xs w-full table-auto">
          <thead>
            <tr>
              <th />
              <th class="text-right">Containers</th>
              <th class="text-right" class:hidden={!showUnits}>Units</th>
              <th class="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr class:bg-highlightYellow={hasOfftakeChanges}>
              <td>Offtake</td>
              <td class="text-right" />
              <td class="text-right" class:hidden={!showUnits}>
                {sku.offtakeUnits}
              </td>
              <td class="text-right text-secondary font-semibold">
                {sku.offtakeUnits}
              </td>
            </tr>
            <tr>
              <td>Beginning</td>
              <td class="text-right">{sku.beginningContainers}</td>
              <td class="text-right" class:hidden={!showUnits}>
                {sku.beginningUnits}
              </td>
              <td class="text-right text-secondary font-semibold">
                {sku.totalBeginningUnits}
              </td>
            </tr>
            <tr class:bg-highlightYellow={hasDeliveryChanges}>
              <td>Delivery</td>
              <td class="text-right">{sku.deliveryContainers}</td>
              <td class="text-right" class:hidden={!showUnits}>
                {sku.deliveryUnits}
              </td>
              <td class="text-right text-secondary font-semibold">
                {sku.totalDeliveryUnits}
              </td>
            </tr>
            <tr class:bg-highlightYellow={hasTransferInChanges}>
              <td>STI</td>
              <td class="text-right">{sku.transferInContainers}</td>
              <td class="text-right" class:hidden={!showUnits}>
                {sku.transferInUnits}
              </td>
              <td class="text-right text-secondary font-semibold">
                {sku.totalTransferInUnits}
              </td>
            </tr>
            <tr class:bg-highlightYellow={hasAdjustmentChanges}>
              <td>Adjustment</td>
              <td class="text-right">{sku.adjustmentContainers}</td>
              <td class="text-right" class:hidden={!showUnits}>
                {sku.adjustmentUnits}
              </td>
              <td class="text-right text-secondary font-semibold">
                {sku.totalAdjustmentUnits}
              </td>
            </tr>
            <tr class:bg-highlightYellow={hasEndingChanges}>
              <td>Ending</td>
              <td class="text-right">{sku.endingContainers}</td>
              <td class="text-right" class:hidden={!showUnits}>
                {sku.endingUnits}
              </td>
              <td class="text-right text-danger font-semibold">
                {sku.totalEndingUnits}
              </td>
            </tr>
            <tr class:bg-highlightYellow={hasTransferOutChanges}>
              <td>STO</td>
              <td class="text-right">{sku.transferOutContainers}</td>
              <td class="text-right" class:hidden={!showUnits}>
                {sku.transferOutUnits}
              </td>
              <td class="text-right text-danger font-semibold">
                {sku.totalTransferOutUnits}
              </td>
            </tr>
            <tr class:bg-highlightYellow={hasBadOrderChanges}>
              <td>
                {#if showBadOrderUnits && !showBadOrderContainers}
                  Bad Order (Units)
                {:else if showBadOrderContainers && !showBadOrderUnits}
                  Bad Order (Containers)
                {:else}Bad Order{/if}
              </td>
              <td class="text-right" class:hidden={!showBadOrderContainers}>
                {sku.badOrderContainers}
              </td>
              <td class="text-right" class:hidden={!showBadOrderUnits}>
                {sku.badOrderUnits}
              </td>
              <td class="text-right text-danger font-semibold">
                {sku.totalBadOrderUnits}
              </td>
            </tr>
          </tbody>

        </table>
      </div>

    </div>
  </div>

</div>
