<script>
  import { toastNotifier } from "@saztrek/firefly";

  import PS3App from "./PS3new.svelte";

  import { PS3Db } from "../../../middleware/database/ps3";

  import { currentUser } from "../../../stores/current_user";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  let loading = true;

  let ps3Sheet;

  let ps3RawSheetsDB;

  $: if (loading && $currentUser.tenantId) {
    if (!ps3RawSheetsDB) {
      ps3RawSheetsDB = PS3Db($currentUser.tenantId);
      ps3RawSheetsDB.findOne(id).then((doc) => {
        ps3Sheet = doc.data();
        loading = false;
      });
    }
  }

  const saveSheet = (event) => {
    const sheetData = { ...event.detail };
    const { jsonData, universeLength } = sheetData;

    ps3RawSheetsDB
      .update({
        sheetCode: id,
        processedSheet: jsonData,
        universeLength
      })
      .then(() => {
        toastNotifier.success("Success!", `PS3 ${id} saved`, 7000);
      });
  };

  const publishSheet = (event) => {
    const sheetData = { ...event.detail };
    const { publishedSheet } = sheetData;

    ps3RawSheetsDB.update({ sheetCode: id, publishedSheet }).then(() => {
      toastNotifier.success("Success!", `PS3 ${id} published`, 7000);
    });
  };
</script>

{#if !loading}
  <div class="w-full h-full">
    <PS3App
      {ps3Sheet}
      on:savePS3Sheet={saveSheet}
      on:publishPS3Sheet={publishSheet} />
  </div>
{/if}
