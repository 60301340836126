<script>
  import { KeypadMenu } from "@saztrek/firefly";
  import { navigateTo } from "svelte-router-spa";

  const items = [
    {
      label: "To Process",
      action: () => navigateTo("/ps3/list"),
      app: "ps3"
    },
    {
      label: "Summary",
      action: () => navigateTo("/ps3/summary"),
      app: "ps3"
    },
    {
      label: "Published",
      action: () => navigateTo("/ps3/published"),
      app: "edgar"
    }
  ];
</script>

<div class="flex justify-center pt-20">
  <KeypadMenu {items} size="l" />
</div>
