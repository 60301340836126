import firebase from "firebase/app";
import "firebase/firebase-firestore";
import "firebase/firebase-auth";
import "firebase/firebase-functions";
import "firebase/firebase-storage";

import { firebaseConfig } from "./settings";

firebase.initializeApp(firebaseConfig);

const Firestore = firebase.firestore();
const FirebaseStorage = firebase.storage();
const Auth = firebase.auth();

// Uncomment this if using cloud functions

/* const { env } = processSvelte; */
// const env = false;

// const { isProd, API_URL } = env;
// const isProd = true;
// const API_URL = "http://localhost:5001";

/* if (env && !isProd && !API_URL) {
  // eslint-disable-next-line no-console
  console.error("You are running in sandbox mode but no .env file was found");
} */

/* if (env && !isProd && API_URL) {
  // eslint-disable-next-line no-console
  console.log(
    "You are in sandbox mode. Functions are locally deployed. Firestore is production-mode though"
  );
  firebase
    .app()
    .functions()
    .useFunctionsEmulator(API_URL);
}

firebase
  .app()
  .functions()
  .useFunctionsEmulator(API_URL); */

const Functions = (() => {
  // if (isProd) {
  //   return firebase.app().functions("asia-northeast1");
  // }

  return firebase.app().functions();
})();

export { Firestore, Auth, Functions, FirebaseStorage };
