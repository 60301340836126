import { FirebaseUsers } from "./index";

const usersDb = () => {
  // TODO: Move to server function to check permission
  const archive = (employeeId) => {
    return FirebaseUsers.doc(employeeId).set(
      { active: false },
      { merge: true }
    );
  };

  const update = (employeeId, employeeInfo) => {
    const employeeInfoLocal = employeeInfo;
    delete employeeInfoLocal.email;
    delete employeeInfoLocal.password;
    return FirebaseUsers.doc(employeeId).update(employeeInfoLocal);
  };

  const findOne = (employeeId) => {
    return FirebaseUsers.doc(employeeId).get();
  };

  const findByUserId = (userId) => {
    return FirebaseUsers.where("userId", "==", userId).get();
  };

  const findAll = ({ tenantId, filter }) => {
    // TODO: Find for alternative ways to make this better.

    let query = FirebaseUsers;

    query = FirebaseUsers.where("active", "==", filter.active === "true")
      .where("tenantId", "==", tenantId)
      .where("superAdmin", "==", false);

    if (filter.username) {
      query = query
        .orderBy("email")
        .startAt(filter.username)
        .endAt(`${filter.username}\uf8ff`);
    } else if (filter.lastName) {
      query = query
        .orderBy("lastName")
        .startAt(filter.lastName)
        .endAt(`${filter.lastName}\uf8ff`);
    } else if (filter.firstName) {
      query = query
        .orderBy("firstName")
        .startAt(filter.firstName)
        .endAt(`${filter.firstName}\uf8ff`);
    }

    return query;
  };

  // TODO: Move to server function to check permissions
  const unarchive = (employeeId) => {
    return FirebaseUsers.doc(employeeId).set({ active: true }, { merge: true });
  };

  const toggleStatus = (employee) => {
    if (!employee.active) {
      return unarchive(employee.id);
    }
    return archive(employee.id);
  };

  return Object.freeze({
    archive,
    update,
    findOne,
    findAll,
    findByUserId,
    toggleStatus,
    unarchive
  });
};

const Users = usersDb();

export { Users };
