<script>
  import { getClient, query } from "svelte-apollo";

  import { Button } from "@saztrek/firefly";

  import { pageSize } from "../../utilities/constants";

  import DataTable from "./DataTable.svelte";
  import SearchForm from "./SearchForm.svelte";
  import Divider from "./Divider.svelte";

  export let gqlQuery;
  export let columns;
  export let tableActions;
  export let findObjectInput;
  export let resetSearch;
  export let updateSearch;
  export let goToAdd;
  export let processResponse;

  const client = getClient();

  let loading = true;
  let currentData;
  let items = [];
  let isError = false;

  const connectionArgs = {
    first: pageSize,
    after: "",
    before: ""
  };

  const graphQLOp = query(client, {
    query: gqlQuery,
    fetchPolicy: "network-only",
    variables: { connectionArgs, ...findObjectInput }
  });

  const processThisResponse = (response) => {
    const processedData = processResponse(response);
    items = processedData.items;
    currentData = processedData.currentData;
    loading = false;
    isError = false;
  };

  const refetch = () => {
    loading = true;
    graphQLOp
      .refetch({
        connectionArgs,
        ...findObjectInput
      })
      .then((response) => processThisResponse(response))
      .catch((error) => {
        isError = true;
        loading = false;
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const nextPage = () => {
    connectionArgs.before = "";
    connectionArgs.after = currentData.pageInfo.endCursor;
    refetch();
  };

  const previousPage = () => {
    connectionArgs.after = "";
    connectionArgs.before = currentData.pageInfo.startCursor;
    refetch();
  };

  $graphQLOp
    .then((response) => processThisResponse(response))
    .catch((error) => {
      isError = true;
      loading = false;
      // eslint-disable-next-line no-console
      console.error(error);
    });

  const resetPaging = () => {
    connectionArgs.after = "";
    connectionArgs.before = "";
  };

  const resetThisSearch = () => {
    findObjectInput = resetSearch();
    resetPaging();
    refetch();
  };

  const updateThisSearch = () => {
    findObjectInput = updateSearch();
    resetPaging();
    refetch();
  };
</script>

<div class="w-full pt-4 flex flex-col justify-start">
  <div class="self-end flex">
    <slot name="actions" />
    <Button color="primary" filled="true" text="New" on:click={goToAdd} />
  </div>

  <Divider />
  {#if findObjectInput}
    <SearchForm
      on:resetSearch={resetThisSearch}
      on:updateSearch={updateThisSearch}>
      <div slot="formelements" class="w-full">
        <slot name="searchform" />
      </div>
    </SearchForm>
  {/if}
  <Divider />

  <DataTable
    {columns}
    {items}
    actions={tableActions}
    {loading}
    {isError}
    on:nextPage={nextPage}
    on:previousPage={previousPage} />

</div>
