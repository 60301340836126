<script>
  //   import { getClient, query } from "svelte-apollo";

  import { Button } from "@saztrek/firefly";

  import { FirebaseResults } from "../../middleware/database";

  import DataTable from "./DataTable.svelte";
  //   import SearchForm from "./SearchForm.svelte";
  import Divider from "./Divider.svelte";

  export let firebaseQuery;

  export let columns;
  export let tableActions;
  export let findObjectInput;
  export let resetSearch;
  export let updateSearch;
  export let goToAdd;
  export let processResponse;

  let loading = true;

  let items = [];
  let isError = false;
  let unsubscribeQuery = null;

  const processThisResponse = (response) => {
    const processedData = processResponse(response);
    items = processedData;

    loading = false;
    isError = false;
  };

  const fetchData = () => {
    if (unsubscribeQuery) {
      unsubscribeQuery();
    }

    unsubscribeQuery = firebaseQuery.onSnapshot((docs) => {
      const data = FirebaseResults.map(docs);
      processThisResponse(data);
    });
  };

  fetchData();
</script>

<div class="w-full pt-4 flex flex-col">
  <div class="self-end">
    <Button color="primary" filled="true" text="New" on:click={goToAdd} />
  </div>

  <Divider />

  <!-- <SearchForm
    on:resetSearch={resetThisSearch}
    on:updateSearch={updateThisSearch}>
    <div slot="formelements" class="w-full">
      <slot name="searchform" />
    </div>
  </SearchForm> -->

  <Divider />

  <DataTable {columns} {items} actions={tableActions} {loading} {isError} />

</div>
