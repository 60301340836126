<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, mutate, query } from "svelte-apollo";

  import { ADD_BRAND, MANUFACTURERS } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import BrandForm from "./BrandForm.svelte";

  const cancelAdd = () => {
    navigateTo("brands");
  };

  const client = getClient();

  // TODO: Make this into a lookup field so we don't get everything

  const manufacturersOp = query(client, {
    query: MANUFACTURERS
  });

  let loading = false;

  const addBrand = async (event) => {
    try {
      const categoryData = { ...event.detail };
      loading = true;
      await mutate(client, {
        mutation: ADD_BRAND,
        variables: {
          addBrandInput: {
            code: categoryData.code,
            name: categoryData.name,
            manufacturerCode: categoryData.manufacturerCode
          }
        }
      });
      toastNotifier.success(
        "Success!",
        `Brand ${categoryData.name} Added`,
        7000
      );
      navigateTo("brands");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to add.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add Brand">
    {#await $manufacturersOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      <BrandForm
        mode="add"
        on:validBrand={addBrand}
        cancelAction={cancelAdd}
        manufacturers={result.data.manufacturers.edges}
        {loading}
        buttonLabel="Add" />
    {/await}
  </Panel>
</div>
