const firebaseConfig = {
  apiKey: "AIzaSyAcnQPT-iyZz7TlHVCU4QviUfPcKfnopNE",
  authDomain: "mermade-dev.firebaseapp.com",
  databaseURL: "https://mermade-dev.firebaseio.com",
  projectId: "mermade-dev",
  storageBucket: "mermade-dev.appspot.com",
  messagingSenderId: "796684177898",
  appId: "1:796684177898:web:6e56c0f72de643fdc22293",
  measurementId: "G-K8TYK3T5KR"
};

export { firebaseConfig };
