<script>
  import { createEventDispatcher } from "svelte";
  import { Gauge, Icon } from "@saztrek/firefly";
  import StickyHeader from "./StickyHeader.svelte";

  export let branches;
  export let hideChart = false;
  export let title = "Branches";
  export let subtitle = "";
  export let emptyBranchesMessage = "No Sheets found.";

  const dispatch = createEventDispatcher();

  const branchClicked = (branch) => {
    dispatch("branchSelected", { branch });
  };
</script>

<div class="border border-darkerGray rounded p-1 h-full overflow-y-auto">
  <StickyHeader>
    <div class="w-full">
      <div class="font-bold text-lg">{title}</div>
      <div class="italic text-sm font-normal">{subtitle}</div>
    </div>
  </StickyHeader>
  {#if branches.length === 0}
    <div class="h-56 w-full flex flex-col items-center justify-center">
      <Icon size="l" type="table" />
      <div class="pt-2">{emptyBranchesMessage}</div>
    </div>
  {:else}
    <div class="list pl-6 pr-4 h-full">
      {#each branches as branch}
        <div
          class="flex flex-row items-start justify-between py-2
          border-b-darkerGray border-b w-full last:border-b-0">
          <div class="flex flex-col justify-start w-9/12 px-1">
            <span class="w-full truncate">
              {#if branch.hasSheet && !branch.generated}
                <a
                  class="text-sm text-primary hover:underline"
                  href="#!"
                  on:click={() => branchClicked(branch)}>
                  {branch.name}
                </a>
              {:else}
                <a class="text-sm text-ghostText italic" href="#!">
                  {branch.name}
                </a>
              {/if}
            </span>

            <div class="text-xs italic">{branch.code}</div>
          </div>
          {#if branch.donSheet && !branch.generated && !hideChart}
            <div class="flex flex-col w-1/12 pr-12">
              {#if branch.skusCovered >= 100}
                <Gauge
                  value={1}
                  size="40"
                  borderWidth="3"
                  valueText="&#10003;"
                  valueFontSize={16}
                  valueFontWeight={500}
                  valueTextColor="#219643"
                  borderColor="#219643" />
              {:else if branch.skusCovered < 100 && branch.skusCovered > 49}
                <Gauge
                  value={branch.skusCovered / 100}
                  size="40"
                  borderWidth="3"
                  valueText="{branch.skusCovered}%"
                  valueFontSize={12}
                  valueFontWeight={300}
                  valueTextColor="#219643"
                  borderColor="#219643" />
              {:else if branch.skusCovered < 100 && branch.skusCovered > 0}
                <Gauge
                  value={branch.skusCovered / 100}
                  size="40"
                  borderWidth="3"
                  valueText="{branch.skusCovered}%"
                  valueFontSize={12}
                  valueFontWeight={300}
                  valueTextColor="#F5A700"
                  borderColor="#F5A700" />
              {:else if branch.donSheet !== null}
                <Gauge
                  value={0}
                  size="40"
                  borderWidth="3"
                  valueText="0%"
                  valueFontSize={12}
                  valueFontWeight={300}
                  valueTextColor="#BD271E"
                  borderBgColor="#BD271E" />
              {/if}
            </div>
          {:else if !hideChart}
            <div class="flex flex-col w-3/12 self-center">
              <div
                class="text-xs font-normal leading-5 px-2 rounded border
                border-transparent bg-danger">
                <div
                  class="flex items-center justify-center flex-row-reverse
                  text-white">
                  <span>No Sheet</span>
                </div>
              </div>
            </div>
          {/if}
        </div>
      {/each}

    </div>
  {/if}
</div>
