<script>
  import { onDestroy } from "svelte";
  import { navigateTo } from "svelte-router-spa";

  import {
    Button,
    TextField,
    SelectField,
    toastNotifier
  } from "@saztrek/firefly";

  import { Users } from "../../../middleware/database/users";
  import { FirebaseResults } from "../../../middleware/database";
  import { Functions } from "../../../config/firebase";
  import { currentUser } from "../../../stores/current_user";

  import LoadingIndicator from "../../components/LargeLoadingIndicator.svelte";

  import NodeTable from "./UserNodeTable.svelte";

  export let currentRoute;
  export let params;

  /* svelte router force feeds these parameters (see above).
     Console warns if we don't do the exports.
     Svelte warns if they are unused, therefore putting them down here, in case code needs it.
  */
  // eslint-disable-next-line no-unused-vars
  const paramsFromRouter = params;
  // eslint-disable-next-line no-unused-vars
  const currentRouteFromRouter = currentRoute;

  const active = "true";
  let filteredUsers = [];
  let unsubscribeQuery = null;
  let loading = true;

  let email;
  let firstName;
  let lastName;

  let isActiveList = true;

  const goToAddUser = () => {
    navigateTo("/users/new");
  };

  const cleanupUserData = usersData => {
    return usersData.map(data => {
      return {
        id: data.id,
        username: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        active: data.active
      };
    });
  };

  const fetchUsers = (tenantId, filter) => {
    if (unsubscribeQuery) {
      unsubscribeQuery();
    }

    if (!tenantId) return;
    unsubscribeQuery = Users.findAll({ tenantId, filter }).onSnapshot(docs => {
      const users = FirebaseResults.map(docs, {
        id: "id",
        value: $currentUser.id
      });

      filteredUsers = cleanupUserData([...users]);
      loading = false;
    });
  };

  let filter = { email, firstName, lastName, active };

  $: fetchUsers($currentUser.tenantId, filter);

  onDestroy(() => {
    unsubscribeQuery();
  });

  const inactivateUserHandler = event => {
    const userToDeactivate = event.detail;

    const deactivateUser = Functions.httpsCallable("deactivateUser");

    deactivateUser(userToDeactivate)
      .then(() => {
        toastNotifier.success("Success!", `User deactivated.`, 7000);
      })
      .catch(error => {
        toastNotifier.danger("Error", `${error.message}`, 7000);
      });
  };

  const selectOptions = [
    {
      option: "Active",
      value: "true"
    },
    {
      option: "Inactive",
      value: "false"
    }
  ];

  const updateSearch = () => {
    filter = { email, firstName, lastName, active };
  };

  const resetSearch = () => {};
</script>

<div class="flex flex-col items-center pt-8">
  <div class="self-end pr-10 pb-4 ">
    <Button color="primary" filled="true" text="New" on:click={goToAddUser} />
  </div>
  <div class="px-10 w-full">
    <form on:submit|preventDefault={updateSearch}>
      <div
        class="p-4 flex flex-row items-start content-start w-full shadow rounded">

        <div class="w-1/3 pr-2">
          <TextField
            type="search"
            placeholder="Username"
            bind:textValue={email} />
        </div>
        <div class="w-1/3 pr-2">
          <TextField
            type="search"
            placeholder="First Name"
            bind:textValue={firstName} />
        </div>
        <div class="w-1/3 pr-2">
          <TextField
            type="search"
            placeholder="Last Name"
            bind:textValue={lastName} />
        </div>
        <div class="w-1/3 pr-2">
          <SelectField
            options={selectOptions}
            bind:value={isActiveList}
            id="isActive"
            label="" />
        </div>

        <div class="flex flex-row-reverse">
          <Button color="primary" icon="search" />
          <Button color="primary" icon="close" on:click={resetSearch} />
        </div>

      </div>
    </form>
  </div>
  <div class="pt-4 w-full">
    <div class="w-full">

      <div class="w-full px-10">
        <div class="w-full rounded shadow px-10" style="min-height: 520px">
          {#if loading}
            <LoadingIndicator />
          {:else}
            <div class="pb-2">
              <NodeTable
                edges={filteredUsers}
                on:inactivateAccount={inactivateUserHandler} />
            </div>
          {/if}
        </div>
      </div>
      <div class="pt-2 px-10">
        <!-- disabled={!result.data.accounts.pageInfo.hasPreviousPage}-->
        <!--  <Button
            icon="arrowLeft"
            color="primary"
            on:click={() => {
              goToPreviousPage(result.data.accounts.pageInfo.startCursor);
            }} /> -->
        <!-- disabled={!result.data.accounts.pageInfo.hasNextPage} -->
        <!--  <Button
            icon="arrowRight"
            color="primary"
            on:click={() => {
              goToNextPage(result.data.accounts.pageInfo.endCursor);
            }} /> -->

      </div>

    </div>
  </div>
</div>
