<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, mutate, query } from "svelte-apollo";

  import { ADD_CARRYTEMPLATE, SKUS } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import CarryTemplateForm from "./CarryTemplateForm.svelte";

  const cancelAdd = () => {
    navigateTo("carryTemplates");
  };

  const client = getClient();

  // TODO: Make this into a lookup field so we don't get everything

  const skusOp = query(client, {
    query: SKUS
  });

  let loading = false;

  const addCarryTemplate = async (event) => {
    try {
      const carryTemplateData = { ...event.detail };
      loading = true;
      await mutate(client, {
        mutation: ADD_CARRYTEMPLATE,
        variables: {
          addCarryTemplateInput: {
            code: carryTemplateData.code,
            name: carryTemplateData.name,
            description: carryTemplateData.description,
            skuCodes: carryTemplateData.skus
          }
        }
      });
      toastNotifier.success(
        "Success!",
        `CarryTemplate ${carryTemplateData.name} Added`,
        7000
      );
      navigateTo("carryTemplates");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to add.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add CarryTemplate">
    {#await $skusOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      <CarryTemplateForm
        mode="add"
        on:validCarryTemplate={addCarryTemplate}
        cancelAction={cancelAdd}
        allSKUs={result.data.skus.edges}
        {loading}
        buttonLabel="Add" />
    {/await}
  </Panel>
</div>
