import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { formatDateToServer, formatDateToUI } from "@saztrek/firefly";

dayjs.extend(customParseFormat);

const dateFormatToServer = (dateObjectOrString) =>
  formatDateToServer(dateObjectOrString, dayjs);
const dateFormatToUI = (dateObjectOrString) =>
  formatDateToUI(dateObjectOrString, dayjs);

export { dateFormatToServer, dateFormatToUI };
