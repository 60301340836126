import { FirebaseUploadJobs } from "./index";

const uploadJobsDB = () => {
  const findOne = (uploadJobID) => {
    return FirebaseUploadJobs.doc(uploadJobID).get();
  };

  const findAllInprogress = (uid) => {
    // TODO: Find for alternative ways to make this better.

    return FirebaseUploadJobs.where("createdBy", "==", uid).where(
      "complete",
      "==",
      false
    );
  };

  const findAllComplete = (uid) => {
    return FirebaseUploadJobs.where("createdBy", "==", uid).where(
      "complete",
      "==",
      true
    );
  };

  const findAll = (uid) => {
    return FirebaseUploadJobs.where("createdBy", "==", uid);
  };

  return Object.freeze({
    findOne,
    findAllComplete,
    findAllInprogress,
    findAll
  });
};

const UploadJobs = uploadJobsDB();

export { UploadJobs };
