const createLayer = require("./layers");

const createBay = ({
  createKonvaStage,
  createKonvaLayer,
  createKonvaLine,
  createKonvaText,
  createKonvaImage,
  wrapAnchors,
  data,
  processedBay,
  container
}) => {
  const bayID = data.id;

  let currentLayerID = `${bayID}-layer-0`;

  let scale;
  let padding;

  const konvaStage = createKonvaStage(container);
  const initialKonvaLayer = createKonvaLayer();

  konvaStage.container = container;
  konvaStage.add(initialKonvaLayer);

  let layers = [
    createLayer({
      id: currentLayerID,
      konvaLayer: initialKonvaLayer,
      createKonvaLine,
      createKonvaText,
      wrapAnchors,
      data,
      processedBay
    })
  ];

  const getLayers = () => layers;

  const setCurrentLayer = (id) => {
    const layerToSelect = layers.find((layer) => layer.id === id);
    if (layerToSelect) {
      currentLayerID = id;
    } else {
      throw new Error("Layer ID not found in Bay");
    }
  };

  const getCurrentLayer = () =>
    layers.find((layer) => layer.id === currentLayerID);

  const addLayer = () => {
    layers = [
      ...layers,
      createLayer({
        id: `${bayID}-layer-${layers.length}`,
        konvaLayer: createKonvaLayer(),
        createKonvaLine,
        wrapAnchors
      })
    ];
  };

  const removeLayer = (id) => {
    const layerToRemove = layers.find((layer) => layer.id === id);
    if (layers.length > 1 && layerToRemove) {
      setCurrentLayer(`${bayID}-layer-0`);
      layerToRemove.destroy();
      layers = layers.filter((layer) => layer.id !== id);
    } else {
      throw new Error("Minimum of 1 layer in bay or Layer ID not found in Bay");
    }
  };

  const getScaledPointerPosition = () => {
    const pointerPosition = konvaStage.getPointerPosition();

    const x = pointerPosition.x / scale;
    const y = pointerPosition.y / scale;

    return { x, y };
  };

  const initializeBay = ({ initialPadding, initialScale }) => {
    padding = initialPadding;
    scale = initialScale;
  };

  const loadImage = (imageNode) => {
    if (padding && scale) {
      // const { attrs } = imageNode;
      // const { height, width } = attrs.image;
      const { height, width } = imageNode;

      const targetW = konvaStage.width() - 2 * padding;
      const targetH = konvaStage.height() - 2 * padding;

      const widthFit = targetW / width;
      const heightFit = targetH / height;

      const isPortrait = widthFit > heightFit;

      const imageScale = isPortrait ? heightFit : widthFit;

      const scaledWidth = parseInt(width * imageScale, 10);
      const scaledHeight = parseInt(height * imageScale, 10);

      // bring back up to container's scale

      const centeredX = (targetW - scaledWidth) / 2 / scale;

      // imageNode.setAttrs({
      //   width: scaledWidth / scale,
      //   height: scaledHeight / scale,
      //   x: centeredX,
      //   y: 2 * padding
      // });
      const konvaImage = createKonvaImage({
        x: centeredX,
        y: padding,
        width: scaledWidth / scale,
        height: scaledHeight / scale,
        image: imageNode
      });

      const bayLayer = getCurrentLayer();
      // bayLayer.konvaLayer.add(imageNode);
      bayLayer.konvaLayer.add(konvaImage);
      konvaImage.moveToBottom();
      bayLayer.konvaLayer.draw();
    } else {
      throw new Error("Padding and scale should both be initialized");
    }
  };

  const getReport = () => {
    return layers.map((layer) => {
      return {
        // id: layer.id,
        id: bayID,
        layerReport: layer.getReport()
      };
    });
  };

  const toJSON = () => {
    return {
      bayID,
      layers: layers.map((layer) => layer.toJSON())
    };
  };

  return {
    konvaStage,
    getLayers,
    getCurrentLayer,
    setCurrentLayer,
    addLayer,
    removeLayer,
    container,
    initializeBay,
    getScaledPointerPosition,
    loadImage,
    getReport,
    ...data,
    toJSON
  };
};

module.exports = createBay;
