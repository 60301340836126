const AdminAppMap = [
  {
    icon: "wow",
    id: "users",
    name: "User Management",
    href: "/users"
  }
  //   {
  //     icon: "picad",
  //     id: "Tenant Management",
  //     name: "Tenant Management",
  //     href: "https://google.com?q=tenants",
  //   },
];

const AppMap = [
  {
    icon: "edgar",
    id: "edgar",
    name: "EDGAR",
    href: "/edgar"
  },
  {
    icon: "don",
    id: "don",
    name: "DON",
    href: "/don"
  },
  {
    icon: "calendar",
    id: "calendar",
    name: "Calendar",
    href: "/calendar"
  },
  {
    icon: "ps3",
    id: "skus",
    name: "SKU Management",
    href: "/skus"
  },
  {
    icon: "ps3",
    id: "branches",
    name: "Branch Management",
    href: "/branches"
  },
  {
    icon: "nemo",
    id: "nemo",
    name: "NEMO",
    href: "/nemo"
  },
  {
    icon: "ps3",
    id: "ps3",
    name: "PS3",
    href: "/ps3"
  }
];

export { AdminAppMap, AppMap };
