import { gql } from "apollo-boost";

// QUERIES
const SKUS_DETAILED = gql`
  query skus($findSKUInput: FindSKUInput, $connectionArgs: ConnectionArgs) {
    skus(findSKUInput: $findSKUInput, connectionArgs: $connectionArgs) {
      edges {
        cursor
        node {
          id
          code
          name
          core
          unitCount
          weekSupply
          version
          brand {
            id
            code
            name
            manufacturer {
              id
              code
              name
            }
          }
          category {
            id
            code
            name
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const SKUS_LITE = gql`
  query skus($findSKUInput: FindSKUInput) {
    skus(findSKUInput: $findSKUInput) {
      edges {
        node {
          code
          name
          brand {
            code
            name
          }
          category {
            code
            name
          }
        }
      }
    }
  }
`;

const GET_SKU = gql`
  query sku($code: String!) {
    sku(code: $code) {
      id
      code
      name
      core
      unitCount
      weekSupply
      brand {
        id
        code
        name
        manufacturer {
          id
          code
          name
          active
          version
        }
        active
        version
      }
      category {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

// MUTATIONS

const ADD_SKU = gql`
  mutation addSKU($addSKUInput: AddSKUInput!) {
    addSKU(addSKUInput: $addSKUInput) {
      id
      code
      name
      core
      unitCount
      weekSupply
      brand {
        id
        code
        name
        manufacturer {
          id
          code
          name
          active
          version
        }
        active
        version
      }
      category {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

const EDIT_SKU = gql`
  mutation editSKU($editSKUInput: EditSKUInput!) {
    editSKU(editSKUInput: $editSKUInput) {
      id
      code
      name
      core
      unitCount
      weekSupply
      brand {
        id
        code
        name
        manufacturer {
          id
          code
          name
          active
          version
        }
        active
        version
      }
      category {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

const INACTIVATE_SKU = gql`
  mutation inactivateSKU($code: String!) {
    inactivateSKU(code: $code) {
      id
      code
      name
      core
      unitCount
      brand {
        id
        code
        name
        manufacturer {
          id
          code
          name
          active
          version
        }
        active
        version
      }
      category {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

const ACTIVATE_SKU = gql`
  mutation activateSKU($code: String!) {
    activateSKU(code: $code) {
      id
      code
      name
      core
      unitCount
      brand {
        id
        code
        name
        manufacturer {
          id
          code
          name
          active
          version
        }
        active
        version
      }
      category {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

const SKUS = gql`
  query skus($findSKUInput: FindSKUInput, $connectionArgs: ConnectionArgs) {
    skus(findSKUInput: $findSKUInput, connectionArgs: $connectionArgs) {
      edges {
        cursor
        node {
          code
          name
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export {
  SKUS,
  SKUS_DETAILED,
  GET_SKU,
  ADD_SKU,
  EDIT_SKU,
  INACTIVATE_SKU,
  ACTIVATE_SKU,
  SKUS_LITE
};
