const exportToCSV = (cols, rows, fileName) => {
  const csvContent = cols + rows;

  const reportBlob = new Blob([csvContent], { type: "text/csv" });

  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";

  const url = window.URL.createObjectURL(reportBlob);
  a.href = url;
  a.download = `${fileName}.csv`;
  a.click();
  window.URL.revokeObjectURL(url);
};

const exportToXLSX = (data, fileName) => {
  const reportBlob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";

  const url = window.URL.createObjectURL(reportBlob);
  a.href = url;
  a.download = `${fileName}.xlsx`;
  a.click();
  window.URL.revokeObjectURL(url);
};

module.exports = { exportToCSV, exportToXLSX };
