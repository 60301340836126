<script>
  import { LoadingContent } from "@saztrek/firefly";

  export let loadingSKUs;
  export let sheets;
</script>

{#if sheets && sheets.length > 0}
  <div
    class="overflow-auto flex justify-center w-full px-4 border
    border-darkerGray rounded pt-4 pb-4"
    style="max-height: 320px; height: 320px;">
    <table class="table-auto leading-5 pt-2">
      <thead class="font-bold text-sm">
        <tr>
          <td class="px-1">Branch Code</td>
          <td class="px-1">Branch Name</td>
          <td class="px-1">Chain Code</td>
          <td class="px-1">Chain Name</td>
          <td class="px-1">Last Update By</td>
          <td class="px-1">Total SKU Count</td>
          <td class="px-1">Carried SKU Count</td>
          <td class="px-1">Available Count</td>
          <td class="px-1">Available %</td>
          <td class="px-1">OOS Count</td>
          <td class="px-1">OOS %</td>
          <td class="px-1">Not Carried Count</td>
          <td class="px-1">Not Carried %</td>
          <td class="px-1">Date</td>

        </tr>
      </thead>
      {#each sheets as sheet, i}
        <tr class="border-b-darkerGray border-b text-sm h-10">
          <td class="px-1">{sheet.branchCode}</td>
          <td class="px-1">{sheet.branchName}</td>
          <td class="px-1">{sheet.chainCode}</td>
          <td class="px-1">{sheet.chainName}</td>
          <td class="px-1">{sheet.lastUpdatedBy}</td>
          <td class="px-1">{sheet.totalSKUCount}</td>
          <td class="px-1">{sheet.carriedSKUCount}</td>
          <td class="px-1">{sheet.availableCount}</td>
          <td class="px-1">{sheet.availablePercentage}%</td>
          <td class="px-1">{sheet.oosCount}</td>
          <td class="px-1">{sheet.oosPercentage}%</td>
          <td class="px-1">{sheet.notCarriedCount}</td>
          <td class="px-1">{sheet.notCarriedPercentage}%</td>
          <td class="px-1">{sheet.inventoryDate}</td>

        </tr>
      {/each}
    </table>
  </div>
{:else if loadingSKUs}
  <div class="w-full pt-2 h-full px-4 ">
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
  </div>
{/if}
