<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, mutate, query } from "svelte-apollo";

  import {
    ADD_BRANCH,
    CHAINS,
    CARRYTEMPLATES_WITHSKUS,
    EDIT_EDGAR_CARRY_TEMPLATE
  } from "../../../gql";

  import Panel from "../../components/Panel.svelte";
  import BranchForm from "./BranchForm.svelte";

  const cancelAdd = () => {
    navigateTo("/branches/list");
  };

  const client = getClient();

  // TODO: Make this into a lookup field so we don't get everything

  const chainsOp = query(client, {
    query: CHAINS
  });

  const carryTemplatesOp = query(client, {
    query: CARRYTEMPLATES_WITHSKUS
  });

  let loading = false;

  const addBranch = async (event) => {
    try {
      const branchData = { ...event.detail };
      loading = true;
      await mutate(client, {
        mutation: ADD_BRANCH,
        variables: {
          addBranchInput: {
            code: branchData.code,
            name: branchData.name,
            baseAddress: branchData.baseAddress,
            chainCode: branchData.chainCode,
            zip: branchData.zip,
            city: branchData.city,
            province: branchData.province,
            regionCode: branchData.regionCode,
            regionName: branchData.regionName,
            coordinates: branchData.coordinates
          }
        }
      });
      if (branchData.carryTemplateCodes.length > 0) {
        await mutate(client, {
          mutation: EDIT_EDGAR_CARRY_TEMPLATE,
          variables: {
            setEDGARBranchCarryTemplateInput: {
              branchCode: branchData.code,
              carryTemplateCodes: branchData.carryTemplateCodes,
              weekSupplyConfigurations: branchData.weekSupplyConfiguration
            }
          }
        });
      }

      toastNotifier.success(
        "Success!",
        `Branch ${branchData.name} Added`,
        7000
      );
      navigateTo("branches");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to add.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add Branch">
    {#await $chainsOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}

      {#await $carryTemplatesOp}
        <div class="p-10 w-full" style="min-height: 500px">
          <LoadingContent />
        </div>
      {:then carryTemplatesResult}
        <BranchForm
          mode="add"
          on:validBranch={addBranch}
          cancelAction={cancelAdd}
          chains={result.data.chains.edges}
          edgarBranchCarryTemplates={[]}
          weekSupplyConfigurations={[]}
          carryTemplates={carryTemplatesResult.data.carryTemplates.edges}
          {loading}
          buttonLabel="Add" />
      {/await}
    {/await}

  </Panel>
</div>
