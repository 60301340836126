<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, query, mutate } from "svelte-apollo";

  import {
    EDIT_ACCOUNT,
    GET_ACCOUNT,
    INACTIVATE_ACCOUNT
  } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import AccountForm from "./AccountForm.svelte";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  const cancelUpdate = () => {
    navigateTo("accounts");
  };

  const client = getClient();

  let loading = false;

  const accountOp = query(client, {
    query: GET_ACCOUNT,
    variables: {
      code: decodeURIComponent(id)
    }
  });

  const inactivateAccount = async (event) => {
    const accountData = { ...event.detail };
    loading = true;
    try {
      await mutate(client, {
        mutation: INACTIVATE_ACCOUNT,
        variables: {
          code: accountData.code
        }
      });
      toastNotifier.success(
        "Success!",
        `Account ${accountData.code} has been deactivated`,
        7000
      );
      navigateTo("accounts");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };

  const editAccount = async (event) => {
    try {
      const accountData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: EDIT_ACCOUNT,
        variables: {
          editAccountInput: {
            code: accountData.code,
            name: accountData.name,
            version: accountData.version
          }
        }
      });
      toastNotifier.success(
        "Success!",
        `Account ${accountData.name} Updated`,
        7000
      );
      navigateTo("accounts");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Update Account">
    {#await $accountOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      <AccountForm
        mode="edit"
        code={result.data.account.code}
        name={result.data.account.name}
        version={result.data.account.version}
        on:validAccount={editAccount}
        on:inactivateAccount={inactivateAccount}
        cancelAction={cancelUpdate}
        {loading}
        buttonLabel="Update" />
    {/await}

  </Panel>
</div>
