import { gql } from "apollo-boost";

// QUERIES
const CARRYTEMPLATES_WITHSKUS = gql`
  query carryTemplates(
    $connectionArgs: ConnectionArgs
    $findCarryTemplateInput: FindCarryTemplateInput
  ) {
    carryTemplates(
      findCarryTemplateInput: $findCarryTemplateInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        node {
          id
          code
          name
          description
          skus {
            edges {
              node {
                code
                name
              }
            }
          }
          active
        }
      }
    }
  }
`;

const GET_CARRYTEMPLATE = gql`
  query carryTemplate($code: String!) {
    carryTemplate(code: $code) {
      code
      description
      name
      version
      skus {
        edges {
          node {
            code
            name
          }
        }
      }
    }
  }
`;

// MUTATIONS

const ADD_CARRYTEMPLATE = gql`
  mutation addCarryTemplate($addCarryTemplateInput: AddCarryTemplateInput!) {
    addCarryTemplate(addCarryTemplateInput: $addCarryTemplateInput) {
      id
      code
      name
      description
      active
      version
    }
  }
`;

const EDIT_CARRYTEMPLATE = gql`
  mutation editCarryTemplate($editCarryTemplateInput: EditCarryTemplateInput!) {
    editCarryTemplate(editCarryTemplateInput: $editCarryTemplateInput) {
      id
      code
      name
      description
      active
      version
      skus {
        edges {
          node {
            code
            name
          }
        }
      }
    }
  }
`;

const INACTIVATE_CARRYTEMPLATE = gql`
  mutation inactivateCarryTemplate($code: String!) {
    inactivateCarryTemplate(code: $code) {
      id
      code
      name
      description
      active
      version
      skus {
        edges {
          node {
            code
            name
          }
        }
      }
    }
  }
`;

const ACTIVATE_CARRYTEMPLATE = gql`
  mutation activateBranch($code: String!) {
    activateBranch(code: $code) {
      id
      code
      name
      baseAddress
      zip
      city
      province
      regionCode
      regionName
      coordinates {
        latitude
        longitude
      }
      chain {
        id
        code
        name
        account {
          id
          code
          name
          active
          version
        }
        active
        version
      }
      active
      version
    }
  }
`;

export {
  CARRYTEMPLATES_WITHSKUS,
  GET_CARRYTEMPLATE,
  ADD_CARRYTEMPLATE,
  EDIT_CARRYTEMPLATE,
  INACTIVATE_CARRYTEMPLATE,
  ACTIVATE_CARRYTEMPLATE
};
