<script>
  import { KeypadMenu } from "@saztrek/firefly";
  import { navigateTo } from "svelte-router-spa";
  import { currentUser } from "../../../stores/current_user";

  const roleTypeForCalendar = $currentUser.roles.find(
    (role) => role.appCode === "calendar"
  ).isAdmin;

  let items = [
    {
      label: "Week View",
      action: () => navigateTo("/calendar/weekview"),
      app: "calendar"
    },

    {
      label: "Leave Requests",
      action: () => navigateTo("/calendar/leaverequests"),
      app: "calendar"
    }
  ];

  if (roleTypeForCalendar === "admin") {
    items = [
      ...items,
      {
        label: "Branch Setup",
        action: () => navigateTo("/calendar/branchAttendance"),
        app: "calendar"
      },
      {
        label: "Sheets Setup",
        action: () => navigateTo("/calendar/branchattendancesheets"),
        app: "calendar"
      }
    ];
  }
  // {
  //   label: "Accounts",
  //   action: () => navigateTo("/accounts"),
  //   app: "edgar"
  // },
</script>

<div class="flex justify-center pt-20">
  <KeypadMenu {items} size="l" />
</div>
