<script>
  import { getClient, query, mutate } from "svelte-apollo";
  import dayjs from "dayjs";

  import { navigateTo } from "svelte-router-spa";
  import {
    Button,
    Modal,
    TextField,
    LoadingContent,
    toastNotifier
  } from "@saztrek/firefly";

  import { currentUser } from "../../../../stores/current_user";

  import {
    dateFormatToUI,
    dateFormatToServer
  } from "../../../../utilities/dateFormatter";

  import {
    GET_APPROVAL_REQUEST,
    GET_EDGAR_SHEET,
    APPROVE_REQUEST,
    REJECT_REQUEST,
    REWORK_REQUEST
  } from "../../../../gql";

  import Card from "../../../components/FlipCard.svelte";
  import SKUCardFace from "../components/EDGARSKUCardFace.svelte";
  import SKUCardBack from "../components/EDGARSKUCardBack.svelte";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  const client = getClient();

  const useRawOfftake = false;

  const approvalRequestOp = query(client, {
    query: GET_APPROVAL_REQUEST,
    fetchPolicy: "network-only",
    variables: {
      code: id
    }
  });

  let edgarSheetOp;

  let updatedSheet = {};
  let originalSheet = {};
  const mergedSheet = {
    originalSheet: {},
    updatedSheet: {}
  };

  const didDatesChange = (oldDates, newDates) => {
    if (oldDates === newDates) {
      return false;
    }

    if (oldDates.length !== newDates.length) {
      return true;
    }

    for (let i = 0; i < oldDates.length; i += 1) {
      const currentOldDate = oldDates[i];
      if (newDates.findIndex((newDate) => newDate === currentOldDate) === -1) {
        return true;
      }
    }

    return false;
  };

  const mergeSKUs = (originalSKUs, updatedSKUs) => {
    // console.log("orig", originalSKUs);
    // console.log("updated", updatedSKUs);

    let mergedSKUs = [];
    let fakeOldSKUs = [];

    if (originalSKUs.length > 0) {
      originalSKUs.forEach((originalSKU) => {
        const newSKU = updatedSKUs.find(
          (updatedSKU) => updatedSKU.details.code === originalSKU.details.code
        );

        const { details, computed, data, meta } = originalSKU;

        const oldSKUObject = {
          code: details.code,
          name: details.name,
          unitCount: details.unitCount,
          weekSupply: details.weekSupply !== null ? details.weekSupply : "N/A",
          offtakeUnits: useRawOfftake
            ? computed.rawOfftakeUnits.toFixed(2)
            : computed.offtakeUnits.toFixed(2),
          // rawOfftakeUnits: computed.rawOfftakeUnits.toFixed(2),
          deliveryContainers: data.deliveryContainers,
          deliveryUnits: data.deliveryUnits,
          totalDeliveryUnits: computed.totalDeliveryUnits,
          endingContainers: data.endingContainers,
          endingUnits: data.endingUnits,
          totalEndingUnits: computed.totalEndingUnits,
          badOrderContainers: data.badOrderContainers,
          badOrderUnits: data.badOrderUnits,
          totalBadOrderUnits: computed.totalBadOrderUnits,
          transferOutContainers: data.transferOutContainers,
          transferOutUnits: data.transferOutUnits,
          totalTransferOutUnits: computed.totalTransferOutUnits,
          transferInContainers: data.transferInContainers,
          transferInUnits: data.transferInUnits,
          totalTransferInUnits: computed.totalTransferInUnits,
          adjustmentContainers: data.adjustmentContainers,
          adjustmentUnits: data.adjustmentUnits,
          totalAdjustmentUnits: computed.totalAdjustmentUnits,
          suggestedOrderUnits: computed.suggestedOrderUnits,
          beginningContainers: meta.beginningContainers,
          beginningUnits: meta.beginningUnits,
          totalBeginningUnits:
            meta.totalBeginningUnits !== null
              ? meta.totalBeginningUnits
              : "N/A",
          noData:
            computed.totalDeliveryUnits +
              computed.totalEndingUnits +
              computed.totalBadOrderUnits +
              computed.totalTransferOutUnits +
              computed.totalTransferInUnits +
              computed.totalAdjustmentUnits ===
            0,
          hasOfftakeChanges:
            newSKU.computed.offtakeUnits !== originalSKU.computed.offtakeUnits,
          hasDeliveryChanges:
            newSKU.computed.totalDeliveryUnits !==
            originalSKU.computed.totalDeliveryUnits,
          hasEndingChanges:
            newSKU.computed.totalEndingUnits !==
            originalSKU.computed.totalEndingUnits,
          hasBadOrderChanges:
            newSKU.computed.totalBadOrderUnits !==
            originalSKU.computed.totalBadOrderUnits,
          hasTransferInChanges:
            newSKU.computed.totalTransferInUnits !==
            originalSKU.computed.totalTransferInUnits,
          hasTransferOutChanges:
            newSKU.computed.totalTransferOutUnits !==
            originalSKU.computed.totalTransferOutUnits,
          hasAdjustmentChanges:
            newSKU.computed.totalAdjustmentUnits !==
            originalSKU.computed.totalAdjustmentUnits
        };

        mergedSKUs = [
          ...mergedSKUs,
          {
            code: originalSKU.details.code,
            originalSKU: oldSKUObject,
            updatedSKU: {},
            hasChanges:
              oldSKUObject.hasDeliveryChanges ||
              oldSKUObject.hasEndingChanges ||
              oldSKUObject.hasBadOrderChanges ||
              oldSKUObject.hasTransferInChanges ||
              oldSKUObject.hasTransferOutChanges ||
              oldSKUObject.hasAdjustmentChanges
          }
        ];
      });
    } else {
      updatedSKUs.forEach((updatedSKU) => {
        const { details } = updatedSKU;
        const oldSKUObject = {
          code: details.code,
          name: details.name,
          unitCount: details.unitCount,
          weekSupply: details.weekSupply !== null ? details.weekSupply : "N/A",
          offtakeUnits: 0,
          deliveryContainers: 0,
          deliveryUnits: 0,
          totalDeliveryUnits: 0,
          endingContainers: 0,
          endingUnits: 0,
          totalEndingUnits: 0,
          badOrderContainers: 0,
          badOrderUnits: 0,
          totalBadOrderUnits: 0,
          transferOutContainers: 0,
          transferOutUnits: 0,
          totalTransferOutUnits: 0,
          transferInContainers: 0,
          transferInUnits: 0,
          totalTransferInUnits: 0,
          adjustmentContainers: 0,
          adjustmentUnits: 0,
          totalAdjustmentUnits: 0,
          suggestedOrderUnits: 0,
          beginningContainers: null,
          beginningUnits: null,
          totalBeginningUnits: "N/A",
          noData: true,
          hasDeliveryChanges: updatedSKU.computed.totalDeliveryUnits !== 0,
          hasEndingChanges: updatedSKU.computed.totalEndingUnits !== 0,
          hasBadOrderChanges: updatedSKU.computed.totalBadOrderUnits !== 0,
          hasTransferInChanges: updatedSKU.computed.totalTransferInUnits !== 0,
          hasTransferOutChanges:
            updatedSKU.computed.totalTransferOutUnits !== 0,
          hasAdjustmentChanges: updatedSKU.computed.totalAdjustmentUnits !== 0
        };

        const fakeOldSKU = JSON.parse(JSON.stringify(updatedSKU));
        fakeOldSKU.computed.totalDeliveryUnits = 0;
        fakeOldSKU.computed.totalEndingUnits = 0;
        fakeOldSKU.computed.totalBadOrderUnits = 0;
        fakeOldSKU.computed.totalTransferInUnits = 0;
        fakeOldSKU.computed.totalTransferOutUnits = 0;
        fakeOldSKU.computed.totalAdjustmentUnits = 0;

        fakeOldSKUs = [...fakeOldSKUs, fakeOldSKU];

        mergedSKUs = [
          ...mergedSKUs,
          {
            code: updatedSKU.details.code,
            originalSKU: oldSKUObject,
            updatedSKU: {},
            hasChanges:
              oldSKUObject.hasDeliveryChanges ||
              oldSKUObject.hasEndingChanges ||
              oldSKUObject.hasBadOrderChanges ||
              oldSKUObject.hasTransferInChanges ||
              oldSKUObject.hasTransferOutChanges ||
              oldSKUObject.hasAdjustmentChanges
          }
        ];
      });
    }

    updatedSKUs.forEach((updatedSKU) => {
      let oldSKU;
      if (originalSKUs.length > 0) {
        oldSKU = originalSKUs.find(
          (originalSKU) => originalSKU.details.code === updatedSKU.details.code
        );
      } else {
        oldSKU = fakeOldSKUs.find(
          (originalSKU) => originalSKU.details.code === updatedSKU.details.code
        );
      }

      const { details, computed, data, meta } = updatedSKU;

      const newSKUObject = {
        code: details.code,
        name: details.name,
        unitCount: details.unitCount,
        weekSupply: details.weekSupply !== null ? details.weekSupply : "N/A",
        offtakeUnits: useRawOfftake
          ? computed.rawOfftakeUnits.toFixed(2)
          : computed.offtakeUnits.toFixed(2),
        // rawOfftakeUnits: computed.rawOfftakeUnits.toFixed(2),
        deliveryContainers: data.deliveryContainers,
        deliveryUnits: data.deliveryUnits,
        totalDeliveryUnits: computed.totalDeliveryUnits,
        endingContainers: data.endingContainers,
        endingUnits: data.endingUnits,
        totalEndingUnits: computed.totalEndingUnits,
        badOrderContainers: data.badOrderContainers,
        badOrderUnits: data.badOrderUnits,
        totalBadOrderUnits: computed.totalBadOrderUnits,
        transferOutContainers: data.transferOutContainers,
        transferOutUnits: data.transferOutUnits,
        totalTransferOutUnits: computed.totalTransferOutUnits,
        transferInContainers: data.transferInContainers,
        transferInUnits: data.transferInUnits,
        totalTransferInUnits: computed.totalTransferInUnits,
        adjustmentContainers: data.adjustmentContainers,
        adjustmentUnits: data.adjustmentUnits,
        totalAdjustmentUnits: computed.totalAdjustmentUnits,
        suggestedOrderUnits: computed.suggestedOrderUnits,
        beginningContainers: meta.beginningContainers,
        beginningUnits: meta.beginningUnits,
        totalBeginningUnits:
          meta.totalBeginningUnits !== null ? meta.totalBeginningUnits : "N/A",
        noData:
          computed.totalDeliveryUnits +
            computed.totalEndingUnits +
            computed.totalBadOrderUnits +
            computed.totalTransferOutUnits +
            computed.totalTransferInUnits +
            computed.totalAdjustmentUnits ===
          0,
        hasOfftakeChanges:
          oldSKU.computed.offtakeUnits !== updatedSKU.computed.offtakeUnits,
        hasDeliveryChanges:
          oldSKU.computed.totalDeliveryUnits !==
          updatedSKU.computed.totalDeliveryUnits,
        hasEndingChanges:
          oldSKU.computed.totalEndingUnits !==
          updatedSKU.computed.totalEndingUnits,
        hasBadOrderChanges:
          oldSKU.computed.totalBadOrderUnits !==
          updatedSKU.computed.totalBadOrderUnits,
        hasTransferInChanges:
          oldSKU.computed.totalTransferInUnits !==
          updatedSKU.computed.totalTransferInUnits,
        hasTransferOutChanges:
          oldSKU.computed.totalTransferOutUnits !==
          updatedSKU.computed.totalTransferOutUnits,
        hasAdjustmentChanges:
          oldSKU.computed.totalAdjustmentUnits !==
          updatedSKU.computed.totalAdjustmentUnits
      };

      const skuToMerge = mergedSKUs.find(
        (skuObj) => skuObj.code === updatedSKU.details.code
      );
      skuToMerge.updatedSKU = newSKUObject;
    });

    mergedSKUs = mergedSKUs.sort((a, b) => b.hasChanges - a.hasChanges);

    return mergedSKUs;
  };

  const mergeSheets = () => {
    mergedSheet.code = originalSheet.code;
    mergedSheet.originalInventoryDates = originalSheet.inventoryDates;
    mergedSheet.updatedInventoryDates = updatedSheet.inventoryDates;

    mergedSheet.inventoryDatesChanged = didDatesChange(
      originalSheet.inventoryDates,
      updatedSheet.inventoryDates
    );

    mergedSheet.originalDeliveryDates = originalSheet.deliveryDates;
    mergedSheet.updatedDeliveryDates = updatedSheet.deliveryDates;
    mergedSheet.deliveryDatesChanged = didDatesChange(
      originalSheet.deliveryDates,
      updatedSheet.deliveryDates
    );

    mergedSheet.skus = mergeSKUs(
      originalSheet.skus.edges.map((edge) => {
        const { node } = edge;
        return { ...node };
      }),
      updatedSheet.skus
    );
  };

  let loading = true;
  let correctionStatus = "PENDING";
  let remarks = "";

  $approvalRequestOp.then((response) => {
    const { data } = response;
    const { approvalRequest } = data;
    const subData = approvalRequest.data;

    const edgarSheetCode = subData.find(
      (datum) => datum.key === "edgarSheetCode"
    ).value;
    updatedSheet = JSON.parse(
      subData.find((datum) => datum.key === "updatedEDGARSheet").value
    );

    // clean up dates

    updatedSheet.deliveryDates = updatedSheet.deliveryDates.map(
      (deliveryDate) => dateFormatToServer(dayjs(deliveryDate).toDate())
    );
    updatedSheet.inventoryDates = updatedSheet.inventoryDates.map(
      (inventoryDate) => dateFormatToServer(dayjs(inventoryDate).toDate())
    );

    remarks = subData.find((datum) => datum.key === "remarks").value;

    correctionStatus = approvalRequest.status;

    mergedSheet.correctionCode = approvalRequest.code;
    edgarSheetOp = query(client, {
      query: GET_EDGAR_SHEET,
      fetchPolicy: "network-only",
      variables: {
        code: edgarSheetCode
      }
    });

    $edgarSheetOp.then((originalSheetResponse) => {
      const edgarSheetData = originalSheetResponse.data;
      const { edgarSheet } = edgarSheetData;
      originalSheet = edgarSheet;

      mergeSheets();
      loading = false;
    });
  });

  const flipACard = (cardToFlipClassName) => {
    const updatedCardElement = document.querySelector(cardToFlipClassName);
    const parentCard = updatedCardElement.parentNode.parentNode;
    parentCard.classList.toggle("is-flipped");
  };

  const originalCardFlipped = (code) => {
    const cardToFlipClassName = `.faceUpdated${code}`;
    flipACard(cardToFlipClassName);
  };

  const updatedCardFlipped = (code) => {
    const cardToFlipClassName = `.faceOriginal${code}`;
    flipACard(cardToFlipClassName);
  };

  let reworkRemarks = "";
  let rejectRemarks = "";

  const triggerComponent = Button;
  const triggerPropsApprove = {
    text: "Approve",
    color: "secondary",
    filled: true,
    loading: false
  };

  const triggerPropsRework = {
    text: "Rework",
    color: "warning",
    filled: true,
    loading: false
  };
  const triggerPropsReject = {
    text: "Reject",
    color: "danger",
    filled: true,
    loading: false
  };

  const approveRequest = async () => {
    try {
      triggerPropsRework.disabled = true;
      triggerPropsReject.disabled = true;
      triggerPropsApprove.loading = true;

      await mutate(client, {
        mutation: APPROVE_REQUEST,
        variables: {
          approverEmployeeCode: $currentUser.id,
          code: id
        }
      });

      toastNotifier.success(
        "Approved",
        `Request ${mergedSheet.code} has been approved`,
        7000
      );

      triggerPropsRework.disabled = false;
      triggerPropsReject.disabled = false;
      triggerPropsApprove.loading = false;

      navigateTo("/");
    } catch (error) {
      triggerPropsRework.disabled = false;
      triggerPropsReject.disabled = false;
      triggerPropsApprove.loading = false;
      toastNotifier.danger("Failed to change request status", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };

  const reworkRequest = async () => {
    try {
      triggerPropsRework.loading = true;
      triggerPropsReject.disabled = true;
      triggerPropsApprove.disabled = true;

      await mutate(client, {
        mutation: REWORK_REQUEST,
        variables: {
          approverEmployeeCode: $currentUser.id,
          code: id,
          remarks: reworkRemarks
        }
      });

      toastNotifier.success(
        "Request for Rework Sent",
        `Rework request for ${mergedSheet.code} sent`,
        7000
      );

      triggerPropsRework.loading = false;
      triggerPropsReject.disabled = false;
      triggerPropsApprove.disabled = false;

      navigateTo("/");
    } catch (error) {
      triggerPropsRework.loading = false;
      triggerPropsReject.disabled = false;
      triggerPropsApprove.disabled = false;
      toastNotifier.danger("Failed to change request status", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };

  const rejectRequest = async () => {
    try {
      triggerPropsRework.disabled = true;
      triggerPropsReject.loading = true;
      triggerPropsApprove.disabled = true;

      await mutate(client, {
        mutation: REJECT_REQUEST,
        variables: {
          approverEmployeeCode: $currentUser.id,
          code: id,
          remarks: rejectRemarks
        }
      });

      toastNotifier.success(
        "Rejected",
        `Request ${mergedSheet.code} has been rejected`,
        7000
      );
      triggerPropsRework.disabled = false;
      triggerPropsReject.loading = false;
      triggerPropsApprove.disabled = false;

      navigateTo("/");
    } catch (error) {
      triggerPropsRework.disabled = false;
      triggerPropsReject.loading = false;
      triggerPropsApprove.disabled = false;
      toastNotifier.danger("Failed to change request status", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="h-full w-full shadow rounded py-4 px-8">
  {#if !loading}
    <div class="w-full flex flex-row justify-between pb-4">
      <div
        class="w-1/2 flex flex-row justify-between text-sm font-bold
        tracking-wide">
        <div class="flex flex-col w-1/2">
          <div class="text-primary text-xs">Sheet Code</div>
          <div class="tracking-wider">{mergedSheet.code}</div>
        </div>
        <div class="flex flex-col w-1/2">
          <div class="text-primary text-xs">Correction Code</div>
          <div class="tracking-wider">{mergedSheet.correctionCode}</div>
        </div>
      </div>
      <div class="w-1/2 flex flex-row justify-end">
        <div class="w-1/2 flex flex-row justify-center">
          <div class="w-full flex flex-col">
            <div class="text-primary text-xs font-bold">Remarks</div>
            <div>{remarks}</div>
          </div>
        </div>
        {#if correctionStatus === 'PENDING'}
          <div class="w-1/2 flex flex-row justify-center">
            <div class="px-1">
              <Modal
                {triggerComponent}
                triggerProps={triggerPropsApprove}
                title="Approve Request"
                on:confirm={approveRequest}
                cancelButtonText="Cancel"
                confirmButtonText="Approve"
                buttonColor="secondary">
                <p class="mb-6">
                  You are about to approve {mergedSheet.correctionCode}.
                </p>
                <p class="mb-6">Are you sure you want to do this?</p>
              </Modal>
            </div>
            <div class="px-1">
              <Modal
                {triggerComponent}
                triggerProps={triggerPropsRework}
                title="Rework Request"
                on:confirm={reworkRequest}
                cancelButtonText="Cancel"
                confirmButtonText="Rework"
                buttonColor="warning">
                <p class="mb-6">
                  You are about to request {mergedSheet.correctionCode} for
                  rework.
                </p>

                <div class="h-24">
                  <TextField
                    bind:textValue={reworkRemarks}
                    required={true}
                    label="Remarks" />
                </div>

              </Modal>

            </div>
            <div class="px-1">
              <Modal
                {triggerComponent}
                triggerProps={triggerPropsReject}
                title="Reject Request"
                on:confirm={rejectRequest}
                cancelButtonText="Cancel"
                confirmButtonText="Reject"
                buttonColor="danger">
                <p class="mb-6">
                  You are about to reject {mergedSheet.correctionCode}.
                </p>
                <p class="mb-6">Are you sure you want to do this?</p>

                <div class="h-24">
                  <TextField
                    bind:textValue={rejectRemarks}
                    required={true}
                    label="Remarks" />
                </div>

              </Modal>
            </div>
          </div>
        {/if}

      </div>

    </div>

    <div
      class="w-full flex flex-row justify-between pb-4 text-xs tracking-wider">
      <div class="w-1/2 flex flex-row justify-between">
        <div class="w-1/2 flex flex-row">
          <div class="w-full text-primary font-bold">Inventory Dates</div>
          <div class="w-1/2 flex flex-col">
            <div class="italic">Before</div>
            {#if mergedSheet.originalInventoryDates}
              {#each mergedSheet.originalInventoryDates as originalInventoryDate}
                <div
                  class:bg-highlightYellow={mergedSheet.updatedInventoryDates.findIndex((item) => dateFormatToUI(item) === dateFormatToUI(originalInventoryDate)) === -1}>
                  {dateFormatToUI(originalInventoryDate)}
                </div>
              {/each}
            {/if}
          </div>
          <div class="w-1/2 flex flex-col">
            <div class="italic">After</div>
            {#if mergedSheet.updatedInventoryDates}
              {#each mergedSheet.updatedInventoryDates as updatedInventoryDate}
                <div
                  class:bg-highlightYellow={mergedSheet.originalInventoryDates.findIndex((item) => dateFormatToUI(item) === dateFormatToUI(updatedInventoryDate)) === -1}>
                  {dateFormatToUI(updatedInventoryDate)}
                </div>
              {/each}
            {/if}
          </div>
        </div>
        <div class="w-1/2 flex flex-row">
          <div class="w-full text-primary font-bold">Delivery Dates</div>
          <div class="w-1/2 flex flex-col">
            <div class="italic">Before</div>
            {#if mergedSheet.originalDeliveryDates}
              {#each mergedSheet.originalDeliveryDates as originalDeliveryDate}
                <div
                  class:bg-highlightYellow={mergedSheet.updatedDeliveryDates.findIndex((item) => dateFormatToUI(item) === dateFormatToUI(originalDeliveryDate)) === -1}>
                  {dateFormatToUI(originalDeliveryDate)}
                </div>
              {/each}
            {/if}
          </div>
          <div class="w-1/2 flex flex-col">
            <div class="italic">After</div>
            {#if mergedSheet.updatedDeliveryDates}
              {#each mergedSheet.updatedDeliveryDates as updatedDeliveryDate}
                <div
                  class:bg-highlightYellow={mergedSheet.originalDeliveryDates.findIndex((item) => dateFormatToUI(item) === dateFormatToUI(updatedDeliveryDate)) === -1}>
                  {dateFormatToUI(updatedDeliveryDate)}
                </div>
              {/each}
            {/if}
          </div>
        </div>
      </div>

    </div>
    <div
      class="w-full overflow-y-auto shadow rounded p-6"
      style="min-height: 800px; max-height: 800px">

      <div class="grid grid-cols-1 xl:grid-cols-2 w-full px-1 h-full">
        {#if mergedSheet.skus && mergedSheet.skus.length > 0}
          {#each mergedSheet.skus as mergedSKU, i}
            <div class="flex flex-row py-2 px-6">
              <div class="w-5/12">
                <Card
                  on:cardFlipped={originalCardFlipped(mergedSKU.code)}
                  cardStatus={mergedSKU.noData ? 'warning' : 'healthy'}>
                  <div
                    slot="face"
                    class={`faceOriginal${mergedSKU.code} h-full`}>
                    <SKUCardFace sku={mergedSKU.originalSKU} />
                  </div>
                  <div slot="back" class="h-full">
                    <SKUCardBack sku={mergedSKU.originalSKU} />
                  </div>
                </Card>
              </div>

              <div
                class="w-2/12 border-dotted border-b-4 flex flex-row items-end
                justify-center text-xs font-bold mx-1"
                class:text-danger={mergedSKU.hasChanges}
                class:text-secondary={!mergedSKU.hasChanges}
                style="height: 50%;">
                {#if mergedSKU.hasChanges}changed to{:else}no changes{/if}
              </div>

              <div class="w-5/12">
                <Card
                  on:cardFlipped={updatedCardFlipped(mergedSKU.code)}
                  cardStatus={mergedSKU.noData ? 'warning' : 'healthy'}>
                  <div
                    slot="face"
                    class={`faceUpdated${mergedSKU.code} h-full`}>
                    <SKUCardFace sku={mergedSKU.updatedSKU} />
                  </div>
                  <div slot="back" class="h-full">
                    <SKUCardBack sku={mergedSKU.updatedSKU} />
                  </div>
                </Card>
              </div>

            </div>
          {/each}
        {/if}
      </div>
    </div>
  {:else}
    <div
      class="w-full flex flex-col justify-center"
      style="min-height: 800px; max-height: 800px">
      <div class="w-full">
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
      </div>
    </div>
  {/if}
</div>
