<script>
  import { Button } from "@saztrek/firefly";

  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  const updateSearch = () => {
    dispatch("updateSearch");
  };

  const resetSearch = () => {
    dispatch("resetSearch");
  };
</script>

<div class="w-full">
  <form on:submit|preventDefault={updateSearch}>
    <div
      class="p-4 flex flex-row items-start content-start w-full shadow rounded">
      <div class="flex flex-row w-full">
        <div class="w-11/12">
          <slot name="formelements" />
        </div>
        <div class="w-1/12 flex flex-row justify-center">

          <Button color="primary" type="submit" icon="search" />
          <Button color="primary" icon="close" on:click={resetSearch} />

        </div>

      </div>
    </div>
  </form>
</div>
