<script>
  import { AvatarIcon, EmptyButton, toastNotifier } from "@saztrek/firefly";
  import { Auth } from "../../../config/firebase";
  import { currentUser } from "../../../stores/current_user";

  const sessionLogout = async () => {
    const url = "https://mermade.saztrek.app/sessionLogout";
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      redirect: "follow",
      "Access-Control-Allow-Credentials": true,
      headers: {
        "Content-Type": "application/json"
      },
      referrerPolicy: "no-referrer"
    });
    return response.json();
  };

  const logoutUser = async () => {
    try {
      await sessionLogout();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("failed to delete session");
    }
    Auth.signOut().then(() => {
      toastNotifier.success(
        "Logout Sucess",
        "You've been logged out successfully.",
        7000
      );

      currentUser.set(null);
    });
  };
</script>

<div class="flex flex-row ">
  <div class="text-center">
    <AvatarIcon size="xl" firstName="Jomel" lastName="Inovejas" />
  </div>
  <div class="flex-col flex text-left">
    <div class="px-6">{$currentUser.displayName}</div>
    <div class="flex-row flex">
      <EmptyButton text="Edit Profile" />
      <EmptyButton text="Logout" on:click={logoutUser} />
    </div>
  </div>
</div>
