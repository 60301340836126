const TENANT_VARIABLES = {
  MOBILE_LOGIN_EXPIRY_MINUTES: "mobile_loginExpiryMinutes",
  EDGAR_COORDINATOR_COMPLIANCE_LIMIT:
    "EDGAR_mobile_edgarCoordinatorComplianceSheetContextLimit",
  EDGAR_DEFAULT_INPUT_PERCENTAGE_THRESHOLD:
    "EDGAR_defaultInputPercentageComplianceThreshold",
  EDGAR_WEEK_SUPPLY_DEFAULT: "EDGAR_weekSupplyDefault",
  EDGAR_START_DAY: "EDGAR_startDay"
};

const FEATURE_FLAGS = {
  EDGAR_DISPLAY_DERIVED_VALUE_IN_CONTAINERS:
    "EDGAR_mobile_displayDerivedValueInContainers",
  EDGAR_USE_BEGINNING_AS_ENDING_FOR_GENERATED_SHEET:
    "EDGAR_generatedSheetUseBeginningAsEnding",
  EDGAR_IS_OOS_OFFTAKE: "EDGAR_isOOSOfftake",
  DON_MOBILE_IS_DEFAULT_IN_STOCK: "DON_mobile_isDefaultInStock",
  EDGAR_IS_DAILY: "EDGAR_isDaily",
  EDGAR_MOBILE_SHOW_CONTAINERS: "EDGAR_mobile_showContainers",
  EDGAR_MOBILE_SHOW_UNITS: "EDGAR_mobile_showUnits",
  EDGAR_MOBILE_SHOW_BAD_ORDER_CONTAINERS: "EDGAR_mobile_showBadOrderContainers",
  EDGAR_MOBILE_SHOW_BAD_ORDER_UNITS: "EDGAR_mobile_showBadOrderUnits",
  EDGAR_MOBILE_INPUT_OFFTAKE: "EDGAR_mobile_inputOfftake",
  EDGAR_MOBILE_HIDE_ADJUSTMENT_CONTAINERS:
    "EDGAR_mobile_hideAdjustmentContainers",
  EDGAR_MOBILE_HIDE_ADJUSTMENT_UNITS: "EDGAR_mobile_hideAdjustmentUnits",
  EDGAR_MOBILE_SKIP_NEGATIVE_VALIDATION:
    "EDGAR_mobile_skipNegativeOfftakeValidation"
};

module.exports = { TENANT_VARIABLES, FEATURE_FLAGS };
