import { Firestore, FirebaseStorage } from "../../config/firebase";
import FirebaseResults from "./firebase_results";

const FirebaseUsers = Firestore.collection("users");
const FirebaseTenants = Firestore.collection("tenants");
const FirebaseUsersToReset = Firestore.collection("usersToReset");
const FirebasePS3 = Firestore.collection("ps3");
const FirebaseUploadJobs = Firestore.collection("uploadJobs");

export {
  FirebaseUsers,
  FirebaseResults,
  FirebaseTenants,
  FirebaseUsersToReset,
  FirebasePS3,
  FirebaseStorage,
  FirebaseUploadJobs
};
