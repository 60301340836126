<script>
  import { navigateTo } from "svelte-router-spa";
  import { Link, Button } from "@saztrek/firefly";
  import { BRANCH_ATTENDANCE_SHEETS_LITE } from "../../../../gql";
  import { dateFormatToUI } from "../../../../utilities/dateFormatter";

  import CRUDPage from "../../../components/CRUDPage.svelte";

  const columns = [
    {
      field: "date",
      name: "Date",
      renderComponent: Link,
      resolveProps: (data) => {
        return {
          href: `branchattendancesheets/edit/${data.startDate},${data.endDate}`
        };
      }
    },
    {
      field: "branchCode",
      name: "Branch Code"
    },
    {
      field: "branchName",
      name: "Branch Name"
    }
  ];

  const processResponse = (response) => {
    const processEdges = (edges) => {
      return edges.map((edge) => {
        const { node } = edge;
        const { branch } = node;
        return {
          date: dateFormatToUI(node.date),
          branchCode: branch.code,
          branchName: branch.name
        };
      });
    };

    const { data } = response;
    const { calendarBranchAttendanceSheets } = data;
    const { edges } = calendarBranchAttendanceSheets;

    return {
      items: processEdges(edges),
      currentData: calendarBranchAttendanceSheets
    };
  };

  const gotoAddSCalendarSheet = () => {
    navigateTo("/calendar/branchattendancesheets/new");
  };

  const gotoRebuild = () => {
    navigateTo("/calendar/branchattendancesheets/rebuild");
  };
</script>

<div class="w-full px-10">
  <CRUDPage
    gqlQuery={BRANCH_ATTENDANCE_SHEETS_LITE}
    {columns}
    {processResponse}
    goToAdd={gotoAddSCalendarSheet}>
    <div slot="actions" class="pr-2">
      <Button color="secondary" text="Rebuild" on:click={gotoRebuild} />
    </div>

  </CRUDPage>
</div>
