<script>
  import dayjs from "dayjs";
  import { getClient, query } from "svelte-apollo";
  import { LoadingContent, EmptyButton, Icon, Button } from "@saztrek/firefly";

  import { EDGAR_SHEET_CONTEXT_COMPLIANCE } from "../../../../gql";

  import { exportToCSV } from "../../../../utilities/export";

  import {
    dateFormatToServer,
    dateFormatToUI
  } from "../../../../utilities/dateFormatter";

  import BranchCard from "./BranchCard.svelte";

  import { currentTenant } from "../../../../stores/current_tenant";

  import { COMPLIANCE_STRINGS } from "./StringConstants";

  import {
    nearestStartDay,
    nearestEndDay,
    getNearestEndDay,
    getNearestStartDay
  } from "../edgarUtils";

  let currentStartDay = nearestStartDay;
  let currentEndDay = nearestEndDay;

  let daysToIncrement = 7;
  let isDaily = false;
  let tenantVarsLoaded = false;

  let sheetContextsComplianceOp;

  const client = getClient();

  $: if ($currentTenant.tenantVariables && !tenantVarsLoaded) {
    daysToIncrement = $currentTenant.featureFlags.find(
      (flag) => flag.code === "EDGAR_isDaily"
    ).value
      ? 1
      : 7;

    isDaily = $currentTenant.featureFlags.find(
      (flag) => flag.code === "EDGAR_isDaily"
    ).value;
    currentStartDay = !isDaily ? getNearestStartDay() : dayjs();
    currentEndDay = !isDaily ? getNearestEndDay() : currentStartDay;
    tenantVarsLoaded = true;

    sheetContextsComplianceOp = query(client, {
      query: EDGAR_SHEET_CONTEXT_COMPLIANCE,
      fetchPolicy: "network-only",
      variables: {
        enrolled: true,
        startDate: dateFormatToServer(currentStartDay.toDate()),
        endDate: dateFormatToServer(currentEndDay.toDate())
      }
    });
  }

  let loading = true;

  let branchComplianceList = [];

  const processResponse = (response) => {
    const { data } = response;
    const { edgarSheetContext } = data;

    if (!edgarSheetContext) {
      branchComplianceList = [];
      return;
    }

    const { compliance } = edgarSheetContext;

    branchComplianceList = compliance.map((branchCompliance) => {
      const { edgarSheet } = branchCompliance;

      const latestCollaborator = edgarSheet
        ? edgarSheet.latestCollaborator
        : null;

      const complianceObject = {
        code: branchCompliance.branch.code,
        name: branchCompliance.branch.name,
        compliant: branchCompliance.compliant,
        enrolled: branchCompliance.enrolled,
        complianceThreshold:
          branchCompliance.inputPercentageComplianceThreshold,
        chainCode: branchCompliance.branch.chain.code,
        chainName: branchCompliance.branch.chain.name
      };
      const edgarSheetObject = {
        sheetCode: edgarSheet ? edgarSheet.code : null,
        inputPercentage: edgarSheet ? edgarSheet.meta.inputPercentage : 0,
        complete: edgarSheet ? edgarSheet.complete : false,
        noDelivery: edgarSheet ? edgarSheet.meta.noDeliveryCount : 0,
        noEnding: edgarSheet ? edgarSheet.meta.noEndingCount : 0,
        withAdjustment: edgarSheet ? edgarSheet.meta.hasAdjustmentCount : 0,
        latestCollaborator:
          latestCollaborator && latestCollaborator.name
            ? `${latestCollaborator.name.lastName}, ${latestCollaborator.name.firstName}`
            : "None"
      };

      return {
        ...complianceObject,
        ...edgarSheetObject
      };
    });
  };

  $: if (tenantVarsLoaded) {
    $sheetContextsComplianceOp.then((response) => {
      processResponse(response);

      loading = false;
    });
  }

  const refetchQueries = () => {
    sheetContextsComplianceOp
      .refetch({
        startDate: dateFormatToServer(currentStartDay.toDate()),
        endDate: dateFormatToServer(currentEndDay.toDate())
      })
      .then((response) => {
        processResponse(response);
        loading = false;
      });
  };

  const previousWeek = () => {
    currentEndDay = currentEndDay.subtract(daysToIncrement, "day");
    currentStartDay = currentStartDay.subtract(daysToIncrement, "day");
    loading = true;
    refetchQueries();
  };

  const nextWeek = () => {
    currentEndDay = currentEndDay.add(daysToIncrement, "day");
    currentStartDay = currentStartDay.add(daysToIncrement, "day");
    loading = true;
    refetchQueries();
  };

  const exportCompliance = () => {
    const cols = [
      COMPLIANCE_STRINGS.BRANCH_CODE,
      COMPLIANCE_STRINGS.BRANCH_NAME,
      COMPLIANCE_STRINGS.CHAIN_CODE,
      COMPLIANCE_STRINGS.CHAIN_NAME,
      COMPLIANCE_STRINGS.MERCH_NAME,
      COMPLIANCE_STRINGS.COMPLETE,
      COMPLIANCE_STRINGS.NO_DELIVERY,
      COMPLIANCE_STRINGS.NO_ENDING,
      COMPLIANCE_STRINGS.WITH_ADJUSTMENT,
      COMPLIANCE_STRINGS.INPUT_PERCENTAGE
    ]
      .join(",")
      .concat("\n");

    const rows = branchComplianceList
      .map(
        (branchCompliance) =>
          `"${branchCompliance.code}","${branchCompliance.name}","${
            branchCompliance.chainCode
          }","${branchCompliance.chainName}","${
            branchCompliance.latestCollaborator
              ? branchCompliance.latestCollaborator
              : "None"
          }","${branchCompliance.complete ? "YES" : "NO"}","${
            branchCompliance.noDelivery
          }","${branchCompliance.noEnding}","${
            branchCompliance.withAdjustment
          }","${(branchCompliance.inputPercentage * 100).toFixed(2)}%"`
      )
      .join("\r\n");

    exportToCSV(
      cols,
      rows,
      `EDGAR Compliance for ${dateFormatToUI(
        currentStartDay
      )} to ${dateFormatToUI(currentEndDay)}`
    );
  };
</script>

<div class="w-full h-full py-4 px-8">

  <div
    class="w-full overflow-y-auto shadow rounded p-6"
    style="min-height: 900px; max-height: 900px">

    <div
      class="m-0 text-base flex flex-row items-center justify-between w-full">
      <div class="w-2/3 flex flex-row justify-end">
        <div>
          <EmptyButton
            icon="arrowLeft"
            text=""
            size="tiny"
            on:click={previousWeek} />
        </div>
        <div class="w-56 text-center">
          {#if !isDaily}
            {dateFormatToUI(currentStartDay.toDate())} to {dateFormatToUI(currentEndDay.toDate())}
          {:else}{dateFormatToUI(currentStartDay.toDate())}{/if}
        </div>
        <div>
          <EmptyButton
            icon="arrowRight"
            text=""
            size="tiny"
            on:click={nextWeek} />
        </div>
      </div>

      {#if branchComplianceList.length > 0}
        <div class="flex px-4 self-end">
          <Button text="Export" on:click={exportCompliance} color="primary" />
        </div>
      {/if}
    </div>
    {#if !loading}
      {#if branchComplianceList.length === 0}
        <div
          class="h-full w-full flex flex-col items-center justify-center pt-20">
          <Icon size="l" type="table" />
          <div class="pt-2">No Sheet Context yet for this coverage.</div>
        </div>
      {:else}
        <div class="pt-4 grid grid-cols-1 xl:grid-cols-4 w-full px-1 h-full">

          {#each branchComplianceList as branchCompliance}
            <BranchCard
              code={branchCompliance.code}
              name={branchCompliance.name}
              compliant={branchCompliance.compliant}
              inputPercentage={branchCompliance.inputPercentage}
              complianceThreshold={branchCompliance.complianceThreshold}
              complete={branchCompliance.complete}
              noDelivery={branchCompliance.noDelivery}
              noEnding={branchCompliance.noEnding}
              withAdjustment={branchCompliance.withAdjustment}
              latestCollaborator={branchCompliance.latestCollaborator} />
          {/each}

        </div>
      {/if}
    {:else}
      <div class="pt-4 h-full w-full flex flex-col justify-center">
        <div class="w-full">
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
        </div>
      </div>
    {/if}
  </div>

</div>
