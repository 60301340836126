<script>
  import { getClient, query, mutate } from "svelte-apollo";
  import { navigateTo } from "svelte-router-spa";
  import {
    LoadingContent,
    Button,
    TextField,
    SelectField,
    toastNotifier
  } from "@saztrek/firefly";
  import {
    BRANCH_ATTENDANCE_CONTEXTS,
    ALL_EMPLOYEES,
    UPDATE_BRANCH_ATTENDANCE_CONTEXT
  } from "../../../../gql";

  import NodeTable from "./NodeBranchAttendanceContext.svelte";

  const connectionArgs = {
    first: 10,
    after: "",
    before: ""
  };

  const findCalendarBranchAttendanceContextInput = {
    code: undefined,
    name: undefined,
    version: undefined
  };

  const gotoAddBranchAttendanceContext = () => {
    navigateTo("/calendar/branchattendance/new");
  };

  const goToNextPage = (keyAfter) => {
    connectionArgs.before = "";
    connectionArgs.after = keyAfter;
  };

  const goToPreviousPage = (keyBefore) => {
    connectionArgs.after = "";
    connectionArgs.before = keyBefore;
  };

  const client = getClient();

  const branchAttendanceContextsOp = query(client, {
    query: BRANCH_ATTENDANCE_CONTEXTS,
    variables: { connectionArgs, findCalendarBranchAttendanceContextInput }
  });

  const allEmployeesOp = query(client, {
    query: ALL_EMPLOYEES
  });

  let code;
  let name;

  let loading = false;

  $: {
    loading = true;
    branchAttendanceContextsOp
      .refetch({
        connectionArgs,
        findCalendarBranchAttendanceContextInput
      })
      .then(() => {
        loading = false;
      });
  }

  const resetSearch = () => {
    code = "";
    name = "";
    findCalendarBranchAttendanceContextInput.code = undefined;
    findCalendarBranchAttendanceContextInput.name = undefined;
  };

  const updateSearch = () => {
    findCalendarBranchAttendanceContextInput.code =
      code !== "" ? code : undefined;
    findCalendarBranchAttendanceContextInput.name =
      name !== "" ? name : undefined;
  };

  const selectOptions = [
    {
      option: "Active",
      value: "true"
    },
    {
      option: "Inactive",
      value: "false"
    }
  ];

  let isActiveList = true;
  let currentBranchCode = "";
  let currentBranchEmployees = [];

  let requiredHeadCount = 0;
  let hasAbsencePenalty = false;

  let allCurrentBranches = [];

  const getEmployeesOfBranch = (branchCode) => {
    const currentBranchContext = allCurrentBranches.find(
      (branchContext) => branchContext.node.branch.code === branchCode
    );
    if (currentBranchContext) {
      currentBranchEmployees = currentBranchContext.node.employees;
      requiredHeadCount = currentBranchContext.node.policy.requiredHeadCount;
      hasAbsencePenalty = currentBranchContext.node.policy.hasAbsencePenalty;
    } else {
      currentBranchEmployees = [];
    }

    return currentBranchEmployees;
  };

  const branchCodeSelected = (event) => {
    const branchCodeData = { ...event.detail };
    currentBranchCode = branchCodeData.branchCode;
    currentBranchEmployees = getEmployeesOfBranch(currentBranchCode);
  };

  const processEdges = (edges) => {
    allCurrentBranches = edges;
    return edges;
  };

  const processEmployees = (edges) => {
    return edges
      .filter(
        (edge) =>
          currentBranchEmployees.findIndex(
            (currentEmp) => currentEmp.code === edge.node.code
          ) < 0
      )
      .map((edge) => {
        return {
          employeeNumber: edge.node.employeeNumber,
          name: edge.node.name,
          code: edge.node.code
        };
      });
  };
  let allEmps = [];

  const addToCurrentBranch = (employeeLocal) => {
    allEmps = allEmps.filter(
      (employee) => employee.code !== employeeLocal.code
    );
    currentBranchEmployees = [...currentBranchEmployees, employeeLocal];
  };

  const removeFromCurrentBranch = (employeeLocal) => {
    allEmps = [...allEmps, employeeLocal];
    currentBranchEmployees = currentBranchEmployees.filter(
      (employee) => employee.code !== employeeLocal.code
    );
  };

  const absenceOptions = [
    {
      option: "Yes",
      value: true
    },
    {
      option: "No",
      value: false
    }
  ];

  let buttonLoading = false;
  const updateBranchContext = async () => {
    if (currentBranchCode) {
      buttonLoading = true;
      try {
        await mutate(client, {
          mutation: UPDATE_BRANCH_ATTENDANCE_CONTEXT,
          variables: {
            setCalendarBranchAttendanceContextInput: {
              branchCode: currentBranchCode,
              employeeCodes: currentBranchEmployees.map((emp) => emp.code),
              policy: {
                requiredHeadCount,
                hasAbsencePenalty
              }
            }
          }
        });
        buttonLoading = false;
        toastNotifier.success(
          "Success!",
          `Branch Attendance ${currentBranchCode} Updated`,
          7000
        );
        navigateTo("branchAttendance");
      } catch (error) {
        buttonLoading = false;
        toastNotifier.danger("Failed to update.", `${error}`, 7000);
        // eslint-disable-next-line no-console
        console.error("cannot mutate", error.message);
      }
    }
  };
</script>

<div class="flex flex-col items-center pt-8">
  <div class="self-end pr-10 pb-4 ">
    <Button
      color="primary"
      filled="true"
      text="New"
      on:click={gotoAddBranchAttendanceContext} />
  </div>
  <div class="px-10 w-full">
    <form on:submit|preventDefault={updateSearch}>
      <div
        class="p-4 flex flex-row items-start content-start w-full shadow rounded">

        <div class="w-1/3 pr-2">
          <TextField type="search" placeholder="Code" bind:textValue={code} />
        </div>
        <div class="w-1/3 pr-2">
          <TextField type="search" placeholder="Name" bind:textValue={name} />
        </div>
        <div class="w-1/3 pr-2">
          <SelectField options={selectOptions} bind:value={isActiveList} />
        </div>

        <div class="flex flex-row-reverse">
          <Button color="primary" icon="search" />
          <Button color="primary" icon="close" on:click={resetSearch} />
        </div>

      </div>
    </form>
  </div>
  <div class="pt-4 w-full">
    <div class="w-full">
      {#await $branchAttendanceContextsOp}
        <div class="px-10 w-full">
          <LoadingContent />
        </div>
      {:then result}
        <div class="w-full px-10">
          <div class="w-full rounded shadow px-10" style="min-height: 400px">
            {#if loading}
              <div class="w-full pt-10">
                <LoadingContent />
                <div class="pt-10" />
                <LoadingContent />
                <div class="pt-10" />
                <LoadingContent />
              </div>
            {:else}
              <div class="pb-2">
                <NodeTable
                  edges={processEdges(result.data.calendarBranchAttendanceContexts.edges)}
                  on:branchCodeSelected={branchCodeSelected} />
              </div>
            {/if}
          </div>
        </div>
        <div class="pt-2 px-10">
          <!-- disabled={!result.data.accounts.pageInfo.hasPreviousPage}-->
          <Button
            icon="arrowLeft"
            color="primary"
            on:click={() => {
              goToPreviousPage(result.data.accounts.pageInfo.startCursor);
            }} />
          <!-- disabled={!result.data.accounts.pageInfo.hasNextPage} -->
          <Button
            icon="arrowRight"
            color="primary"
            on:click={() => {
              goToNextPage(result.data.accounts.pageInfo.endCursor);
            }} />

        </div>
        {#if currentBranchCode}
          <div class="flex flex-col px-10">
            <div class="text-right pb-4">
              <Button
                color="primary"
                text="Update"
                loading={buttonLoading}
                on:click={updateBranchContext} />
            </div>
            <div class=" px-10 w-full rounded shadow pt-4">
              <div class="flex flex-row px-10 ">
                <div class="w-full pr-2">
                  <SelectField
                    options={absenceOptions}
                    bind:value={hasAbsencePenalty}
                    label="Has Absence Penalty?" />
                </div>
                <div class="w-full pl-2">
                  <TextField
                    type="number"
                    label="Required Head Count"
                    bind:textValue={requiredHeadCount} />
                </div>

              </div>
              <div class="flex flex-row pt-10 px-10 pb-10">

                <div
                  class="w-1/2 rounded shadow px-10 pt-2 mr-5 ml-5
                  overflow-y-auto"
                  style="height: 400px">
                  <span class="text-xs text-primary">Available Employees</span>
                  {#await $allEmployeesOp}
                    <div class="w-full pt-10">
                      <LoadingContent />
                      <div class="pt-10" />
                      <LoadingContent />
                      <div class="pt-10" />
                      <LoadingContent />
                    </div>
                  {:then resultEmployees}
                    {#each processEmployees(resultEmployees.data.employees.edges, currentBranchCode, allEmps) as employee}
                      <div class="flex flex-row justify-between">
                        <div class="text-left">
                          {employee.employeeNumber} - {employee.name.lastName}, {employee.name.firstName}
                        </div>
                        <div class="pr-1">
                          <a
                            href="#!"
                            class="text-primary"
                            on:click={addToCurrentBranch(employee)}>
                            +
                          </a>
                        </div>
                      </div>
                    {/each}
                  {/await}
                </div>
                <div
                  class="w-1/2 rounded shadow px-10 pt-2 flex flex-col ml-5 mr-5
                  overflow-y-auto"
                  style="height: 400px">
                  <span class="text-xs text-primary">Assigned Employees</span>
                  {#each currentBranchEmployees as employee}
                    <div class="flex flex-row">
                      <div class="pr-1" style="width: 5%">
                        <a
                          href="#!"
                          class="text-primary"
                          on:click={removeFromCurrentBranch(employee)}>
                          -
                        </a>
                      </div>
                      <div class="text-left" style="width: 95%">
                        {employee.employeeNumber} - {employee.name.lastName}, {employee.name.firstName}
                      </div>

                    </div>
                  {/each}
                </div>
              </div>
            </div>
          </div>
        {/if}
      {:catch error}
        <div>Something went wrong. Try refreshing the page</div>
      {/await}
    </div>
  </div>
</div>
