import { navigateTo } from "svelte-router-spa";
import { currentUser } from "../../stores/current_user";
import { Auth } from "../../config/firebase";

import { Users } from "../database/users";
import { Tenants } from "../database/tenants";

const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const checkAuthStatus = async (csst) => {
  const bearer = csst;
  const url = "https://mermade.saztrek.app/checkAuthStatus";
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    redirect: "follow",
    "Access-Control-Allow-Credentials": true,
    Origin: "https://mermade.saztrek.app",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer: ${bearer}`
    },
    referrerPolicy: "no-referrer"
  });

  return response.json();
};

Auth.onAuthStateChanged(() => {
  if (Auth.currentUser) {
    const userInfo = {
      email: Auth.currentUser.email,
      id: Auth.currentUser.uid,
      phoneNumber: Auth.currentUser.phoneNumber,
      photoUrl: Auth.currentUser.photoUrl
    };
    // currentUser.set(userInfo);

    Users.findOne(Auth.currentUser.uid).then((doc) => {
      userInfo.employee = doc.data();
      userInfo.displayName = `${userInfo.employee.firstName} ${userInfo.employee.lastName}`;

      Auth.currentUser.getIdTokenResult().then((idToken) => {
        userInfo.tenantId = idToken.claims.tenantId;
        userInfo.superAdmin = !!idToken.claims.superAdmin;

        userInfo.isAdmin =
          idToken.claims.superAdmin ||
          idToken.claims.roles.find((role) => role.appName === "Users").isAdmin;

        userInfo.roles = idToken.claims.roles;

        Auth.currentUser.getIdToken().then((idTokenForAuth) => {
          console.log(idTokenForAuth);
          userInfo.idToken = idTokenForAuth;

          Tenants.findOne(userInfo.tenantId).then((tenantDoc) => {
            const tenantData = tenantDoc.data();
            userInfo.gqlEndpoint = tenantData.gqlEndpoint;
            currentUser.set(userInfo);
          });
        });
      });
    });
  } else {
    const csst = getCookie("csst");

    if (csst) {
      checkAuthStatus(csst).then((token) => {
        if (token) {
          Auth.signInWithCustomToken(token)
            .then(() => {
              navigateTo("/");
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error("failed to auto-login", error);
            });
        }
      });
    } else {
      currentUser.set({ id: 0 });
    }
  }
});
