import { FirebaseTenants } from "./index";

const tenantsDb = () => {
  const findOne = (tenantId) => {
    return FirebaseTenants.doc(tenantId).get();
  };

  const findAll = () => {
    return FirebaseTenants;
  };

  return Object.freeze({
    findOne,
    findAll,
  });
};

const Tenants = tenantsDb();

export { Tenants };
