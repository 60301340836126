import { gql } from "apollo-boost";

// QUERIES
const EDGAR_SHEET_CONTEXT = gql`
  query edgarSheetContext($startDate: Date!, $endDate: Date!) {
    edgarSheetContext(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      gracePeriodDate
      weekNumber
      monthNumber
      branchSheets {
        branch {
          code
          name
          chain {
            code
            name
          }
        }
        edgarSheet {
          code
          inventoryDates
          deliveryDates
          complete
          sheetContext {
            startDate
            endDate
            weekNumber
            monthNumber
          }
          branch {
            code
            name
            chain {
              code
              name
            }
          }
          skus {
            edges {
              node {
                details {
                  code
                  name
                  unitCount
                  weekSupply
                  brand {
                    code
                    name
                  }
                  category {
                    code
                    name
                  }
                }
                data {
                  deliveryContainers
                  deliveryUnits
                  endingContainers
                  endingUnits
                  badOrderContainers
                  badOrderUnits
                  transferOutContainers
                  transferOutUnits
                  transferInContainers
                  transferInUnits
                  adjustmentContainers
                  adjustmentUnits
                }
                computed {
                  rawOfftakeUnits
                  offtakeUnits
                  totalDeliveryUnits
                  totalEndingUnits
                  totalBadOrderUnits
                  totalTransferOutUnits
                  totalTransferInUnits
                  totalAdjustmentUnits
                  suggestedOrderUnits
                  suggestedOrderContainers
                  offtakeContainers
                }
                meta {
                  beginningContainers
                  beginningUnits
                  oosCount
                  totalBeginningUnits
                }
              }
            }
          }
          meta {
            hasNegativeOfftake
            hasAdjustment
            totalDONSheets
          }
        }
      }
    }
  }
`;

const EDGAR_SHEET_CONTEXT_LITE = gql`
  query edgarSheetContext($startDate: Date!, $endDate: Date!) {
    edgarSheetContext(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      gracePeriodDate
      weekNumber
      monthNumber
      branchSheets {
        branch {
          code
          name
          chain {
            code
            name
          }
        }
        edgarSheet {
          code
          inventoryDates
          deliveryDates
          meta {
            inputPercentage
            generated
          }
        }
      }
      version
    }
  }
`;

const SIMPLE_EDGAR_SHEET_CONTEXT = gql`
  query edgarSheetContext($startDate: Date!, $endDate: Date!) {
    we
    were
    edgarSheetContext(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      gracePeriodDate
      weekNumber
      monthNumber
      version
    }
  }
`;
const RECOMPUTE_EDGAR_SHEET = gql`
  mutation recomputeEDGARSheet($code: String!) {
    recomputeEDGARSheet(code: $code) {
      id
      code
      sheetContext {
        startDate
        endDate
        weekNumber
        monthNumber
      }
      inventoryDates
      deliveryDates
      complete
      branch {
        code
        name
        baseAddress
        zip
        city
        province
        regionCode
        regionName
        coordinates {
          latitude
          longitude
        }
        chain {
          code
          name
          account {
            code
            name
          }
        }
        active
        version
      }
      carryTemplate {
        code
        name
        description
        active
        version
      }
      skus {
        edges {
          cursor
          node {
            details {
              code
              name
              core
              unitCount
              weekSupply
              brand {
                code
                name
                manufacturer {
                  code
                  name
                }
              }
              category {
                code
                name
              }
            }
            data {
              deliveryContainers
              deliveryUnits
              endingContainers
              endingUnits
              badOrderContainers
              badOrderUnits
              transferOutContainers
              transferOutUnits
              transferInContainers
              transferInUnits
              adjustmentContainers
              adjustmentUnits
            }
            computed {
              offtakeUnits
              offtakeContainers
              rawOfftakeUnits
              rawOfftakeContainers
              suggestedOrderUnits
              suggestedOrderContainers
              totalDeliveryUnits
              totalEndingUnits
              totalBadOrderUnits
              totalTransferOutUnits
              totalTransferInUnits
              totalAdjustmentUnits
            }
            meta {
              beginningContainers
              beginningUnits
              totalBeginningUnits
              oosCount
              hasNegativeOfftake
              hasAdjustment
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
      meta {
        hasNegativeOfftake
        hasAdjustment
        generated
        edited
        totalDONSheets
      }
      active
      version
    }
  }
`;

const EDGAR_CARRY_TEMPLATE = gql`
  query edgarBranchCarryTemplate($branchCode: String!) {
    edgarBranchCarryTemplate(branchCode: $branchCode) {
      branch {
        id
        code
        name
        baseAddress
        zip
        city
        province
        regionCode
        regionName
        coordinates {
          latitude
          longitude
        }
        chain {
          id
          code
          name
          account {
            id
            code
            name
            active
            version
          }
          active
          version
        }
        active
        version
      }
      carryTemplates {
        id
        code
        name
        description
        skus {
          edges {
            cursor
            node {
              id
              code
              name
              core
              unitCount
              brand {
                id
                code
                name
                manufacturer {
                  id
                  code
                  name
                }
              }
              category {
                id
                code
                name
              }
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
        active
        version
      }
      weekSupplyConfigurations {
        sku {
          code
          name
        }
        weekSupply
      }
      version
    }
  }
`;

const EDIT_EDGAR_CARRY_TEMPLATE = gql`
  mutation setEDGARBranchCarryTemplate(
    $setEDGARBranchCarryTemplateInput: SetEDGARBranchCarryTemplateInput!
  ) {
    setEDGARBranchCarryTemplate(
      setEDGARBranchCarryTemplateInput: $setEDGARBranchCarryTemplateInput
    ) {
      branch {
        id
        code
        name
        baseAddress
        zip
        city
        province
        regionCode
        regionName
        coordinates {
          latitude
          longitude
        }
        chain {
          id
          code
          name
          account {
            id
            code
            name
            active
            version
          }
          active
          version
        }
        active
        version
      }
      carryTemplates {
        id
        code
        name
        description
        skus {
          edges {
            cursor
            node {
              id
              code
              name
              core
              unitCount
              brand {
                id
                code
                name
                manufacturer {
                  id
                  code
                  name
                }
              }
              category {
                id
                code
                name
              }
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
        active
        version
      }
      weekSupplyConfigurations {
        sku {
          code
          name
        }
        weekSupply
      }
      version
    }
  }
`;

const GET_EDGAR_SHEET = gql`
  query edgarSheet($code: String!) {
    edgarSheet(code: $code) {
      id
      code
      sheetContext {
        startDate
        endDate
        weekNumber
        monthNumber
      }
      inventoryDates
      deliveryDates
      complete
      branch {
        code
        name
        baseAddress
        zip
        city
        province
        regionCode
        regionName
        coordinates {
          latitude
          longitude
        }
        chain {
          code
          name
          account {
            code
            name
          }
        }
        active
        version
      }
      carryTemplate {
        code
        name
        description
        active
        version
      }
      skus {
        edges {
          cursor
          node {
            details {
              code
              name
              core
              unitCount
              brand {
                code
                name
                manufacturer {
                  code
                  name
                }
              }
              category {
                code
                name
              }
            }
            data {
              deliveryContainers
              deliveryUnits
              endingContainers
              endingUnits
              badOrderContainers
              badOrderUnits
              transferOutContainers
              transferOutUnits
              transferInContainers
              transferInUnits
              adjustmentContainers
              adjustmentUnits
            }
            computed {
              offtakeUnits
              totalDeliveryUnits
              totalEndingUnits
              totalBadOrderUnits
              totalTransferOutUnits
              totalTransferInUnits
              totalAdjustmentUnits
            }
            meta {
              beginningContainers
              beginningUnits
              totalBeginningUnits
              oosCount
              hasNegativeOfftake
              hasAdjustment
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
      meta {
        hasNegativeOfftake
        hasAdjustment
        generated
        edited
        totalDONSheets
      }
      active
      version
    }
  }
`;

const GET_EDGAR_SHEET_LITE = gql`
  query edgarSheet($code: String!) {
    edgarSheet(code: $code) {
      code
      inventoryDates
      deliveryDates
      complete
      branch {
        code
        name
      }
      skus {
        edges {
          cursor
          node {
            details {
              code
              name
              core
              unitCount
              weekSupply
              category {
                code
                name
              }
            }
            data {
              deliveryContainers
              deliveryUnits
              endingContainers
              endingUnits
              badOrderContainers
              badOrderUnits
              transferOutContainers
              transferOutUnits
              transferInContainers
              transferInUnits
              adjustmentContainers
              adjustmentUnits
            }
            computed {
              offtakeUnits
              totalDeliveryUnits
              totalEndingUnits
              totalBadOrderUnits
              totalTransferOutUnits
              totalTransferInUnits
              totalAdjustmentUnits
              suggestedOrderUnits
              suggestedOrderContainers
            }
            meta {
              beginningContainers
              beginningUnits
              totalBeginningUnits
              oosCount
              hasNegativeOfftake
              hasAdjustment
              hasInput
            }
          }
        }
      }
      meta {
        hasNegativeOfftake
        hasAdjustment
        hasInput
        inputPercentage
        generated
        edited
        totalDONSheets
      }
    }
  }
`;

const GET_EDGAR_SHEET_LITE_WITH_BRAND_AND_CATEGORY = gql`
  query edgarSheet($code: String!) {
    edgarSheet(code: $code) {
      code
      inventoryDates
      deliveryDates
      complete
      branch {
        code
        name
      }
      sheetContext {
        startDate
        endDate
        weekNumber
        monthNumber
      }
      skus {
        edges {
          cursor
          node {
            details {
              code
              name
              core
              unitCount
              weekSupply
              brand {
                code
                name
              }
              category {
                code
                name
              }
            }
            data {
              deliveryContainers
              deliveryUnits
              endingContainers
              endingUnits
              badOrderContainers
              badOrderUnits
              transferOutContainers
              transferOutUnits
              transferInContainers
              transferInUnits
              adjustmentContainers
              adjustmentUnits
            }
            computed {
              offtakeUnits
              totalDeliveryUnits
              totalEndingUnits
              totalBadOrderUnits
              totalTransferOutUnits
              totalTransferInUnits
              totalAdjustmentUnits
              suggestedOrderUnits
              suggestedOrderContainers
              offtakeContainers
            }
            meta {
              beginningContainers
              beginningUnits
              totalBeginningUnits
              oosCount
              hasNegativeOfftake
              hasAdjustment
              hasInput
            }
          }
        }
      }
      meta {
        hasNegativeOfftake
        hasAdjustment
        hasInput
        inputPercentage
        generated
        edited
        totalDONSheets
      }
    }
  }
`;

const EDGAR_SHEET_CONTEXTS = gql`
  query edgarSheetContexts($connectionArgs: ConnectionArgs) {
    edgarSheetContexts(connectionArgs: $connectionArgs) {
      edges {
        cursor
        node {
          startDate
          endDate
          gracePeriodDate
          weekNumber
          monthNumber
          version
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const EDGAR_SHEET_CONTEXT_COMPLIANCE = gql`
  query edgarSheetContext(
    $startDate: Date!
    $endDate: Date!
    $findEDGARSheetContextCompliance: FindEDGARSheetContextCompliance
  ) {
    edgarSheetContext(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      gracePeriodDate
      weekNumber
      monthNumber
      compliance(
        findEDGARSheetContextCompliance: $findEDGARSheetContextCompliance
      ) {
        branch {
          id
          code
          name
          chain {
            code
            name
            account {
              code
              name
            }
          }
        }
        enrolled
        edgarSheet {
          id
          code
          complete
          meta {
            hasAdjustment
            hasAdjustmentCount
            hasNoDelivery
            noDeliveryCount
            hasNoEnding
            noEndingCount
            inputPercentage
          }
          latestCollaborator {
            code
            name {
              firstName
              lastName
            }
          }
        }
        inputPercentageComplianceThreshold
        compliant
        tags {
          key
          value
        }
      }
      version
    }
  }
`;

const EDGAR_SHEET_SUMMARIES = gql`
  query edgarSheetSummaries(
    $findEDGARSheetSummaryInput: FindEDGARSheetSummaryInput
  ) {
    edgarSheetSummaries(
      findEDGARSheetSummaryInput: $findEDGARSheetSummaryInput
    ) {
      edges {
        node {
          code
          sheetSummaryContext {
            startDate
            endDate
            sheetSummaryType
            tags {
              key
              values
            }
            version
          }
          branch {
            code
            name
            chain {
              code
              name
              account {
                code
                name
              }
            }
          }
          skus {
            details {
              code
              name
              core
              unitCount
              brand {
                code
                name
                manufacturer {
                  code
                  name
                }
              }
              category {
                code
                name
              }
            }
            sheetContextEntries {
              sheetContext {
                startDate
                endDate
                weekNumber
                monthNumber
              }
              data {
                deliveryContainers
                deliveryUnits
                endingContainers
                endingUnits
                badOrderContainers
                badOrderUnits
                transferOutContainers
                transferOutUnits
                transferInContainers
                transferInUnits
                adjustmentContainers
                adjustmentUnits
              }
              computed {
                offtakeUnits
                totalDeliveryUnits
                totalEndingUnits
                totalBadOrderUnits
                totalTransferOutUnits
                totalTransferInUnits
                totalAdjustmentUnits
              }
              meta {
                beginningContainers
                beginningUnits
                totalBeginningUnits
                oosCount
                hasNegativeOfftake
                hasAdjustment
                noDelivery
                noEnding
                hasInput
              }
              summary {
                oos
              }
            }
            summary {
              totalOfftakeContainers
              totalOfftakeUnits
              totalRawOfftakeContainers
              totalRawOfftakeUnits
              averageOfftakeUnits
              averageOfftakeContainers
              totalDeliveryUnits
              totalEndingUnits
              totalBadOrderUnits
              totalTransferOutUnits
              totalTransferInUnits
              totalAdjustmentUnits
              totalOOS
            }
          }
          sheetContextEDGARSheetPairs {
            sheetContext {
              startDate
              endDate
            }
            edgarSheet {
              id
              code
            }
          }
          version
        }
      }
    }
  }
`;

const EDGAR_SHEET_SUMMARY_CONTEXTS = gql`
  query edgarSheetSummaryContexts($connectionArgs: ConnectionArgs) {
    edgarSheetSummaryContexts(connectionArgs: $connectionArgs) {
      edges {
        cursor
        node {
          startDate
          endDate
          sheetSummaryType
          tags {
            key
            values
          }
          version
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const SIMPLE_EDGAR_SHEET_SUMMARY_CONTEXT = gql`
  query edgarSheetSummaryContext(
    $startDate: Date!
    $endDate: Date!
    $sheetSummaryType: EDGARSheetSummaryType!
  ) {
    edgarSheetSummaryContext(
      startDate: $startDate
      endDate: $endDate
      sheetSummaryType: $sheetSummaryType
    ) {
      startDate
      endDate
      sheetSummaryType
      tags {
        key
        values
      }
      version
    }
  }
`;

const ADD_EDGAR_SHEET_CONTEXT = gql`
  mutation addEDGARSheetContext(
    $addEDGARSheetContextInput: AddEDGARSheetContextInput!
  ) {
    addEDGARSheetContext(
      addEDGARSheetContextInput: $addEDGARSheetContextInput
    ) {
      startDate
      endDate
      gracePeriodDate
      weekNumber
      monthNumber
      version
    }
  }
`;

const EDIT_EDGAR_SHEET_CONTEXT = gql`
  mutation editEDGARSheetContext(
    $editEDGARSheetContextInput: EditEDGARSheetContextInput!
  ) {
    editEDGARSheetContext(
      editEDGARSheetContextInput: $editEDGARSheetContextInput
    ) {
      startDate
      endDate
      gracePeriodDate
      weekNumber
      monthNumber
      version
    }
  }
`;

const DELETE_EDGAR_SHEET_CONTEXT = gql`
  mutation deleteEDGARSheetContext($startDate: Date!, $endDate: Date!) {
    deleteEDGARSheetContext(startDate: $startDate, endDate: $endDate)
  }
`;

const ADD_EDGAR_SHEET_SUMMARY_CONTEXT = gql`
  mutation addEDGARSheetSummaryContext(
    $addEDGARSheetSummaryContextInput: AddEDGARSheetSummaryContextInput!
  ) {
    addEDGARSheetSummaryContext(
      addEDGARSheetSummaryContextInput: $addEDGARSheetSummaryContextInput
    ) {
      startDate
      endDate
      sheetSummaryType
      tags {
        key
        values
      }
      version
    }
  }
`;

const EDIT_EDGAR_SHEET_SUMMARY_CONTEXT = gql`
  mutation editEDGARSheetSummaryContext(
    $editEDGARSheetSummaryContextInput: EditEDGARSheetSummaryContextInput!
  ) {
    editEDGARSheetSummaryContext(
      editEDGARSheetSummaryContextInput: $editEDGARSheetSummaryContextInput
    ) {
      startDate
      endDate
      sheetSummaryType
      tags {
        key
        values
      }
      version
    }
  }
`;

export {
  EDGAR_SHEET_CONTEXT,
  RECOMPUTE_EDGAR_SHEET,
  EDGAR_CARRY_TEMPLATE,
  EDIT_EDGAR_CARRY_TEMPLATE,
  GET_EDGAR_SHEET,
  EDGAR_SHEET_CONTEXTS,
  EDIT_EDGAR_SHEET_CONTEXT,
  DELETE_EDGAR_SHEET_CONTEXT,
  SIMPLE_EDGAR_SHEET_CONTEXT,
  ADD_EDGAR_SHEET_CONTEXT,
  EDGAR_SHEET_CONTEXT_COMPLIANCE,
  EDGAR_SHEET_CONTEXT_LITE,
  GET_EDGAR_SHEET_LITE,
  EDGAR_SHEET_SUMMARIES,
  EDGAR_SHEET_SUMMARY_CONTEXTS,
  ADD_EDGAR_SHEET_SUMMARY_CONTEXT,
  EDIT_EDGAR_SHEET_SUMMARY_CONTEXT,
  SIMPLE_EDGAR_SHEET_SUMMARY_CONTEXT,
  GET_EDGAR_SHEET_LITE_WITH_BRAND_AND_CATEGORY
};
