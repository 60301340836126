<script>
  import dayjs from "dayjs";
  import { getClient, query } from "svelte-apollo";
  import { LoadingContent, EmptyButton, Button } from "@saztrek/firefly";

  import { DON_SHEETS_LITE, BRANCHES } from "../../../../gql";

  import { exportToCSV } from "../../../../utilities/export";

  import { currentUser } from "../../../../stores/current_user";

  import {
    dateFormatToServer,
    dateFormatToUI
  } from "../../../../utilities/dateFormatter";

  import BranchCard from "./BranchCard.svelte";

  let currentStartDay = dayjs();

  let loading = true;

  const daysToIncrement = 1;

  const client = getClient();

  const donSheetComplianceOp = query(client, {
    query: DON_SHEETS_LITE,
    fetchPolicy: "network-only",
    variables: {
      findDONSheetInput: {
        inventoryDate: dateFormatToServer(currentStartDay.toDate())
      }
    }
  });

  const branchesOp = query(client, {
    query: BRANCHES
  });

  $: if ($currentUser.employee.defaultChain) {
    branchesOp.refetch({
      findBranchInput: {
        chainCode: $currentUser.employee.defaultChain
      }
    });
  }

  let branchComplianceList = [];

  const processResponse = (response) => {
    const { data } = response;
    const { donSheets } = data;

    if (!donSheets) {
      branchComplianceList = [];
      return;
    }

    const { edges } = donSheets;

    branchComplianceList = edges.map((branchCompliance) => {
      const { node } = branchCompliance;

      const { branch, meta, timestamps, inventoryDate } = node;

      const hasTimeStamps = timestamps && timestamps.length > 0;
      const lastTimeStamp = hasTimeStamps
        ? timestamps[timestamps.length - 1]
        : "";
      const firstTimeStamp = hasTimeStamps ? timestamps[0] : "";

      return {
        code: branch.code,
        name: branch.name,
        chainCode: branch.chain.code,
        chainName: branch.chain.name,
        complete: node.complete,
        totalSKUCount: meta.totalSKUCount,
        carriedSKUCount: meta.carriedSKUCount,
        availableCount: meta.availableCount,
        availablePercentage: meta.availablePercentage,
        oosCount: meta.oosCount,
        oosPercentage: meta.oosPercentage,
        notCarriedCount: meta.notCarriedCount,
        notCarriedPercentage: meta.notCarriedPercentage,
        dateCreated: inventoryDate,
        timeCreated: hasTimeStamps
          ? dayjs(firstTimeStamp).format("HH:mm:ss")
          : "",
        dateSync: hasTimeStamps
          ? dateFormatToServer(dayjs(lastTimeStamp).toDate())
          : "",
        timeSync: hasTimeStamps ? dayjs(lastTimeStamp).format("HH:mm:ss") : ""
      };
    });
  };

  $donSheetComplianceOp.then((response) => {
    processResponse(response);

    loading = false;
  });

  const refetchQueries = () => {
    donSheetComplianceOp
      .refetch({
        findDONSheetInput: {
          inventoryDate: dateFormatToServer(currentStartDay.toDate())
        }
      })
      .then((response) => {
        processResponse(response);
        loading = false;
      });
  };

  const previousWeek = () => {
    currentStartDay = currentStartDay.subtract(daysToIncrement, "day");
    loading = true;
    refetchQueries();
  };

  const nextWeek = () => {
    currentStartDay = currentStartDay.add(daysToIncrement, "day");
    loading = true;
    refetchQueries();
  };

  let branchList = [];

  const buildBranchCompObject = (branch, branchComp) => {
    if (branchComp) {
      return {
        ...branchComp
      };
    }

    return {
      code: branch.node.code,
      name: branch.node.name,
      chainCode: branch.node.chain.code,
      chainName: branch.node.chain.name,
      complete: false,
      totalSKUCount: 0,
      carriedSKUCount: 0,
      availableCount: 0,
      availablePercentage: 0,
      oosCount: 0,
      oosPercentage: 0,
      notCarriedCount: 0,
      notCarriedPercentage: 0,
      dateCreated: "N/A",
      timeCreated: "N/A",
      dateSync: "N/A",
      timeSync: "N/A"
    };
  };

  const processBranchCompliance = (branchCompList, branches) => {
    branchList = branches.edges;
    return branches.edges.map((branch) =>
      buildBranchCompObject(
        branch,
        branchCompList.find(
          (branchComp) => branchComp.code === branch.node.code
        )
      )
    );
  };

  const exportCompliance = () => {
    const cols = [
      `"Branch Code"`,
      `"Branch Name"`,
      `"Chain Code"`,
      `"Chain Name"`,
      `"Complete?"`,
      `"Total SKU Count"`,
      `"Carried SKU Count"`,
      `"Available Count"`,
      `"Available Percentage"`,
      `"OOS Count"`,
      `"OOS Percentage"`,
      `"Not Carried Count"`,
      `"Not Carried Percentage"`,
      `"Date Created"`,
      `"Time Created"`,
      `"Date Sync"`,
      `"Time Sync"`
    ]
      .join(",")
      .concat("\n");

    const rows = branchList
      .map((branch) => {
        const branchCompObject = buildBranchCompObject(
          branch,
          branchComplianceList.find(
            (branchComp) => branchComp.code === branch.node.code
          )
        );

        return `"${branchCompObject.code}","${branchCompObject.name}","${
          branchCompObject.chainCode
        }","${branchCompObject.chainName}","${
          branchCompObject.complete ? "Yes" : "No"
        }","${branchCompObject.totalSKUCount}","${
          branchCompObject.carriedSKUCount
        }","${branchCompObject.availableCount}","${(
          branchCompObject.availablePercentage * 100
        ).toFixed(2)}%","${branchCompObject.oosCount}","${(
          branchCompObject.oosPercentage * 100
        ).toFixed(2)}%","${branchCompObject.notCarriedCount}","${(
          branchCompObject.notCarriedPercentage * 100
        ).toFixed(2)}%","${branchCompObject.dateCreated}","${
          branchCompObject.timeCreated
        }","${branchCompObject.dateSync}","${branchCompObject.timeSync}"`;
      })
      .join("\r\n");

    exportToCSV(
      cols,
      rows,
      `DON Compliance for ${dateFormatToUI(currentStartDay)}`
    );
  };
</script>

<div class="w-full h-full py-4 px-8">

  <div
    class="w-full overflow-y-auto shadow rounded p-6"
    style="min-height: 900px; max-height: 900px">

    <div
      class="m-0 text-base flex flex-row items-center justify-between w-full">
      <div class="w-2/3 flex flex-row justify-end">
        <div>
          <EmptyButton
            icon="arrowLeft"
            text=""
            size="tiny"
            on:click={previousWeek} />
        </div>
        <div class="w-56 text-center">
          {dateFormatToUI(currentStartDay.toDate())}
        </div>
        <div>
          <EmptyButton
            icon="arrowRight"
            text=""
            size="tiny"
            on:click={nextWeek} />
        </div>
      </div>

      <div class="flex px-4 self-end">
        <Button text="Export" on:click={exportCompliance} color="primary" />
      </div>

    </div>
    {#await $branchesOp}
      <div class="pt-4 h-full w-full flex flex-col justify-center">
        <div class="w-full">
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
        </div>
      </div>
    {:then branchesResult}
      {#if !loading}
        <div class="pt-4 grid grid-cols-1 xl:grid-cols-4 w-full px-1 h-full">

          {#each processBranchCompliance(branchComplianceList, branchesResult.data.branches) as branchCompliance}
            <BranchCard
              code={branchCompliance.code}
              name={branchCompliance.name}
              totalSKUCount={branchCompliance.totalSKUCount}
              carriedSKUCount={branchCompliance.carriedSKUCount}
              availableCount={branchCompliance.availableCount}
              availablePercentage={branchCompliance.availablePercentage}
              oosCount={branchCompliance.oosCount}
              oosPercentage={branchCompliance.oosPercentage}
              notCarriedCount={branchCompliance.notCarriedCount}
              notCarriedPercentage={branchCompliance.notCarriedPercentage}
              dateCreated={branchCompliance.dateCreated}
              timeCreated={branchCompliance.timeCreated}
              dateSync={branchCompliance.dateSync}
              timeSync={branchCompliance.timeSync} />
          {/each}

        </div>
      {:else}
        <div class="pt-4 h-full w-full flex flex-col justify-center">
          <div class="w-full">
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
          </div>
        </div>
      {/if}
    {/await}
  </div>

</div>
