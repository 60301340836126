<script>
  import { createEventDispatcher } from "svelte";
  import { Icon } from "@saztrek/firefly";
  import StickyHeader from "../../../components/StickyHeader.svelte";

  export let branches;
  export let title = "Branches";
  export let subtitle = "";

  const dispatch = createEventDispatcher();

  const branchClicked = (branch) => {
    dispatch("branchSelected", { branch });
  };
</script>

<div class="border border-darkerGray rounded p-1 h-full overflow-y-auto">
  <StickyHeader>
    <div class="w-full">
      <div class="font-bold text-lg">{title}</div>
      <div class="italic text-sm font-normal">{subtitle}</div>
    </div>
  </StickyHeader>
  {#if branches.length === 0}
    <div class="h-56 w-full flex flex-col items-center justify-center">
      <Icon size="l" type="table" />
      <div class="pt-2">No Sheets yet for this time period.</div>
    </div>
  {:else}
    <div class="list pl-6 pr-4 h-full">
      {#each branches as branch}
        <div
          class="flex flex-row items-start justify-between py-2
          border-b-darkerGray border-b w-full last:border-b-0">
          <div class="flex flex-col justify-start w-9/12 px-1">
            <span class="w-full truncate">
              {#if branch.nemoSheet}
                <a
                  class="text-sm text-primary hover:underline"
                  href="#!"
                  on:click={() => branchClicked(branch)}>
                  {branch.name}
                </a>
              {:else}
                <a class="text-sm text-ghostText italic" href="#!">
                  {branch.name}
                </a>
              {/if}
            </span>

            <div class="text-xs italic">{branch.code}</div>
          </div>
          {#if !branch.nemoSheet}
            <div class="flex flex-col w-3/12 self-center">
              <div
                class="text-xs font-normal leading-5 px-2 rounded border
                border-transparent bg-danger">
                <div
                  class="flex items-center justify-center flex-row-reverse
                  text-white">
                  <span>No Sheet</span>
                </div>
              </div>
            </div>
          {/if}
        </div>
      {/each}

    </div>
  {/if}
</div>
