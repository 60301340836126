<script>
  import { LoadingContent } from "@saztrek/firefly";
  import SKUCardFace from "../components/EDGARSKUCardFace.svelte";
  import SKUCardBack from "../components/EDGARSKUCardBack.svelte";

  import Card from "../../../components/FlipCard.svelte";

  export let skus;
  export let loadingSKUs;
</script>

{#if skus && skus.length > 0}
  <div
    class="border border-darkerGray rounded grid grid-cols-1 xl:grid-cols-3
    col-gap-2 row-gap-2 py-4 px-4 overflow-y-auto"
    style="max-height: 780px;">

    {#each skus as sku}
      <Card>
        <div slot="face" class="h-full">
          <SKUCardFace {sku} />
        </div>
        <div slot="back" class="h-full">
          <SKUCardBack {sku} />
        </div>
      </Card>
    {/each}
  </div>
{:else if loadingSKUs}
  <div class="w-full pt-2 h-full px-4 ">
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
  </div>
  <!-- {:else}
      <div class="h-full w-full flex flex-col items-center justify-center">
        <Icon size="l" type="table" />
        <div class="pt-2">
          {#if currentSheetCode === ''}
            Choose an EDGAR Sheet on the left.
          {:else}No Sheet created yet for this branch.{/if}
        </div>
      </div> -->
{/if}
