<script>
  import { createEventDispatcher } from "svelte";
  import {
    TextField,
    Button,
    EmptyButton,
    toastNotifier
  } from "@saztrek/firefly";

  import { currentTenant } from "../../../../stores/current_tenant";

  // import DeactivateModal from "./DeactivateModal.svelte";

  export let mode;
  export let cancelAction;

  export let startDate;
  export let endDate;
  export let gracePeriodDate;
  export let weekNumber;
  export let monthNumber;

  export let version;

  export let buttonLabel;

  export let loading = false;

  let isDaily = false;
  let tenantVarsLoaded = false;

  $: if ($currentTenant.tenantVariables && !tenantVarsLoaded) {
    isDaily = $currentTenant.featureFlags.find(
      (flag) => flag.code === "EDGAR_isDaily"
    ).value;

    tenantVarsLoaded = true;
  }

  $: if (startDate && isDaily) {
    endDate = startDate;
  }

  const dispatch = createEventDispatcher();

  const requiredFieldsFilled = () => {
    return !!(
      startDate &&
      endDate &&
      gracePeriodDate &&
      weekNumber &&
      monthNumber
    );
  };

  const submitForm = () => {
    if (requiredFieldsFilled()) {
      if (mode === "add") {
        dispatch("validSheetContext", {
          startDate,
          endDate,
          gracePeriodDate,
          weekNumber,
          monthNumber
        });
      } else {
        dispatch("validSheetContext", {
          startDate,
          endDate,
          gracePeriodDate,
          weekNumber,
          monthNumber,
          version
        });
      }
    } else {
      toastNotifier.warning(
        "Required fields.",
        "Please fill in required fields",
        7000
      );
    }
  };

  //   const inactivateSheetContext = () => {
  //     dispatch("inactivateSheetContext", { code });
  //   };

  const resolveReadOnly = () =>
    mode === "edit" ? { readonly: true } : undefined;
</script>

{#if mode === 'edit'}
  <div class="w-full text-right pr-12">
    <!-- <DeactivateModal sheetContextCode={code} confirmEvent={inactivateSheetContext} /> -->
  </div>
{/if}
<form on:submit|preventDefault={submitForm}>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={startDate}
      {...resolveReadOnly()}
      type="date"
      label="Start Date" />
  </div>
  <div class="p-4 w-full" class:hidden={isDaily}>
    <TextField
      bind:textValue={endDate}
      {...resolveReadOnly()}
      type="date"
      label="End Date" />
  </div>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={gracePeriodDate}
      type="date"
      label="Grace Period Date" />
  </div>
  <div class="p-4 w-full">
    <TextField bind:textValue={weekNumber} type="number" label="Week Number" />
  </div>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={monthNumber}
      type="number"
      label="Month Number" />
  </div>
  {#if mode === 'edit'}
    <input type="hidden" bind:value={version} />
  {/if}
  <div class="w-full flex flex-row-reverse justify-evenly pb-2 mt-8 px-4">
    <Button text={buttonLabel} type="submit" color="primary" {loading} />
    <div class="w-25" />
    <div class="text-center">
      <EmptyButton text="Cancel" color="text" on:click={cancelAction} />
    </div>
  </div>
</form>
