// import Login from "../../views/public/login/index.svelte";
import PrivacyPolicy from "../../views/public/privacyPolicy/PrivacyPolicy.html";
import DownloadApp from "../../views/public/appDownload/mobileDownload.svelte";
import PasswordReset from "../../views/public/passwordReset/PasswordReset.svelte";
// import NoAccess from "../../noaccess.svelte";

const publicRoutes = [
  {
    name: "privacypolicy",
    component: PrivacyPolicy
  },
  {
    name: "download",
    component: DownloadApp
  },
  {
    name: "passwordreset",
    component: PasswordReset
  }
  /* {
    name: "noaccess",
    component: NoAccess
  } */
];

export { publicRoutes };
