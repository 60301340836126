import { writable } from "svelte/store";

const tenantInfo = writable({});

const setTenant = (tenant) => {
  tenantInfo.set(tenant);
};

const removeTenant = () => {
  tenantInfo.set({});
};

const currentTenant = {
  subscribe: tenantInfo.subscribe,
  set: setTenant,
  remove: removeTenant
};

export { currentTenant };
