const firebaseResults = () => {
  const map = (docs, exclude) => {
    const newResults = [];
    docs.forEach((doc) => {
      const newResult = doc.data();

      if (exclude && doc[exclude.id] === exclude.value) return;
      newResult.id = doc.id;
      newResults.push(newResult);
    });
    return newResults;
  };

  return Object.freeze({
    map
  });
};

const FirebaseResults = firebaseResults();

export default FirebaseResults;
