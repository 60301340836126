<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, mutate, query } from "svelte-apollo";

  import { REBUILD_BRANCH_ATTENDANCE_SHEET, BRANCHES } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import BranchAttendanceForm from "./BranchAttendanceSheetForm.svelte";

  const cancelAdd = () => {
    navigateTo("/calendar/branchattendancesheets");
  };

  const client = getClient();

  const branchesOp = query(client, {
    query: BRANCHES
  });

  let loading = false;

  const rebuildBranchAttendahceSheets = async (event) => {
    try {
      const branchAttendanceSheetData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: REBUILD_BRANCH_ATTENDANCE_SHEET,
        variables: {
          rebuildCalendarBranchAttendanceSheetEmployeesInput: {
            ...branchAttendanceSheetData
          }
        }
      });
      toastNotifier.success("Success!", `Calendar Sheets Rebuilt`, 7000);
      navigateTo("/calendar/branchattendancesheets");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to Rebuild.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Rebuild Calendar Sheets">
    {#await $branchesOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      <BranchAttendanceForm
        mode="rebuild"
        on:validSheetContext={rebuildBranchAttendahceSheets}
        cancelAction={cancelAdd}
        branchList={result.data.branches.edges}
        {loading}
        buttonLabel="Rebuild" />
    {/await}
  </Panel>
</div>
