<script>
  import dayjs from "dayjs";
  import LeavesWidget from "./LeavesWidget.svelte";
  import EDGARCorrectionsWidget from "./EDGARCorrectionsWidget.svelte";
  import EDGARSheetsWidget from "./EDGARSheetsWidget.svelte";
  import DONSheetsWidget from "./DONSheetsWidget.svelte";

  import {
    nearestEndDay,
    nearestStartDay,
    getNearestEndDay,
    getNearestStartDay
  } from "../edgar/edgarUtils";

  import { dateFormatToServer } from "../../../utilities/dateFormatter";

  import { currentTenant } from "../../../stores/current_tenant";

  let currentStartDay = nearestStartDay;
  let currentEndDay = nearestEndDay;
  let daysToIncrement = 7;
  let isDaily = false;
  let tenantVarsLoaded = false;
  let inventoryDate = dayjs();

  $: if ($currentTenant.tenantVariables && !tenantVarsLoaded) {
    daysToIncrement = $currentTenant.featureFlags.find(
      (flag) => flag.code === "EDGAR_isDaily"
    ).value
      ? 1
      : 7;

    isDaily = $currentTenant.featureFlags.find(
      (flag) => flag.code === "EDGAR_isDaily"
    ).value;
    currentStartDay = !isDaily ? getNearestStartDay() : dayjs();
    currentEndDay = !isDaily ? getNearestEndDay() : currentStartDay;
    tenantVarsLoaded = true;
  }
</script>

<div class="py-2 w-full flex flex-wrap justify-between">
  <div class="flex flex-wrap md:-mx-2 justify-between w-full">
    <div class="lg:w-1/3 w-full px-2 py-2">
      {#if tenantVarsLoaded}
        <DONSheetsWidget
          subtitle={'Today'}
          inventoryDate={dateFormatToServer(inventoryDate)} />
      {/if}
    </div>
    <div class="lg:w-1/3 w-full px-2 py-2">
      {#if tenantVarsLoaded}
        <DONSheetsWidget
          subtitle={'Yesterday'}
          inventoryDate={dateFormatToServer(inventoryDate.subtract(1, 'day'))} />
      {/if}
    </div>
    <div class="lg:w-1/3 w-full px-2 py-2">
      {#if tenantVarsLoaded}
        <EDGARSheetsWidget
          subtitle={isDaily ? 'Today' : 'This Week'}
          startDate={dateFormatToServer(currentStartDay)}
          endDate={dateFormatToServer(currentEndDay)} />
      {/if}
    </div>

    <div
      class="lg:w-1/3 w-full px-2 py-2"
      style="min-height: 360px; max-height: 360px; height: 360px;">
      {#if tenantVarsLoaded}
        <EDGARSheetsWidget
          subtitle={isDaily ? 'Yesterday' : 'Last Week'}
          startDate={dateFormatToServer(currentStartDay.subtract(daysToIncrement, 'day'))}
          endDate={dateFormatToServer(currentEndDay.subtract(daysToIncrement, 'day'))} />
      {/if}
    </div>

    <div class="lg:w-1/3 w-full px-2 py-2">
      <LeavesWidget />
    </div>

    <div class="lg:w-1/3 w-full px-2 py-2">
      <EDGARCorrectionsWidget />
    </div>
  </div>
</div>
