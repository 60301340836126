<script>
  import { onMount, setContext, createEventDispatcher } from "svelte";
  import Konva from "konva";
  import { Button, SelectField, TextField } from "@saztrek/firefly";
  import { FirebaseStorage } from "../../../middleware/database/index";

  import createPS3Manager from "./PS3Manager";

  import BayReportCard from "./PS3BayReport.svelte";

  export let ps3Sheet;

  const dispatch = createEventDispatcher();

  // const stageWidth = 4032;
  // const stageHeight = 2268;
  const stageWidth = 987;
  const stageHeight = 887;

  const padding = 20;

  let parentContainer;
  let ps3Manager;

  let scale;
  let isDrawingLine = false;
  let isPainting = false;
  let isALineSelected = false;
  let isReferenceLinePresent = false;
  let isDrawingReferenceLine = false;
  // eslint-disable-next-line no-unused-vars
  let isReferenceLineSelected = false;
  let addingSKULine = false;

  let currentSelectedColor = "#0000FF";

  let currentSelectedCategoryCode;
  let currentSelectedSKUCompetitor;

  let currentSelectedSKUCode;
  let currentSelectedLength = 100;
  let currentReport;

  const referenceLineColor = "#00FF00";

  const referenceLineLength = 156;

  let currentUniverseLength = ps3Sheet.universeLength || 120;

  // const bays = [
  //   {
  //     id: "bay-0",
  //     name: "Bay 1",
  //     imageURL: "http://localhost:5000/samplePhotos/milk-bay1.png"
  //   },
  //   {
  //     id: "bay-1",
  //     name: "Bay 2",
  //     imageURL: "http://localhost:5000/samplePhotos/milk-bay2.png"
  //   },
  //   {
  //     id: "bay-2",
  //     name: "Bay 3",
  //     imageURL: "http://localhost:5000/samplePhotos/milk-bay3.png"
  //   }
  // ];

  const { bays, processedSheet } = ps3Sheet;

  let currentBay = bays[0].id;
  let currentBayObject = bays[0];

  const containers = new Array(bays.length);

  const bayOptions = bays.map((bay) => {
    return {
      // option: bay.name,
      option: bay.id,
      value: bay.id
    };
  });

  const buildSKUOptions = (bay) => {
    const skuOptions = bay.skus.map((sku) => {
      return {
        option: sku.skuCode,
        value: sku.skuCode
      };
    });

    skuOptions.unshift({
      option: "Choose SKU",
      value: null
    });

    return skuOptions;
  };

  const createKonvaStage = (container) => {
    return new Konva.Stage({
      container,
      width: stageWidth,
      height: stageHeight
    });
  };

  const defaultStrokeWidth = 5;
  const defaultFontSize = 22;

  const createKonvaLayer = () => new Konva.Layer();

  const createKonvaLine = (ps3Line) => {
    const points = ps3Line.initRightX
      ? [
          ps3Line.initLeftX,
          ps3Line.initLeftY,
          ps3Line.initRightX,
          ps3Line.initRightY
        ]
      : [ps3Line.initLeftX, ps3Line.initLeftY];

    return new Konva.Line({
      id: ps3Line.lineID,
      stroke: ps3Line.details.strokeColor,
      strokeWidth: defaultStrokeWidth,
      globalCompositeOperation: "source-over",
      points,
      hitStrokeWidth: 50
    });
  };

  const createKonvaText = (ps3Line) => {
    return new Konva.Text({
      id: ps3Line.details.skuLengthID,
      x: ps3Line.leftX,
      y: ps3Line.leftY + 12,
      text: `${ps3Line.details.skuLength} cm`,
      fill: ps3Line.details.strokeColor,
      // fill: "#FFFFFF",
      // stroke: "#FFFFFF",
      fontSize: defaultFontSize,
      strokeWidth: 1,
      width: ps3Line.rightX - ps3Line.leftX,
      align: "center",
      fontFamily: "Courier New",
      // fontFamily: "Calibri",
      fontStyle: "bold"
      // letterSpacing: 1
      // fillAfterStrokeEnabled: true
    });
  };

  const getCurrentBayAndLayer = () => {
    const bay = ps3Manager.getCurrentBay();
    const currentLayer = bay.getCurrentLayer();
    return {
      bay,
      currentLayer
    };
  };

  const destroyAnchors = () => {
    // const bay = ps3Manager.getCurrentBay();
    // const currentLayer = bay.getCurrentLayer();

    const { bay, currentLayer } = getCurrentBayAndLayer();

    const currentStage = bay.konvaStage;

    const leftAnchor = currentStage.find("#anchorLeft")[0];
    const midAnchor = currentStage.find("#anchorMid")[0];
    const rightAnchor = currentStage.find("#anchorRight")[0];

    if (leftAnchor) {
      leftAnchor.destroy();
    }

    if (midAnchor) {
      midAnchor.destroy();
    }

    if (rightAnchor) {
      rightAnchor.destroy();
    }

    currentLayer.konvaLayer.draw();
  };

  const stageMouseDown = () => {
    isPainting = true;
    const { bay, currentLayer } = getCurrentBayAndLayer();

    if (isDrawingReferenceLine) {
      const pos = bay.getScaledPointerPosition();

      currentLayer.addLine({
        initLeftX: pos.x,
        initLeftY: pos.y,
        initRightX: pos.x,
        initRightY: pos.y,
        initDetails: {
          skuLength: referenceLineLength,
          strokeColor: referenceLineColor
        },
        initReference: true
      });
    } else if (isDrawingLine) {
      const pos = bay.getScaledPointerPosition();

      // compute width based on ratio vs reference line

      const referenceLine = currentLayer.getReferenceLine().getLine();
      const refLineLength = referenceLine.details.skuLength;
      const ratio = currentSelectedLength / refLineLength;

      // console.log(referenceLine.konvaLine.width() * ratio);

      currentLayer.addLine({
        initLeftX: pos.x,
        initLeftY: pos.y,
        // initRightX: pos.x,
        // initRightY: pos.y,
        initRightX: pos.x + referenceLine.konvaLine.width() * ratio,
        initRightY: pos.y,
        initDetails: {
          skuLength: currentSelectedLength,
          sku: currentSelectedSKUCode,
          strokeColor: currentSelectedColor,
          categoryCode: currentSelectedCategoryCode,
          isCompetitor: currentSelectedSKUCompetitor
        },
        initReference: false
      });
      isDrawingLine = false;
      addingSKULine = false;
      currentReport = ps3Manager.getReport();
    }
  };

  const wrapAnchors = (lineTarget) => {
    const { currentLayer } = getCurrentBayAndLayer();
    const currentKonvaLayer = currentLayer.konvaLayer;

    const { leftX, leftY, rightX, rightY, konvaLine } = lineTarget;

    const anchorLeft = new Konva.Rect({
      id: "anchorLeft",
      name: "anchor",
      height: 10,
      width: 10,
      fill: "white",
      stroke: "black",
      strokeWidth: 2,
      hitStrokeWidth: 10,
      x: leftX - 5,
      y: leftY - 5,
      draggable: true,
      // eslint-disable-next-line object-shorthand, func-names
      dragBoundFunc: function (pos) {
        return {
          x: pos.x,
          y: this.absolutePosition().y
        };
      }
    });

    const anchorRight = new Konva.Rect({
      id: "anchorRight",
      name: "anchor",
      height: 10,
      width: 10,
      fill: "white",
      stroke: "black",
      strokeWidth: 2,
      hitStrokeWidth: 10,
      x: rightX - 5,
      y: rightY - 5,
      draggable: true,
      // eslint-disable-next-line object-shorthand, func-names
      dragBoundFunc: function (pos) {
        return {
          x: pos.x,
          y: this.absolutePosition().y
        };
      }
    });

    const anchorMid = new Konva.Circle({
      id: "anchorMid",
      name: "anchor",
      radius: 8,
      x: (rightX + leftX) / 2 - 4,
      y: rightY,
      fill: "white",
      stroke: "black",
      strokeWidth: 2,
      draggable: true
    });

    currentKonvaLayer.add(anchorLeft);
    currentKonvaLayer.add(anchorMid);
    currentKonvaLayer.add(anchorRight);

    const updateLine = () => {
      const currentLine = currentLayer.getCurrentLine();

      const currentPoints = [
        anchorLeft.x() + 5,
        konvaLine.points()[1],
        anchorRight.x() + 5,
        konvaLine.points()[3]
      ];

      anchorMid.position({
        x: (currentPoints[0] + currentPoints[2]) / 2 - 4,
        y: currentPoints[1]
      });

      konvaLine.points(currentPoints);

      currentLine.update({
        newLeftX: currentPoints[0],
        newLeftY: currentPoints[1],
        newRightX: currentPoints[2],
        newRightY: currentPoints[3]
      });

      const { konvaLineText } = currentLine;
      if (konvaLineText) {
        konvaLineText.width(currentPoints[2] - currentPoints[0]);
      }

      currentLayer.konvaLayer.batchDraw();
    };

    const moveLine = () => {
      const currentLine = currentLayer.getCurrentLine();

      const currentPoints = konvaLine.points();

      const currentLength = currentPoints[2] - currentPoints[0];
      const currentX = anchorMid.x();
      const currentY = anchorMid.y();
      const newLineLeftX = currentX - currentLength / 2;
      const newLineRightX = currentX + currentLength / 2;

      const newLinePoints = [newLineLeftX, currentY, newLineRightX, currentY];
      konvaLine.points(newLinePoints);
      currentLine.update({
        newLeftX: newLinePoints[0],
        newLeftY: newLinePoints[1],
        newRightX: newLinePoints[2],
        newRightY: newLinePoints[3]
      });

      anchorLeft.position({ x: newLineLeftX - 5, y: currentY - 5 });

      anchorRight.position({ x: newLineRightX - 5, y: currentY - 5 });

      const { konvaLineText } = currentLine;
      if (konvaLineText) {
        konvaLineText.position({ x: newLineLeftX, y: currentY + 12 });
      }
      currentLayer.konvaLayer.batchDraw();
    };

    anchorLeft.on("dragmove", updateLine);
    anchorRight.on("dragmove", updateLine);
    anchorMid.on("dragmove", moveLine);
  };

  const stageMouseMove = () => {
    if (!isPainting) {
      return;
    }

    if (isDrawingReferenceLine) {
      destroyAnchors();

      const { bay, currentLayer } = getCurrentBayAndLayer();

      const pos = bay.getScaledPointerPosition();

      let currentLine = currentLayer.getCurrentLine();

      const currentKonvaLine = currentLine.konvaLine;

      const firstPointX = currentKonvaLine.points()[0];
      const firstPointY = currentKonvaLine.points()[1];

      const newPoints = [firstPointX, firstPointY, pos.x, firstPointY];

      currentKonvaLine.points(newPoints);

      const newDetails = {
        skuLength: referenceLineLength,
        strokeColor: referenceLineColor
      };

      currentLine.update({
        newLeftX: firstPointX,
        newLeftY: firstPointY,
        newRightX: pos.x,
        newRightY: firstPointY,
        newDetails
      });

      currentLine = currentLayer.getCurrentLine();
      if (currentLine.konvaLineText) {
        currentLayer.konvaLayer.add(currentLine.konvaLineText);
      }

      wrapAnchors(currentLine);

      currentLayer.konvaLayer.batchDraw();
    }
  };

  const stageMouseUp = () => {
    isPainting = false;
    isDrawingLine = false;
    if (isDrawingReferenceLine) {
      isDrawingReferenceLine = false;
      isReferenceLinePresent = true;
    }
  };

  const stageClick = (e) => {
    const { attrs } = e.target;
    const { currentLayer } = getCurrentBayAndLayer();

    destroyAnchors();

    if (attrs.id && attrs.id.indexOf("line-") > -1) {
      currentLayer.setCurrentLine(attrs.id);

      const currentLine = currentLayer.getCurrentLine();

      if (currentLine.reference) {
        isALineSelected = false;
        isReferenceLineSelected = true;
      } else {
        isALineSelected = true;
        isReferenceLineSelected = false;
      }

      currentSelectedColor = currentLine.details.strokeColor;
      currentSelectedLength = currentLine.details.skuLength
        ? currentLine.details.skuLength
        : 100;
      currentSelectedCategoryCode = currentLine.details.categoryCode;
      currentSelectedSKUCompetitor = currentLine.details.isCompetitor;
      currentSelectedSKUCode = currentLine.details.sku;
    } else {
      isALineSelected = false;
      isReferenceLineSelected = false;
    }
    currentLayer.konvaLayer.draw();
  };

  const initializeBays = () => {
    const allBays = ps3Manager.getBays();

    allBays.forEach((bay) => {
      bay.initializeBay({ initialPadding: padding, initialScale: scale });

      const bayStage = bay.konvaStage;

      bayStage.on("mousemove", stageMouseMove);
      bayStage.on("mousedown", stageMouseDown);
      bayStage.on("mouseup", stageMouseUp);
      bayStage.on("click", stageClick);

      const imageObj = new Image();

      imageObj.crossOrigin = "Anonymous";
      imageObj.onload = () => {
        bay.loadImage(imageObj);
      };

      imageObj.src = bay.imageURL;

      // Konva.Image.fromURL(bay.imageURL, bay.loadImage);
    });
  };

  const createKonvaImage = ({ x, y, width, height, image }) => {
    return new Konva.Image({ x, y, width, height, image });
  };

  const fitStagesIntoParentContainer = () => {
    // now we need to fit stage into parent
    // const containerWidth = parentContainer.offsetWidth;
    const containerHeight = parentContainer.offsetHeight;

    // to do this we need to scale the stage
    // scale = containerWidth / stageWidth;
    scale = containerHeight / stageHeight;

    const allBays = ps3Manager.getBays();

    allBays.forEach((bay) => {
      const localStage = bay.konvaStage;

      localStage.width(stageWidth * scale);
      localStage.height(stageHeight * scale);
      localStage.scale({ x: scale, y: scale });

      localStage.draw();
    });
  };

  onMount(() => {
    ps3Manager = createPS3Manager({
      createKonvaStage,
      createKonvaLayer,
      createKonvaLine,
      createKonvaText,
      createKonvaImage,
      baysData: bays,
      processedSheet,
      containers,
      wrapAnchors
    });

    fitStagesIntoParentContainer();

    initializeBays();

    currentReport = ps3Manager.getReport();
  });

  const toggleAddLine = () => {
    isDrawingLine = !isDrawingLine;
  };

  const toggleReferenceLine = () => {
    isDrawingReferenceLine = !isDrawingReferenceLine;
    isDrawingLine = !isDrawingLine;
  };

  const updateCurrentLine = () => {
    const { currentLayer } = getCurrentBayAndLayer();

    let currentLine = currentLayer.getCurrentLine();

    const newDetails = {
      strokeColor: currentSelectedColor,
      skuLength: currentSelectedLength,
      sku: currentSelectedSKUCode,
      categoryCode: currentSelectedCategoryCode,
      isCompetitor: currentSelectedSKUCompetitor
    };

    currentLine.update({ newDetails });

    currentLine = currentLayer.getCurrentLine();

    currentLayer.konvaLayer.add(currentLine.konvaLineText);

    currentLine.konvaLine.stroke(currentSelectedColor);

    destroyAnchors();
    isALineSelected = false;
    currentSelectedSKUCode = null;
    currentSelectedLength = 100;

    currentLayer.konvaLayer.batchDraw();

    // console.log(currentLayer.getLines().length);

    currentReport = ps3Manager.getReport();
  };

  const deleteLine = () => {
    const { currentLayer } = getCurrentBayAndLayer();

    currentLayer.removeCurrentLine();
    destroyAnchors();
    currentLayer.konvaLayer.draw();
    isALineSelected = false;
  };

  $: if (currentBay !== null && ps3Manager) {
    ps3Manager.setCurrentBay(currentBay);

    const currentPS3Bay = ps3Manager.getCurrentBay();

    currentBayObject = currentPS3Bay;
    const currentLayer = currentPS3Bay.getCurrentLayer();

    const referenceLine = currentLayer.getReferenceLine();

    if (referenceLine) {
      isReferenceLinePresent = true;
    } else {
      isReferenceLinePresent = false;
    }

    setContext("konva", {
      getStage: () => currentPS3Bay.konvaStage
    });
  }

  $: if (currentSelectedSKUCode) {
    // currentSelectedSKUCode = currentSelectedSKU.skuCode;
    const currentSKU = currentBayObject.skus.find(
      (sku) => sku.skuCode === currentSelectedSKUCode
    );

    currentSelectedLength =
      currentSKU && currentSKU.length > 0 ? currentSKU.length : 100;
    currentSelectedCategoryCode = currentSKU.categoryCode;
    currentSelectedSKUCompetitor = currentSKU.isCompetitor;
  }

  // const setSelectedSKUValues = () => {
  //   // currentSelectedSKUCode = currentSelectedSKU.skuCode;
  //   const currentSKU = currentBayObject.skus.find(
  //     sku => sku.skuCode === currentSelectedSKUCode
  //   );

  //   currentSelectedLength = currentSKU.length;
  // };

  const saveCurrentSheet = () => {
    const sheetJSON = ps3Manager.toJSON();

    dispatch("savePS3Sheet", {
      ...sheetJSON,
      universeLength: currentUniverseLength
    });
  };

  const publishReport = async () => {
    currentReport = ps3Manager.getReport();

    const publishedSheet = await Promise.all(
      currentReport.map(async (bay) => {
        const imageData = ps3Manager.getBay(bay.id).konvaStage.toDataURL();
        const ref = FirebaseStorage.ref().child(
          `ps3/kusina/PG6/processed/${bay.id}-PROCESSED.png`
        );
        const snapshot = await ref.putString(imageData, "data_url");
        const imageURL = await snapshot.ref.getDownloadURL();

        return {
          imageURL,
          universeLength: currentUniverseLength,
          ...bay
        };
      })
    );

    dispatch("publishPS3Sheet", { publishedSheet });
  };

  const addSKULine = () => {
    addingSKULine = true;
  };
</script>

<div class="w-full h-full">
  <div class="w-full flex flex-row justify-between pb-2">
    <Button
      text="Publish"
      color="secondary"
      filled={true}
      on:click={publishReport} />
    <Button text="Save" filled={false} on:click={saveCurrentSheet} />

  </div>

  <div class="w-full justify-between flex flex-row" style="height: 887px;">
    <div
      class="border rounded border-darkerGray w-8/12 h-full"
      bind:this={parentContainer}>

      {#each bays as bay, i}
        <div
          bind:this={containers[i]}
          class:flex={currentBay === bay.id}
          class:hidden={currentBay !== bay.id}>
          {#if containers[i]}
            <slot />
          {/if}
        </div>
      {/each}

    </div>

    <div
      class="w-2/12 px-4 py-4 ml-2 h-full border rounded border-darkerGray
      items-center flex flex-col">

      {#if !isDrawingLine}
        <SelectField
          options={bayOptions}
          bind:value={currentBay}
          label="Current Bay" />
        <div class="w-full py-8">
          <TextField
            label="Universe Length (cm)"
            bind:textValue={currentUniverseLength} />
        </div>

        {#if !isALineSelected}
          {#if !isReferenceLinePresent}
            <div class="w-full pt-4 text-center">
              <Button
                text="Reference Line"
                color="primary"
                filled={false}
                on:click={toggleReferenceLine} />

            </div>
          {:else if !addingSKULine}
            <div class="w-full pt-4 text-center">
              <Button
                text="Add SKU"
                color="primary"
                filled={false}
                on:click={addSKULine} />

            </div>
          {:else if addingSKULine}
            <div class="w-full pt-3">
              <input
                id="strokeColor"
                type="color"
                bind:value={currentSelectedColor} />

            </div>

            <div class="w-full pt-5">
              <!-- <TextField bind:textValue={currentSelectedSKU} label="SKU" /> -->
              <SelectField
                label="Link SKU"
                options={buildSKUOptions(currentBayObject)}
                bind:value={currentSelectedSKUCode} />

            </div>

            <div class="w-full pt-8">
              <TextField
                bind:textValue={currentSelectedLength}
                label="Length (cm)" />

            </div>
            <div class="w-full pt-8 text-center">
              <Button
                text="Add Line"
                color="primary"
                filled={false}
                on:click={toggleAddLine} />

            </div>
          {/if}

          <!-- <div class="w-full">
          <Button text="Test Add" on:click={testAdd} />
        </div> -->
        {:else if isALineSelected}
          <div class="w-full h-full pt-2 text-center flex flex-col">
            <div class="w-full">
              <Button
                text="Delete"
                color="danger"
                filled={false}
                on:click={deleteLine} />
            </div>

            <div class="w-full pt-3">
              <input
                id="strokeColor"
                type="color"
                bind:value={currentSelectedColor} />

            </div>

            <div class="w-full pt-5">
              <!-- <TextField bind:textValue={currentSelectedSKU} label="SKU" /> -->
              <SelectField
                label="Link SKU"
                options={buildSKUOptions(currentBayObject)}
                bind:value={currentSelectedSKUCode} />

            </div>

            <div class="w-full pt-8">
              <TextField
                bind:textValue={currentSelectedLength}
                label="Length (cm)" />

            </div>

            <div class="w-full pt-8 self-end">
              <Button
                text="Save"
                color="primary"
                filled={false}
                on:click={updateCurrentLine} />
            </div>

          </div>
        {/if}
      {:else}
        <div class="w-full h-full text-sm">
          Start drawing a line on the image on the left.
        </div>
        <!-- <Button
      text="Export Image"
      color="primary"
      filled={false}
      on:click={exportImage} /> -->
      {/if}
    </div>

    <div
      class="w-2/12 px-2 py-4 ml-2 h-full border rounded border-darkerGray
      items-center flex flex-col overflow-y-auto">
      {#if currentReport}
        {#each currentReport as reportItem}
          <BayReportCard
            bayId={reportItem.bayReport[0].layerReport.category}
            skus={reportItem.bayReport[0].layerReport.skus}
            expectedSKUs={reportItem.bayReport[0].layerReport.expectedSKUs}
            totalCategoryLength={reportItem.bayReport[0].layerReport.totalCategoryLength}
            isCompletelyMapped={reportItem.bayReport[0].layerReport.isCompletelyMapped} />
        {/each}
      {/if}
    </div>

  </div>
</div>
