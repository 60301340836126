import { gql } from "apollo-boost";

// QUERIES
const CHAINS = gql`
  query chains(
    $findChainInput: FindChainInput
    $connectionArgs: ConnectionArgs
  ) {
    chains(findChainInput: $findChainInput, connectionArgs: $connectionArgs) {
      edges {
        cursor
        node {
          id
          code
          name
          account {
            id
            code
            name
            active
            version
          }
          active
          version
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

const GET_CHAIN = gql`
  query chain($code: String!) {
    chain(code: $code) {
      id
      code
      name
      account {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

// MUTATIONS

const ADD_CHAIN = gql`
  mutation addChain($addChainInput: AddChainInput!) {
    addChain(addChainInput: $addChainInput) {
      id
      code
      name
      account {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

const EDIT_CHAIN = gql`
  mutation editChain($editChainInput: EditChainInput!) {
    editChain(editChainInput: $editChainInput) {
      id
      code
      name
      account {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

const INACTIVATE_CHAIN = gql`
  mutation inactivateChain($code: String!) {
    inactivateChain(code: $code) {
      id
      code
      name
      account {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

const ACTIVATE_CHAIN = gql`
  mutation activateChain($code: String!) {
    activateChain(code: $code) {
      id
      code
      name
      account {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

export {
  CHAINS,
  GET_CHAIN,
  ADD_CHAIN,
  EDIT_CHAIN,
  INACTIVATE_CHAIN,
  ACTIVATE_CHAIN
};
