<script>
  import { flip } from "svelte/animate";
  import { dndzone } from "svelte-dnd-action";
  import { createEventDispatcher } from "svelte";
  import { TextField, Button } from "@saztrek/firefly";

  const flipDurationMs = 200;

  const dispatch = createEventDispatcher();

  export let items = [];

  export let type;
  export let title;
  export let side;

  let originalItems = [];

  let searchTerm = "";

  const handleSort = (e) => {
    items = e.detail.items;
    originalItems = [...items];
  };

  const handleFinalize = (e) => {
    items = e.detail.items;
    originalItems = [...items];
    dispatch("changedItems", {
      items: originalItems
    });
  };

  const dropTargetStyle = {
    background: "rgba(0, 107, 180, 0.1)"
  };

  const isPartOfSearch = (item) => {
    if (searchTerm === "") {
      return true;
    }
    const searchTermLower = searchTerm.toLowerCase();
    return (
      item.name.toLowerCase().indexOf(searchTermLower) > -1 ||
      item.code.toLowerCase().indexOf(searchTermLower) > -1
    );
  };

  $: if (searchTerm !== "") {
    originalItems = originalItems.filter((item) => isPartOfSearch(item));
  } else {
    originalItems = [...items];
  }

  const clearSearch = () => {
    searchTerm = "";
    originalItems = [...items];
  };

  const transferItem = (item) => {
    dispatch("transferItem", {
      item
    });
  };
</script>

<div
  style="min-height: 300px; min-width: 300px; max-width: 300px; max-height:
  300px;"
  class="rounded shadow border-darkerGray p-4">
  <div class="p-1 text-primary text-sm">{title}</div>
  <div class="flex flex-row justify-between px-1">
    <TextField type="search" compressed={true} bind:textValue={searchTerm} />
    <div class="-mt-1">
      <Button icon="close" on:click={clearSearch} />
    </div>
  </div>
  <section
    style="min-height:200px; max-height: 200px; max-width: 284px; min-width:
    284x"
    class="pr-4 pl-1 py-1 overflow-y-auto"
    use:dndzone={{ items, flipDurationMs, type, dropTargetStyle }}
    on:consider={handleSort}
    on:finalize={handleFinalize}>
    {#each originalItems as item (item.code)}
      <div
        class="shadow border-darkerGray rounded my-1"
        animate:flip={{ duration: flipDurationMs }}>
        <div
          class="py-2 px-4 w-full flex flex-row items-center"
          class:justify-between={side === 'left'}>
          {#if side === 'right'}
            <div class="pr-2">
              <Button
                size="small"
                icon="arrowLeft"
                on:click={transferItem(item)} />
            </div>
          {/if}
          <div class="flex flex-col">
            <div class="truncate text-sm">{item.name}</div>
            <div class="italic text-xs font-bold">{item.code}</div>
          </div>
          {#if side === 'left'}
            <div>
              <Button
                size="small"
                icon="arrowRight"
                on:click={transferItem(item)} />
            </div>
          {/if}
        </div>
      </div>
    {/each}
  </section>
</div>
