import { gql } from "apollo-boost";

// QUERIES

const BRANCH_ATTENDANCE_CONTEXTS = gql`
  query calendarBranchAttendanceContexts(
    $findCalendarBranchAttendanceContextInput: FindCalendarBranchAttendanceContextInput!
  ) {
    calendarBranchAttendanceContexts(
      findCalendarBranchAttendanceContextInput: $findCalendarBranchAttendanceContextInput
    ) {
      edges {
        node {
          branch {
            code
            name
          }
          policy {
            requiredHeadCount
            hasAbsencePenalty
          }
          employees {
            code
            name {
              firstName
              lastName
            }
            employeeNumber
          }
        }
      }
    }
  }
`;

const ALL_EMPLOYEES = gql`
  query {
    employees {
      edges {
        node {
          code
          name {
            firstName
            lastName
            suffix
            middleName
          }
          employeeNumber
        }
      }
    }
  }
`;

// MUTATIONS

const UPDATE_BRANCH_ATTENDANCE_CONTEXT = gql`
  mutation setCalendarBranchAttendanceContext(
    $setCalendarBranchAttendanceContextInput: SetCalendarBranchAttendanceContextInput!
  ) {
    setCalendarBranchAttendanceContext(
      setCalendarBranchAttendanceContextInput: $setCalendarBranchAttendanceContextInput
    ) {
      branch {
        id
        code
      }
      employees {
        id
        code
        name {
          firstName
          lastName
        }
        version
      }
      policy {
        requiredHeadCount
        hasAbsencePenalty
      }
      version
    }
  }
`;

export {
  BRANCH_ATTENDANCE_CONTEXTS,
  ALL_EMPLOYEES,
  UPDATE_BRANCH_ATTENDANCE_CONTEXT
};
