<script>
  import { Table, Link } from "@saztrek/firefly";
  import { navigateTo } from "svelte-router-spa";
  import { createEventDispatcher } from "svelte";

  export let edges;
  const columns = [
    {
      field: "code",
      name: "Code",
      renderComponent: Link,
      resolveProps: (data) => {
        return {
          href: `javascript:updateEmployeeListing('${data.code}')`
        };
      }
    },
    {
      field: "name",
      name: "Name",
      dataType: "string"
    }
  ];

  const dispatch = createEventDispatcher();

  const actions = [
    {
      icon: "pencil",
      title: "Edit",
      action: (data) => {
        navigateTo(`manufacturers/edit/${data.code}`);
      }
    },
    {
      icon: "trash",
      title: "Inactivate",
      action: (data) => {
        dispatch("inactivateManufacturer", { code: data.code });
      }
    }
  ];

  let nodes = [];

  $: if (edges && edges.length) {
    nodes = edges.map((edge) => {
      const { node } = edge;
      return {
        code: node.branch.code,
        name: node.branch.name
      };
    });
  }

  window.updateEmployeeListing = (branchCode) => {
    dispatch("branchCodeSelected", { branchCode });
  };
</script>

<div>
  <div class="pt-3 pl-3 pr-3 pb-8">
    <Table {columns} items={nodes} {actions} />
  </div>
</div>
