<script>
  import { KeypadMenu } from "@saztrek/firefly";
  import { navigateTo } from "svelte-router-spa";

  const items = [
    {
      label: "Branches",
      action: () => navigateTo("/branches/list"),
      app: "mermade"
    },
    {
      label: "Chains",
      action: () => navigateTo("/chains"),
      app: "don"
    },
    {
      label: "Accounts",
      action: () => navigateTo("/accounts"),
      app: "edgar"
    }
  ];
</script>

<div class="flex justify-center pt-20">
  <KeypadMenu {items} size="l" />
</div>
