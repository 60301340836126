<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier } from "@saztrek/firefly";
  import { getClient, mutate } from "svelte-apollo";

  import { ADD_CATEGORY } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import CategoryForm from "./CategoryForm.svelte";

  const cancelAdd = () => {
    navigateTo("/categories");
  };

  const client = getClient();

  let loading = false;

  const addCategory = async (event) => {
    try {
      const categoryData = { ...event.detail };
      loading = true;
      await mutate(client, {
        mutation: ADD_CATEGORY,
        variables: {
          addCategoryInput: {
            code: categoryData.code,
            name: categoryData.name
          }
        }
      });
      toastNotifier.success(
        "Success!",
        `Category ${categoryData.name} Added`,
        7000
      );
      navigateTo("/categories");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to add.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add Category">
    <CategoryForm
      mode="add"
      on:validCategory={addCategory}
      cancelAction={cancelAdd}
      {loading}
      buttonLabel="Add" />
  </Panel>
</div>
