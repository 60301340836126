<script>
  import { navigateTo } from "svelte-router-spa";
  import { getClient, mutate } from "svelte-apollo";
  import { toastNotifier } from "@saztrek/firefly";
  import { Functions } from "../../../config/firebase";
  import { Tenants } from "../../../middleware/database/tenants";
  import { currentUser } from "../../../stores/current_user";
  import {
    ADD_EMPLOYEE,
    ASSIGN_TO_DON,
    ASSIGN_TO_EDGAR,
    ASSIGN_TO_CALENDAR,
    UNASSIGN_FROM_CALENDAR,
    SET_CALENDAR_EMPLOYEE_CONTEXT
  } from "../../../gql";

  import Panel from "../../components/Panel.svelte";
  import UserForm from "./UserForm.svelte";

  export let currentRoute;
  export let params;

  /* svelte router force feeds these parameters (see above).
     Console warns if we don't do the exports.
     Svelte warns if they are unused, therefore putting them down here, in case code needs it.
  */
  // eslint-disable-next-line no-unused-vars
  const paramsFromRouter = params;
  // eslint-disable-next-line no-unused-vars
  const currentRouteFromRouter = currentRoute;

  const cancelAdd = () => {
    navigateTo("/users/list");
  };

  let userRolesLoading = false;
  let savingStatus = false;

  const user = {
    email: "",
    firstName: "",
    middleName: "",
    suffix: "",
    employeeNumner: "",
    roleGroup: "",
    supervisorCode: "",
    lastName: "",
    roles: [],
    tenantId: "",
    superAdmin: false
  };

  $: user.tenantId = $currentUser.tenantId;

  const fetchCurrentTenant = async (tenantId) => {
    userRolesLoading = true;
    if (!tenantId) {
      userRolesLoading = false;
      return;
    }
    const currentTenantData = await Tenants.findOne(tenantId);
    if (currentTenantData.exists) {
      const tenantData = currentTenantData.data();

      user.roles = tenantData.applications
        .filter((app) => app.enabled)
        .map((app) => {
          return {
            appName: app.appName,
            appCode: app.appCode,
            isAdmin: false
          };
        });
      userRolesLoading = false;
    } else {
      // eslint-disable-next-line no-console
      console.error("no tenant data found");
      userRolesLoading = false;
      throw Error("No tenant data found");
    }
  };

  $: fetchCurrentTenant($currentUser.tenantId);

  const client = getClient();

  const addUserHandler = async (event) => {
    savingStatus = true;
    const userData = { ...event.detail };

    userData.user.createdBy = $currentUser.id;

    const addUser = Functions.httpsCallable("addUser");
    try {
      await mutate(client, {
        mutation: ADD_EMPLOYEE,
        variables: {
          addEmployeeInput: {
            code: `${userData.user.tenantId}_${userData.user.email}`,
            name: {
              firstName: userData.user.firstName,
              middleName: userData.user.middleName,
              lastName: userData.user.lastName,
              suffix: userData.user.suffix
            },
            employeeNumber: userData.user.employeeNumber,
            roleGroup: userData.user.roleGroup,
            supervisorCode: userData.user.supervisorCode
          },
          tenantIdOverride: $currentUser.superAdmin
            ? userData.user.tenantId
            : undefined
        }
      });

      addUser(userData.user).then(() => {
        savingStatus = false;
        toastNotifier.success("Success!", `User ${user.email} Added`, 7000);

        navigateTo("/users/list");
      });

      if (
        userData.user.assignedDonBranches &&
        userData.user.assignedDonBranches.length > 0
      ) {
        mutate(client, {
          mutation: ASSIGN_TO_DON,
          variables: {
            setDONEmployeeAssignmentInput: {
              employeeCode: `${userData.user.tenantId}_${userData.user.email}`,
              branchCodes: userData.user.assignedDonBranches.map(
                (assignedDonBranch) => assignedDonBranch.code
              )
            }
          }
        });
      }

      if (
        userData.user.assignedEdgarBranches &&
        userData.user.assignedEdgarBranches.length > 0
      ) {
        mutate(client, {
          mutation: ASSIGN_TO_EDGAR,
          variables: {
            setEDGAREmployeeAssignmentInput: {
              employeeCode: `${userData.user.tenantId}_${userData.user.email}`,
              branchCodes: userData.user.assignedEdgarBranches.map(
                (assignedEdgarBranch) => assignedEdgarBranch.code
              )
            }
          }
        });
      }

      if (
        userData.user.assignedCalendarBranches &&
        userData.user.assignedCalendarBranches.length > 0
      ) {
        userData.user.assignedCalendarBranches.forEach((calendarBranch) => {
          mutate(client, {
            mutation: ASSIGN_TO_CALENDAR,
            variables: {
              branchCode: calendarBranch.code,
              employeeCodes: [
                `${userData.user.tenantId}_${userData.user.email}`
              ]
            }
          });
        });
      }

      if (
        userData.user.unassignedCalendarBranches &&
        userData.user.unassignedCalendarBranches.length > 0
      ) {
        userData.user.unassignedCalendarBranches.forEach(
          (unassignedCalendarBranch) => {
            mutate(client, {
              mutation: UNASSIGN_FROM_CALENDAR,
              variables: {
                branchCode: unassignedCalendarBranch.code,
                employeeCodes: [
                  `${userData.user.tenantId}_${userData.user.email}`
                ]
              }
            });
          }
        );
      }

      mutate(client, {
        mutation: SET_CALENDAR_EMPLOYEE_CONTEXT,
        variables: {
          setCalendarEmployeeContextInput: {
            employeeCode: `${userData.user.tenantId}_${userData.user.email}`,
            restDays: userData.user.restDays ? userData.user.restDays : [],
            stationary: false
          }
        }
      });
    } catch (error) {
      savingStatus = false;
      toastNotifier.danger("Failed to add!", `${error}`, 7000);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add User">
    <UserForm
      mode="add"
      on:validUser={addUserHandler}
      cancelAction={cancelAdd}
      {user}
      superAdmin={$currentUser.superAdmin}
      {userRolesLoading}
      {savingStatus}
      buttonLabel="Add" />
  </Panel>
</div>
