import AppLayout from "../../views/app/layout/index.svelte";
import Login from "../../views/public/login/index.svelte";
import MermadeHome from "../../views/app/landing/MermadeHome.svelte";
import UsersHome from "../../views/app/user/UsersHome.svelte";

import UserList from "../../views/app/user/Users.svelte";
import AddUser from "../../views/app/user/AddUser.svelte";
import EditUser from "../../views/app/user/EditUser.svelte";
import BranchAssignments from "../../views/app/user/BranchAssignments.svelte";

import BranchesHome from "../../views/app/branchModule/BranchesHome.svelte";

import Accounts from "../../views/app/branchModule/account/Accounts.svelte";
import AddAccount from "../../views/app/branchModule/account/AddAccount.svelte";
import EditAccount from "../../views/app/branchModule/account/EditAccount.svelte";

import ChainApp from "../../views/app/branchModule/chain/Chains.svelte";
import AddChain from "../../views/app/branchModule/chain/AddChain.svelte";
import EditChain from "../../views/app/branchModule/chain/EditChain.svelte";

import BranchApp from "../../views/app/branchModule/Branches.svelte";
import AddBranch from "../../views/app/branchModule/AddBranch.svelte";
import EditBranch from "../../views/app/branchModule/EditBranch.svelte";

import BrandApp from "../../views/app/skuModule/brand/Brands.svelte";
import AddBrand from "../../views/app/skuModule/brand/AddBrand.svelte";
import EditBrand from "../../views/app/skuModule/brand/EditBrand.svelte";

import CategoryApp from "../../views/app/skuModule/category/Categories.svelte";
import AddCategory from "../../views/app/skuModule/category/AddCategory.svelte";
import EditCategory from "../../views/app/skuModule/category/EditCategory.svelte";

import ManufacturerApp from "../../views/app/skuModule/manufacturer/Manufacturers.svelte";
import AddManufacturer from "../../views/app/skuModule/manufacturer/AddManufacturer.svelte";
import EditManufacturer from "../../views/app/skuModule/manufacturer/EditManufacturer.svelte";

import SKUHome from "../../views/app/skuModule/SKUHome.svelte";
import SKUApp from "../../views/app/skuModule/SKUs.svelte";
import AddSKU from "../../views/app/skuModule/AddSKU.svelte";
import EditSKU from "../../views/app/skuModule/EditSKU.svelte";

import DONHome from "../../views/app/don/DONHome.svelte";
import DONApp from "../../views/app/don/sheet/DONSheets.svelte";
import DONCompliance from "../../views/app/don/compliance/DONCompliance.svelte";
import DONReports from "../../views/app/don/report/DONReport.svelte";

import EDGARHome from "../../views/app/edgar/EDGARHome.svelte";
import EDGARCurrentWeek from "../../views/app/edgar/currentweek/CurrentWeek.svelte";
import EDGARCorrection from "../../views/app/edgar/correction/CompareSheet.svelte";
import EDGARSheetContext from "../../views/app/edgar/sheetcontexts/SheetContext.svelte";
import EditEDGARSheetContext from "../../views/app/edgar/sheetcontexts/EditSheetContext.svelte";
import AddEDGARSheetContext from "../../views/app/edgar/sheetcontexts/AddSheetContext.svelte";

import BranchAttendance from "../../views/app/calendar/branchAttendance/BranchAttendanceContexts.svelte";
import WeekViewApp from "../../views/app/calendar/weekview/WeekView.svelte";
import CalendarHome from "../../views/app/calendar/CalendarHome.svelte";
import AddBranchAttendance from "../../views/app/calendar/branchAttendance/AddBranchAttendanceContext.svelte";

import CarryTemplateApp from "../../views/app/skuModule/carrytemplate/CarryTemplates.svelte";
import AddCarryTemplate from "../../views/app/skuModule/carrytemplate/AddCarryTemplate.svelte";
import EditCarryTemplate from "../../views/app/skuModule/carrytemplate/EditCarryTemplate.svelte";

import LeaveRequestsApp from "../../views/app/calendar/leaveRequest/LeaveRequests.svelte";

import UsersToReset from "../../views/app/user/resetpasswordlist/UsersToReset.svelte";

import EDGARCompliance from "../../views/app/edgar/compliance/CompliancePage.svelte";
import EDGARSheetSummary from "../../views/app/edgar/sheetSummary/SheetSummary.svelte";
import EDGARSheetSummarySetup from "../../views/app/edgar/sheetSummary/SheetSummaryCRUD.svelte";
import AddEDGARSheetSummaryContext from "../../views/app/edgar/sheetSummary/AddEDGARSheetSummaryContext.svelte";
import EditEDGARSheetSummaryContext from "../../views/app/edgar/sheetSummary/EditEDGARSheetSummaryContext.svelte";

import BranchAttendanceSheet from "../../views/app/calendar/branchAttendanceSheet/BranchAttendanceSheet.svelte";
import AddBranchAttendanceSheet from "../../views/app/calendar/branchAttendanceSheet/AddBranchAttendanceSheet.svelte";
import RebuildBranchAttendanceSheet from "../../views/app/calendar/branchAttendanceSheet/RebuildBranchAttendanceSheet.svelte";

import TenantConfigs from "../../views/app/tenant/TenantConfig.svelte";
import EditTenantConfig from "../../views/app/tenant/EditTenantConfig.svelte";
import AddTenantConfig from "../../views/app/tenant/AddTenantConfig.svelte";

import NEMOHome from "../../views/app/nemo/NEMOHome.svelte";
import NEMOSheets from "../../views/app/nemo/sheets/NEMOSheets.svelte";

import PS3App from "../../views/app/ps3/EditPS3.svelte";
import PS3Home from "../../views/app/ps3/PS3Home.svelte";
import PS3List from "../../views/app/ps3/PS3List.svelte";
import PS3Summary from "../../views/app/ps3/PS3Summary.svelte";
import PS3PublishedList from "../../views/app/ps3/PS3PublishedList.svelte";
import ViewPublishedSheet from "../../views/app/ps3/ViewPublishedSheet.svelte";

import ImportUsers from "../../views/app/user/ImportUsers.svelte";
import Jobs from "../../views/app/jobs/Jobs.svelte";
import ViewJob from "../../views/app/jobs/ViewJob.svelte";

import EDGARCurrentWeek2 from "../../views/app/edgar/currentweek/CurrentWeek2.svelte";
import DONReports2 from "../../views/app/don/report/DONReport2.svelte";
import DONReports3 from "../../views/app/don/report/DONReport3.svelte";

const protectedRoutes = [
  {
    name: "/",
    layout: AppLayout,
    component: MermadeHome
  },
  {
    name: "login",
    component: Login
  },

  {
    name: "users",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: UsersHome
      },
      {
        name: "list",
        component: UserList
      },
      {
        name: "new",
        component: AddUser
      },
      {
        name: "edit/:id",
        component: EditUser
      },
      {
        name: "branchAssignments/:id",
        component: BranchAssignments
      },
      {
        name: "import",
        component: ImportUsers
      }
    ]
  },
  {
    name: "resetpasswordlist",
    component: AppLayout,
    nestedRoutes: [{ name: "index", component: UsersToReset }]
  },
  {
    name: "branches",
    layout: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: BranchesHome
      },

      {
        name: "list",
        component: BranchApp
      },
      {
        name: "new",
        component: AddBranch
      },
      {
        name: "edit/:id",
        component: EditBranch
      }
    ]
  },
  {
    name: "accounts",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: Accounts
      },
      {
        name: "new",
        component: AddAccount
      },
      {
        name: "edit/:id",
        component: EditAccount
      }
    ]
  },
  {
    name: "chains",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: ChainApp
      },
      {
        name: "new",
        component: AddChain
      },
      {
        name: "edit/:id",
        component: EditChain
      }
    ]
  },
  {
    name: "brands",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: BrandApp
      },
      {
        name: "new",
        component: AddBrand
      },
      {
        name: "edit/:id",
        component: EditBrand
      }
    ]
  },
  {
    name: "categories",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: CategoryApp
      },
      {
        name: "new",
        component: AddCategory
      },
      {
        name: "edit/:id",
        component: EditCategory
      }
    ]
  },
  {
    name: "manufacturers",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: ManufacturerApp
      },
      {
        name: "new",
        component: AddManufacturer
      },
      {
        name: "edit/:id",
        component: EditManufacturer
      }
    ]
  },
  {
    name: "skus",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: SKUHome
      },
      {
        name: "list",
        component: SKUApp
      },
      {
        name: "new",
        component: AddSKU
      },
      {
        name: "edit/:id",
        component: EditSKU
      }
    ]
  },

  {
    name: "don",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: DONHome
      },
      {
        name: "sheets",
        component: DONApp
      },
      {
        name: "compliance",
        component: DONCompliance
      },
      {
        name: "reports",
        component: DONReports
      },
      {
        name: "reports2",
        component: DONReports2
      },

      {
        name: "reports3",
        component: DONReports3
      }
    ]
  },
  {
    name: "edgar",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: EDGARHome
      },
      {
        name: "sheets",
        component: EDGARCurrentWeek
      },
      {
        name: "corrections/:id",
        component: EDGARCorrection
      },
      {
        name: "sheetcontexts",
        component: EDGARSheetContext
      },
      {
        name: "sheetcontexts/edit/:id",
        component: EditEDGARSheetContext
      },
      {
        name: "sheetcontexts/new",
        component: AddEDGARSheetContext
      },
      {
        name: "compliance",
        component: EDGARCompliance
      },
      {
        name: "reports",
        component: EDGARSheetSummary
      },
      {
        name: "reportssetup",
        component: EDGARSheetSummarySetup
      },
      {
        name: "reportssetup/new",
        component: AddEDGARSheetSummaryContext
      },
      {
        name: "reportssetup/edit/:id",
        component: EditEDGARSheetSummaryContext
      },
      {
        name: "sheets2",
        component: EDGARCurrentWeek2
      }
      /* {
        name: "new",
        component: AddAccount
      },
      {
        name: "edit/:id",
        component: EditAccount
      } */
    ]
  },
  {
    name: "calendar",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: CalendarHome
      },
      {
        name: "branchattendance",
        component: BranchAttendance
      },
      {
        name: "branchattendance/new",
        component: AddBranchAttendance
      },
      {
        name: "weekview",
        component: WeekViewApp
      },
      { name: "leaverequests", component: LeaveRequestsApp },
      {
        name: "branchattendancesheets",
        component: BranchAttendanceSheet
      },
      {
        name: "branchattendancesheets/new",
        component: AddBranchAttendanceSheet
      },
      {
        name: "branchattendancesheets/rebuild",
        component: RebuildBranchAttendanceSheet
      }
    ]
  },
  {
    name: "carrytemplates",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: CarryTemplateApp
      },
      {
        name: "new",
        component: AddCarryTemplate
      },
      {
        name: "edit/:id",
        component: EditCarryTemplate
      }
    ]
  },
  {
    name: "tenantconfigs",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: TenantConfigs
      },
      {
        name: "new",
        component: AddTenantConfig
      },
      {
        name: "edit/:id",
        component: EditTenantConfig
      }
    ]
  },
  {
    name: "nemo",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: NEMOHome
      },
      {
        name: "sheets",
        component: NEMOSheets
      }
    ]
  },
  {
    name: "ps3",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: PS3Home
      },
      {
        name: "list",
        component: PS3List
      },
      {
        name: "edit/:id",
        component: PS3App
      },
      {
        name: "summary",
        component: PS3Summary
      },
      {
        name: "published",
        component: PS3PublishedList
      },
      {
        name: "viewpublished/:id",
        component: ViewPublishedSheet
      }
    ]
  },
  {
    name: "jobs",
    component: AppLayout,
    nestedRoutes: [
      {
        name: "index",
        component: Jobs
      },
      {
        name: "view/:id",
        component: ViewJob
      }
    ]
  }
];
export { protectedRoutes };
