import ExcelJS from "exceljs";

import { exportToXLSX } from "../../../../utilities/export";
import { skuMap } from "./tempCP";

const formatAndExportCPReport = (
  flattenedSheets,
  fromDate,
  toDate,
  chainCode
) => {
  const workbook = new ExcelJS.Workbook();

  const sheetName = chainCode === "" ? "All Chains" : chainCode;

  const worksheet = workbook.addWorksheet(sheetName);

  if (chainCode === "GAISANOGRAND") {
    worksheet.getColumn("A").width = 12;
    worksheet.getColumn("B").width = 15;
    worksheet.getColumn("C").width = 90;
    worksheet.getColumn("D").width = 6;
    worksheet.getColumn("E").width = 6;
    worksheet.getColumn("F").width = 6;
    worksheet.getColumn("G").width = 6;
    worksheet.getColumn("H").width = 6;
    worksheet.getColumn("I").width = 6;
    worksheet.getColumn("J").width = 6;
    worksheet.getColumn("K").width = 6;
    worksheet.getColumn("L").width = 6;
    worksheet.getColumn("M").width = 6;
    worksheet.getColumn("N").width = 12;

    worksheet.getColumn("B").numFmt = "@";
    worksheet.getColumn("L").hidden = true;

    worksheet.getCell("A1").value = "COLGATE PALMOLIVE";
    worksheet.getCell("A1").font = {
      name: "Tahoma",
      bold: true,
      size: 8
    };

    worksheet.getCell("A2").value = "DATE";
    worksheet.getCell("A2").font = {
      name: "Tahoma",
      bold: true,
      size: 8,
      italic: true
    };

    worksheet.getCell("B2").value = `${fromDate} - ${toDate}`;
    worksheet.getCell("B2").font = {
      italic: true,
      name: "Tahoma",
      size: 8
    };
    const headerRows = worksheet.getRow(7);
    headerRows.font = {
      name: "Tahoma",
      bold: true,
      size: 8
    };
    headerRows.height = 30;
    headerRows.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true
    };

    worksheet.getCell("I6").value = "pcs";

    worksheet.getCell("I6").font = {
      color: { argb: "FFFF0000" },
      size: 8,
      bold: true
    };
    worksheet.getCell("I6").alignment = {
      horizontal: "center",
      vertical: "middle"
    };

    worksheet.getCell("J6").value = "cases";

    worksheet.getCell("J6").font = {
      color: { argb: "FFFF0000" },
      size: 8,
      bold: true
    };
    worksheet.getCell("J6").alignment = {
      horizontal: "center",
      vertical: "middle"
    };

    worksheet.getCell("K6").value = "pcs";

    worksheet.getCell("K6").font = {
      color: { argb: "FFFF0000" },
      size: 8,
      bold: true
    };
    worksheet.getCell("K6").alignment = {
      horizontal: "center",
      vertical: "middle"
    };

    worksheet.getCell("M6").value = "cases";

    worksheet.getCell("M6").font = {
      color: { argb: "FFFF0000" },
      size: 8,
      bold: true
    };
    worksheet.getCell("M6").alignment = {
      horizontal: "center",
      vertical: "middle"
    };

    worksheet.getCell("N6").value = "cases";
    worksheet.getCell("N6").font = {
      color: { argb: "FFFF0000" },
      size: 8,
      bold: true
    };
    worksheet.getCell("N6").alignment = {
      horizontal: "center",
      vertical: "middle"
    };

    worksheet.getColumn("N").numFmt = "0.00";
    if (chainCode !== "") {
      worksheet.getCell("A7").value = `${chainCode} CODE`;
    }
    worksheet.getCell("B7").value = "Item Barcode";
    worksheet.getCell("C7").value = "BRAND/VARIANT";
    worksheet.getCell("D7").value = "SIZE";

    worksheet.getCell("E7").value = "COLPAL PCS/CS";
    worksheet.getCell("E7").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFF0000" },
      bgColor: { argb: "FFFF0000" }
    };

    worksheet.getCell("E7").font = {
      bold: true,
      name: "Tahoma",
      size: 8,
      color: { argb: "FFFFFFFF" }
    };

    // worksheet.mergeCells("F6:F7");
    worksheet.getCell("F7").value = "GRAND SELLING UNIT";
    worksheet.getCell("F7").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF002060" },
      bgColor: { argb: "FF002060" }
    };

    worksheet.getCell("F7").font = {
      bold: true,
      name: "Tahoma",
      size: 7,
      color: { argb: "FFFFFFFF" }
    };

    worksheet.getCell("G7").value = "LOC";
    worksheet.getCell("H7").value = "MAX CAP";
    worksheet.getCell("I7").value = "BO";
    worksheet.getCell("J7").value = "WH INV";
    worksheet.getCell("K7").value = "SA INV";
    worksheet.getCell("L7").value = "DEL";
    worksheet.getCell("M7").value = "DEL";
    // worksheet.getCell("N7").value = "TOTAL INV";
    worksheet.getCell("N7").value = "TOTAL INV";

    let branchCode = "";

    for (
      let i = 0, currentRow = 8;
      i < flattenedSheets.length;
      i += 1, currentRow += 1
    ) {
      const currentObject = flattenedSheets[i];

      if (branchCode !== currentObject.branchCode) {
        branchCode = currentObject.branchCode;
        worksheet.mergeCells(`A${currentRow}:O${currentRow}`);
        worksheet.getCell(
          `A${currentRow}`
        ).value = `${branchCode} - ${currentObject.branchName}`;
        worksheet.getCell(`A${currentRow}`).style.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD9D9D9" },
          bgColor: { argb: "FFD9D9D9" }
        };
        currentRow += 1;
      }

      let skuMapObject = skuMap.find(
        (sku) => sku.skuCode === currentObject.code
      );

      if (!skuMapObject) {
        skuMapObject = {
          size: "",
          gsSellingUnit: 0,
          storageLoc: ""
        };
      }

      skuMapObject.gsSellingUnit = parseInt(skuMapObject.gsSellingUnit, 10);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(skuMapObject.gsSellingUnit)) {
        skuMapObject.gsSellingUnit = 0;
      }

      worksheet.getCell(`B${currentRow}`).value = `${currentObject.code}`;
      worksheet.getCell(`C${currentRow}`).value = currentObject.name;
      worksheet.getCell(`D${currentRow}`).value = skuMapObject.size;
      worksheet.getCell(`E${currentRow}`).value = currentObject.unitCount;
      worksheet.getCell(`F${currentRow}`).value = parseInt(
        skuMapObject.gsSellingUnit,
        10
      );
      worksheet.getCell(`G${currentRow}`).value = skuMapObject.storageLoc;
      worksheet.getCell(`I${currentRow}`).value =
        currentObject.totalBadOrderUnits;
      worksheet.getCell(`J${currentRow}`).value =
        currentObject.endingContainers;
      worksheet.getCell(`K${currentRow}`).value = currentObject.endingUnits;
      worksheet.getCell(`L${currentRow}`).value =
        currentObject.totalDeliveryUnits;

      worksheet.getCell(`M${currentRow}`).value = {
        formula: `L${currentRow}/E${currentRow}`
      };

      worksheet.getCell(`N${currentRow}`).value = {
        formula: `((K${currentRow}*F${currentRow})/E${currentRow})+J${currentRow}+(L${currentRow}/E${currentRow})-(I${currentRow}/E${currentRow})`
      };
    }
  } else {
    worksheet.getColumn("A").width = 20;
    worksheet.getColumn("B").width = 15;
    worksheet.getColumn("C").width = 90;
    worksheet.getColumn("D").width = 6;
    worksheet.getColumn("E").width = 6;
    worksheet.getColumn("F").width = 6;
    worksheet.getColumn("G").width = 8;
    worksheet.getColumn("H").width = 8;
    worksheet.getColumn("I").width = 8;
    worksheet.getColumn("J").width = 8;

    worksheet.getColumn("B").numFmt = "@";
    worksheet.getColumn("L").hidden = true;

    worksheet.getColumn("J").numFmt = "0.00";
    worksheet.getColumn("G").numFmt = "0.00";
    worksheet.getColumn("H").numFmt = "0.00";

    worksheet.mergeCells("F6:J6");

    worksheet.getCell("F6").alignment = {
      horizontal: "center",
      vertical: "middle"
    };

    worksheet.getCell("F6").font = {
      name: "Tahoma",
      bold: true,
      size: 8
    };

    worksheet.getCell("F6").border = {
      top: { style: "medium" },
      left: { style: "medium" },
      bottom: { style: "medium" },
      right: { style: "medium" }
    };

    worksheet.getCell("F6").value = "Cases";

    worksheet.getCell("A1").value = "COLGATE PALMOLIVE";
    worksheet.getCell("A1").font = {
      name: "Tahoma",
      bold: true,
      size: 8
    };

    worksheet.getCell("A2").value = "DATE";
    worksheet.getCell("A2").font = {
      name: "Tahoma",
      bold: true,
      size: 8,
      italic: true
    };

    worksheet.getCell("B2").value = `${fromDate} - ${toDate}`;
    worksheet.getCell("B2").font = {
      italic: true,
      name: "Tahoma",
      size: 8
    };
    const headerRows = worksheet.getRow(7);
    headerRows.font = {
      name: "Tahoma",
      bold: true,
      size: 8
    };
    headerRows.height = 30;
    headerRows.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true
    };

    worksheet.getColumn("N").numFmt = "0.00";

    worksheet.getCell("A7").value = "Category";

    worksheet.getCell("B7").value = "Item Barcode";
    worksheet.getCell("C7").value = "BRAND/VARIANT";
    worksheet.getCell("D7").value = "SIZE";

    worksheet.getCell("E7").value = "COLPAL PCS/CS";

    worksheet.getCell("F7").value = "Beg Inv";
    worksheet.getCell("G7").value = "Delivery";
    worksheet.getCell("H7").value = "End Inv";
    worksheet.getCell("I7").value = "Bad Order";
    worksheet.getCell("J7").value = "Offtake";
    worksheet.getCell("K7").value = "S.O.";

    let branchCode = "";

    for (
      let i = 0, currentRow = 8;
      i < flattenedSheets.length;
      i += 1, currentRow += 1
    ) {
      const currentObject = flattenedSheets[i];

      if (branchCode !== currentObject.branchCode) {
        branchCode = currentObject.branchCode;
        worksheet.mergeCells(`A${currentRow}:O${currentRow}`);
        worksheet.getCell(
          `A${currentRow}`
        ).value = `${branchCode} - ${currentObject.branchName}`;
        worksheet.getCell(`A${currentRow}`).style.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD9D9D9" },
          bgColor: { argb: "FFD9D9D9" }
        };
        currentRow += 1;
      }
      worksheet.getCell(
        `A${currentRow}`
      ).value = `${currentObject.categoryName}`;
      worksheet.getCell(`B${currentRow}`).value = `${currentObject.code}`;
      worksheet.getCell(`C${currentRow}`).value = currentObject.name;
      // worksheet.getCell(`D${currentRow}`).value = skuMapObject.size;
      worksheet.getCell(`E${currentRow}`).value = currentObject.unitCount;

      worksheet.getCell(`F${currentRow}`).value =
        currentObject.beginningContainers;

      worksheet.getCell(`G${currentRow}`).value =
        currentObject.totalDeliveryUnits / currentObject.unitCount;

      worksheet.getCell(`H${currentRow}`).value =
        currentObject.totalEndingUnits / currentObject.unitCount;

      worksheet.getCell(`I${currentRow}`).value =
        currentObject.totalBadOrderUnits / currentObject.unitCount;

      worksheet.getCell(`J${currentRow}`).value =
        currentObject.offtakeContainers;
    }
  }

  workbook.xlsx
    .writeBuffer({
      base64: true
    })
    .then((xls64) => {
      exportToXLSX(
        xls64,
        `EDGAR Sheets ${sheetName} from ${fromDate} to  ${toDate}`
      );
    });
};

export { formatAndExportCPReport };
