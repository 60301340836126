<script>
  import { onMount, createEventDispatcher } from "svelte";

  export let cardStatus = "";

  let cardElement;

  const dispatch = createEventDispatcher();

  onMount(() => {
    if (cardElement) {
      cardElement.addEventListener("click", () => {
        cardElement.classList.toggle("is-flipped");
        dispatch("cardFlipped");
      });
    }
  });
</script>

<div class="scene w-full cursor-pointer">
  <div class="card" bind:this={cardElement}>
    <div class="card__face card__face--front shadow rounded h-full">
      <slot name="face" />
    </div>
    <div class="card__face card__face--back shadow rounded h-full">
      <slot name="back" />
    </div>
  </div>
</div>
<slot />
