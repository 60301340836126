<script>
  import { getClient, query } from "svelte-apollo";

  import dayjs from "dayjs";

  import {
    SelectField,
    LoadingContent,
    Icon,
    Health,
    EmptyButton,
    Spinner,
    Button
  } from "@saztrek/firefly";

  import { exportToCSV } from "../../../../utilities/export";

  import {
    dateFormatToUI,
    dateFormatToServer
  } from "../../../../utilities/dateFormatter";

  import { currentTenant } from "../../../../stores/current_tenant";

  import {
    nearestEndDay,
    nearestStartDay,
    getNearestEndDay,
    getNearestStartDay
  } from "../../edgar/edgarUtils";

  import {
    BRANCH_ATTENDANCE_CONTEXTS,
    BRANCH_ATTENDANCE_SHEETS
  } from "../../../../gql";

  const client = getClient();

  let currentStartDay = nearestStartDay;
  let currentEndDay = nearestEndDay;
  let weekDates;

  const adjustWeekDates = () => {
    weekDates = [
      currentStartDay,
      currentStartDay.add(1, "day"),
      currentStartDay.add(2, "day"),
      currentStartDay.add(3, "day"),
      currentStartDay.add(4, "day"),
      currentStartDay.add(5, "day"),
      currentStartDay.add(6, "day")
    ];
  };

  $: if ($currentTenant.tenantVariables) {
    currentStartDay = getNearestStartDay();
    currentEndDay = getNearestEndDay();
    adjustWeekDates();
  }

  const allBranchContextsOp = query(client, {
    query: BRANCH_ATTENDANCE_CONTEXTS,
    variables: {
      findCalendarBranchAttendanceContextInput: {}
    }
  });

  let branchAttendanceSheetsOp;

  let branchCode;
  let selectedVisit;

  const processBranches = (branchesData) => {
    const { calendarBranchAttendanceContexts } = branchesData;
    const branchOptions = calendarBranchAttendanceContexts.edges.map((edge) => {
      const { node } = edge;
      const { branch } = node;
      return {
        option: branch.name,
        value: branch.code
      };
    });

    branchOptions.unshift({ option: "Choose a Branch", value: null });
    return branchOptions;
  };

  $: if (branchCode) {
    if (!branchAttendanceSheetsOp) {
      branchAttendanceSheetsOp = query(client, {
        query: BRANCH_ATTENDANCE_SHEETS,
        variables: {
          findCalendarBranchAttendanceSheetInput: {
            fromDate: dateFormatToServer(currentStartDay),
            toDate: dateFormatToServer(currentEndDay),
            branchCode
          }
        }
      });
    } else {
      branchAttendanceSheetsOp.refetch({
        findCalendarBranchAttendanceSheetInput: {
          fromDate: dateFormatToServer(currentStartDay),
          toDate: dateFormatToServer(currentEndDay),
          branchCode
        }
      });
    }
    selectedVisit = null;
  }

  const resolveVisit = (dayDetail) => {
    if (dayDetail.visits && dayDetail.visits.length === 1) {
      if (dayDetail.visits[0].checkOut === null) {
        return "checkedIn";
      }

      return "checkedOut";
    }

    if (dayDetail.visits && dayDetail.visits.length > 1) {
      return "multipleVisits";
    }

    if (dayDetail.restDay) {
      return "restDay";
    }

    if (dayDetail.visits && dayDetail.visits.length === 0) {
      return "novisit";
    }
    return "unknown";
  };

  const buildEmployeeMap = (edges) => {
    const employeeMap = [];

    edges.forEach((edge) => {
      const empArray = edge.node.employees;
      const currentSheetDetails = {
        code: edge.node.code,
        date: edge.node.date,
        branchCode: edge.node.branch.code,
        branchName: edge.node.branch.name
      };

      empArray.forEach((emp) => {
        const currentVisitDetail = {
          date: currentSheetDetails.date,
          branchCode: currentSheetDetails.branchCode,
          branchName: currentSheetDetails.branchName,
          restDay: emp.data.restDay,
          visits: emp.data.visits
        };
        if (
          employeeMap.findIndex(
            (empInMap) => empInMap.code === emp.details.code
          ) < 0
        ) {
          const employee = {
            firstName: emp.details.name.firstName,
            lastName: emp.details.name.lastName,
            code: emp.details.code,
            dayDetails: [currentVisitDetail],
            resolvedDayDetails: [
              emp.data.leaveStatus !== "YES"
                ? resolveVisit(currentVisitDetail)
                : "onLeave"
            ]
          };
          employeeMap.push(employee);
        } else {
          const existingEmp = employeeMap.find(
            (empFind) => empFind.code === emp.details.code
          );
          existingEmp.dayDetails.unshift(currentVisitDetail);
          existingEmp.resolvedDayDetails.unshift(
            emp.data.leaveStatus !== "YES"
              ? resolveVisit(currentVisitDetail)
              : "onLeave"
          );
        }
      });
    });

    // check for blank entries, which means they are not employees yet at that date

    employeeMap.forEach((employee) => {
      if (employee.resolvedDayDetails.length < 7) {
        // const lenToUnshift = 7 - employee.resolvedDayDetails.length;
        // for (let i = 0; i < lenToUnshift; i += 1) {
        //   // employee.resolvedDayDetails.unshift("noData");
        //  // employee.resolvedDayDetails.push("noData");
        // }
        const currentDate = dayjs();

        weekDates.forEach((weekDate) => {
          const dateStr = dateFormatToServer(weekDate);

          const employeeDayDetail = employee.dayDetails.find(
            (dayDetail) => dayDetail.date === dateStr
          );
          if (!employeeDayDetail) {
            if (weekDate.diff(currentDate) <= 0) {
              employee.resolvedDayDetails.unshift("noData");
            } else {
              employee.resolvedDayDetails.push("noData");
            }
          }
        });
      }
    });

    console.log(employeeMap);

    return employeeMap;
  };

  const showCheckinDetail = (employee, index) => {
    const dayToLookFor = currentStartDay.add(index, "day");

    // const dayDetail = employee.dayDetails[detailIndex];
    const dayDetail = employee.dayDetails.find(
      (detail) => detail.date === dateFormatToServer(dayToLookFor)
    );

    if (
      employee.resolvedDayDetails[index] === "checkedIn" ||
      employee.resolvedDayDetails[index] === "checkedOut" ||
      employee.resolvedDayDetails[index] === "multipleVisits"
    ) {
      const currentCheckin =
        dayDetail.visits[dayDetail.visits.length - 1].checkIn;
      const currentCheckout =
        dayDetail.visits[dayDetail.visits.length - 1].checkOut;

      const defaultVisitObject = {
        firstName: employee.firstName,
        lastName: employee.lastName,
        date: dayDetail.date
      };

      const checkInObject = currentCheckin
        ? {
            ...defaultVisitObject,
            ...currentCheckin
          }
        : null;

      const checkOutObject = currentCheckout
        ? {
            ...defaultVisitObject,
            ...currentCheckout
          }
        : null;

      selectedVisit = {
        checkin: {
          ...checkInObject
        },
        checkout: {
          ...checkOutObject
        }
      };
    } else {
      selectedVisit = null;
    }
  };

  const previousWeek = () => {
    currentEndDay = currentEndDay.subtract(7, "day");
    currentStartDay = currentStartDay.subtract(7, "day");
    adjustWeekDates();
  };

  const nextWeek = () => {
    currentEndDay = currentEndDay.add(7, "day");
    currentStartDay = currentStartDay.add(7, "day");
    adjustWeekDates();
  };

  const buildMapURL = (latitude, longitude) =>
    `https://www.google.com/maps?q=${latitude},${longitude}`;

  const translateResolvedDayDetail = (resolvedDayDetail) => {
    if (resolvedDayDetail === "novisit") {
      return "Absent";
    }

    if (resolvedDayDetail === "onLeave") {
      return "On Leave";
    }

    if (resolvedDayDetail === "restDay") {
      return "Rest Day";
    }

    return "Present";
  };

  let allSheetsOp;

  // TODO: Currently only getting the first visit object. Please handle multiple visits next iteration

  const exportAll = () => {
    allSheetsOp = query(client, {
      query: BRANCH_ATTENDANCE_SHEETS,
      variables: {
        findCalendarBranchAttendanceSheetInput: {
          fromDate: dateFormatToServer(currentStartDay),
          toDate: dateFormatToServer(currentEndDay)
        }
      }
    });

    const notApplicableString = "N/A";

    $allSheetsOp.then((response) => {
      const empMapAll = buildEmployeeMap(
        response.data.calendarBranchAttendanceSheets.edges
      );

      const cols = [
        `"Employee Name"`,
        `"Branch Code"`,
        `"Branch Name"`,
        `"Visit Date"`,
        `"Visit Status"`,
        `"Checkin Time"`,
        `"Checkin Photo URL"`,
        `"Checkin Map URL"`,
        `"Checkout Time"`,
        `"Checkout Photo URL"`,
        `"Checkout Map URL"`
      ]
        .join(",")
        .concat("\n");

      const empRows = empMapAll.map((emp) => {
        return emp.dayDetails.map((dayDetail, i) => {
          const resolvedDayDetail = emp.resolvedDayDetails[i];
          const hasCheckinDetails =
            (resolvedDayDetail === "checkedIn" ||
              resolvedDayDetail === "checkedOut" ||
              resolvedDayDetail === "multipleVisits") &&
            dayDetail.visits.length > 0;

          const checkIn = hasCheckinDetails
            ? dayDetail.visits[0].checkIn
            : null;
          const checkOut =
            hasCheckinDetails && dayDetail.visits[0].checkOut
              ? dayDetail.visits[0].checkOut
              : null;

          return {
            employee: `${emp.lastName}, ${emp.firstName}`,
            branchCode: dayDetail.branchCode,
            branchName: dayDetail.branchName,
            date: dayDetail.date,
            status: translateResolvedDayDetail(resolvedDayDetail),
            checkInTime: checkIn
              ? dayjs(checkIn.timestamp).format("hh:mm A")
              : notApplicableString,
            checkInPhotoURL: checkIn ? checkIn.photoPath : notApplicableString,
            checkInMapURL: checkIn
              ? buildMapURL(checkIn.latitude, checkIn.longitude)
              : notApplicableString,
            checkOutTime: checkOut
              ? dayjs(checkOut.timestamp).format("hh:mm A")
              : notApplicableString,
            checkOutPhotoURL: checkOut
              ? checkOut.photoPath
              : notApplicableString,
            checkOutMapURL: checkOut
              ? buildMapURL(checkOut.latitude, checkIn.longitude)
              : notApplicableString
          };
        });
      });
      const flattendEmpRows = empRows.flat();
      const rows = flattendEmpRows
        .map((empRow) => {
          return [
            `"${empRow.employee}"`,
            `"${empRow.branchCode}"`,
            `"${empRow.branchName}"`,
            `"${empRow.date}"`,
            `"${empRow.status}"`,
            `"${empRow.checkInTime}"`,
            `"${empRow.checkInPhotoURL}"`,
            `"${empRow.checkInMapURL}"`,
            `"${empRow.checkOutTime}"`,
            `"${empRow.checkOutPhotoURL}"`,
            `"${empRow.checkOutMapURL}"`
          ].join(",");
        })
        .join("\n");

      exportToCSV(
        cols,
        rows,
        `Attendance Sheets for ${dateFormatToServer(
          currentStartDay
        )} - ${dateFormatToServer(currentEndDay)}`
      );
    });
  };
</script>

<div class="flex flex-col items-center pt-8">
  <div class="px-4 w-full">

    <div
      class="w-full px-4 pb-5 h-24 border rounded border-lightGray flex flex-row
      justify-between">
      <div>
        <div class="flex flex-row w-full justify-end pt-4">
          <div class="flex flex-col w-24">
            <div class="w-24">
              <Health status="healthy">Present</Health>
            </div>
            <div class="w-24">
              <Health status="warning">On Site</Health>
            </div>
            <div class="w-24">
              <Health status="failure">Absent</Health>
            </div>
          </div>
          <div class="flex flex-col w-24">
            <div class="w-24">
              <Health customColor="#006BB4">Rest Day</Health>
            </div>
            <div class="w-24">
              <Health customColor="#DD0A73">On Leave</Health>
            </div>
          </div>

        </div>
      </div>
      <div class="m-0 text-base flex flex-row items-center justify-center pt-4">
        <div>
          <EmptyButton
            icon="arrowLeft"
            text=""
            size="tiny"
            on:click={previousWeek} />
        </div>
        <div class="w-64 text-center">
          {dateFormatToUI(currentStartDay.toDate())} to {dateFormatToUI(currentEndDay.toDate())}
        </div>
        <div>
          <EmptyButton
            icon="arrowRight"
            text=""
            size="tiny"
            on:click={nextWeek} />
        </div>

        <div class="px-4 h-16 self-center" style="min-width: 11rem;">
          {#await $allBranchContextsOp}
            <div class="p-4 w-20">
              <Spinner size="large" />
            </div>
          {:then branchesResult}
            <SelectField
              bind:value={branchCode}
              options={processBranches(branchesResult.data)}
              label="Branch" />
          {/await}
        </div>

      </div>

      <div class="flex items-center h-full pt-2">
        <Button
          text="Export All"
          color="secondary"
          filled={false}
          on:click={exportAll} />
      </div>
    </div>
  </div>
  <div class="px-4 w-full pt-4 flex flex-row">
    <div
      class="rounded px-4 shadow overflow-x-auto w-2/3"
      style="min-height: 840px;">
      {#if branchCode}
        {#await $branchAttendanceSheetsOp}
          <div class="pt-10">
            <LoadingContent />
            <div class="pt-5" />
            <LoadingContent />
            <div class="pt-5" />
            <LoadingContent />
            <div class="pt-5" />
          </div>
        {:then result}
          {#if result.data.calendarBranchAttendanceSheets.edges.length > 0}
            <div class="flex flex-row w-full">
              <div class="w-11/12">
                <div class="flex flex-col w-full">

                  <div class="flex flex-row pt-4 font-semibold">
                    <div class="w-48 text-sm h-8">Employee</div>
                    {#each weekDates as weekDate}
                      <div class="w-24 text-sm text-center">
                        {dateFormatToUI(weekDate)}
                      </div>
                    {/each}
                  </div>

                </div>
                <div class="flex flex-col w-full">
                  {#each buildEmployeeMap(result.data.calendarBranchAttendanceSheets.edges) as employee, i}
                    <div class="flex flex-row pt-4">
                      <div class="w-48 text-sm h-8 truncate pt-1 font-semibold">
                        {employee.lastName}, {employee.firstName}
                      </div>
                      <div class="flex flex-row rounded-full shadow-lg">
                        {#each employee.resolvedDayDetails as dayDetail, i}
                          <div
                            class="w-24 text-xs h-8 border-r-2 border-white
                            text-center"
                            style="padding-top: 0.45rem;"
                            class:rounded-l-full={i === 0}
                            class:rounded-r-full={i === employee.resolvedDayDetails.length - 1}
                            class:border-dotted={i !== employee.resolvedDayDetails.length - 1}
                            class:bg-warning={dayDetail === 'checkedIn'}
                            class:bg-secondary={dayDetail === 'checkedOut' || dayDetail === 'multipleVisits'}
                            class:bg-danger={dayDetail === 'novisit'}
                            class:bg-primary={dayDetail === 'restDay'}
                            class:bg-pinkAccent={dayDetail === 'onLeave'}
                            class:bg-darkerGray={dayDetail === 'noData'}>
                            {#if dayDetail !== 'noData'}
                              <a
                                href="#!"
                                class=" text-white text-sx no-underline
                                hover:underline text-center"
                                on:click={showCheckinDetail(employee, i)}>
                                detail
                              </a>
                            {:else}
                              <span>No Data</span>
                            {/if}
                          </div>
                        {/each}
                      </div>

                    </div>
                  {/each}
                </div>
              </div>

            </div>
          {:else}
            <div class="flex flex-col items-center pt-40">
              <Icon type="calendar" size="l" />
              <div>No Data found</div>
            </div>
          {/if}
        {/await}
      {:else}
        <div class="flex flex-col items-center pt-40">
          <Icon type="calendar" size="l" />
          <div>Choose a branch</div>
        </div>
      {/if}
    </div>
    <div class="pl-4 w-1/3 ">
      <div class="rounded px-4 shadow overflow-x-auto h-full">
        {#if selectedVisit}
          <div>
            <div class="text-lg pt-4 pb-4">
              {selectedVisit.checkin.lastName}, {selectedVisit.checkin.firstName}
              - {'Checked In at'} {selectedVisit.checkin.timestamp}
            </div>
            <div class="w-full flex flex-row justify-evenly">
              <div class="border rounded border-darkerGray">
                <img
                  src={selectedVisit.checkin.photoPath}
                  alt="Checkin"
                  style="height: 320px; width: 220px;" />
              </div>
              <div class="border rounded border-darkerGray">
                <iframe
                  title="map"
                  id="preview"
                  allowfullscreen=""
                  width="220"
                  height="320"
                  src="https://www.google.com/maps/embed/v1/place?q={selectedVisit.checkin.latitude},{selectedVisit.checkin.longitude}&key=AIzaSyAcnQPT-iyZz7TlHVCU4QviUfPcKfnopNE" />
              </div>
            </div>
          </div>
          {#if selectedVisit.checkout && selectedVisit.checkout.date}
            <div>
              <div class="text-lg pt-4 pb-4">
                {selectedVisit.checkout.lastName}, {selectedVisit.checkout.firstName}
                - {'Checked Out at'} {selectedVisit.checkout.timestamp}
              </div>
              <div class="w-full flex flex-row justify-evenly">
                <div class="border rounded border-darkerGray">
                  <img
                    src={selectedVisit.checkout.photoPath}
                    alt="Checkout"
                    style="height: 320px; width: 220px;" />
                </div>
                <div class="border rounded border-darkerGray">
                  <iframe
                    title="map"
                    id="preview"
                    allowfullscreen=""
                    width="220"
                    height="320"
                    src="https://www.google.com/maps/embed/v1/place?q={selectedVisit.checkout.latitude},{selectedVisit.checkout.longitude}&key=AIzaSyAcnQPT-iyZz7TlHVCU4QviUfPcKfnopNE" />
                </div>
              </div>
            </div>
          {/if}
        {/if}
      </div>
    </div>
  </div>

</div>
