import dayjs from "dayjs";
import { currentTenant } from "../../../stores/current_tenant";

let startingDay = 3;

currentTenant.subscribe((tenantConfiguration) => {
  if (tenantConfiguration.tenantVariables) {
    startingDay = tenantConfiguration.tenantVariables.find(
      (variable) => variable.code === "EDGAR_startDay"
    ).value;
  }
});

let now = dayjs();

const dayNow = now.day();

let nearestStartDay = now.day(startingDay);
let nearestEndDay = nearestStartDay.add(6, "day");

if (dayNow < startingDay) {
  // use last week
  now = now.subtract(1, "week");

  nearestStartDay = now.day(startingDay);

  now = now.add(1, "week");

  nearestEndDay = now.day(((parseInt(startingDay, 10) + 7) % 7) - 1);
}

const getNearestEndDay = () => {
  now = dayjs();
  nearestStartDay = now.day(startingDay);

  nearestEndDay = nearestStartDay.add(6, "day");

  if (dayNow < startingDay) {
    // use last week
    now = now.subtract(1, "week");

    nearestStartDay = now.day(startingDay);

    now = now.add(1, "week");

    nearestEndDay = now.day(((parseInt(startingDay, 10) + 7) % 7) - 1);
  }

  return nearestEndDay;
};

const getNearestStartDay = () => {
  now = dayjs();
  nearestStartDay = now.day(startingDay);

  if (dayNow < startingDay) {
    // use last week
    now = now.subtract(1, "week");

    nearestStartDay = now.day(startingDay);
  }

  return nearestStartDay;
};

export { nearestEndDay, nearestStartDay, getNearestEndDay, getNearestStartDay };
