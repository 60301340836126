const createLine = require("./lines");

const createLayer = ({
  konvaLayer,
  id,
  createKonvaLine,
  createKonvaText,
  wrapAnchors,
  drawLineText,

  data,
  processedBay
}) => {
  const destroy = () => {};
  let lines = [];

  let currentLineID = null;
  let expectedSKUs = data.skus;
  let { category } = data;

  if (processedBay) {
    const processedLayer = processedBay.layers.find((layer) => layer.id === id);
    if (processedLayer) {
      category = processedLayer.category;
      expectedSKUs = processedLayer.expectedSKUs;
      lines = processedLayer.lines.map((processedLine) => {
        const ps3Line = createLine({
          lineID: processedLine.lineID,
          initLeftX: processedLine.leftX,
          initLeftY: processedLine.leftY,
          initRightX: processedLine.rightX,
          initRightY: processedLine.rightY,
          createKonvaLine,
          createKonvaText,
          drawLineText,
          initReference: processedLine.reference,
          initDetails: processedLine.details
        });

        return ps3Line;
      });

      lines.forEach((line) => {
        konvaLayer.add(line.konvaLine);

        konvaLayer.add(line.konvaLineText);
      });

      // konvaLayer.draw();
    }
  }

  const findLine = (lineID) => lines.find((line) => line.lineID === lineID);

  const getLines = () => lines;

  const setCurrentLine = (lineID) => {
    const lineToSelect = findLine(lineID);
    if (lineToSelect) {
      currentLineID = lineID;
      wrapAnchors(lineToSelect.getLine());
    } else {
      throw new Error("Line ID not found in layer");
    }
  };

  const addLine = ({
    initLeftX,
    initLeftY,
    initRightX,
    initRightY,
    initReference,
    initDetails
  }) => {
    const newLineID = `${id}-line-${lines.length}`;

    const ps3Line = createLine({
      lineID: newLineID,
      initLeftX,
      initLeftY,
      initRightX,
      initRightY,
      createKonvaLine,
      createKonvaText,
      drawLineText,
      initReference,
      initDetails
    });
    lines = [...lines, ps3Line];

    konvaLayer.add(ps3Line.konvaLine);
    if (ps3Line.konvaLineText) {
      konvaLayer.add(ps3Line.konvaLineText);
    }

    setCurrentLine(newLineID);
  };

  const updateCurrentLine = ({
    newLeftX,
    newLeftY,
    newRightX,
    newRightY,
    newDetails
  }) => {
    const lineToUpdate = findLine(currentLineID);
    if (lineToUpdate) {
      lineToUpdate.update({
        newLeftX,
        newLeftY,
        newRightX,
        newRightY,
        newDetails
      });
    } else {
      throw new Error("Line ID not found in layer");
    }
  };

  const removeCurrentLine = () => {
    const lineToRemove = findLine(currentLineID);

    if (lineToRemove) {
      lineToRemove.destroy();

      lines = lines.filter((line) => line.lineID !== currentLineID);
      currentLineID = null;
    } else {
      throw new Error("Line ID not found in layer");
    }
  };

  const getCurrentLine = () => {
    const lineToSelect = findLine(currentLineID);
    if (lineToSelect) {
      return lineToSelect.getLine();
    }
    throw new Error("No Current Line Set");
  };

  const getReport = () => {
    // TODO: filter reference lines out

    let skus = lines
      .filter(
        (line) =>
          line.getLine().details.skuLength !== "" && !line.getLine().reference
      )
      .map((line) => {
        // TODO: this is wrong, find a way to not do this
        const updatedLine = line.getLine();
        return {
          skuLength: updatedLine.details.skuLength,
          sku: updatedLine.details.sku,
          strokeColor: updatedLine.details.strokeColor,
          categoryCode: updatedLine.details.categoryCode,
          isCompetitor: updatedLine.details.isCompetitor
        };
      });

    const totalCategoryLength = skus.reduce(
      (accumulator, sku) => accumulator + parseInt(sku.skuLength, 10),
      0
    );
    // TODO: lol, too lazy to think of a better solution
    skus = skus.map((line) => {
      return {
        ...line,
        percentage: line.skuLength / totalCategoryLength
      };
    });

    const isCompletelyMapped = skus.length >= expectedSKUs.length;

    return {
      skus,
      expectedSKUs,
      totalCategoryLength,
      isCompletelyMapped,
      category
    };
  };

  const toJSON = () => {
    return {
      id,
      expectedSKUs,
      category,
      lines: lines.map((line) => line.toJSON())
    };
  };

  const getReferenceLine = () => {
    return lines.find((line) => line.getLine().reference);
  };

  return {
    id,
    konvaLayer,
    destroy,
    getLines,
    addLine,
    updateCurrentLine,
    removeCurrentLine,
    setCurrentLine,
    getCurrentLine,
    getReport,
    getReferenceLine,
    toJSON
  };
};
module.exports = createLayer;
