<script>
  import { getClient, query } from "svelte-apollo";
  import { LoadingContent, Button, SelectField } from "@saztrek/firefly";
  import { DON_ASSIGNMENT, BRANCHES } from "../../../gql";

  import Panel from "../../components/Panel.svelte";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  const client = getClient();

  const donBranchAssignmentOp = query(client, {
    query: DON_ASSIGNMENT,
    variables: {
      employeeCode: decodeURIComponent(id)
    }
  });

  const branchesOp = query(client, {
    query: BRANCHES
  });

  let donBranchesOptions = [];

  let currentDONUserBranches = [];

  let allBranches = [];

  $donBranchAssignmentOp.then((response) => {
    if (response.data && response.data.donEmployeeAssignment) {
      const { branches } = response.data.donEmployeeAssignment;
      currentDONUserBranches = branches;

      $branchesOp.then((branchesResponse) => {
        if (branchesResponse.data && branchesResponse.data.branches) {
          allBranches = branchesResponse.data.branches.edges.map(
            (node) => node
          );

          donBranchesOptions = branchesResponse.data.branches.edges
            .filter(
              (branch) =>
                currentDONUserBranches.findIndex(
                  (currentBranch) => branch.node.code === currentBranch.code
                ) < 0
            )
            .map((branch) => {
              return {
                option: branch.node.name,
                value: branch.node.code
              };
            });
          donBranchesOptions.unshift({
            option: "Please choose a branch",
            value: null
          });
        } else {
          donBranchesOptions = [];
        }
      });
    } else {
      currentDONUserBranches = [];
    }
  });

  const removeDONBranch = (code) => {
    currentDONUserBranches = currentDONUserBranches.filter(
      (branch) => branch.code !== code
    );
    donBranchesOptions = [
      ...donBranchesOptions,
      {
        value: code,
        option: allBranches.find((branch) => branch.node.code === code).node
          .name
      }
    ];
  };

  let selectedDONBranchToAdd;

  const addDONBranchToEmployee = () => {
    if (
      selectedDONBranchToAdd !== undefined &&
      selectedDONBranchToAdd !== null
    ) {
      currentDONUserBranches = [
        ...currentDONUserBranches,
        {
          code: selectedDONBranchToAdd,
          name: allBranches.find(
            (branch) => branch.node.code === selectedDONBranchToAdd
          ).node.name
        }
      ];

      donBranchesOptions = donBranchesOptions.filter(
        (donBranch) => donBranch.value !== selectedDONBranchToAdd
      );
      selectedDONBranchToAdd = null;
    }
  };
</script>

<div>

  <div class="w-full flex items-center flex-row pt-10">
    <div class="w-1/2 shadow rounded">
      <div class="flex flex-row justify-between pt-5 px-5">
        <div class="font-bold">DON Branches</div>
      </div>

      <div class="flex flex-row justify-between pt-5 px-5">
        <div>
          <SelectField
            options={donBranchesOptions}
            bind:value={selectedDONBranchToAdd} />
        </div>
        <div>
          <Button
            text="Assign"
            color="primary"
            on:click={addDONBranchToEmployee} />
        </div>
      </div>

      {#await $donBranchAssignmentOp}
        <div class="p-10 w-full" style="min-height: 500px">
          <LoadingContent />
        </div>
      {:then donBranches}
        <div class="w-full p-5">
          {#each currentDONUserBranches as branch}
            <div class="flex flex-row w-full px-10">
              <div class="pt-2 h-10 w-11/12">{branch.name}</div>
              <div class="w-1/12">
                <Button icon="trash" on:click={removeDONBranch(branch.code)} />
              </div>
            </div>
          {/each}
        </div>
      {:catch error}
        <div>Something went wrong. Try refreshing the page</div>
      {/await}

    </div>
    <Panel header="EDGAR Branches">HELLO WORLD!</Panel>
  </div>

</div>
