<script>
  import { LoadingContent } from "@saztrek/firefly";

  export let loadingSKUs;
  export let skus;
</script>

{#if skus && skus.length > 0}
  <div
    class="overflow-auto flex w-full px-4 border border-darkerGray rounded pt-4"
    style="max-height: 600px; height: 600px; ">
    <table class="table-auto leading-5 w-full whitespace-no-wrap">
      <thead class="font-bold text-xs">
        <tr>
          <td class="px-1">Updated By</td>
          <td class="px-1">Category Code</td>
          <td class="px-1">Category Name</td>
          <td class="px-1">Segment</td>
          <td class="px-1">Brand Code</td>
          <td class="px-1">Brand Name</td>
          <td class="px-1 w-1/6">SKU Name</td>
          <td class="px-1">SKU Code</td>
          <td class="px-1">Expiry Date</td>
          <td class="px-1">Pullout Date</td>
          <td class="px-1">NearEx Ctrs</td>
          <td class="px-1">NearEx Units</td>
          <td class="px-1">Total</td>
          <td class="px-1">Branch Code</td>
          <td class="px-1">Branch Name</td>
          <td class="px-1">Chain Code</td>
          <td class="px-1">Chain Name</td>
          <td class="px-1">Date Created</td>
          <td class="px-1">Date Synced</td>
        </tr>
      </thead>
      {#each skus as sku, i}
        <tr class="border-b-darkerGray border-b text-xs h-10">
          <td class="px-1">{sku.updatedBy}</td>
          <td class="px-1">{sku.categoryCode}</td>
          <td class="px-1">{sku.categoryName}</td>
          <td class="px-1">{sku.segment}</td>
          <td class="px-1">{sku.brandCode}</td>
          <td class="px-1">{sku.brandName}</td>
          <td class="px-1">{sku.name}</td>
          <td class="px-1">{sku.code}</td>
          <td class="px-1">{sku.expiryDate}</td>
          <td class="px-1">{sku.pulloutDate}</td>
          <td class="px-1">{sku.nearExpiryContainers}</td>
          <td class="px-1">{sku.nearExpiryUnits}</td>
          <td class="px-1">{sku.totalNearExpiryUnits}</td>
          <td class="px-1">{sku.branchCode}</td>
          <td class="px-1">{sku.branchName}</td>
          <td class="px-1">{sku.chainCode}</td>
          <td class="px-1">{sku.chainName}</td>
          <td class="px-1">{sku.dateCreated}</td>
          <td class="px-1">{sku.dateSynced}</td>
        </tr>
      {/each}
    </table>
  </div>
{:else if loadingSKUs}
  <div class="w-full pt-2 h-full px-4 ">
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
  </div>
{/if}
