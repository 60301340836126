import { gql } from "apollo-boost";

// QUERIES
const BRANDS = gql`
  query brands(
    $findBrandInput: FindBrandInput
    $connectionArgs: ConnectionArgs
  ) {
    brands(findBrandInput: $findBrandInput, connectionArgs: $connectionArgs) {
      edges {
        cursor
        node {
          id
          code
          name
          manufacturer {
            id
            code
            name
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const GET_BRAND = gql`
  query brand($code: String!) {
    brand(code: $code) {
      id
      code
      name
      manufacturer {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

// MUTATIONS

const ADD_BRAND = gql`
  mutation addBrand($addBrandInput: AddBrandInput!) {
    addBrand(addBrandInput: $addBrandInput) {
      id
      code
      name
      manufacturer {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

const EDIT_BRAND = gql`
  mutation editBrand($editBrandInput: EditBrandInput!) {
    editBrand(editBrandInput: $editBrandInput) {
      id
      code
      name
      manufacturer {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

const INACTIVATE_BRAND = gql`
  mutation inactivateBrand($code: String!) {
    inactivateBrand(code: $code) {
      id
      code
      name
      manufacturer {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

const ACTIVATE_BRAND = gql`
  mutation activateBrand($code: String!) {
    activateBrand(code: $code) {
      id
      code
      name
      manufacturer {
        id
        code
        name
        active
        version
      }
      active
      version
    }
  }
`;

export {
  BRANDS,
  GET_BRAND,
  ADD_BRAND,
  EDIT_BRAND,
  INACTIVATE_BRAND,
  ACTIVATE_BRAND
};
