<script>
  import {
    Icon,
    LoadingContent,
    // TextField,
    Button,
    EmptyButton,
    toastNotifier
  } from "@saztrek/firefly";
  import { getClient, query } from "svelte-apollo";

  import { DON_SHEETS_LITE, BRANCHES, SHEETS_META } from "../../../../gql";
  import {
    dateFormatToServer,
    dateFormatToUI
  } from "../../../../utilities/dateFormatter";

  import { currentTenant } from "../../../../stores/current_tenant";

  import { exportToCSV } from "../../../../utilities/export";

  import {
    nearestStartDay,
    nearestEndDay,
    getNearestStartDay,
    getNearestEndDay
  } from "../../edgar/edgarUtils";

  let currentStartDay = nearestStartDay;
  let currentEndDay = nearestEndDay;

  $: if ($currentTenant.tenantVariables) {
    currentStartDay = getNearestStartDay();
    currentEndDay = getNearestEndDay();
  }

  import BranchList from "../../../components/BranchList.svelte";

  const isDaily = false;
  const daysToIncrement = 7;

  //   import CardView from "./CardView.svelte";
  import TableView from "../components/TableView.svelte";
  import MetaView from "./MetaView.svelte";

  const client = getClient();

  const sheetsOp = query(client, {
    query: DON_SHEETS_LITE,
    // fetchPolicy: "cache-and-network",
    variables: {
      findDONSheetInput: {
        fromInventoryDate: dateFormatToServer(currentStartDay),
        toInventoryDate: dateFormatToServer(currentEndDay)
      }
    }
  });

  const branchesOp = query(client, {
    query: BRANCHES
  });

  let branchSheetsOp;
  let allSheetsOp;

  let skus = [];

  let currentSheetCode = "";
  let currentBranch;

  const processBranches = (sheetsResult, branchList) => {
    if (!branchList.data.branches) {
      return [];
    }

    const branchData = branchList.data;
    const sheetsData = sheetsResult.data;

    const { branches } = branchData;
    const { donSheets } = sheetsData;

    const returnData = branches.edges.map((edge) => {
      const { node } = edge;
      const branchCode = node.code;

      const donSheet = donSheets.edges.find(
        (donEdge) => donEdge.node.branch.code === branchCode
      );

      return {
        code: node.code,
        name: node.name,
        donSheet
      };
    });

    return returnData;
  };

  const processSKUs = (edgeNode) => {
    const { inventoryDate, branch } = edgeNode;

    return edgeNode.skus.edges.map((skuEdge) => {
      const { node } = skuEdge;
      const { details, data } = node;

      return {
        code: details.code,
        name: details.name,
        unitCount: details.unitCount,
        core: details.core ? "Core" : "Regular",
        oos: data.oos ? "Yes" : "No",
        suggestedOrder: data.suggestedOrder ? data.suggestedOrder : 0,
        branchCode: branch.code,
        branchName: branch.name,
        brandCode: details.brand.code,
        brandName: details.brand.name,
        categoryCode: details.category.code,
        categoryName: details.category.name,
        inventoryDate
      };
    });
  };

  let originalSKUs;
  let loadingSKUs = false;

  let branchSummaries = [];

  const sheetsSKUCache = new Map();

  const processBranchSummary = (node) => {
    return {
      branchCode: node.branch.code,
      branchName: node.branch.name,
      chainCode: node.branch.chain.code,
      chainName: node.branch.chain.name,
      lastUpdatedBy: `${node.latestCollaborator.name.firstName} ${node.latestCollaborator.name.lastName}`,
      totalSKUCount: node.meta.totalSKUCount,
      carriedSKUCount: node.meta.carriedSKUCount,
      availableCount: node.meta.availableCount,
      availablePercentage: (node.meta.availablePercentage * 100).toFixed(2),
      oosCount: node.meta.oosCount,
      oosPercentage: (node.meta.oosPercentage * 100).toFixed(2),
      notCarriedCount: node.meta.notCarriedCount,
      notCarriedPercentage: (node.meta.notCarriedPercentage * 100).toFixed(2),
      inventoryDate: node.inventoryDate
    };
  };

  const branchSelected = (event) => {
    const { branch } = { ...event.detail };

    skus = [];
    loadingSKUs = true;

    if (!branch.donSheet) {
      currentSheetCode = null;

      loadingSKUs = false;
    } else {
      // currentSheetCode = branch.donSheet.node.code;
      currentBranch = branch;
      const cacheKey = `${branch}-${dateFormatToServer(currentStartDay)}`;
      const cachedSKUs = sheetsSKUCache.get(cacheKey);

      if (cachedSKUs) {
        skus = cachedSKUs;
        originalSKUs = skus;
        loadingSKUs = false;
      } else {
        branchSheetsOp = query(client, {
          query: SHEETS_META,
          variables: {
            findDONSheetInput: {
              branch: {
                code: currentBranch.code
              },
              fromInventoryDate: dateFormatToServer(currentStartDay),
              toInventoryDate: dateFormatToServer(currentEndDay)
            }
          }
        });

        $branchSheetsOp.then((response) => {
          const { data } = response;
          const { donSheets } = data;
          const { edges } = donSheets;
          originalSKUs = [];
          skus = [];
          edges.forEach((edge) => {
            skus = [...skus, ...processSKUs(edge.node)];
            branchSummaries = [
              ...branchSummaries,
              processBranchSummary(edge.node)
            ];
          });

          // skus = processBranc(response.data);
          originalSKUs = skus;
          loadingSKUs = false;
          sheetsSKUCache.set(cacheKey, [...skus]);
        });
      }
    }
  };

  let searchSKUCode;
  let searchSKUName;

  $: if (searchSKUCode || searchSKUName) {
    if (searchSKUCode) {
      skus = skus.filter(
        (sku) =>
          searchSKUCode !== "" &&
          sku.code.toUpperCase().indexOf(searchSKUCode.toUpperCase()) > -1
      );
    }

    if (searchSKUName) {
      skus = skus.filter(
        (sku) =>
          searchSKUName !== "" &&
          sku.name.toUpperCase().indexOf(searchSKUName.toUpperCase()) > -1
      );
    }
  } else if (searchSKUCode === "" && searchSKUName === "") {
    skus = originalSKUs;
  }

  const previousWeek = () => {
    currentSheetCode = "";
    skus = [];
    currentStartDay = currentStartDay.subtract(daysToIncrement, "day");
    currentEndDay = currentEndDay.subtract(daysToIncrement, "day");
  };

  const nextWeek = () => {
    currentSheetCode = "";
    skus = [];
    currentStartDay = currentStartDay.add(daysToIncrement, "day");
    currentEndDay = currentEndDay.add(daysToIncrement, "day");
  };

  $: sheetsOp.refetch({
    findDONSheetInput: {
      fromInventoryDate: dateFormatToServer(currentStartDay),
      toInventoryDate: dateFormatToServer(currentEndDay)
    }
  });

  //   let isCardView = true;

  //   const toggleView = () => {
  //     isCardView = !isCardView;
  //   };

  const exportCurrentSheet = () => {
    const cols = [
      `"OOS"`,
      `"SKU Code"`,
      `"SKU Name"`,
      `"UOM"`,
      `"Core"`,
      `"Brand Code"`,
      `"Brand Name"`,
      `"Category Code"`,
      `"Category Name"`,
      `"Branch Code"`,
      `"Branch Name"`
    ]
      .join(",")
      .concat("\n");

    const rows = skus
      .map(
        (node) =>
          `"${node.oos}","${node.code}","${node.name}","${node.unitCount}","${node.core}","${node.brandCode}","${node.brandName}","${node.categoryCode}","${node.categoryName}","${node.branchCode}","${node.branchName}"`
      )
      .join("\r\n");
    // `"${node.code}","${node.name}","${node.unitCount}","${node.weekSupply}","${node.totalBeginningUnits}","${node.offtakeUnits}","${node.suggestedOrderUnits}","${node.deliveryContainers}","${node.deliveryUnits}","${node.totalDeliveryUnits}","${node.endingContainers}","${node.endingUnits}","${node.totalEndingUnits}","${node.badOrderContainers}","${node.badOrderUnits}","${node.totalBadOrderUnits}","${node.transferOutContainers}","${node.transferOutUnits}","${node.totalTransferOutUnits}","${node.transferInContainers}","${node.transferInUnits}","${node.totalTransferInUnits}","${node.adjustmentContainers}","${node.adjustmentUnits}","${node.totalAdjustmentUnits}","${node.branchCode}","${node.branchName}"`
    // const csvContent = csvHeader + cols + rows;

    // const encodedUri = encodeURI(csvContent);
    // window.open(encodedUri);
    const csvContent = cols + rows;

    const reportBlob = new Blob([csvContent], { type: "text/csv" });

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    const url = window.URL.createObjectURL(reportBlob);
    a.href = url;
    a.download = `${currentSheetCode}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  let exportAllLoading = false;

  const exportAll = () => {
    exportAllLoading = true;
    allSheetsOp = query(client, {
      query: SHEETS_META,
      variables: {
        findDONSheetInput: {
          fromInventoryDate: dateFormatToServer(currentStartDay),
          toInventoryDate: dateFormatToServer(currentEndDay)
        }
      }
    });

    $allSheetsOp
      .then((response) => {
        let allSheets = [];
        let allSummaries = [];

        const { data } = response;
        const { donSheets } = data;
        const { edges } = donSheets;

        edges.forEach((branchSheet) => {
          allSheets = [...allSheets, processSKUs(branchSheet.node)];
        });

        edges.forEach((branchSheet) => {
          allSummaries = [
            ...allSummaries,
            processBranchSummary(branchSheet.node)
          ];
        });

        if (allSheets.length === 0) {
          exportAllLoading = false;
          toastNotifier.danger(
            "No Sheets Found!",
            `There are no EDGAR Sheets in this coverage`,
            7000
          );
        } else {
          const cols = [
            `"Branch Code"`,
            `"Branch Name"`,
            `"OOS"`,
            `"SKU Code"`,
            `"SKU Name"`,
            `"UOM"`,
            `"Core"`,
            `"Brand Code"`,
            `"Brand Name"`,
            `"Category Code"`,
            `"Category Name"`,
            `"Inventory Date"`
          ]
            .join(",")
            .concat("\n");

          const flattenedSheets = allSheets.flat();

          const rows = flattenedSheets
            .map(
              (node) =>
                `"${node.branchCode}","${node.branchName}","${node.oos}","${node.code}","${node.name}","${node.unitCount}","${node.core}","${node.brandCode}","${node.brandName}","${node.categoryCode}","${node.categoryName}","${node.inventoryDate}"`
            )
            .join("\r\n");

          exportToCSV(
            cols,
            rows,
            `DON Sheets for ${dateFormatToServer(
              currentStartDay
            )} to ${dateFormatToServer(currentEndDay)}`
          );

          toastNotifier.success(
            "Success!",
            `DON Sheets Export successful, waiting for download.`,
            7000
          );

          const summaryCols = [
            `"Branch Code"`,
            `"Branch Name"`,
            `"Chain Code"`,
            `"Chain Name"`,
            `"Last Updated By"`,
            `"Total SKU Count"`,
            `"Carried SKU Count"`,
            `"Available Count"`,
            `"Available %"`,
            `"OOS Count"`,
            `"OOS %"`,
            `"Not Carried Count"`,
            `"Not Carried %"`,
            `"Date"`
          ]
            .join(",")
            .concat("\n");

          const flattenedSummaries = allSummaries.flat();

          const summaryRows = flattenedSummaries
            .map(
              (node) =>
                `"${node.branchCode}","${node.branchName}","${node.chainCode}","${node.chainName}","${node.lastUpdatedBy}","${node.totalSKUCount}","${node.carriedSKUCount}","${node.availableCount}","${node.availablePercentage}","${node.oosCount}","${node.oosPercentage}","${node.notCarriedCount}","${node.notCarriedPercentage}","${node.inventoryDate}"`
            )
            .join("\r\n");

          exportToCSV(
            summaryCols,
            summaryRows,
            `DON Summaries for ${dateFormatToServer(
              currentStartDay
            )} to ${dateFormatToServer(currentEndDay)}`
          );

          exportAllLoading = false;

          toastNotifier.success(
            "Success!",
            `DON Summaries Export successful, waiting for download.`,
            7000
          );
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        toastNotifier.danger(
          "Something went wrong!",
          `Export failed, please contact the administrator`,
          7000
        );
      });
  };
</script>

<div class="m-0 pb-3 text-base flex flex-row items-center justify-center">
  <div class="w-2/12">
    <Button
      text="Export All"
      on:click={exportAll}
      color="secondary"
      loading={exportAllLoading} />
  </div>
  <div class="justify-center w-8/12 flex flex-row">
    <div>
      <EmptyButton
        icon="arrowLeft"
        text=""
        size="tiny"
        on:click={previousWeek} />
    </div>
    <div class="w-56 text-center">
      {#if !isDaily}
        {dateFormatToUI(currentStartDay.toDate())} to {dateFormatToUI(currentEndDay.toDate())}
      {:else}{dateFormatToUI(currentStartDay.toDate())}{/if}
    </div>
    <div>
      <EmptyButton icon="arrowRight" text="" size="tiny" on:click={nextWeek} />
    </div>
  </div>
  <div
    class="w-1/12 flex flex-row justify-end"
    class:justify-between={currentSheetCode !== '' && !loadingSKUs}>
    <!-- <Button
      text={isCardView ? 'List View' : 'Card View'}
      on:click={toggleView} /> -->
    {#if currentSheetCode !== '' && !loadingSKUs}
      <Button color="secondary" text="Export" on:click={exportCurrentSheet} />
    {/if}
  </div>
</div>

<div
  class="flex flex-row w-full md:-mx-2 justify-between"
  style="min-height: 945px; max-height: 945px; height: 945px;">
  <div class="w-3/12 lg:3/12 px-2 h-full">
    {#await $sheetsOp}
      <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
        <div class="pt-10" />
        <LoadingContent />
      </div>
    {:then sheetsResult}
      {#if sheetsResult.loading}
        <div class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
        </div>
      {:else}
        {#await $branchesOp}
          <div
            class="w-full h-full pt-10 px-4 border border-darkerGray rounded ">
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
            <div class="pt-10" />
            <LoadingContent />
          </div>
        {:then branchesResult}
          <BranchList
            branches={processBranches(sheetsResult, branchesResult)}
            hideChart={true}
            on:branchSelected={branchSelected} />
        {/await}
      {/if}

    {/await}
  </div>
  <div class="w-9/12 lg:4/12 pt-2 px-2 h-full border-lightGray rounded border">
    {#if currentSheetCode}
      <div class="w-full flex flex-row pb-4 px-2 h-24">
        <div class="flex flex-col justify-between w-1/2">
          <div class="flex flex-col font-bold">
            <div class="text-sm">{currentSheetCode}</div>
            <div class="text-xs">
              {currentBranch.code} - {currentBranch.name}
            </div>
          </div>
        </div>
        <div class="flex-row flex w-1/2" />
      </div>
    {/if}

    <MetaView sheets={branchSummaries} {loadingSKUs} />
    <div class="pt-1" />
    <TableView {skus} {loadingSKUs} />

    {#if !currentBranch || branchSummaries.length === 0}
      <div class="h-full w-full flex flex-col items-center justify-center">
        <Icon size="l" type="table" />
        <div class="pt-2">
          {#if !currentBranch}
            Choose an EDGAR Sheet on the left.
          {:else}No Sheet created yet for this branch.{/if}
        </div>
      </div>
    {/if}
  </div>
</div>
