<script>
  import { createEventDispatcher } from "svelte";
  import {
    TextField,
    Button,
    EmptyButton,
    SelectField,
    toastNotifier
  } from "@saztrek/firefly";

  import DeactivateModal from "./DeactivateModal.svelte";

  export let mode;
  export let cancelAction;

  export let code;
  export let name;

  export let accounts;

  export let version;

  export let buttonLabel;

  export let loading = false;

  export let accountCode;

  const dispatch = createEventDispatcher();
  let listOfAccounts;

  listOfAccounts = accounts.map((edge) => {
    const { node } = edge;
    return {
      option: `${node.code} - ${node.name}`,
      value: node.code
    };
  });

  listOfAccounts.unshift({ option: "Choose Account", value: null });

  const requiredFieldsFilled = () => {
    return !!(code && name && accountCode);
  };

  const submitForm = () => {
    if (requiredFieldsFilled()) {
      if (mode === "add") {
        dispatch("validChain", {
          code: code.trim(),
          name: name.trim(),
          accountCode: accountCode.trim()
        });
      } else {
        dispatch("validChain", {
          code: code.trim(),
          name: name.trim(),
          version,
          accountCode: accountCode.trim()
        });
      }
    } else {
      toastNotifier.warning(
        "Required fields.",
        "Please fill in required fields",
        7000
      );
    }
  };

  const inactivateChain = () => {
    dispatch("inactivateChain", { code });
  };

  const resolveReadOnly = () =>
    mode === "edit" ? { readonly: true } : undefined;
</script>

{#if mode === 'edit'}
  <div class="w-full text-right pr-12">
    <DeactivateModal chainCode={code} confirmEvent={inactivateChain} />
  </div>
{/if}
<form on:submit|preventDefault={submitForm}>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={code}
      {...resolveReadOnly()}
      placeholder="Code..."
      type="text"
      label="Code" />
  </div>
  <div class="p-4 w-full">
    <TextField
      bind:textValue={name}
      placeholder="Name..."
      type="text"
      label="Name" />
  </div>
  <div class="p-4 w-full">
    <SelectField
      bind:value={accountCode}
      options={listOfAccounts}
      label="Manufacturer" />
  </div>
  {#if mode === 'edit'}
    <input type="hidden" bind:value={version} />
  {/if}
  <div class="w-full flex flex-row-reverse justify-evenly pb-2 mt-8 px-4">
    <Button text={buttonLabel} type="submit" color="primary" {loading} />
    <div class="w-25" />
    <div class="text-center">
      <EmptyButton text="Cancel" color="text" on:click={cancelAction} />
    </div>
  </div>
</form>
