<script>
  import {
    SelectField,
    Button,
    Spinner,
    Icon,
    EmptyButton,
    LoadingContent
  } from "@saztrek/firefly";

  import { getClient, query } from "svelte-apollo";

  import { BRANCHES, EDGAR_SHEET_SUMMARIES } from "../../../../gql";

  import {
    dateFormatToUI,
    dateFormatToServer
  } from "../../../../utilities/dateFormatter";

  import { currentTenant } from "../../../../stores/current_tenant";

  import {
    nearestEndDay,
    nearestStartDay,
    getNearestEndDay,
    getNearestStartDay
  } from "../edgarUtils";

  const client = getClient();

  let sheetSummaryType = "WEEKLY";

  const summaryTypeOptions = [
    { option: "Weekly", value: "WEEKLY" },
    { option: "Weekdays", value: "WEEKDAYS" },
    { option: "Weekend", value: "WEEKEND" },
    { option: "Monthly", value: "MONTHLY" }
  ];

  //   if ($currentTenant.tenantVariables) {
  //     console.log("HERE@");
  //   }
  //   console.log("HERE1", $currentTenant.tenantVariables);

  let currentStartDay = nearestStartDay;
  let currentEndDay = nearestEndDay;

  $: if ($currentTenant.tenantVariables) {
    currentStartDay = getNearestStartDay();
    currentEndDay = getNearestEndDay();
  }

  const branchesOp = query(client, {
    query: BRANCHES
  });

  let branchCode = null;
  let loading = false;
  let isNewPage = true;

  const processBranches = (branchesData) => {
    const branchOptions = branchesData.branches.edges.map((branch) => {
      const { node } = branch;

      return {
        option: node.name,
        value: node.code
      };
    });

    branchOptions.unshift({ option: "All Branches", value: null });
    return branchOptions;
  };

  let sheetSummaryOp;

  let skuRows = [];
  let headers = [];
  let branchSummary = [];

  const generateHeaders = () => {
    const generateDays = () => {
      return [
        dateFormatToServer(currentStartDay.toDate()),
        dateFormatToServer(currentStartDay.add(1, "day").toDate()),
        dateFormatToServer(currentStartDay.add(2, "day").toDate()),
        dateFormatToServer(currentStartDay.add(3, "day").toDate()),
        dateFormatToServer(currentStartDay.add(4, "day").toDate()),
        dateFormatToServer(currentStartDay.add(5, "day").toDate()),
        dateFormatToServer(currentStartDay.add(6, "day").toDate())
      ];
    };

    headers = [
      "SKU Name",
      "SKU Code",
      "Branch Code",
      "Branch Name",

      "Begin Inventory",
      ...generateDays(),
      "Total Deliveries",
      ...generateDays(),
      "Total Bad Orders",
      ...generateDays(),
      "Total STI",
      ...generateDays(),
      "Total STO",
      ...generateDays(),
      "Total Offtake?",
      "Average Offtake",
      ...generateDays(), // ending
      ...generateDays(), // oos
      "Days OOS"
    ];
  };

  const formatValue = (value) => {
    const isNumber = Number.isFinite(value);
    const isInteger = Number.isInteger(value);
    const isBoolean = typeof value === "boolean";

    if (isInteger) {
      return value;
    }

    if (isNumber && !isInteger) {
      return Number.parseFloat(value).toFixed(2);
    }

    if (isBoolean) {
      return value ? "1" : "0";
    }

    return value;
  };

  const extractArray = (sheetContextEntries, subObject, key) => {
    return sheetContextEntries.map((entry) => {
      if (entry[subObject]) {
        return formatValue(entry[subObject][key]);
      }
      return 0;
    });
  };

  const getBranchSummary = (branch, edgarSheetPairs) => {
    if (!edgarSheetPairs || edgarSheetPairs.length === 0) {
      return {
        branch,
        sheets: []
      };
    }

    return {
      branch,
      sheets: edgarSheetPairs.map((sheetPair) => {
        return {
          startDate: sheetPair.sheetContext.startDate,
          endDate: sheetPair.sheetContext.endDate,
          sheetCode: sheetPair.edgarSheet ? sheetPair.edgarSheet.code : null
        };
      })
    };
  };

  const processBranchEdges = (branchEdges) => {
    generateHeaders();

    branchSummary = [];

    branchEdges.forEach((branchEdge) => {
      const { node } = branchEdge;
      const { skus, branch, sheetContextEDGARSheetPairs } = node;

      branchSummary = [
        ...branchSummary,
        getBranchSummary(branch, sheetContextEDGARSheetPairs)
      ];

      const branchSKURows = skus.map((sku) => [
        sku.details.name,
        sku.details.code,
        branch.code,
        branch.name,
        sku.sheetContextEntries[0].meta
          ? sku.sheetContextEntries[0].meta.totalBeginningUnits
          : "N/A", // total beginning inventory
        ...extractArray(
          sku.sheetContextEntries,
          "computed",
          "totalDeliveryUnits"
        ),
        sku.summary.totalDeliveryUnits,
        ...extractArray(
          sku.sheetContextEntries,
          "computed",
          "totalBadOrderUnits"
        ),
        sku.summary.totalBadOrderUnits,
        ...extractArray(
          sku.sheetContextEntries,
          "computed",
          "totalTransferInUnits"
        ),
        sku.summary.totalTransferInUnits,
        ...extractArray(
          sku.sheetContextEntries,
          "computed",
          "totalTransferOutUnits"
        ),
        sku.summary.totalTransferOutUnits,
        ...extractArray(sku.sheetContextEntries, "computed", "offtakeUnits"),
        formatValue(sku.summary.totalOfftakeUnits),
        formatValue(sku.summary.averageOfftakeUnits),
        ...extractArray(
          sku.sheetContextEntries,
          "computed",
          "totalEndingUnits"
        ),
        ...extractArray(sku.sheetContextEntries, "summary", "oos"),
        sku.summary.totalOOS
      ]);

      skuRows = [...skuRows, ...branchSKURows];
    });
  };

  const generateReport = () => {
    isNewPage = false;
    loading = true;
    sheetSummaryOp = query(client, {
      query: EDGAR_SHEET_SUMMARIES,
      variables: {
        findEDGARSheetSummaryInput: {
          sheetSummaryContext: {
            startDate: dateFormatToServer(currentStartDay.toDate()),
            endDate: dateFormatToServer(currentEndDay.toDate()),
            sheetSummaryType
          },
          branch: branchCode !== null ? { code: branchCode } : undefined
        }
      }
    });
    skuRows = [];
    headers = [];
    $sheetSummaryOp.then((response) => {
      processBranchEdges(response.data.edgarSheetSummaries.edges);
      loading = false;
    });
  };

  const previousWeek = () => {
    currentEndDay = currentEndDay.subtract(7, "day");
    currentStartDay = currentStartDay.subtract(7, "day");
  };

  const nextWeek = () => {
    currentEndDay = currentEndDay.add(7, "day");
    currentStartDay = currentStartDay.add(7, "day");
  };

  const exportReport = () => {
    const cols = headers.join(",").concat("\n");
    const rows = skuRows.map((skuRow) => skuRow.join(",")).join("\n");

    const csvContent = cols + rows;

    const reportBlob = new Blob([csvContent], { type: "text/csv" });

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    const url = window.URL.createObjectURL(reportBlob);
    a.href = url;
    a.download = `EDGAR Summary - ${dateFormatToServer(
      currentStartDay
    )} to ${dateFormatToServer(currentEndDay)}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };
</script>

<div class="w-full h-full p-4 shadow rounded">

  <div
    class="w-full px-4 pt-2 pb-5 h-24 border rounded border-lightGray flex
    flex-row justify-center items-center">
    <div class="m-0 text-base flex flex-row items-center justify-center">
      <div>
        <EmptyButton
          icon="arrowLeft"
          text=""
          size="tiny"
          on:click={previousWeek} />
      </div>
      <div class="w-64 text-center">
        {dateFormatToUI(currentStartDay.toDate())} to {dateFormatToUI(currentEndDay.toDate())}
      </div>
      <div>
        <EmptyButton
          icon="arrowRight"
          text=""
          size="tiny"
          on:click={nextWeek} />
      </div>

    </div>
    <div class="px-4 h-16" style="min-width: 11rem;">
      {#await $branchesOp}
        <div class="p-4 w-20">
          <Spinner size="large" />
        </div>
      {:then branchesResult}
        <SelectField
          bind:value={branchCode}
          options={processBranches(branchesResult.data)}
          label="Branch" />
      {/await}
    </div>
    <div class="px-4 h-16" style="min-width: 11rem;">

      <SelectField
        bind:value={sheetSummaryType}
        options={summaryTypeOptions}
        label="Type" />

    </div>
    <div class="px-4 pt-6">
      <Button
        text="Generate"
        on:click={generateReport}
        color="primary"
        filled={true} />
    </div>
    {#if skuRows.length > 0}
      <div class="px-4 pt-6">
        <Button text="Export" on:click={exportReport} color="primary" />
      </div>
    {/if}
  </div>
  <div
    class="w-full pt-2 px-1 mt-2 flex flex-row overflow-x-auto"
    style="min-height: 800px; height: 800px; max-height: 800px;">
    {#if loading}
      <div class="h-full w-full flex flex-col justify-center">
        <div class="w-full">
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
          <LoadingContent />
          <div class="pt-10" />
        </div>
      </div>
    {:else if skuRows.length > 0}
      <div
        class="flex flex-row w-full justify-evenly"
        style="min-height: 780px; height: 780px; max-height: 780px;">
        <div class="w-3/12 pr-1 h-full">
          <div
            class="border rounded border-lightGray p-1 overflow-y-auto h-full">
            <div class="list pl-6 pr-4 h-full">
              {#each branchSummary as summary}
                <div
                  class="flex flex-row items-start justify-between py-2
                  border-b-darkerGray border-b w-full last:border-b-0">

                  <div class="flex flex-col justify-start w-full px-1">
                    <span class="text-primary text-sm w-full truncate">
                      {summary.branch.name}
                    </span>
                    <div class="text-xs italic">{summary.branch.code}</div>
                    {#each summary.sheets as sheet}
                      <div
                        class="flex flex-row text-xs font-mono w-full
                        justify-between">
                        <div class="w-4/12">
                          {dateFormatToUI(sheet.startDate)}
                        </div>
                        <div class="w-8/12">
                          {sheet.sheetCode ? sheet.sheetCode : 'No Sheet'}
                        </div>
                      </div>
                    {/each}
                  </div>
                </div>
              {/each}
            </div>
          </div>

        </div>
        <div class="border rounded border-lightGray px-2 w-9/12 overflow-auto">
          <table class="table-fixed pb-5">
            <thead>
              <tr class="text-xs">
                {#each headers as header, i}
                  <th
                    class="px-4 py-2"
                    style="background-clip: padding-box"
                    class:-left-10={i === 0}
                    class:px-32={i === 0}
                    class:sticky={i === 0}
                    class:left-0={i === 0}
                    class:bg-white={i === 0}>
                    {header}
                  </th>
                {/each}
              </tr>
            </thead>
            <tbody>
              {#each skuRows as skuRow, i}
                <tr class="text-xs" class:bg-primaryLighter={i % 2 === 1}>
                  {#each skuRow as row, j}
                    <td
                      class="border border-gray px-4 py-2"
                      style="background-clip: padding-box;"
                      class:-left-10={j === 0}
                      class:sticky={j === 0}
                      class:left-0={j === 0}
                      class:bg-white={j === 0 && i % 2 === 0}
                      class:bg-primaryTable={j === 0 && i % 2 === 1}
                      class:-mx-1={j === 0}>
                      {row}
                    </td>
                  {/each}
                </tr>
              {/each}
            </tbody>
          </table>
        </div>

      </div>
    {:else}
      <div class="h-full w-full flex flex-col items-center justify-center">
        <Icon size="l" type="table" />
        {#if isNewPage}
          <div class="pt-2">
            Please select a date range and press the Generate button.
          </div>
        {:else}
          <div class="pt-2">
            No Sheet Summary yet for this coverage or branch
          </div>
        {/if}
      </div>
    {/if}
  </div>
</div>
