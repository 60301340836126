<script>
  import { LoadingContent } from "@saztrek/firefly";

  export let loadingSKUs;
  export let skus;
  export let hideUnitsAndContainers = false;
</script>

{#if skus && skus.length > 0}
  <div
    class="overflow-auto flex justify-center w-full px-4 border
    border-darkerGray rounded "
    style="max-height: 780px; height: 780px;">
    <table class="table-fixed leading-5">
      <thead class="font-bold text-xs">
        <tr>
          <td class="px-1">Code</td>
          <td class="w-1/6">Name</td>
          <td class="text-right">Unit</td>
          <td class="px-1 text-right">Week Sply.</td>
          <td class="text-center">Offtake</td>
          <td class="pr-3 text-right">Rec.</td>
          <td class="px-3 text-center">Beginning</td>
          <td class="px-3 text-center">Delivery</td>
          <td class="px-3 text-center">Ending</td>
          <td class="px-3 text-center">Bad Order</td>
          <td class="px-3 text-center">Xfer Out</td>
          <td class="px-3 text-center">Xfer In</td>
          <td class="px-3 text-center">Adjustment</td>
        </tr>
      </thead>
      {#each skus as sku, i}
        <tr
          class="border-b-darkerGray border-b-2 text-xs"
          class:bg-primaryLighter={i % 2 === 1}>
          <td>{sku.code}</td>
          <td class="">{sku.name}</td>
          <td class="text-lg font-thin text-right">{sku.unitCount}</td>
          <td class="text-lg font-thin text-right">{sku.weekSupply}</td>
          <td class="px-2">
            <div class="flex flex-col">
              <div
                class="text-lg font-thin text-right"
                class:text-secondary={sku.offtakeUnits > 0}
                class:text-warning={sku.offtakeUnits === 0}
                class:text-danger={sku.offtakeUnits < 0}>
                {sku.offtakeUnits}
              </div>
            </div>
          </td>
          <td class="px-2">
            <div class="flex flex-col">
              <div
                class="text-lg font-thin text-right"
                class:text-secondary={sku.suggestedOrderUnits > 0}
                class:text-warning={sku.suggestedOrderUnits === 0}
                class:text-danger={sku.suggestedOrderUnits < 0}>
                {sku.suggestedOrderUnits}
              </div>
            </div>
          </td>
          <td class="w-25 px-2 whitespace-no-wrap">
            <div class="flex flex-row items-center w-full flex-grow px-3">
              <div
                class="w-2/3 text-lg font-thin"
                class:text-secondary={sku.totalBeginningUnits > 0}>
                {sku.totalBeginningUnits}
              </div>
              {#if !hideUnitsAndContainers}
                <div class="w-1/3 flex flex-col text-sm">
                  <div>C: {sku.beginningContainers}</div>
                  <div>U: {sku.beginningUnits}</div>
                </div>
              {/if}
            </div>
          </td>
          <td class="w-25 px-2 whitespace-no-wrap">
            <div class="flex flex-row items-center w-full flex-grow px-3">
              <div
                class="w-2/3 text-lg font-thin"
                class:text-secondary={sku.totalDeliveryUnits > 0}>
                {sku.totalDeliveryUnits}
              </div>
              {#if !hideUnitsAndContainers}
                <div class="w-1/3 flex flex-col text-sm">
                  <div>C: {sku.deliveryContainers}</div>
                  <div>U: {sku.deliveryUnits}</div>
                </div>
              {/if}
            </div>
          </td>
          <td class="w-25 px-2 whitespace-no-wrap">
            <div class="flex flex-row items-center w-full flex-grow px-3">
              <div
                class="w-2/3 text-lg font-thin"
                class:text-danger={sku.totalEndingUnits > 0}>
                {sku.totalEndingUnits}
              </div>
              {#if !hideUnitsAndContainers}
                <div class="w-1/3 flex flex-col text-sm">
                  <div>C: {sku.endingContainers}</div>
                  <div>U: {sku.endingUnits}</div>
                </div>
              {/if}
            </div>
          </td>
          <td class="w-25 px-2 whitespace-no-wrap">
            <div class="flex flex-row items-center w-full flex-grow px-3">
              <div
                class="w-2/3 text-lg font-thin"
                class:text-danger={sku.totalBadOrderUnits > 0}>
                {sku.totalBadOrderUnits}
              </div>
              {#if !hideUnitsAndContainers}
                <div class="w-1/3 flex flex-col text-sm">
                  <div>C: {sku.badOrderContainers}</div>
                  <div>U: {sku.badOrderUnits}</div>
                </div>
              {/if}
            </div>
          </td>
          <td class="w-25 px-2 whitespace-no-wrap">
            <div
              class="flex flex-row items-center w-full flex-grow px-3"
              class:text-danger={sku.totalTransferOutUnits > 0}>
              <div class="w-2/3 text-lg font-thin">
                {sku.totalTransferOutUnits}
              </div>
              {#if !hideUnitsAndContainers}
                <div class="w-1/3 flex flex-col text-sm">
                  <div>C: {sku.transferOutContainers}</div>
                  <div>U: {sku.transferOutUnits}</div>
                </div>
              {/if}
            </div>
          </td>
          <td class="w-25 px-2 whitespace-no-wrap">
            <div class="flex flex-row items-center w-full flex-grow px-3">
              <div
                class="w-2/3 text-lg font-thin"
                class:text-secondary={sku.totalTransferInUnits > 0}>
                {sku.totalTransferInUnits}
              </div>
              {#if !hideUnitsAndContainers}
                <div class="w-1/3 flex flex-col text-sm">
                  <div>C: {sku.transferInContainers}</div>
                  <div>U: {sku.transferInUnits}</div>
                </div>
              {/if}
            </div>
          </td>
          <td class="w-25 px-2 whitespace-no-wrap">
            <div
              class="flex flex-row items-center w-full flex-grow px-3"
              class:text-secondary={sku.totalAdjustmentUnits > 0}>
              <div class="w-2/3 text-lg font-thin">
                {sku.totalAdjustmentUnits}
              </div>
              {#if !hideUnitsAndContainers}
                <div class="w-1/3 flex flex-col text-sm">
                  <div>C: {sku.adjustmentContainers}</div>
                  <div>U: {sku.adjustmentUnits}</div>
                </div>
              {/if}
            </div>
          </td>
        </tr>
      {/each}
    </table>
  </div>
{:else if loadingSKUs}
  <div class="w-full pt-2 h-full px-4 ">
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
    <div class="pt-10" />
    <LoadingContent />
  </div>
{/if}
