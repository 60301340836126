<script>
  export let bayId;
  export let isCompletelyMapped = false;
  export let totalCategoryLength = 0;
  export let skus;
  export let expectedSKUs;
  let reportSKUs;

  const convertPercentageString = (num) => (num ? (num * 100).toFixed(2) : "");

  $: reportSKUs = expectedSKUs.map((expectedSKU) => {
    const ps3SKU = skus.find((sku) => sku.sku === expectedSKU.skuCode);
    let resolvedDetails = "Unmapped";
    let fontColor = "#BD271E";
    let percentage = "";
    if (ps3SKU) {
      resolvedDetails = `${ps3SKU.skuLength}cm`;
      fontColor = ps3SKU.strokeColor;
      percentage = ps3SKU.percentage;
    }

    return {
      skuCode: expectedSKU.skuCode,
      resolvedDetails,
      fontColor,
      percentage
    };
  });
</script>

<div class="w-full flex flex-row py-2">
  <div class="w-full shadow rounded">
    <div
      class="pb-2 h-full border-l-8 rounded"
      class:border-secondary={isCompletelyMapped}
      class:border-warning={!isCompletelyMapped}>
      <div class="border-b border-darkerGray" style="height: 3.5rem;">
        <div class="flex flex-row justify-between items-start py-2 px-4">
          <div class="flex flex-col items-start pr-3">
            <div class="text-xs font-bold italic">{bayId}</div>
            <div class="text-sm">{totalCategoryLength}cm</div>
          </div>

        </div>

      </div>
      <div class="h-full">
        <div
          class="flex flex-row pt-1 mx-3 pr-4 bg-white text-tableText
          justify-center">
          <table class="w-full table-auto text-xs">
            <tbody>
              {#each reportSKUs as reportSKU}
                <tr style={`color: ${reportSKU.fontColor}`}>
                  <td>{reportSKU.skuCode}</td>
                  <td>{reportSKU.resolvedDetails}</td>
                  <td>{convertPercentageString(reportSKU.percentage)}%</td>
                </tr>
              {/each}

            </tbody>

          </table>
        </div>
      </div>
    </div>
  </div>
</div>
