import { gql } from "apollo-boost";

// QUERIES

const BRANCH_ATTENDANCE_SHEETS = gql`
  query calendarBranchAttendanceSheets(
    $findCalendarBranchAttendanceSheetInput: FindCalendarBranchAttendanceSheetInput
    $connectionArgs: ConnectionArgs
  ) {
    calendarBranchAttendanceSheets(
      findCalendarBranchAttendanceSheetInput: $findCalendarBranchAttendanceSheetInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        cursor
        node {
          id
          code
          date
          branch {
            code
            name
          }
          employees {
            details {
              code
              name {
                firstName
                lastName
              }
            }
            meta {
              restDays
              stationary
            }
            data {
              leaveStatus
              restDay
              visits {
                code
                checkIn {
                  photoPath
                  latitude
                  longitude
                  timestamp
                }
                checkOut {
                  photoPath
                  latitude
                  longitude
                  timestamp
                }
              }
            }
          }
          policy {
            requiredHeadCount
            hasAbsencePenalty
          }
          version
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const BRANCH_ATTENDANCE_SHEETS_LITE = gql`
  query calendarBranchAttendanceSheets(
    $findCalendarBranchAttendanceSheetInput: FindCalendarBranchAttendanceSheetInput
    $connectionArgs: ConnectionArgs
  ) {
    calendarBranchAttendanceSheets(
      findCalendarBranchAttendanceSheetInput: $findCalendarBranchAttendanceSheetInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        cursor
        node {
          code
          date
          branch {
            code
            name
          }
          version
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const ADD_BRANCH_ATTENDANCE_SHEET = gql`
  mutation addCalendarBranchAttendanceSheet(
    $addCalendarBranchAttendanceSheetInput: AddCalendarBranchAttendanceSheetInput!
  ) {
    addCalendarBranchAttendanceSheet(
      addCalendarBranchAttendanceSheetInput: $addCalendarBranchAttendanceSheetInput
    ) {
      code
    }
  }
`;

const REBUILD_BRANCH_ATTENDANCE_SHEET = gql`
  mutation rebuildCalendarBranchAttendanceSheetEmployees(
    $rebuildCalendarBranchAttendanceSheetEmployeesInput: RebuildCalendarBranchAttendanceSheetEmployeesInput!
  ) {
    rebuildCalendarBranchAttendanceSheetEmployees(
      rebuildCalendarBranchAttendanceSheetEmployeesInput: $rebuildCalendarBranchAttendanceSheetEmployeesInput
    ) {
      code
    }
  }
`;

export {
  BRANCH_ATTENDANCE_SHEETS,
  BRANCH_ATTENDANCE_SHEETS_LITE,
  ADD_BRANCH_ATTENDANCE_SHEET,
  REBUILD_BRANCH_ATTENDANCE_SHEET
};
