<script>
  import dayjs from "dayjs";
  import {
    TextField,
    Tabs,
    TabList,
    TabPanel,
    Tab,
    Icon
  } from "@saztrek/firefly";

  import { dateFormatToUI } from "../../../utilities/dateFormatter";
  import { UploadJobs } from "../../../middleware/database/uploadJobs";
  import Panel from "../../components/Panel.svelte";
  import LoadingIndicator from "../../components/LargeLoadingIndicator.svelte";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  let jobId = id;

  let loading = true;
  let job;
  let failedJobs;
  let successfulJobs;
  let createdDate;
  let completedDate;

  $: if (loading) {
    UploadJobs.findOne(id).then((doc) => {
      job = doc.data();

      createdDate = `${dateFormatToUI(dayjs(job.createdOn.toDate()))} ${dayjs(
        job.createdOn.toDate()
      ).format("HH:mm:ss")}`;
      completedDate = job.completedOn
        ? `${dateFormatToUI(dayjs(job.completedOn.toDate()))} ${dayjs(
            job.completedOn.toDate()
          ).format("HH:mm:ss")}`
        : "";
      failedJobs = job.log.filter((jobItem) => jobItem.status === "Failed");
      successfulJobs = job.log.filter(
        (jobItem) => jobItem.status === "Successful"
      );

      loading = false;
    });
  }
</script>

<div class="w-full flex items-center flex-col justify-center pt-10 pb-6">
  <Panel header="Job Information">
    <div class="w-full px-10">
      {#if loading}
        <LoadingIndicator />
      {:else}
        <div class="w-full h-full flex flex-row pt-4 pb-8 justify-center">
          <div class="w-1/2 flex flex-col">
            <div>
              <TextField disabled bind:textValue={jobId} label="ID" />
            </div>
            <div class="pt-8">
              <TextField
                disabled
                bind:textValue={createdDate}
                label="Created On" />
            </div>
            <div class="pt-8">
              <TextField
                disabled
                bind:textValue={job.expectedRecords}
                label="Expected Records" />
            </div>
            <div class="pt-8">
              <TextField
                disabled
                bind:textValue={job.successfulRecords}
                label="Successful Records" />
            </div>

          </div>
          <div class="w-1/2 flex flex-col">
            <div>
              <TextField disabled bind:textValue={job.type} label="Type" />
            </div>
            <div class="pt-8">
              <TextField
                disabled
                bind:textValue={completedDate}
                label="Completed On" />
            </div>
            <div class="pt-8">
              <TextField
                disabled
                bind:textValue={job.processedRecords}
                label="Processed Records" />
            </div>
            <div class="pt-8">
              <TextField
                disabled
                bind:textValue={job.failedRecords}
                label="Failed Records" />
            </div>
          </div>

        </div>
        <div class="w-full px-4">
          <Tabs>
            <TabList>
              <Tab>Failed</Tab>
              <Tab>Successful</Tab>
            </TabList>
            <TabPanel>
              <div class="w-full pb-4 px-4 border-darkerGray rounded border">
                {#if failedJobs.length > 0}
                  <table class="table-fixed text-sm w-full">
                    <thead>
                      <tr>
                        <th class="w-1/2">Key</th>
                        <th class="w-1/2">Error</th>
                      </tr>
                    </thead>
                    <tbody>
                      {#each failedJobs as failedJob}
                        <tr class="py-2">
                          <td class="border-darkerGray border-b">
                            {failedJob.email}
                          </td>
                          <td class="border-darkerGray border-b">
                            {failedJob.error}
                          </td>
                        </tr>
                      {/each}
                    </tbody>
                  </table>
                {:else}
                  <div
                    class="w-full h-full flex flex-col items-center
                    justify-center pt-10">
                    <Icon size="l" type="table" />
                    <div class="pt-2">No Failed Imports</div>
                  </div>
                {/if}
              </div>

            </TabPanel>
            <TabPanel>
              <div class="w-full pb-4 px-4 border-darkerGray rounded border">
                {#if successfulJobs.length > 0}
                  <table class="table-fixed text-sm w-full">
                    <thead>
                      <tr>
                        <th class="w-1/2">Key</th>
                      </tr>
                    </thead>
                    <tbody>
                      {#each successfulJobs as successfulJob}
                        <tr class="py-2">
                          <td class="border-darkerGray border-b">
                            {successfulJob.email}
                          </td>
                        </tr>
                      {/each}
                    </tbody>
                  </table>
                {:else}
                  <div
                    class="w-full h-full flex flex-col items-center
                    justify-center pt-10">
                    <Icon size="l" type="table" />
                    <div class="pt-2">No Successful Imports</div>
                  </div>
                {/if}
              </div>

            </TabPanel>
          </Tabs>

        </div>
      {/if}
    </div>
  </Panel>
</div>
