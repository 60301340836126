<script>
  import { createEventDispatcher } from "svelte";
  import {
    TextField,
    Button,
    EmptyButton,
    toastNotifier,
    SelectField,
    CheckboxGroup
  } from "@saztrek/firefly";

  import { TENANT_VARIABLES, FEATURE_FLAGS } from "./tenantKeys";

  export let mode;
  export let cancelAction;

  export let code;
  export let description;
  export let tenantVariables = [];
  export let featureFlags = [];

  export let version;

  export let buttonLabel;

  export let loading = false;

  const dispatch = createEventDispatcher();

  const buildTenantVariableObject = (tenantVars) => {
    const hasTenantVars = tenantVars.length > 0;
    return {
      mobileLoginExpiryMinutes: hasTenantVars
        ? tenantVars.find(
            (variable) =>
              TENANT_VARIABLES.MOBILE_LOGIN_EXPIRY_MINUTES === variable.code
          ).value
        : "",
      complianceSheetContextLimit: hasTenantVars
        ? tenantVariables.find(
            (variable) =>
              TENANT_VARIABLES.EDGAR_COORDINATOR_COMPLIANCE_LIMIT ===
              variable.code
          ).value
        : "",
      defaultInputPercentageThreshold: hasTenantVars
        ? tenantVariables.find(
            (variable) =>
              TENANT_VARIABLES.EDGAR_DEFAULT_INPUT_PERCENTAGE_THRESHOLD ===
              variable.code
          ).value
        : "1.0",
      weekSupplyDefault: hasTenantVars
        ? tenantVariables.find(
            (variable) =>
              TENANT_VARIABLES.EDGAR_WEEK_SUPPLY_DEFAULT === variable.code
          ).value
        : "",
      edgarStartDay: hasTenantVars
        ? tenantVariables.find(
            (variable) => TENANT_VARIABLES.EDGAR_START_DAY === variable.code
          ).value
        : "0"
    };
  };

  const tenantVariablesObject = buildTenantVariableObject(tenantVariables);

  let featureFlagToggles = featureFlags
    .filter((flag) => flag.value)
    .map((flag) => flag.code);

  const daysOptions = [
    { option: "Sunday", value: "0" },
    { option: "Monday", value: "1" },
    { option: "Tuesday", value: "2" },
    { option: "Wednesday", value: "3" },
    { option: "Thursday", value: "4" },
    { option: "Friday", value: "5" },
    { option: "Saturday", value: "6" }
  ];
  const featureFlagOptions = [
    {
      option: "Display Derived Value in Containers",
      value: FEATURE_FLAGS.EDGAR_DISPLAY_DERIVED_VALUE_IN_CONTAINERS
    },
    {
      option: "Use Beginning as Ending for Generated Sheet",
      value: FEATURE_FLAGS.EDGAR_USE_BEGINNING_AS_ENDING_FOR_GENERATED_SHEET
    },
    {
      option: "Is OOS Considered on offtake?",
      value: FEATURE_FLAGS.EDGAR_IS_OOS_OFFTAKE
    },
    {
      option: "Default Stock Status on Add",
      value: FEATURE_FLAGS.DON_MOBILE_IS_DEFAULT_IN_STOCK
    },
    {
      option: "Is Daily EDGAR Submission?",
      value: FEATURE_FLAGS.EDGAR_IS_DAILY
    },
    {
      option: "Show EDGAR Containers in Mobile?",
      value: FEATURE_FLAGS.EDGAR_MOBILE_SHOW_CONTAINERS
    },
    {
      option: "Show EDGAR Units in Mobile?",
      value: FEATURE_FLAGS.EDGAR_MOBILE_SHOW_UNITS
    },
    {
      option: "Show EDGAR Bad Order Containers in Mobile?",
      value: FEATURE_FLAGS.EDGAR_MOBILE_SHOW_BAD_ORDER_CONTAINERS
    },
    {
      option: "Show EDGAR Bad Order Units in Mobile?",
      value: FEATURE_FLAGS.EDGAR_MOBILE_SHOW_BAD_ORDER_UNITS
    },
    {
      option: "Hide EDGAR Adjustment Containers in Mobile?",
      value: FEATURE_FLAGS.EDGAR_MOBILE_HIDE_ADJUSTMENT_CONTAINERS
    },
    {
      option: "Hide EDGAR Adjustment Units in Mobile?",
      value: FEATURE_FLAGS.EDGAR_MOBILE_HIDE_ADJUSTMENT_UNITS
    },
    {
      option: "Skip EDGAR Negative Validation in Mobile?",
      value: FEATURE_FLAGS.EDGAR_MOBILE_SKIP_NEGATIVE_VALIDATION
    },
    {
      option: "Use Offtake as Input",
      value: FEATURE_FLAGS.EDGAR_MOBILE_INPUT_OFFTAKE
    }
  ];

  const buildTenantVariables = () => {
    return [
      {
        code: TENANT_VARIABLES.MOBILE_LOGIN_EXPIRY_MINUTES,
        value: `${tenantVariablesObject.mobileLoginExpiryMinutes}`
      },
      {
        code: TENANT_VARIABLES.EDGAR_COORDINATOR_COMPLIANCE_LIMIT,
        value: `${tenantVariablesObject.complianceSheetContextLimit}`
      },
      {
        code: TENANT_VARIABLES.EDGAR_DEFAULT_INPUT_PERCENTAGE_THRESHOLD,
        value: tenantVariablesObject.defaultInputPercentageThreshold
      },
      {
        code: TENANT_VARIABLES.EDGAR_WEEK_SUPPLY_DEFAULT,
        value: `${tenantVariablesObject.weekSupplyDefault}`
      },
      {
        code: TENANT_VARIABLES.EDGAR_START_DAY,
        value: tenantVariablesObject.edgarStartDay
      }
    ];
  };

  const requiredFieldsFilled = () => {
    return !!(code && description);
  };

  const submitForm = () => {
    if (requiredFieldsFilled()) {
      if (mode === "add") {
        dispatch("validTenantConfig", {
          code: code.trim(),
          description: description.trim(),
          tenantVariables: buildTenantVariables(),
          featureFlags: featureFlagOptions.map((flag) => {
            return {
              code: flag.value,
              value:
                featureFlagToggles.find((toggle) => toggle === flag.value) !==
                undefined
            };
          })
        });
      } else {
        dispatch("validTenantConfig", {
          code: code.trim(),
          description: description.trim(),
          tenantVariables: buildTenantVariables(),
          featureFlags: featureFlagOptions.map((flag) => {
            return {
              code: flag.value,
              value:
                featureFlagToggles.find((toggle) => toggle === flag.value) !==
                undefined
            };
          }),
          version
        });
      }
    } else {
      toastNotifier.warning(
        "Required fields.",
        "Please fill in required fields",
        7000
      );
    }
  };

  const resolveReadOnly = () =>
    mode === "edit" ? { readonly: true } : undefined;
</script>

<form on:submit|preventDefault={submitForm}>
  <div class="flex flex-row">
    <div class="w-1/2">
      <div class="p-6 w-full">
        <TextField
          bind:textValue={code}
          {...resolveReadOnly()}
          placeholder="Code..."
          type="text"
          required="true"
          label="Code" />
      </div>
      <div class="p-6 w-full">
        <TextField
          bind:textValue={description}
          placeholder="Description..."
          type="text"
          required="true"
          label="Description" />
      </div>
      <div class="p-6 w-full">
        <TextField
          bind:textValue={tenantVariablesObject.mobileLoginExpiryMinutes}
          placeholder="Minutes..."
          type="number"
          required="true"
          label="Mobile Expiry (Minutes)" />
      </div>
      <div class="p-6 w-full">
        <TextField
          bind:textValue={tenantVariablesObject.complianceSheetContextLimit}
          placeholder="Limit..."
          type="number"
          required="true"
          label="# of Sheets to display on EDGAR Coor Compliance" />
      </div>
      <div class="p-6 w-full">
        <TextField
          bind:textValue={tenantVariablesObject.defaultInputPercentageThreshold}
          placeholder="Threshold..."
          type="text"
          required="true"
          label="Default Compliance Threshold" />
      </div>
      <div class="p-6 w-full">
        <TextField
          bind:textValue={tenantVariablesObject.weekSupplyDefault}
          placeholder="Week Supply..."
          type="number"
          required="true"
          label="Week Supply Default" />
      </div>
      <div class="p-6 w-full">
        <SelectField
          options={daysOptions}
          bind:value={tenantVariablesObject.edgarStartDay}
          label="Start Day for EDGAR" />
      </div>

    </div>
    <div class="w-1/2">
      <div class="p-6 w-full">
        <CheckboxGroup
          label=""
          options={featureFlagOptions}
          bind:selectedValues={featureFlagToggles} />
      </div>
    </div>
  </div>
  {#if mode === 'edit'}
    <input type="hidden" bind:value={version} />
  {/if}
  <div class="w-full flex flex-row-reverse justify-evenly pb-2 mt-8 px-4">
    <Button text={buttonLabel} color="primary" {loading} type="submit" />
    <div class="w-25" />
    <div class="text-center">
      <EmptyButton text="Cancel" color="text" on:click={cancelAction} />
    </div>
  </div>
</form>
