<script>
  import { PS3Db } from "../../../middleware/database/ps3";

  import { currentUser } from "../../../stores/current_user";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  let loading = true;

  let ps3Sheet;

  let publishedSheets = [];

  let ps3RawSheetsDB;

  $: if (loading && $currentUser.tenantId) {
    if (!ps3RawSheetsDB) {
      ps3RawSheetsDB = PS3Db($currentUser.tenantId);
      ps3RawSheetsDB.findOne(id).then((doc) => {
        ps3Sheet = doc.data();
        publishedSheets = ps3Sheet.publishedSheet;
        loading = false;
      });
    }
  }
</script>

{#if !loading}
  <div class="w-full h-full">
    {#each publishedSheets as publishedSheet}
      <div>
        <img src={publishedSheet.imageURL} alt={publishedSheet.id} />
      </div>
    {/each}
  </div>
{/if}
