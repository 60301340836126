<script>
  import { Table, Link } from "@saztrek/firefly";
  import { navigateTo } from "svelte-router-spa";
  import { createEventDispatcher } from "svelte";

  export let edges;
  const columns = [
    {
      field: "id",
      name: "ID",
      hidden: true
    },
    {
      field: "username",
      name: "Username",
      renderComponent: Link,
      resolveProps: (data) => {
        return {
          href: `edit/${data.id}`
        };
      },
      truncate: true
    },
    {
      field: "firstName",
      name: "First Name",
      dataType: "string"
    },
    {
      field: "lastName",
      name: "Last Name",
      dataType: "string"
    },
    {
      field: "active",
      name: "Active",
      dataType: "boolean"
    }
  ];

  const dispatch = createEventDispatcher();

  const actions = [
    {
      icon: "pencil",
      title: "Edit",
      action: (data) => {
        navigateTo(`users/edit/${data.id}`);
      }
    },
    {
      icon: "exit",
      title: "Assign",
      action: (data) => {
        navigateTo(`users/branchAssignments/${data.id}`);
      }
    },
    {
      icon: "trash",
      title: "Inactivate",
      action: (data) => {
        dispatch("inactivateUser", { code: data.id });
      }
    }
  ];
</script>

<div>
  <div class="pt-3 pl-3 pr-3 pb-8">
    <Table {columns} items={edges} {actions} />
  </div>
</div>
