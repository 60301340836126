<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, query, mutate } from "svelte-apollo";

  import { TENANT_CONFIGURATION, EDIT_TENANT_CONFIG } from "../../../gql";

  import Panel from "../../components/Panel.svelte";
  import TenantConfigForm from "./TenantConfigForm.svelte";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  const cancelUpdate = () => {
    navigateTo("/tenantconfigs");
  };

  const client = getClient();

  let loading = false;

  const tenantConfigOp = query(client, {
    query: TENANT_CONFIGURATION,
    variables: {
      code: decodeURIComponent(id)
    }
  });

  const editTenantConfig = async (event) => {
    try {
      const tenantConfigData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: EDIT_TENANT_CONFIG,
        variables: {
          editTenantConfigurationInput: {
            code: tenantConfigData.code,
            description: tenantConfigData.description,
            tenantVariableInput: tenantConfigData.tenantVariables,
            featureFlagInput: tenantConfigData.featureFlags,
            version: tenantConfigData.version
          }
        }
      });

      toastNotifier.success(
        "Success!",
        `Account ${tenantConfigData.name} Updated`,
        7000
      );
      navigateTo("/tenantconfigs");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Update Tenant Config">
    {#await $tenantConfigOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      <TenantConfigForm
        mode="edit"
        code={result.data.tenantConfiguration.code}
        description={result.data.tenantConfiguration.description}
        version={result.data.tenantConfiguration.version}
        tenantVariables={result.data.tenantConfiguration.tenantVariables}
        featureFlags={result.data.tenantConfiguration.featureFlags}
        on:validTenantConfig={editTenantConfig}
        cancelAction={cancelUpdate}
        {loading}
        buttonLabel="Update" />
    {/await}

  </Panel>
</div>
