<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier } from "@saztrek/firefly";
  import { getClient, mutate, query } from "svelte-apollo";
  import { Functions } from "../../../config/firebase";
  import { Users } from "../../../middleware/database/users";

  import { currentUser } from "../../../stores/current_user";
  import {
    EDIT_EMPLOYEE,
    GET_EMPLOYEE,
    ASSIGN_TO_DON,
    ASSIGN_TO_EDGAR,
    ASSIGN_TO_CALENDAR,
    UNASSIGN_FROM_CALENDAR,
    SET_CALENDAR_EMPLOYEE_CONTEXT
  } from "../../../gql";

  import LoadingIndicator from "../../components/LargeLoadingIndicator.svelte";
  import Panel from "../../components/Panel.svelte";
  import UserForm from "./UserForm.svelte";

  export let currentRoute;

  export let params;

  /* svelte router force feeds these parameters (see above).
     Console warns if we don't do the exports.
     Svelte warns if they are unused, therefore putting them down here, in case code needs it.
  */
  // eslint-disable-next-line no-unused-vars
  const paramsFromRouter = params;

  const { id } = currentRoute.namedParams;

  const cancelEdit = () => {
    navigateTo("/users/list");
  };

  const client = getClient();

  let loading = true;
  let savingStatus = false;
  let user;

  const employeeOp = query(client, {
    query: GET_EMPLOYEE,
    variables: {
      code: decodeURIComponent(id)
    }
  });

  $: if (loading) {
    Users.findOne(id).then((doc) => {
      user = doc.data();

      user.id = doc.id;
      loading = false;
    });
  }

  const editUserHandler = async (event) => {
    const userData = { ...event.detail };

    const updateUser = Functions.httpsCallable("updateUser");

    savingStatus = true;
    try {
      await mutate(client, {
        mutation: EDIT_EMPLOYEE,
        variables: {
          editEmployeeInput: {
            code: `${userData.user.tenantId}_${userData.user.email}`,
            name: {
              firstName: userData.user.firstName,
              middleName: userData.user.middleName,
              lastName: userData.user.lastName,
              suffix: userData.user.suffix
            },
            employeeNumber: userData.user.employeeNumber,
            roleGroup: userData.user.roleGroup,
            supervisorCode: userData.user.supervisorCode,
            version: userData.user.version
          }
        }
      });

      updateUser(userData.user).then(() => {
        savingStatus = false;
        toastNotifier.success("Success!", `User ${user.email} updated`, 7000);
        navigateTo("/users/list");
      });

      if (userData.user.assignedDonBranches.length > 0) {
        mutate(client, {
          mutation: ASSIGN_TO_DON,
          variables: {
            setDONEmployeeAssignmentInput: {
              employeeCode: `${userData.user.tenantId}_${userData.user.email}`,
              branchCodes: userData.user.assignedDonBranches.map(
                (assignedDonBranch) => assignedDonBranch.code
              )
            }
          }
        });
      }

      if (userData.user.assignedEdgarBranches.length > 0) {
        mutate(client, {
          mutation: ASSIGN_TO_EDGAR,
          variables: {
            setEDGAREmployeeAssignmentInput: {
              employeeCode: `${userData.user.tenantId}_${userData.user.email}`,
              branchCodes: userData.user.assignedEdgarBranches.map(
                (assignedEdgarBranch) => assignedEdgarBranch.code
              )
            }
          }
        });
      }

      userData.user.assignedCalendarBranches.forEach((calendarBranch) => {
        mutate(client, {
          mutation: ASSIGN_TO_CALENDAR,
          variables: {
            branchCode: calendarBranch.code,
            employeeCodes: [`${userData.user.tenantId}_${userData.user.email}`]
          }
        });
      });

      userData.user.unassignedCalendarBranches.forEach(
        (unassignedCalendarBranch) => {
          mutate(client, {
            mutation: UNASSIGN_FROM_CALENDAR,
            variables: {
              branchCode: unassignedCalendarBranch.code,
              employeeCodes: [
                `${userData.user.tenantId}_${userData.user.email}`
              ]
            }
          });
        }
      );

      // TODO: Add support for stationary
      mutate(client, {
        mutation: SET_CALENDAR_EMPLOYEE_CONTEXT,
        variables: {
          setCalendarEmployeeContextInput: {
            employeeCode: `${userData.user.tenantId}_${userData.user.email}`,
            restDays: userData.user.restDays ? userData.user.restDays : [],
            stationary: false
          }
        }
      });
    } catch (error) {
      savingStatus = false;
      toastNotifier.danger("Failed to update!", `${error}`, 7000);
    }
  };

  const stitchEmployee = (employeeData) => {
    const { supervisor, employeeNumber, roleGroup, version } = employeeData;
    const { middleName, suffix } = employeeData.name;

    return {
      ...user,
      middleName,
      suffix,
      employeeNumber,
      roleGroup,
      supervisorCode: supervisor ? supervisor.code : "",
      version,
      assignedDonBranches: [],
      assignedEdgarBranches: [],
      assignedCalendarBranches: [],
      unassignedCalendarBranches: []
    };
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Update User">
    <div class="w-full px-10">
      {#if loading}
        <LoadingIndicator />
      {:else}
        {#await $employeeOp}
          <LoadingIndicator />
        {:then employeeData}
          <UserForm
            mode="edit"
            on:validUser={editUserHandler}
            cancelAction={cancelEdit}
            user={stitchEmployee(employeeData.data.employee)}
            userRolesLoading={loading}
            {savingStatus}
            superAdmin={$currentUser.superAdmin}
            buttonLabel="Update" />
        {/await}
      {/if}
    </div>
  </Panel>
</div>
