<script>
  import { navigateTo } from "svelte-router-spa";
  import { Link } from "@saztrek/firefly";
  import { TENANTS } from "../../../gql";

  import CRUDPage from "../../components/CRUDPage.svelte";

  const columns = [
    {
      field: "code",
      name: "Code",
      renderComponent: Link,
      resolveProps: (data) => {
        return {
          href: `tenantconfigs/edit/${data.code}`
        };
      }
    },

    {
      field: "description",
      name: "Description",
      dataType: "string"
    }
  ];

  const goToAddTenant = () => {
    navigateTo("tenantconfigs/new");
  };

  const processResponse = (response) => {
    const processEdges = (edges) => {
      return edges.map((edge) => {
        const { node } = edge;
        return {
          code: node.code,
          description: node.description
        };
      });
    };

    const { data } = response;
    const { tenantConfigurations } = data;
    const { edges } = tenantConfigurations;

    return {
      items: processEdges(edges),
      currentData: tenantConfigurations
    };
  };
</script>

<div class="w-full px-10">
  <CRUDPage
    gqlQuery={TENANTS}
    {columns}
    {processResponse}
    goToAdd={goToAddTenant} />
</div>
