const COMPLIANCE_STRINGS = {
  BRANCH_CODE: "Branch Code",
  BRANCH_NAME: "Branch Name",
  CHAIN_CODE: "Chain Code",
  CHAIN_NAME: "Chain Name",
  MERCH_NAME: "Merchandiser Name",
  COMPLETE: "Complete?",
  NO_DELIVERY: "SKUs with No Delivery",
  NO_ENDING: "SKUs with No Ending",
  WITH_ADJUSTMENT: "SKUs with Adjustment",
  INPUT_PERCENTAGE: "Input Percentage",
  COMPLIANT: "Compliant",
  COMPLIANCE_THRESHOLD: "Compliance Threshold",
  LAST_UPDATED_BY: "Last Updated By"
};

module.exports = { COMPLIANCE_STRINGS };
