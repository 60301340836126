import { gql } from "apollo-boost";

const TENANT_CONFIGURATION = gql`
  query tenantConfiguration($code: String!) {
    tenantConfiguration(code: $code) {
      id
      code
      description
      tenantVariables {
        code
        description
        value
      }
      featureFlags {
        code
        description
        value
      }
      version
    }
  }
`;

const TENANTS = gql`
  query tenantConfigurations(
    $findTenantConfigurationInput: FindTenantConfigurationInput
    $connectionArgs: ConnectionArgs
  ) {
    tenantConfigurations(
      findTenantConfigurationInput: $findTenantConfigurationInput
      connectionArgs: $connectionArgs
    ) {
      edges {
        cursor
        node {
          id
          code
          description
          tenantVariables {
            code
            description
            value
          }
          featureFlags {
            code
            description
            value
          }
          version
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const ADD_TENANT_CONFIG = gql`
  mutation addTenantConfiguration(
    $addTenantConfigurationInput: AddTenantConfigurationInput!
  ) {
    addTenantConfiguration(
      addTenantConfigurationInput: $addTenantConfigurationInput
    ) {
      id
      code
      description
      tenantVariables {
        code
        description
        value
      }
      featureFlags {
        code
        description
        value
      }
      version
    }
  }
`;

const EDIT_TENANT_CONFIG = gql`
  mutation editTenantConfiguration(
    $editTenantConfigurationInput: EditTenantConfigurationInput!
  ) {
    editTenantConfiguration(
      editTenantConfigurationInput: $editTenantConfigurationInput
    ) {
      id
      code
      description
      tenantVariables {
        code
        description
        value
      }
      featureFlags {
        code
        description
        value
      }
      version
    }
  }
`;

export { TENANT_CONFIGURATION, TENANTS, EDIT_TENANT_CONFIG, ADD_TENANT_CONFIG };
