<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, query, mutate } from "svelte-apollo";

  import {
    EDIT_BRAND,
    GET_BRAND,
    INACTIVATE_BRAND,
    MANUFACTURERS
  } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import BrandForm from "./BrandForm.svelte";

  export let currentRoute;

  const { id } = currentRoute.namedParams;

  const cancelUpdate = () => {
    navigateTo("brands");
  };

  const client = getClient();

  let loading = false;

  const brandOp = query(client, {
    query: GET_BRAND,
    variables: {
      code: decodeURIComponent(id)
    }
  });

  const manufacturersOp = query(client, {
    query: MANUFACTURERS
  });

  const inactivateBrand = async (event) => {
    const brandData = { ...event.detail };
    loading = true;
    try {
      await mutate(client, {
        mutation: INACTIVATE_BRAND,
        variables: {
          code: brandData.code
        }
      });
      toastNotifier.success(
        "Success!",
        `Brand ${brandData.code} has been deactivated`,
        7000
      );
      navigateTo("brands");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };

  const editBrand = async (event) => {
    try {
      const brandData = { ...event.detail };
      loading = true;

      await mutate(client, {
        mutation: EDIT_BRAND,
        variables: {
          editBrandInput: {
            code: brandData.code,
            name: brandData.name,
            version: brandData.version,
            manufacturerCode: brandData.manufacturerCode
          }
        }
      });
      toastNotifier.success(
        "Success!",
        `Brand ${brandData.name} Updated`,
        7000
      );
      navigateTo("brands");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to update.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Update Brand">
    {#await $brandOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then result}
      {#await $manufacturersOp}
        <div class="p-10 w-full" style="min-height: 500px">
          <LoadingContent />
        </div>
      {:then manufacturersResult}
        <BrandForm
          mode="edit"
          manufacturers={manufacturersResult.data.manufacturers.edges}
          code={result.data.brand.code}
          name={result.data.brand.name}
          manufacturerCode={result.data.brand.manufacturer.code}
          version={result.data.brand.version}
          on:validBrand={editBrand}
          on:inactivateBrand={inactivateBrand}
          cancelAction={cancelUpdate}
          {loading}
          buttonLabel="Update" />
      {/await}
    {/await}

  </Panel>
</div>
