import { FirebasePS3 } from "./index";

const PS3Db = (tenantID) => {
  const ps3SheetsDB = FirebasePS3.doc(tenantID).collection("rawSheets");

  const update = ({
    sheetCode,
    processedSheet,
    publishedSheet,
    universeLength
  }) => {
    if (processedSheet) {
      return ps3SheetsDB
        .doc(sheetCode)
        .update({ processedSheet, universeLength });
    }
    return ps3SheetsDB
      .doc(sheetCode)
      .update({ publishedSheet, published: true });
  };

  const findOne = (sheetCode) => {
    return ps3SheetsDB.doc(sheetCode).get();
  };

  const findAllIncomplete = () => {
    // TODO: Find for alternative ways to make this better.

    let query = ps3SheetsDB;

    // query = ps3SheetsDB.where("complete", "==", false);

    return query;
  };

  const findAllPublished = () => {
    return ps3SheetsDB.where("published", "==", true);
  };

  return Object.freeze({
    update,
    findOne,
    findAllIncomplete,
    findAllPublished
  });
};

export { PS3Db };
