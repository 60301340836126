<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier } from "@saztrek/firefly";
  import { getClient, mutate } from "svelte-apollo";

  import { ADD_ACCOUNT } from "../../../../gql";

  import Panel from "../../../components/Panel.svelte";
  import AccountForm from "./AccountForm.svelte";

  const cancelAdd = () => {
    navigateTo("accounts");
  };

  const client = getClient();

  let loading = false;

  const addAccount = async (event) => {
    try {
      const accountData = { ...event.detail };
      loading = true;
      await mutate(client, {
        mutation: ADD_ACCOUNT,
        variables: {
          addAccountInput: {
            code: accountData.code,
            name: accountData.name
          }
        }
      });
      toastNotifier.success(
        "Success!",
        `Account ${accountData.name} Added`,
        7000
      );
      navigateTo("accounts");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to add.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add Account">
    <AccountForm
      mode="add"
      on:validAccount={addAccount}
      cancelAction={cancelAdd}
      {loading}
      buttonLabel="Add" />
  </Panel>
</div>
