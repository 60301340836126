<script>
  import { navigateTo } from "svelte-router-spa";
  import { toastNotifier, LoadingContent } from "@saztrek/firefly";
  import { getClient, mutate, query } from "svelte-apollo";

  import { ADD_SKU, BRANDS, CATEGORIES } from "../../../gql";

  import Panel from "../../components/Panel.svelte";
  import SKUForm from "./SKUForm.svelte";

  const cancelAdd = () => {
    navigateTo("/skus/list");
  };

  const client = getClient();

  // TODO: Make this into a lookup field so we don't get everything

  const brandsOp = query(client, {
    query: BRANDS
  });

  const categoriesOp = query(client, {
    query: CATEGORIES
  });

  let loading = false;

  const addSKU = async (event) => {
    try {
      const skuData = { ...event.detail };
      loading = true;
      await mutate(client, {
        mutation: ADD_SKU,
        variables: {
          addSKUInput: {
            code: skuData.code,
            name: skuData.name,
            unitCount: skuData.unitCount,
            brandCode: skuData.brandCode,
            categoryCode: skuData.categoryCode,
            core: skuData.core
          }
        }
      });
      toastNotifier.success("Success!", `SKU ${skuData.name} Added`, 7000);
      navigateTo("skus");
    } catch (error) {
      loading = false;
      toastNotifier.danger("Failed to add.", `${error}`, 7000);
      // eslint-disable-next-line no-console
      console.error("cannot mutate", error.message);
    }
  };
</script>

<div class="w-full flex items-center flex-col justify-center pt-10">
  <Panel header="Add SKU">
    {#await $brandsOp}
      <div class="p-10 w-full" style="min-height: 500px">
        <LoadingContent />
      </div>
    {:then brandsResult}
      {#await $categoriesOp}
        <div class="p-10 w-full" style="min-height: 500px">
          <LoadingContent />
        </div>
      {:then categoriesResult}
        <SKUForm
          mode="add"
          on:validSKU={addSKU}
          cancelAction={cancelAdd}
          brands={brandsResult.data.brands.edges}
          categories={categoriesResult.data.categories.edges}
          {loading}
          buttonLabel="Add" />
      {/await}
    {/await}
  </Panel>
</div>
